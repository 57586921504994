import moment from "moment";
import * as functions from "../../../CMS/functions";

// L'ordre des produits peut être différent de la date de publication
// On peut publier un produit dans 2 jours et changer l'ordre d'affichage des produits
// Il faut bien que cela soit 2 choses différentes
export function initialize(data, boutique) {
  const objet = functions.initialize(data);
  return {
    ...objet,
    boutique: boutique.id,
    name: ""
  };
}

/**
 * Cette méthode permet de mettre en capital la chaine de caractère s
 * @param s
 * @returns {string}
 */
function capitalize(s) {
  if (typeof s !== "string") return "";
  return s.charAt(0).toUpperCase() + s.slice(1).toLowerCase();
}

export function validate(contact) {
  contact.updatedAt = moment().toISOString();
  contact.email = contact.email.trim().toLowerCase();
  if (contact.firstname && typeof contact.firstname === "string") {
    contact.firstname = capitalize(contact.firstname.trim());
  }
  if (contact.lastname && typeof contact.lastname === "string") {
    contact.lastname = capitalize(contact.lastname.trim());
  }

  if (contact.commercialName && typeof contact.commercialName === "string") {
    contact.commercialName = contact.commercialName
      .trim()
      .split(" ")
      .map(capitalize)
      .join(" ");
  }

  // TODO revoir ce traitement car il efface le name si il est renseigné par le client
  if (contact.firstname) {
    if (contact.lastname) {
      contact.name = `${contact.firstname} ${contact.lastname}`;
    } else {
      contact.name = contact.firstname;
    }
    // if (contact.commercialName) {
    //   console.info(
    //     "commercialName",
    //     contact.commercialName
    //       .trim()
    //       .toLowerCase()
    //       .substr(0, 1)
    //   );
    //   if (
    //     ["a", "e", "i", "o", "u", "y"].includes(
    //       contact.commercialName
    //         .trim()
    //         .toLowerCase()
    //         .substr(0, 1)
    //     )
    //   ) {
    //     contact.name += " d'" + contact.commercialName;
    //   } else {
    //     contact.name += " de " + contact.commercialName;
    //   }
    // }
  } else {
    if (contact.lastname) {
      contact.name = contact.lastname;

      // if (contact.commercialName) {
      //   console.info(
      //     "commercialName",
      //     contact.commercialName
      //       .trim()
      //       .toLowerCase()
      //       .substr(0, 1)
      //   );
      //   if (
      //     ["a", "e", "i", "o", "u", "y"].includes(
      //       contact.commercialName
      //         .trim()
      //         .toLowerCase()
      //         .substr(0, 1)
      //     )
      //   ) {
      //     contact.name += " d'" + contact.commercialName.trim();
      //   } else {
      //     contact.name += " de " + contact.commercialName.trim();
      //   }
      // }
    } else {
      if (contact.commercialName) {
        contact.name = contact.commercialName;
      } else {
        if (contact.email) {
          contact.name = contact.email;
        } else {
          if (contact.phone) {
            contact.name = contact.phone.trim();
          }
        }
      }
    }
  }

  return contact;
}
