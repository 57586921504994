import styled from 'styled-components'

export default styled.ul`
    list-style: none;
    margin: 0;
    padding: 0;
    
    > li {
        cursor: pointer;
        display: flex;
        border: 1px solid #e4e7ed;
        padding: 0.375rem;
        background: #fbfbfc;
        margin-bottom: 0.375rem;
        
        &:not(:last-child) {
          // border-bottom: 0;
        }
        
        div, ul {
          display: block;
          align-items: center;
        }
        
        .numero {
            display: inline-block;
            //background: #dfe4e8;
            color: #576775;
            //color: #dfe4e8;
            //border-radius: 10px;
            line-height: 20px;
            height: 20px;
            padding: 0 10px;
            font-size: 11px;
            font-style: italic;
        }
        
        .date {
          background: #e4e7ed;
          text-align: center;
          padding: 0.375rem;
          border-radius: 4px;
          height: 50px;
          width: 50px;
          box-sizing: border-box;
          color: hsla(0,0%,0%,0.7) !important;
          
          .day {
            font-size: 18px;
            line-height: 22px;
            display: block;
          }
          .month {
            font-size: 12px;
            display: block;
          }
        }
        
        .demande {
          flex: 1;
          padding: 0 1.5rem;
          
          .produits {
            font-size: 95%;
            
            li {
              margin: 0;
              padding: 0;
            }
          }
        }
        
        .devis {
          flex: 1;
          padding: 0 1.5rem;
          
          .client {
            display: block;
          }
          
          .produits {
            font-size: 90%;
          }
        }
        
        .production {
          background: #4b71fc;
          border-radius: 50%;
          height: 40px;
          width: 40px;
          text-align: center;
          line-height: 40px;
          margin-right: 1.5rem;
          margin-top: 5px;
          font-size: 12px;
          color: #fff;
        }
        
        .total {
          text-align: right;
          
          .montant {
            font-weight: bold;
          }
          
          .paiement {
            display: block;
            font-size: 75%;
            font-style: italic;
          }
        }
        .status {
          margin: 0;
          padding: 0;
          list-style: none; 
          text-align: right;
          // width: calc(50px + 0.75rem);
                 
          li {
            display: inline-block;
            //background: #dfe4e8;
            color: #576775;
            line-height: 75px;
            vertical-align: bottom;
            height: 50px;
            font-size: 11px;
            width: 50px;
            box-sizing: border-box;
            text-align: center;
            white-space: nowrap;
            overflow: hidden;
            
            &.is-completed {
                background: #65c96d;
                color: #fff;
            }
            
            &.devis,
            &.solde,
            &.commande,
            &.expedition,
            &.delivery,
            &.fabrication {
              border-bottom: 5px solid #dfe4e8;
              background: none;
              //color: #576775;
              color: #dfe4e8;
              transform: rotate(-90deg);
            }
            
            &.delivery {
              border-color: #00AEEF;
              color: #00AEEF;
            }
            &.fabrication {
              border-color: #E3C369;
              color: #E3C369;
            }
            &.solde {
              border-color: red;
              color: red;
            }
          }
        }
        
        .finish {
          border-right: 5px solid #65c96d;
          margin-left: 1.5rem;
          height: 50px;
          width: 0 !important;
        }
        
        &.isValidated {
          .paiement {
            &:before {
                //content: "\\01F44C"; 
                content: "\\2611"; 
                margin-right: 0.375rem;
                font-size: 15px;
            }
          }
        }
        
        &.cent {
          //color: #65c96d;
        }
        &.zero {
          color: #dfe4e8;
        }
    }
`;