import React, { Component } from "react";
import PropTypes from "prop-types";
import StyledRow from "../../../../../CMS/styles/View/List/Row";

class Row extends Component {
  render() {
    const { doc, onClick } = this.props;
    const item = doc.data();

    return (
      <StyledRow>
        <p className="name" style={{ flex: 1 }}>
          {item.name}
        </p>
        <p className="control actions">
          <button onClick={onClick} className="edit small">
            <span className="icon" />
            <span className="txt">Edit</span>
          </button>
        </p>
      </StyledRow>
    );
  }
}

Row.propTypes = {
  doc: PropTypes.object.isRequired
};

export default Row;
