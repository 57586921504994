import { getFirestore } from "../../Boutique/functions";

class Collection {
  /**
   * Recherche une campagne par l'intermédiaire de l'id
   *
   * @param boutique
   * @param name
   * @param id
   * @returns {Promise<unknown>}
   */
  static findById(boutique, name, id) {
    if (!id) {
      throw new Error("L'identifiant est obligatoire");
    }
    return getFirestore(boutique)
      .collection(name)
      .doc(id)
      .get()
      .then(documentSnapshot => {
        if (!documentSnapshot.exists) {
          throw new Error(
            `Le document de la collection ${name} qui a pour id "${id}" n'existe pas`
          );
        }

        if (documentSnapshot.get("boutique") !== boutique.id) {
          throw new Error("Ce document n'appartient pas à cette boutique");
        }

        if (documentSnapshot.get("deleted")) {
          throw new Error("Ce document a été supprimé");
        }

        return documentSnapshot;
      });
  }
}

export default Collection;
