import moment from "moment";
import * as functions from "../../../CMS/functions";

// L'ordre des produits peut être différent de la date de publication
// On peut publier un produit dans 2 jours et changer l'ordre d'affichage des produits
// Il faut bien que cela soit 2 choses différentes
export function initialize(data, boutique) {
  const objet = functions.initialize(data);
  return {
    ...objet,
    isDraft: true,
    boutique: boutique.id,
    datetime: parseInt(moment().format("x")), // Pour l'ordre des produits
    publishedAt: moment().format("YYYY-MM-DDTHH:mm"), // Pour connaitre la date de publication
    isOnlineStore: true,
    isFeatured: false,
    optionPrice: boutique.data().optionPriceByDefault
      ? boutique.data().optionPriceByDefault
      : "",
    freeShipping: boutique.data().freeShippingByDefault
      ? boutique.data().freeShippingByDefault
      : false,
    deliveryIncluded: boutique.data().deliveryIncludedByDefault
      ? boutique.data().deliveryIncludedByDefault
      : false,
    updatedAt: "",
    sku: "",
    pathname: ""
  };
}

export function validate(modele) {
  modele.updatedAt = moment().toISOString();
  modele.datetime = modele.datetime
    ? parseInt(modele.datetime, 10)
    : parseInt(moment().format("x"));
  modele.deliveryIncluded = Boolean(modele.deliveryIncluded);
  modele.freeShipping = Boolean(modele.freeShipping);
  modele.isOnlineStore = Boolean(modele.isOnlineStore);
  if (modele.name && modele.pathname && modele.photos) {
    modele.isDraft = false;
  }

  return modele;
}
