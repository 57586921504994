import styled from "styled-components";
import times from "../../../../../images/font-awesome/solid/times.svg";

export default styled.li`
  width: 100%;
  display: flex;
  background: #fff;
  border: 1px solid #e4e7ed;
  margin-bottom: 0;
  margin-left: 0;

  &:not(:first-child) {
    border-top: none;
  }
  ul {
    list-style: none;
    margin: 0.75rem !important;
    border-left: 1px solid #e4e7ed;
    padding: 0 0 0 0.75rem;
    font-size: 85%;
    flex: 1;

    li {
      margin: 0.1875rem 1.5rem 0.1875rem 0;
      float: left;
      display: block;
      background: #f6f6f6;
      padding: 0 0.75rem;
      line-height: 24px;
      max-height: 24px;
      overflow: hidden;
      border-radius: 5px;

      label {
        display: inline-block;
        font-style: italic;
        &:after {
          content: " :";
          margin-right: 0.75rem;
        }
      }

      span {
        font-weight: bold;
      }
    }
  }
  > span,
  > p {
    line-height: 50px;
    margin: 0 0 0 0.75rem;
    font-size: 13px;
  }

  .name {
    line-height: 50px;
    margin: 0 0 0 0.75rem;
    font-size: 13px;
    width: 20%;
  }

  .date {
    background: #e4e7ed;
    text-align: center;
    padding: 0.375rem;
    border-radius: 4px;
    height: 40px;
    width: 40px;
    min-width: 40px;
    box-sizing: border-box;
    color: hsla(0, 0%, 0%, 0.7) !important;
    margin: 5px;

    span {
      margin: 0;
    }

    .day {
      font-size: 16px;
      line-height: 18px;
      display: block;
      font-weight: bold;
    }
    .month {
      font-size: 10px;
      display: block;
      line-height: 15px;
    }
  }

  .price {
    &:after {
      content: " €";
    }
  }

  .actions {
    flex: 1;
    text-align: right;
  }

  button {
    height: 20px;
    margin: 0.375rem;

    .text {
      display: none;
    }
    .icon {
      display: block;
      height: 20px;
      width: 20px;
      background-color: #86848c;
      mask-image: url(${times});
      mask-repeat: no-repeat;
      mask-position: center;
    }
  }
`;
