import firebase from "./firebase";
import "firebase/auth";

class User {
  static login(email, password) {
    console.log("login");
    return firebase.auth().signInWithEmailAndPassword(email, password);
  }

  static logout() {
    return firebase.auth().signOut();
  }

  static get() {
    return firebase.auth();
  }

  static update(user) {
    const currentUser = firebase.auth().currentUser;
    return currentUser.updateProfile({
      displayName: user.displayName,
      phoneNumber: user.phoneNumber
    });
  }
}

export default User;
