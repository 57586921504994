import { IS_MOBILE, IS_WEBAPP } from "./actionTypes";

export function isMobile(value) {
  return {
    type: IS_MOBILE,
    test: value
  };
}

export function isWebapp(value) {
  return {
    type: IS_WEBAPP,
    test: value
  };
}
