import moment from "moment";
import * as functions from "../../../CMS/functions";

// L'ordre des produits peut être différent de la date de publication
// On peut publier un produit dans 2 jours et changer l'ordre d'affichage des produits
// Il faut bien que cela soit 2 choses différentes
export function initialize(data, boutique) {
  const objet = functions.initialize(data);
  return {
    ...objet,
    boutique: boutique.id,
    billingDate: moment().format("YYYY-MM-DD")
  };
}

export function validate(item) {
  item.updatedAt = moment().toISOString();

  return item;
}
