import React, { Component } from "react";
import StyledModal from "../../styles/Modal";
import Button from "../Button/index";

class Modal extends Component {
  componentDidMount() {
    document.documentElement.classList.toggle("is-clipped");
  }

  componentWillUnmount() {
    document.documentElement.classList.remove("is-clipped");
  }

  render() {
    const { title, handleClose, buttons, children } = this.props;
    return (
      <StyledModal>
        <div className="dialog">
          <div className="dialog__header">
            <h3>{title}</h3>
            <button onClick={handleClose} className="dialog--header--close">
              <span className="text">Fermer</span>
            </button>
          </div>
          <div className="dialog__content">{children}</div>
          {buttons &&
            buttons.length > 0 && (
              <div className="dialog__footer">
                <ul>
                  {buttons.map((button, index) => (
                    <li key={index}>
                      <Button {...button} />
                    </li>
                  ))}
                </ul>
              </div>
            )}
        </div>
      </StyledModal>
    );
  }
}

export default Modal;
