import React from "react";
import Section from "../Data/Section";

export default ({ title, addText, handleAdd, children }) => (
  <Section
    title={title}
    button={{
      text: addText,
      onClick: handleAdd
    }}
    group={true}
  >
    {children}
  </Section>
);
