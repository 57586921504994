import React from "react";
import StyledField from "../styles/Field";

export default ({ label, options, noDefaultOption, className, ...others }) => {
  let classNames = ["select"];
  if (className) classNames = classNames.concat(className.split(" "));

  return (
    <StyledField className={[...new Set(classNames)].join(" ")}>
      <select {...others}>
        {!noDefaultOption && <option value="">Non spécifié</option>}
        {options &&
          options.map((option, index) => (
            <option key={index} value={option.value}>
              {option.label}
            </option>
          ))}
      </select>
      <label className="label">
        <span>{label}</span>
      </label>
    </StyledField>
  );
};
