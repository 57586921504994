import React from "react";
import { connect } from "react-redux";
import { formValueSelector } from "redux-form";

import Data from "../../../../../../CMS/components/Data/index";
import acompte from "../../../params/acompte.json";

const Acompte = props => {
  return (
    <div className="boutique__demande__component--acompte">
      {props.advancePayment && <Data data={acompte} />}
    </div>
  );
};

const selector = formValueSelector("item");

export default connect((state, props) => {
  return {
    advancePayment:
      selector(state, "validation") &&
      selector(state, "validation") === "advancePayment"
  };
})(Acompte);
