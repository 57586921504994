import React, { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { FieldArray } from "redux-form";
import Produit from "./Produits/Produit";
import Input from "../../../../../CMS/components/Input";
import Select from "../../../../../CMS/components/Select";
import Textarea from "../../../../../CMS/components/Textarea";
import Modal from "../../../../../CMS/components/View/Modal";
import Section from "../../../../../CMS/components/Data/Section";
import StyledTabs from "../../../../../CMS/styles/Tabs";
import StyledColumns from "../../../../../CMS/components/Data/Columns";
import StyledColumn from "../../../../../CMS/styles/Column";
import Title from "../../../../../CMS/styles/Title";
import { search as searchProduits } from "../../../Produit/actions";

const ListStyled = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;

  li.produit {
    width: 100%;
    display: flex;
    background: #fff;
    border: 1px solid #e4e7ed;
    align-items: flex-start;
    padding: 1.5rem;
    box-sizing: border-box;
    margin-bottom: 0.75rem;

    .infos {
      flex: 1;

      .tarif {
        .remise {
          font-style: italic;
          font-size: 12px;
        }
        p.prix {
          margin: 0.375rem 0;
        }
        span {
          display: inline-block;
        }
        span + span {
          margin-left: 0.75rem;
        }
      }
    }
  }
`;

const initProduit = () => {
  return {
    name: "",
    sku: "",
    dimensions: "",
    width: "",
    depth: "",
    height: "",
    qty: "",
    price: "",
    discount: "",
    discountType: "",
    description: "",
    aFabriquer: false
  };
};

class Produits extends Component {
  static contextTypes = {
    store: PropTypes.object
  };

  tabs = [
    {
      name: "Détails basics"
    },
    {
      name: "Photos"
    }
  ];

  constructor(props) {
    super(props);

    this.state = {
      produit: null,
      tab: 0,
      index: -1,
      q: ""
    };

    this.handleNew = this.handleNew.bind(this);
    this.handleAdd = this.handleAdd.bind(this);
    this.handleChange = this.handleChange.bind(this);

    this.handleSetTab = this.handleSetTab.bind(this);
    this.handleChangeField = this.handleChangeField.bind(this);
  }

  handleSearch = e => {
    e.preventDefault();

    this.context.store
      .dispatch(searchProduits(this.props.boutique, this.state.q))
      .then(action => {
        // console.log(action);
      });
  };

  handleChangeSearch = e => {
    this.setState({
      q: e.currentTarget.value
    });
  };

  handleNew = () => {
    this.setState({
      produit: initProduit(),
      index: -1
    });
  };

  handleAdd = e => {
    e.preventDefault();
    this.props.fields.push(this.state.produit);
    this.setState({
      produit: null,
      index: -1
    });
  };

  handleEdit = (e, idx) => {
    this.setState({
      produit: this.props.fields.get(idx),
      index: idx
    });
  };

  handleChange = e => {
    e.preventDefault();
    this.props.fields.push(this.state.produit);
    this.props.fields.swap(this.state.index, this.props.fields.length);
    this.props.fields.pop();
    this.setState({
      produit: null,
      index: -1
    });
  };

  handleSetTab = event => {
    const tab = event.currentTarget.getAttribute("data-index");
    this.setState({
      tab: parseInt(tab, 10)
    });
  };

  handleChangeField = event => {
    const value =
      event.currentTarget.type === "checkbox"
        ? event.currentTarget.checked
        : event.currentTarget.value;
    const name = event.currentTarget.name;
    this.setState(oldState => {
      oldState.produit[name] = value;
      return {
        produit: oldState.produit
      };
    });
  };

  handleCloseModal = e => {
    e.preventDefault();

    this.setState({
      index: -1,
      produit: null
    });
  };

  render() {
    const { fields } = this.props;

    const buttons = [
      {
        className: "close",
        text: "Fermer",
        onClick: this.handleCloseModal
      }
    ];

    if (this.state.index === -1) {
      buttons.push({
        onClick: this.handleAdd,
        text: "Ajouter",
        className: "save"
      });
    } else {
      buttons.push({
        onClick: this.handleChange,
        text: "Modifier",
        primary: true
      });
    }

    return (
      <Section className="boutique__commande__component--produits">
        <button className="is-pulled-right" onClick={e => this.handleNew(e)}>
          Ajouter un produit
        </button>
        <Title>Produits</Title>
        {fields.length > 0 ? (
          <ListStyled>
            {fields.map((name, index, produits) => (
              <Produit
                key={index}
                name={name}
                index={index}
                produits={produits}
                handleEdit={e => this.handleEdit(e, index)}
              />
            ))}
          </ListStyled>
        ) : (
          <div className="group">
            <p>Aucun produit</p>
          </div>
        )}
        {this.state.produit && (
          <Modal
            title="Produit"
            handleClose={this.handleCloseModal}
            buttons={buttons}
          >
            <StyledTabs>
              <ul>
                {this.tabs.map((tab, index) => (
                  <li
                    key={index}
                    className={index === this.state.tab ? "is-active" : ""}
                  >
                    <button data-index={index} onClick={this.handleSetTab}>
                      {tab.name}
                    </button>
                  </li>
                ))}
              </ul>
            </StyledTabs>
            <div className="tab">
              {/*<CMSData data={produit} prefix={`produits[${this.state.index}]`}/>*/}
              <StyledColumns>
                <StyledColumn>
                  <Input
                    label="Nom"
                    placeholder="Nom"
                    name="name"
                    type="text"
                    value={this.state.produit.name}
                    onChange={this.handleChangeField}
                  />
                  <Input
                    label="SKU"
                    placeholder="SKU"
                    name="sku"
                    type="text"
                    value={this.state.produit.sku}
                    onChange={this.handleChangeField}
                  />
                  <StyledColumns>
                    <Input
                      label="Largeur"
                      placeholder="Largeur"
                      name="width"
                      type="text"
                      value={this.state.produit.width}
                      onChange={this.handleChangeField}
                    />
                    <Input
                      label="Profondeur"
                      placeholder="Profondeur"
                      name="depth"
                      type="text"
                      value={this.state.produit.depth}
                      onChange={this.handleChangeField}
                    />
                    <Input
                      label="Hauteur"
                      placeholder="Hauteur"
                      name="height"
                      type="text"
                      value={this.state.produit.height}
                      onChange={this.handleChangeField}
                    />
                  </StyledColumns>
                  <Input
                    label="Fabrication"
                    description="Le produit doit être fabriqué ?"
                    name="aFabriquer"
                    type="checkbox"
                    checked={this.state.produit.aFabriquer}
                    onChange={this.handleChangeField}
                  />
                </StyledColumn>
                <StyledColumn>
                  <StyledColumns>
                    <StyledColumn>
                      <Input
                        label="Quantité"
                        placeholder="Quantité"
                        name="qty"
                        type="number"
                        value={this.state.produit.qty}
                        onChange={this.handleChangeField}
                      />
                      <Input
                        label="Remise"
                        placeholder="Remise"
                        name="discount"
                        type="number"
                        value={this.state.produit.discount}
                        onChange={this.handleChangeField}
                      />
                    </StyledColumn>
                    <StyledColumn>
                      <Input
                        label="Prix"
                        placeholder="Prix"
                        name="price"
                        type="number"
                        value={this.state.produit.price}
                        onChange={this.handleChangeField}
                      />
                      <Select
                        label="Type de remise"
                        name="discountType"
                        value={this.state.produit.discountType}
                        onChange={this.handleChangeField}
                        options={[
                          {
                            label: "Valeur en €",
                            value: "valeur"
                          },
                          {
                            label: "Pourcentage",
                            value: "pourcentage"
                          }
                        ]}
                      />
                    </StyledColumn>
                  </StyledColumns>
                  <Textarea
                    label="Déclinaison"
                    placeholder="Déclinaison"
                    name="variantLibelle"
                    value={this.state.produit.variantLibelle}
                    onChange={this.handleChangeField}
                  />
                  <Textarea
                    label="Description"
                    placeholder="Description"
                    name="description"
                    value={this.state.produit.description}
                    onChange={this.handleChangeField}
                  />
                </StyledColumn>
              </StyledColumns>
            </div>
          </Modal>
        )}
      </Section>
    );
  }
}

export default props => <FieldArray {...props} component={Produits} />;
