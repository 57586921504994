import React from "react";
import { Field } from "redux-form";
import Menu from "./Menu";
import ViewTitle from "../styles/View/Title";

const MyCustomInput = ({ input: { value } }) => <span>{value}</span>;

const Title = ({ title }) => {
  if (Array.isArray(title)) {
    return (
      <ViewTitle>
        {title.map((item, key) => (
          <Field
            component={MyCustomInput}
            key={key}
            disabled={true}
            name={item.attribut}
          />
        ))}
      </ViewTitle>
    );
  }

  if (typeof title === "object") {
    return (
      <ViewTitle>
        <Field
          component={MyCustomInput}
          disabled={true}
          name={title.attribut}
        />
      </ViewTitle>
    );
  }

  return title ? <ViewTitle>{title}</ViewTitle> : null;
};

const Header = props => (
  <nav className="navbar">
    {props.title && <Title title={props.title} />}
    <Menu menu={props.menu} docIndex={props.docIndex} />
  </nav>
);

export default Header;
