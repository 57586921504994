import React, { Component } from "react";
import StyledRow from "../../../../styles/View/List/Row";
import Button from "../../../../components/Button";
import Field from "../../../../styles/Field";

class Row extends Component {
  render() {
    const { doc, onClick } = this.props;
    const user = doc.data();

    return (
      <StyledRow>
        <div className="infos">
          <span className="name" onClick={onClick}>
            {user.displayname}
          </span>
        </div>
        <Field noMarginTop className="field-actions">
          <div className="has-addons">
            {doc.id && (
              <Button
                smallIcon
                id={doc.id}
                type="id"
                className="no-left-radius"
              />
            )}
            <Button smallIcon icon="fa-edit" onClick={onClick} />
          </div>
        </Field>
      </StyledRow>
    );
  }
}
export default Row;
