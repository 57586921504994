import React, { Component } from "react";
import { connect } from "react-redux";
import { change } from "redux-form";

import Crud from "../../Crud/index";

class ProduitsList extends Component {
  constructor(props) {
    super(props);

    this.handleOnChangeField = this.handleOnChangeField.bind(this);
  }
  handleOnChangeField(value) {
    this.props.dispatch(change("item", this.props.name, value));
  }

  render() {
    return (
      <Crud
        items={this.props.produits ? this.props.produits : []}
        updateItems={this.handleOnChangeField}
        boutique={this.props.boutique}
      />
    );
  }
}

const mapStateToProps = (globalState, ownProps) => {
  const props = {};
  if (globalState.form.item) {
    props.produits = globalState.form.item.values[ownProps.name];
  }

  return props;
};

export default connect(mapStateToProps)(ProduitsList);
