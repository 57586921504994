import React, { Component } from "react";
import { DragSource, DropTarget } from "react-dnd";
import StyledField from "../../../../CMS/styles/Field";
import Button from "../../../components/Button";

const ItemTypes = {
  FIELD: "field"
};

/**
 * Implements the drag source contract.
 */
const fieldSource = {
  beginDrag(props) {
    return {
      index: props.index
    };
  }
};

/**
 * Specifies the props to inject into your component.
 */
function collectField(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview(),
    isDragging: monitor.isDragging()
  };
}

class Field extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: "",
      label: "",
      type: "",
      order: "",
      updated: false
    };

    this.handleOnChange = this.handleOnChange.bind(this);
    this.handleUpdate = this.handleUpdate.bind(this);
  }

  componentWillMount() {
    const field = this.props.datas;

    this.setState({
      name: field.name ? field.name : "",
      label: field.label ? field.label : "",
      type: field.type ? field.type : "",
      order: field.order
    });
  }

  componentWillReceiveProps(nextProps) {
    const field = nextProps.datas;

    // Si les données sont les mêmes, on ne fait pas de mise à jour
    // Cela pourrait effacer les données modifiées non enregistrées
    //TODO vérifier si le test est OK ou un peu trop trivial
    if (this.props.datas !== nextProps.datas) {
      this.setState({
        name: field.name ? field.name : "",
        label: field.label ? field.label : "",
        type: field.type ? field.type : "",
        order: field.order,
        updated: false
      });
    }
  }

  handleOnChange = event => {
    event.preventDefault();
    let value = "";
    const name = event.currentTarget.name;

    switch (event.currentTarget.type) {
      case "number":
        value = parseInt(event.currentTarget.value, 10);
        break;

      case "checkbox":
        value = event.currentTarget.checked;
        break;

      case "text":
      default:
        value = event.currentTarget.value;
    }

    this.setState({
      [name]: value,
      updated: true
    });
  };

  handleUpdate = e => {
    e.preventDefault();
    const field = Object.assign({}, this.state);
    delete field.updated;

    this.props.handleUpdate(this.props.index, field);
  };

  render() {
    const { connectDragPreview, connectDragSource, isDragging } = this.props;

    return connectDragPreview(
      <div className={`${isDragging ? "isDragging" : ""}`}>
        <StyledField
          className="is-expanded"
          noMarginTop
          noMarginRight
          noMarginBottom
          noMarginLeft
          noBorderRight
          noRightRadius
        >
          <input
            type="text"
            name="name"
            placeholder="Nom"
            value={this.state.name}
            onChange={this.handleOnChange}
          />
        </StyledField>
        <StyledField
          className="is-expanded"
          noMarginTop
          noMarginRight
          noMarginBottom
          noMarginLeft
          noBorderRight
          noRightRadius
          noLeftRadius
        >
          <input
            type="text"
            name="label"
            placeholder="Label"
            value={this.state.label}
            onChange={this.handleOnChange}
          />
        </StyledField>
        <StyledField
          className="select"
          noMarginTop
          noMarginRight
          noMarginBottom
          noMarginLeft
          noBorderRight
          noRightRadius
          noLeftRadius
        >
          <select
            name="type"
            value={this.state.type}
            onChange={this.handleOnChange}
          >
            <option value="text">Text</option>
            <option value="email">Email</option>
            <option value="phone">Phone</option>
            <option value="number">Nombre</option>
            <option value="radio">Radio</option>
            <option value="checkbox">Checkbox</option>
            <option value="select">Select</option>
            <option value="textarea">Textarea</option>
            <option value="boolean">Booléen</option>
            <option value="date">Date</option>
            <option value="document_id">Document ID</option>
            <option value="array">Array</option>
          </select>
        </StyledField>
        <StyledField
          noMarginTop
          noMarginRight
          noMarginBottom
          noMarginLeft
          noBorderRight
          noRightRadius
          noLeftRadius
          style={{ width: "60px" }}
        >
          <input
            type="number"
            name="order"
            value={this.state.order}
            onChange={this.handleOnChange}
          />
        </StyledField>
        <StyledField
          noMarginTop
          noMarginRight
          noMarginBottom
          noMarginLeft
          noLeftRadius
        >
          <div className="has-addons">
            {connectDragSource(<div className="button-order" />)}
            {this.state.updated && (
              <Button
                smallIcon
                icon="fa-save"
                className="button"
                onClick={this.handleUpdate}
              />
            )}
            <Button
              smallIcon
              icon="fa-trash"
              onClick={e => this.props.handleRemove(e, this.props.index)}
            />
          </div>
        </StyledField>
      </div>
    );
  }
}

Field = DragSource(ItemTypes.FIELD, fieldSource, collectField)(Field);

const positionTarget = {
  drop(props, monitor) {
    const item = monitor.getItem();
    console.info("positionTarget", item);
    // props.handleChangePosition(item.index, props.index);
  }
};

function collect(connect, monitor) {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver()
  };
}

class FieldPosition extends Component {
  render() {
    const {
      index,
      datas,
      handleUpdate,
      handleRemove,
      connectDropTarget,
      isOver
    } = this.props;

    return connectDropTarget(
      <li className={isOver ? "isOver" : ""}>
        <Field
          index={index}
          datas={datas}
          handleUpdate={handleUpdate}
          handleRemove={handleRemove}
        />
      </li>
    );
  }
}

export default DropTarget(ItemTypes.FIELD, positionTarget, collect)(
  FieldPosition
);
