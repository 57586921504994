import { connect } from "react-redux";
import List from "../../../CMS/containers/Boutique/components/List";
import Item from "./Item";
import page from "./params.json";

import { initialize, validate } from "../../../Boutique/functions";

class CouponList extends List {
  collectionName = "coupons";
  defaultOrderField = "name";
  defaultOrderDirection = "asc";
  listTitle = "Coupons de réduction";
  viewList = Item;
  itemTitle = { type: "attribut", attribut: "name" };
  initializeItem = initialize;
  validateItem = validate;
  itemParams = page;
  messageNoDocument = "Aucun coupon";

  constructor(props) {
    super(props, {
      newItemLibelle: "Nouveau coupon"
    });

    this.state.form.required = ["name"];

    // this.state.list.menu.left = [
    //   {
    //     text: "Ajouter",
    //     onClick: this.handleCreate
    //   }
    // ];
  }
}

const mapStateToProps = (globalState, ownProps) => {
  const props = {};

  props.docs = globalState.boutique.docs;

  return props;
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CouponList);
