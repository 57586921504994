import styled from "styled-components";

export default styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;

  > li.demande {
    display: flex;
    flex-direction: column;
    border: 1px solid #e4e7ed;

    background: #fbfbfc;
    margin-bottom: 0.375rem;

    > div {
      padding: 0.375rem;
      &.messages {
        padding: 0;

        div:first-child {
          padding: 0.375rem;
        }
      }
    }

    div,
    ul {
      display: block;
      align-items: center;
    }

    .title {
      font-weight: bold;
      margin: 0 0 0.375rem 0;
      padding: 0;
      font-size: 14px;
      line-height: 22px;
    }

    .reponse {
      border: none;
      border-top: 1px solid #e4e7ed;
      outline: none;
      width: 100%;
      box-sizing: border-box;
      padding: 0.375rem;
      margin-top: 0.75rem;
      display: block;
    }

    .produit {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      list-style: none;
      font-size: 90%;
      margin: 0;

      > ul {
        list-style: none;
      }

      ul {
        flex: 1;
        font-size: 90%;
        margin-top: 0;
        li {
          margin: 0;
        }
      }

      .photo {
        height: 80px;
        width: 80px;
        margin: 0;
        border-radius: 5px;
      }

      .rupture-stock {
        color: red;
      }
      .prix-infos {
        margin-left: 0.375rem;
      }
      .prix-barre {
        text-decoration: line-through;
      }

      .actions {
        text-align: right;

        input {
          border-radius: 5px;
          border: 1px solid #ddd;
          width: 75px;
          padding-left: 0.375rem;
          height: 30px;
          outline: none;
        }
        select {
          height: 30px;
          display: inline-block;
          margin-left: 0.375rem;
        }
      }
    }

    &:last-child {
      margin-bottom: 1.5rem;
    }
  }
`;
