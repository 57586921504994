import React, { Component } from "react";
import { DragSource, DropTarget } from "react-dnd";
import PropTypes from "prop-types";
import StyledCard from "../../../Produit/styles/Card";
import nextPhoto from "../../../../../../images/next-photo.svg";
import moment from "moment";
import { getDomainAssetsURL } from "../../../../functions";

const ItemTypes = {
  CARD: "card"
};

/**
 * Implements the drag source contract.
 */
const cardSource = {
  beginDrag(props) {
    return {
      index: props.index
    };
  }
};

/**
 * Specifies the props to inject into your component.
 */
function collectCard(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging()
  };
}

class Card extends Component {
  constructor(props) {
    super(props);

    this.handleOnChangeAttribut = this.handleOnChangeAttribut.bind(this);
    this.handleOnChangeReserved = this.handleOnChangeReserved.bind(this);
    this.handleOnChangeDatetime = this.handleOnChangeDatetime.bind(this);
    this.handleOnChangeRedo = this.handleOnChangeRedo.bind(this);
  }

  handleOnChangeAttribut(event, attribut) {
    event.preventDefault();
    this.props.handleUpdate({
      [attribut]: !this.props.doc.data()[attribut]
    });
  }

  handleOnChangeReserved(event) {
    event.preventDefault();
    this.props.handleUpdate({
      isReserved: !this.props.doc.data().isReserved,
      reservedAt: moment().format("YYYY-MM-DDTHH:mm")
    });
  }

  handleOnChangeSoldout(event) {
    event.preventDefault();
    this.props.handleUpdate({
      inStock: false,
      qtyAvailable: 0,
      isInPOS: false,
      soldoutAt: moment().format("YYYY-MM-DDTHH:mm")
    });
  }

  handleOnChangeDatetime(event) {
    event.preventDefault();
    this.props.handleUpdate({
      datetime: parseInt(moment().format("x"), 10)
    });
  }

  handleOnChangeRedo(event) {
    event.preventDefault();
    this.props.handleUpdate({
      datetime: parseInt(
        moment(this.props.doc.data().publishedAt).format("x"),
        10
      )
    });
  }

  render() {
    const { doc, onClick, connectDragSource, isDragging } = this.props;
    if (!doc) {
      return null;
    }

    const item = doc.data();

    let photo;
    try {
      if (
        this.props.doc.get("photos") &&
        this.props.doc.get("photos")[0] !== undefined
      ) {
        photo = `${getDomainAssetsURL(this.props.boutique)}/${
          this.props.doc.get("photos")[0]
        }`;
        if (
          this.props.doc
            .get("photos")[0]
            .split(".")
            .pop() !== "svg"
        ) {
          photo += "?w=320&h=320&c=max";
        }
      } else {
        photo = nextPhoto;
      }
    } catch (e) {
      photo = nextPhoto;
    }

    return connectDragSource(
      <div className={`card ${isDragging ? "isDragging" : ""}`}>
        <figure
          className="card-image aspect-ratio aspect-ratio--square"
          onClick={onClick}
        >
          <img
            src={photo}
            alt={item.name}
            className="aspect-ratio__content"
            ref={img => {
              this.img = img;
            }}
          />

          {item.isDraft && (
            <div className="draft">
              <span>brouillon</span>
            </div>
          )}
          <div className="bulles">
            {!item.isOnlineStore && (
              <div className="showUnavailable">
                <span>caché</span>
              </div>
            )}
          </div>
        </figure>
        <div className="informations">
          <h5 className="name" onClick={onClick}>
            {item.name ? item.name : "<Nom du modèle>"}
          </h5>
          <span className="sku">{item.sku}</span>
        </div>
        <div className="controls">
          <button
            onClick={e => this.handleOnChangeAttribut(e, "isFeatured")}
            className={`goFeatured ${item.isFeatured ? "yes" : "no"}`}
          >
            <span>En vedette</span>
          </button>
          <button
            onClick={e => this.handleOnChangeAttribut(e, "isOnlineStore")}
            className={`goOnlineStore ${item.isOnlineStore ? "yes" : "no"}`}
          >
            <span>Online Store</span>
          </button>
          <button
            onClick={e => this.handleOnChangeDatetime(e)}
            className="goTop"
          >
            <span>Top</span>
          </button>
          <button onClick={e => this.handleOnChangeRedo(e)} className="goRedo">
            <span>Refresh</span>
          </button>
        </div>
      </div>
    );
  }
}

Card.propTypes = {
  doc: PropTypes.object.isRequired
};

Card = DragSource(ItemTypes.CARD, cardSource, collectCard)(Card);

const positionTarget = {
  drop(props, monitor) {
    const item = monitor.getItem();
    props.handleChangePosition(item.index, props.index);
  }
};

function collect(connect, monitor) {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver()
  };
}

class CardPosition extends Component {
  render() {
    const {
      index,
      doc,
      boutique,
      handleUpdate,
      handleChangePosition,
      onClick,
      connectDropTarget,
      isOver,
      showInfo,
      showUnavailable,
      showBulles
    } = this.props;

    const width = this.props.nbParLigne
      ? parseFloat(100 / this.props.nbParLigne)
      : 20;
    return connectDropTarget(
      <li
        style={{
          display: "block",
          padding: "1.5rem 0 0 1.5rem",
          boxSizing: "border-box",
          width: `${width}%`
        }}
      >
        <StyledCard
          className={isOver ? "isOver" : ""}
          showInfo={showInfo}
          showUnavailable={showUnavailable}
          showBulles={showBulles}
        >
          <Card
            index={index}
            doc={doc}
            boutique={boutique}
            handleUpdate={handleUpdate}
            handleChangePosition={handleChangePosition}
            onClick={onClick}
          />
        </StyledCard>
      </li>
    );
  }
}

export default DropTarget(
  ItemTypes.CARD,
  positionTarget,
  collect
)(CardPosition);
