import styled from "styled-components";
import edit from "../../../../images/font-awesome/regular/edit.svg";
import featuredFalse from "../../../../images/font-awesome/regular/bookmark.svg";
import featuredTrue from "../../../../images/font-awesome/solid/bookmark.svg";

export const List = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;

  > li {
    cursor: pointer;
    display: flex;
    border: 1px solid #e4e7ed;
    padding: 0.375rem;
    background: #fbfbfc;
    margin-bottom: 0.375rem;

    &:not(:last-child) {
      // border-bottom: 0;
    }

    div,
    ul {
      display: block;
      align-items: center;
    }

    .numero {
      display: inline-block;
      //background: #dfe4e8;
      color: #576775;
      //color: #dfe4e8;
      //border-radius: 10px;
      line-height: 20px;
      height: 20px;
      padding: 0 10px;
      font-size: 11px;
      font-style: italic;
    }

    .date {
      background: #e4e7ed;
      text-align: center;
      padding: 0.375rem;
      border-radius: 4px;
      height: 50px;
      width: 50px;
      box-sizing: border-box;
      color: hsla(0, 0%, 0%, 0.7) !important;

      .day {
        font-size: 18px;
        line-height: 22px;
        display: block;
      }
      .month {
        font-size: 12px;
        display: block;
      }
    }

    .demande {
      flex: 1;
      padding: 0 1.5rem;

      .produits {
        font-size: 95%;

        li {
          margin: 0;
          padding: 0;
        }
      }
    }

    .devis {
      flex: 1;
      padding: 0 1.5rem;

      .client {
        display: block;
      }

      .produits {
        font-size: 90%;
      }
    }

    .production {
      background: #4b71fc;
      border-radius: 50%;
      height: 40px;
      width: 40px;
      text-align: center;
      line-height: 40px;
      margin-right: 1.5rem;
      margin-top: 5px;
      font-size: 12px;
      color: #fff;
    }

    .total {
      text-align: right;

      .montant {
        font-weight: bold;
      }

      .paiement {
        display: block;
        font-size: 75%;
        font-style: italic;
      }
    }
    .status {
      margin: 0;
      padding: 0;
      list-style: none;
      text-align: right;
      // width: calc(50px + 0.75rem);

      li {
        display: inline-block;
        //background: #dfe4e8;
        color: #576775;
        line-height: 75px;
        vertical-align: bottom;
        height: 50px;
        font-size: 11px;
        width: 50px;
        box-sizing: border-box;
        text-align: center;
        white-space: nowrap;
        overflow: hidden;

        &.is-completed {
          background: #65c96d;
          color: #fff;
        }

        &.devis,
        &.solde,
        &.commande,
        &.expedition,
        &.delivery,
        &.fabrication {
          border-bottom: 5px solid #dfe4e8;
          background: none;
          //color: #576775;
          color: #dfe4e8;
          transform: rotate(-90deg);
        }

        &.delivery {
          border-color: #00aeef;
          color: #00aeef;
        }
        &.fabrication {
          border-color: #e3c369;
          color: #e3c369;
        }
        &.solde {
          border-color: red;
          color: red;
        }
      }
    }

    .finish {
      border-right: 5px solid #65c96d;
      margin-left: 1.5rem;
      height: 50px;
      width: 0 !important;
    }

    &.isValidated {
      .paiement {
        &:before {
          //content: "\\01F44C";
          content: "\\2611";
          margin-right: 0.375rem;
          font-size: 15px;
        }
      }
    }

    &.cent {
      //color: #65c96d;
    }
    &.zero {
      color: #dfe4e8;
    }
  }
`;

export const Card = styled.div`
  cursor: pointer;

  > div {
    border: 1px solid #e4e7ed;
    border-radius: 4px;
  }

  &.isOver > div {
    border-color: red;
  }

  .isDragging {
    border-color: blue;
  }

  &.isOver > div.isDragging {
    border-color: green;
  }

  .informations {
    padding: 0.375rem;
    border-bottom: 1px solid rgba(195, 207, 216, 0.3);
  }

  .name {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

    text-align: left;
    margin-top: 0;
    margin-bottom: 0.375rem;

    //font-weight: 400;
    font-size: 13px;
    color: #6f8dfd;
    transition: color 0.2s ease;

    &:hover {
      color: #3e5ed6;
    }
  }

  .publishedAt {
    display: block;
    font-size: 12px;
    font-style: italic;
  }

  .control {
    label {
      flex: 1;
    }
  }

  .controls {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding: 0.375rem;
    background: white;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  .goMemo,
  .goFeatured {
    background-color: #86848c;
    mask-image: url(${edit});
    mask-repeat: no-repeat;
    mask-position: center;
    height: 18px;
    width: 18px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: #9f9da4;
    //border: 1px solid #b7c6fe;
    text-decoration: none;
    margin-left: 0.375rem;
    span {
      display: none;
    }

    &:hover {
      background-color: #34313f;
    }
  }
  
  .goFeatured.no {
      mask-image: url(${featuredFalse});
    }
    .goFeatured.yes {
      mask-image: url(${featuredTrue});
    }

  figure {
    margin: 0;
    position: relative;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }

  .soldout,
  .showUnavailable,
  .notVisible,
  .draft {
    position: absolute;
    top: 0;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    width: 100%;
    background: rgba(255, 255, 255, 0.2);

    span {
      display: inline-block;
      flex: 1;
      width: 100%;
      text-align: center;
      font-size: 13px;
      color: #86848c;
      font-weight: normal;
      font-style: italic;
    }
  }

  .bulles {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
  }
  .bulle {
    display: block;
    float: right;
    margin: 10px 10px 0 0;
    //color: white;
    color: #000;
    font-size: 15px;
    //background-color: rgba(0, 127, 188, 0.5);
    background-color: rgba(255, 255, 255, 0.7);
    border-radius: 15px;
    height: 25px;
    width: 25px;
    line-height: 23px;
    box-sizing: border-box;
    text-align: center;
  }

  .qtyAvailable {
    font-size: 12px;
    line-height: 25px;
  }

  .isOnlineStore,
  .isInPOS {
    font-size: 8px;
    line-height: 25px;
  }

  .showDiscount {
    font-weight: bold;

    &:before {
      content: "";
      display: inline-block;
      margin-left: 5px;
    }
  }

  .soldout + .bulles .showUnavailable {
    align-items: flex-end;
    background: none;

    span {
      padding-bottom: 1.5rem;
      font-size: 90%;
    }
  }

  input[type="checkbox"] + span:before {
    background-size: 14px 14px !important;
    height: 14px !important;
    width: 14px !important;
  }

  input[type="checkbox"]:checked + span:before {
    background-position: -1px -1px !important;
  }

  .price,
  .sold {
    margin-top: 0.375rem;
    font-size: 12px;
  }

  #retailPrice:before {
    content: "";
    display: inline-block;
    margin-left: 5px;
  }

  .sold {
    float: right;
  }

  .aspect-ratio {
    position: relative;
    display: block;
    background: #fafbfc;
    padding: 0;

    &:before {
      z-index: 1;
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      border-bottom: 1px solid rgba(195, 207, 216, 0.3);
    }

    &.isDragging:before {
      border: 1px solid red;
    }
  }

  .aspect-ratio--square {
    width: 100%;
    padding-bottom: 100%;
    flex-shrink: 0;
  }

  .aspect-ratio__content {
    position: absolute;
    max-width: 100%;
    max-height: 100%;
    display: block;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
  }
`;
