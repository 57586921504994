import React, { Component } from "react";
import Section from "../../../../../CMS/components/Data/Section";
import Group from "../../../../../CMS/components/Data/Group";
import styled from "styled-components";
import { connect } from "react-redux";

const StyledContact = styled.div`
  padding: 0.75rem;
  span {
    display: block;
  }
`;

class Contact extends Component {
  render() {
    const { demande } = this.props;

    const ville = [];
    if (demande.contact_zip) {
      ville.push(demande.contact_zip);
    }
    if (demande.contact_city) {
      ville.push(demande.contact_city);
    }

    const name = [];
    if (demande.contact_firstname) {
      name.push(demande.contact_firstname);
    }
    if (demande.contact_lastname) {
      name.push(demande.contact_lastname);
    }
    return (
      <Section title="Contact">
        <Group className="alt">
          <StyledContact>
            <span>{name.join(" ")}</span>
            <span>{demande.contact_email}</span>
            {demande.contact_phone && <span>{demande.contact_phone}</span>}
            {demande.contact_entreprise && (
              <span>{demande.contact_entreprise}</span>
            )}
            {ville.length > 0 && <span>{ville.join(" ")}</span>}
            {demande.contact_country && <span>{demande.contact_country}</span>}
          </StyledContact>
        </Group>
      </Section>
    );
  }
}

export default connect((globalState, props) => {
  return {
    demande: globalState.form.item.values
  };
})(Contact);
