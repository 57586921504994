import firestore from "../../../../api/firestore";
import moment from "moment";

import {
  BOUTIQUES_WATCH,
  BOUTIQUES_UNSUBSCRIBE,
  BOUTIQUE_CREATE,
  BOUTIQUE_UPDATE,
  BOUTIQUE_REMOVE,
  BOUTIQUE_CHANGE
} from "../../actionTypes";

export function watch(user) {
  return dispatch => {
    const unsubscribe = firestore
      .collection("boutiques")
      .where("deleted", "==", false)
      .orderBy("name")
      .onSnapshot(function(querySnapshot) {
        const docs =
          user.get("type") === "admin"
            ? querySnapshot.docs
            : querySnapshot.docs.filter(doc =>
                user.get("boutiques").includes(doc.id)
              );

        let indexBoutique = 0;
        if (user.get("boutique")) {
          // On met à jour l'index de la boutique par défaut
          indexBoutique = docs.findIndex(boutique => {
            return boutique.id === user.get("boutique");
          });
        }

        dispatch({
          type: BOUTIQUES_WATCH,
          boutiques: docs,
          indexBoutique: indexBoutique
        });
      });

    dispatch({
      type: BOUTIQUES_UNSUBSCRIBE,
      unsubscribe: unsubscribe
    });
  };
}

export function create(item) {
  return {
    type: BOUTIQUE_CREATE,
    payload: firestore.collection("boutiques").add(item)
  };
}

export function update(ref, item) {
  return {
    type: BOUTIQUE_UPDATE,
    payload: ref.update({ ...item })
  };
}

export function remove(ref) {
  return {
    type: BOUTIQUE_REMOVE,
    payload: ref.update({
      deleted: true,
      updatedAt: moment().toISOString()
    })
  };
}

export function changeIndex(index) {
  return {
    type: BOUTIQUE_CHANGE,
    index
  };
}
