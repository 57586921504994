import React, { Component } from "react";
import PropTypes from "prop-types";
import firestore from "../../../../api/firestore";

const action = (type, limit, collection) => {
  return dispatch => {
    let batch = firestore.batch();
    firestore
      .collection("pages")
      .where("type", "==", type)
      .limit(limit)
      .get()
      .then(querySnapshot => {
        if (!querySnapshot.empty) {
          querySnapshot.forEach(document => {
            const ref = firestore.collection(collection).doc(document.id);

            batch.set(ref, document.data());
            batch.delete(document.ref);
          });

          return batch.commit().then(function() {
            return dispatch({
              type: "TRANSFERT"
            });
          });
        }
      });
  };
};

class Transfert extends Component {
  static contextTypes = {
    store: PropTypes.object
  };

  state = {
    nbProduits: 200,
    type: "post",
    collection: "posts"
  };

  constructor(props) {
    super(props);

    this.handleTransfertDocument = this.handleTransfertDocument.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    console.info("props", props);
    return state;
  }

  handleTransfertDocument() {
    this.context.store.dispatch(
      action(this.state.type, this.state.nbProduits, this.state.collection)
    );
  }

  render() {
    return (
      <div>
        <h1>
          Transfert des documents de type "{this.state.type}" vers la collection
          "{this.state.collection}"
        </h1>
        <button onClick={this.handleTransfertDocument}>
          Transferer {this.state.nbProduits}
        </button>
      </div>
    );
  }
}
export default Transfert;
