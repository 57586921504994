import React, { Component } from "react";
import Section from "../../../../../CMS/components/Data/Section";

class Algolia extends Component {
  render() {
    return (
      <Section title="Produits" group={true}>
        Index algolia pour les produits : {this.props.algoliaType}
      </Section>
    );
  }
}

export default Algolia;
