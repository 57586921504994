import { connect } from "react-redux";
import List from "../../../../../CMS/containers/Boutique/components/List";
import Row from "./Row";
import fournisseur from "../../params.json";

import { initialize, validate } from "../../../../functions";
import Fournisseur from "../../../../../CMS/datas/Fournisseur";

class FournisseursList extends List {
  collectionName = "fournisseurs";
  defaultOrderField = "nom";
  defaultOrderDirection = "asc";
  listTitle = "Fournisseurs";
  viewList = Row;
  itemTitle = { type: "attribut", attribut: "nom" };
  initializeItem = initialize;
  validateItem = validate;
  itemParams = fournisseur;
  messageNoDocument = "Aucun fournisseur";

  constructor(props) {
    super(props, {
      defaultViewList: "list",
      tabsArray: [
        {
          label: "Tous les fournisseurs",
          actif: true,
          wheres: {}
        }
      ]
    });

    this.handleTransfertToProject = this.handleTransfertToProject.bind(this);
    this.state.form.newItemLibelle = "Nouveau fournisseur";
    this.state.form.required = ["nom"];

    this.state.list.menu.left = [
      {
        text: "Ajouter",
        onClick: this.handleCreate
      },
      {
        text: "Transférer",
        onClick: this.handleTransfertToProject
      }
    ];
  }

  handleTransfertToProject(e) {
    e.preventDefault();
    if (!this.props.boutique.get("firebaseApiKey")) {
      console.error(
        "Il n'est pas possible de transférer les fournisseurs car le projet n'est pas configuré"
      );
    }

    Fournisseur.transfertToBoutique(this.props.boutique).then(result => {
      console.info("result", result);
    });
  }
}

const mapStateToProps = (globalState, ownProps) => {
  const props = {};
  props.docs = globalState.boutique.docs;

  return props;
};

// const mapDispatchToProps = (dispatch, ownProps) => {
//   return {
//     searchCategories: boutique =>
//       dispatch(searchInCollection(boutique.ref, "productCategories"))
//   };
// };

export default connect(
  mapStateToProps,
  null
)(FournisseursList);
