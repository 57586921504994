import React from "react";
import Input from "../../../../../../CMS/components/Data/Input/index";
import Field from "../../../../../../CMS/styles/Field";
import Button from "../../../../../../CMS/components/Button/index";

// Les unités sont toujours en cm pour les dimensions
export default ({ name, data, handleRemove, dispatch }) => (
  <div
    style={{
      display: "flex"
    }}
    className="cms__component--columns"
  >
    <Input
      type="text"
      name={`${name}.libelle`}
      placeholder="Libellé"
      label="Libellé"
      className="is-expanded no-margin-right no-margin-bottom"
    />
    <Input
      name={`${name}.valeur`}
      type="number"
      label="Valeur"
      placeholder="en cm"
      className="no-border-right no-margin-right no-margin-bottom"
    />
    {/*<Input*/}
    {/*name={`${name}.unite`}*/}
    {/*type="text"*/}
    {/*label="Unité"*/}
    {/*placeholder="Unité"*/}
    {/*/>*/}

    <Field noMarginLeft noMarginBottom>
      <div className="has-addons">
        {data.id && (
          <Button smallIcon id={data.id} type="id" className="no-left-radius" />
        )}
        <Button
          smallIcon
          icon="fa-trash"
          onClick={handleRemove}
          className={!data.id && "no-left-radius"}
        />
      </div>
    </Field>
  </div>
);
