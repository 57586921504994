import { getFirestore } from "../../Boutique/functions";
import slug from "slug";
import moment from "moment";
import Collection from "./Collection";

class Url {
  static search(boutique, wheres) {
    let query = getFirestore(boutique)
      .collection("urls")
      .where("boutique", "==", boutique.id)
      .where("deleted", "==", false);

    if (wheres && Object.keys(wheres).length > 0) {
      Object.keys(wheres).forEach(key => {
        if (wheres[key]) {
          if (Array.isArray(wheres[key])) {
            wheres[key].forEach(where => {
              let operator = "==";
              let value = where;
              if (typeof where === "object") {
                operator = where.operator ? where.operator : operator;
                if (where.value) {
                  value = where.value ? where.value : value;
                } else {
                  if (parseInt(where.value, 10) === 0) {
                    value = 0;
                  } else {
                    value = false;
                  }
                }
              }
              query = query.where(String(key), operator, value);
            });
          } else {
            let operator = "==";
            let value = wheres[key];
            if (typeof wheres[key] === "object") {
              operator = wheres[key].operator ? wheres[key].operator : operator;
              if (wheres[key].value) {
                value = wheres[key].value ? wheres[key].value : value;
              } else {
                if (parseInt(wheres[key].value, 10) === 0) {
                  value = 0;
                } else {
                  value = false;
                }
              }
            }
            query = query.where(String(key), operator, value);
          }
        } else {
          if (parseInt(wheres[key], 10) === 0) {
            query = query.where(String(key), "==", 0);
          } else {
            query = query.where(String(key), "==", false);
          }
        }
      });
    }
    return query.get().then(querySnapshot => {
      return querySnapshot.docs;
    });
  }

  /**
   * Cette methode permet de regarder les urls d'une boutique
   *
   * @param boutique
   * @param wheres
   * @param next
   * @returns {unsubscribe}
   */
  static watch(boutique, wheres, next) {
    let query = getFirestore(boutique)
      .collection("urls")
      .where("boutique", "==", boutique.id)
      .where("deleted", "==", false);

    if (wheres && Object.keys(wheres).length > 0) {
      Object.keys(wheres).forEach(key => {
        if (wheres[key]) {
          query = query.where(String(key), "==", wheres[key]);
        } else {
          if (parseInt(wheres[key], 10) === 0) {
            query = query.where(String(key), "==", 0);
          } else {
            query = query.where(String(key), "==", false);
          }
        }
      });
    }

    return query.onSnapshot(next);
  }

  /**
   * Recherche une vente par l'intermédiaire de l'id
   *
   * @param boutique
   * @param id
   * @returns {Promise<unknown>}
   */
  static findById(boutique, id) {
    return getFirestore(boutique)
      .collection("urls")
      .doc(id)
      .get()
      .then(documentSnapshot => {
        if (!documentSnapshot.exists) {
          throw new Error("L'url n'existe pas");
        }

        if (documentSnapshot.get("boutique") !== boutique.id) {
          throw new Error("Cette url n'appartient pas à cette boutique");
        }

        if (documentSnapshot.get("deleted")) {
          throw new Error("Cette url a été supprimé");
        }

        return documentSnapshot;
      });
  }

  /**
   *
   * @param boutique
   * @param pathname
   * @returns {Promise<firebase.firestore.QueryDocumentSnapshot>}
   */
  static findByPathname(boutique, pathname) {
    if (!boutique || !boutique.exists) {
      throw new Error("La boutique n'existe pas");
    }
    return getFirestore(boutique)
      .collection("urls")
      .where("boutique", "==", boutique.id)
      .where("deleted", "==", false)
      .where("pahtname", "==", pathname)
      .get()
      .then(querySnapshot => {
        if (querySnapshot.empty) {
          throw new Error("L'url n'existe pas");
        }

        if (querySnapshot.size > 1) {
          throw new Error("Cette url n'est pas unique");
        }

        return querySnapshot.docs[0];
      });
  }

  static create(boutique, data) {
    // Les champs type, docId, pathname sont obligatoire
    if (!data.type) {
      throw new Error("Le champs type est obligatoire");
    }
    if (!data.docId) {
      throw new Error("Le champs docId est obligatoire");
    }
    if (!data.pathname) {
      throw new Error("Le champs pathname est obligatoire");
    }
    data.boutique = boutique.id;
    data.createdAt = moment().toISOString();
    data.deleted = false;

    return getFirestore(boutique)
      .collection("urls")
      .add(data);
  }

  static delete(ref) {
    return ref.update({ deleted: true });
  }

  /**
   * Cette méthode permet de générer l'url du document
   *
   * @param boutique
   * @param libelle
   * @param type
   * @param docId
   * @param parentId
   * @returns {Promise<unknown>}
   */
  static async clear(boutique, libelle, type, docId, parentId) {
    if (!boutique || !boutique.exists) {
      throw new Error("La boutique n'existe pas");
    }
    if (!libelle) {
      throw new Error("Le libellé est obligatoire pour réaliser le traitement");
    }
    if (!type) {
      throw new Error("Le type est obligatoire pour réaliser le traitement");
    }

    let urlArray = [];
    if (parentId) {
      const parent = await Collection.findById(boutique, `${type}s`, parentId);
      if (parent && parent.get("pathname")) {
        urlArray.push(parent.get("pathname"));
      }
    } else {
      if (type !== "page") {
        urlArray.push(type);
      }
    }

    urlArray.push(slug(libelle));
    let url = urlArray.join("/");

    return this.search(boutique, {
      pathname: url
    }).then(urls => {
      switch (urls.length) {
        case 0:
          // L'url n'existe pas, il faut la créer
          return this.create(boutique, {
            type: type,
            docId: docId,
            parentId: parentId ? parentId : "",
            pathname: url
          }).then(documentReference => {
            return documentReference.get();
          });

        case 1:
          // On vérifie que l'url correspond au même document
          if (
            urls[0].exists &&
            urls[0].get("type") === type &&
            urls[0].get("docId") === docId
          ) {
            return urls[0];
          }

        // eslint-disable-next-line no-fallthrough
        default:
          // Il existe plusieurs urls, il faut en créer une nouvelle
          // On génère un prefix temporel
          urlArray.pop();
          urlArray.push(`${moment().format("X")}-${slug(libelle)}`);
          url = urlArray.join("/");

          return this.create(boutique, {
            type: type,
            docId: docId,
            parentId: parentId ? parentId : "",
            pathname: url
          }).then(documentRef => {
            return documentRef.get();
          });
      }
    });
  }
}

export default Url;
