import React from "react";
import StyledRow from "../../../CMS/styles/View/List/Row";
import Button from "../../../CMS/components/Button";
import Field from "../../../CMS/styles/Field";
import moment from "moment";

/**
 * Ce composant permet d'afficher une page dans la liste des pages
 *
 * @param doc
 * @param boutique
 * @param onClick
 * @returns {*}
 */
export default ({ doc, boutique, onClick }) => {
  const item = doc.data();

  return (
    <StyledRow>
      <div className="date">
        <span className="day">{moment(item.publishedAt).format("Do")}</span>
        <span className="month">{moment(item.publishedAt).format("MMM")}</span>
      </div>
      <div className="infos">
        <span className="name" onClick={onClick}>
          {item.name}
        </span>
      </div>
      <Field noMarginTop className="field-actions">
        <div className="has-addons">
          {doc.id && (
            <Button
              smallIcon
              id={doc.id}
              type="id"
              className="no-left-radius"
            />
          )}
          <Button
            smallIcon
            icon="fa-eye"
            as="a"
            target="_blank"
            href={`${boutique.get("websiteURL")}/${item.pathname}`}
          />
          <Button smallIcon icon="fa-edit" onClick={onClick} />
        </div>
      </Field>
    </StyledRow>
  );
};
