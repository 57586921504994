import React, { Component } from "react";
import PropTypes from "prop-types";
import nextPhoto from "../../../../images/next-photo.svg";
import { Card as StyledCard } from "./styles";
import moment from "moment";
import { getDomainAssetsURL } from "../../../Boutique/functions";

class Card extends Component {
  constructor(props) {
    super(props);

    this.handleOnChangeAttribut = this.handleOnChangeAttribut.bind(this);
  }

  handleOnChangeAttribut(event, attribut) {
    event.preventDefault();
    this.props.handleUpdate({
      [attribut]: !this.props.doc.data()[attribut]
    });
  }

  render() {
    const { doc, onClick } = this.props;
    if (!doc) {
      return null;
    }

    const item = doc.data();

    let photo;
    try {
      if (
        this.props.doc.get("photos") &&
        this.props.doc.get("photos")[0] !== undefined
      ) {
        photo = `${getDomainAssetsURL(this.props.boutique)}/${
          this.props.doc.get("photos")[0]
        }?w=320&h=320&c=max`;
      } else {
        photo = nextPhoto;
      }
    } catch (e) {
      photo = nextPhoto;
    }

    return (
      <div>
        <figure
          className="card-image aspect-ratio aspect-ratio--square"
          onClick={onClick}
        >
          <img src={photo} alt={item.name} className="aspect-ratio__content" />
          {item.isDraft && (
            <div className="draft">
              <span>brouillon</span>
            </div>
          )}
        </figure>
        <div className="informations">
          <h5 className="name" onClick={onClick}>
            {item.title}
          </h5>
          <span className="publishedAt">
            {moment(item.publishedAt).format("DD/MM/YY")}
          </span>
        </div>
        <div className="controls">
          <button
            onClick={e => this.handleOnChangeAttribut(e, "isFeatured")}
            className={`goFeatured ${item.isFeatured ? "yes" : "no"}`}
          >
            <span>En vedette</span>
          </button>
          <button onClick={onClick} className="goMemo">
            <span>Memo</span>
          </button>
        </div>
      </div>
    );
  }
}

Card.propTypes = {
  doc: PropTypes.object.isRequired
};

class CardPosition extends Component {
  render() {
    const { index, doc, boutique, handleUpdate, onClick } = this.props;

    return (
      <li
        style={{
          display: "block",
          padding: "1.5rem 0 0 1.5rem",
          boxSizing: "border-box",
          width: "20%"
        }}
      >
        <StyledCard>
          <Card
            index={index}
            doc={doc}
            boutique={boutique}
            handleUpdate={handleUpdate}
            onClick={onClick}
          />
        </StyledCard>
      </li>
    );
  }
}

export default CardPosition;
