import React, { Component } from "react";
import PropTypes from "prop-types";
import CmpSelect from "../../../../components/Data/Select";

class Select extends Component {
  constructor(props) {
    super(props);

    this.state = {
      boutique: undefined,
      collectionName: undefined,
      shouldBeLoaded: false,
      options: []
    };

    this.loadFeatures = this.loadFeatures.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    if (
      props.boutique &&
      props.collectionName &&
      (!state.boutique || state.boutique.id !== props.boutique.id) &&
      (!state.collectionName || state.collectionName !== props.collectionName)
    ) {
      state.boutique = props.boutique;
      state.collectionName = props.collectionName;
      state.shouldBeLoaded = true;
    }

    return state;
  }

  componentDidMount() {
    if (this.state.shouldBeLoaded) {
      this.loadFeatures();
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.state.shouldBeLoaded) {
      this.loadFeatures();
    }
  }

  loadFeatures() {
    this.setState({
      shouldBeLoaded: false
    });

    this.state.boutique.ref
      .collection(this.state.collectionName)
      .where("deleted", "==", false)
      .orderBy("datetime")
      .get()
      .then(querySnapshot => {
        this.setState({
          options: querySnapshot.docs
        });
      });
  }

  // handleChangeValue(event) {
  //   // TODO j'ai retiré les <nom>Ref car les données enregistrées ne sont pas dans le même projet
  //   // const index = this.state.options.findIndex(
  //   //   value => value.id === event.currentTarget.value
  //   // );
  //   // this.props.changeRef(this.state.options[index] ? this.state.options[index].ref : '');
  // }

  render() {
    if (!this.state.options || this.state.options.length === 0) {
      return null;
    }

    return (
      <CmpSelect
        name={this.props.name}
        label={this.props.label}
        className={this.props.className}
        options={this.state.options.map((item, index) => {
          return {
            value: item.id,
            label: item.get("libelle")
          };
        })}
      />
    );
  }
}

Select.propTypes = {
  label: PropTypes.string,
  collectionName: PropTypes.string.isRequired,
  boutique: PropTypes.object.isRequired
};

export default Select;
