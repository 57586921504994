import React, { Component } from "react";
import { change } from "redux-form";
import InputElement from "react-maskedinput";
// import moment from 'moment';
import ComponentField from "../../../styles/Field";

const InputMask = props => {
  const { name, value, ...rest } = props;

  delete rest.meta;

  return <InputElement name={name} value={value} {...rest} />;
};

class Date extends Component {
  constructor(props) {
    super(props);

    this.handleOnChange = this.handleOnChange.bind(this);
  }

  componentDidMount() {
    if (this.input.value) {
      this.input.classList.remove("is-empty");
    } else {
      this.input.classList.add("is-empty");
    }

    this.input.addEventListener("focus", function(event) {
      event.currentTarget.classList.add("is-focused");
    });
    this.input.addEventListener("blur", function(event) {
      event.currentTarget.classList.remove("is-focused");
    });
    this.input.addEventListener("keyup", function(event) {
      if (event.currentTarget.value.length === 0) {
        event.currentTarget.classList.add("is-empty");
      } else {
        event.currentTarget.classList.remove("is-empty");
      }
    });
  }

  handleOnChange = event => {
    this.props.meta.dispatch(
      change(
        "item",
        event.currentTarget.name,
        event.currentTarget.value
        // moment(event.currentTarget.value).toISOString()
      )
    );
  };

  render() {
    const {
      input: { name, value },
      icon,
      mask,
      className,
      label,
      horizontal,
      meta: { touched, error },
      ...others
    } = this.props;

    return (
      <ComponentField
        className={
          "cms__field " +
          (touched && error ? "error " : "") +
          (horizontal ? "horizontal " : "") +
          (className ? " " + className : "")
        }
      >
        {icon &&
          false && (
            <span className="icon is-small">
              <i className={"fa " + icon} />
            </span>
          )}

        {mask ? (
          <InputMask
            value={value}
            {...others}
            ref={input => {
              this.input = input;
            }}
            onChange={this.handleOnChange}
          />
        ) : (
          <input
            name={name}
            value={value}
            {...others}
            ref={input => {
              this.input = input;
            }}
            onChange={this.handleOnChange}
          />
        )}

        <label className="label">
          <span>{label}</span>
        </label>
        {touched &&
          error && (
            <div className={"error error_" + error.code}>
              <span className="icon">
                <i className="fa fa-exclamation" />
              </span>
              <span>{error.message}</span>
            </div>
          )}
      </ComponentField>
    );
  }
}

export default Date;
