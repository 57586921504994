import styled from "styled-components";
import storeAlt from "../../../../images/font-awesome/solid/store-alt.svg";
// import inbox from "../../../../images/font-awesome/solid/inbox.svg";
import clipboardList from "../../../../images/font-awesome/solid/clipboard-list.svg";
import globe from "../../../../images/font-awesome/solid/globe.svg";
import users from "../../../../images/font-awesome/solid/users.svg";
import calculator from "../../../../images/font-awesome/solid/calculator.svg";
import inneditWhite from "../../../../images/innedit-white.svg";
// import inneditWebapp from "../../../../images/innedit-webapp.svg";

export const NavBase = styled.ul`
  flex: 1;
  display: flex;
  flex-direction: column;
  list-style: none;
  left: ${props => (props.top ? 0 : props.theme.mobile ? "-100vw" : "-200px")};
  margin: 0;
  padding: 0;
  height: 100%;
  position: relative;
  transition: left 250ms cubic-bezier(0.25, 0.1, 0.25, 1);

  li {
    margin: 0;

    &.spacer {
      flex: 1;
    }

    &.separator {
      height: 20px;
    }
  }
`;

export const Nav = styled.nav`
  //position: static;
  

  transition-duration: 200ms;
  transition-property: flex-basis, opacity, width;
  transition-timing-function: cubic-bezier(0.21, 0.61, 0.35, 1);

  ${props => {
    if (props.theme.mobile) {
      return {
        display: "block"
      };
    }

    return {
      position: "fixed",
      top: 0,
      left: 0,
      bottom: 0,
      display: "flex",
      width: "200px",
      outline: 0,
      overflow: "hidden"
    };
  }};

  background-color: #34313f;

  flex-direction: column;

  z-index: 500;

  .logo {
    display: inline-block;
    width: 100%;
    height: 32px;
    text-align: center;
    box-sizing: border-box;
    padding: 8px;
    margin-bottom: 2rem;

    #logo {
      display: inline-block;
      height: 32px;
      width: 100%;
      background-image: url(${inneditWhite});
      background-repeat: no-repeat;
      background-position: center;
    }
  }
  .hamburger {
    position: absolute;
    top: 0;
    left: 0;
    height: 48px;
    width: 48px;
    display: ${props => (props.theme.mobile ? "block" : "none")};

    .hamburger-top,
    .hamburger-bottom {
      position: absolute;
      top: 0;
      left: 0;
      width: 48px;
      height: 48px;

      transition: transform 0.25s cubic-bezier(0.4, 0.01, 0.165, 0.99),
        -webkit-transform 0.25s cubic-bezier(0.4, 0.01, 0.165, 0.99);
      transform: rotate(0);

      span {
        display: block;
        width: 17px;
        height: 1px;
        background: #fff;
        position: absolute;
        left: 16px;
        z-index: 1;
      }
    }

    .hamburger-top span {
      top: 23px;
      transform: translateY(-3px);
    }

    .hamburger-bottom span {
      bottom: 23px;
      transform: translateY(3px);
    }

    &.is-open {
      .hamburger-top {
        transform: rotate(45deg);
        span {
          transform: translateY(0px);
          top: 23px;
        }
      }

      .hamburger-bottom {
        transform: rotate(-45deg);

        span {
          transform: translateY(0px);
          bottom: 23px;
        }
      }
    }
  }

  .header {
    background-color: #273a8a;
    flex: 1 0 auto;

    .items-header {
      box-sizing: border-box;
      .home {
        vertical-align: top;
        svg {
          height: 17px;
          display: inline-block;
          margin: 0 0.75rem 0 0;
          fill: #b7c6fe;
        }
        span {
          display: inline-block;
          vertical-align: top;
        }
      }

      .boutique,
      .club,
      .website,
      .organisation,
      .calculatrices {
        span {
          display: inline-block;
          vertical-align: top;
        }
        .icon {
          mask-image: url(${storeAlt});
          background-color: #b7c6fe;
          mask-repeat: no-repeat;
          mask-position: center;
          height: 17px;
          width: 17px;
          margin: 0 0.75rem 0 0;
        }
      }

      .club {
        .icon {
          mask-image: url(${users});
        }
      }

      .website {
        .icon {
          mask-image: url(${globe});
        }
      }

      .organisation {
        .icon {
          mask-image: url(${clipboardList});
        }
      }

      .calculatrices {
        .icon {
          mask-image: url(${calculator});
        }
      }

      a {
        padding: 0.5rem 0.5rem 0.5rem 1.5rem;
        box-sizing: border-box;
        color: #b7c6fe;
        cursor: pointer;
        display: block;
        position: relative;
        width: 100%;

        &.is-active,
        &:hover {
          background-color: #334caf;
          color: #fff;
        }
      }
    }
  }

  .container {
    flex: 1 1 100%;
    overflow: auto;
    overflow-x: hidden;
    padding-bottom: 1rem;
    padding-top: 1rem;
  }

  .footer {
    flex: 1 0 auto;
    overflow: hidden;

    .user-profile {
      background-color: #34313f;
      box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.1),
        0 1px 4px 0 rgba(0, 0, 0, 0.14);
      position: relative;
      z-index: 600;

      &.is-open {
        bottom: 0;
        height: calc(100% - 48px);
        position: absolute;
        width: 100%;
      }

      .user-toogle {
        background-color: #44414e;
        border: 0;
        color: #e1e6eb;
        cursor: pointer;
        height: 50px;
        padding: 0 0 0 1.5rem;
        position: relative;
        text-align: left;
        width: 100%;
        outline: none;

        .icon {
          position: absolute;
          top: 17px;
          right: 17px;
        }
      }

      .user-body {
        padding-top: 2rem;
      }

      ul {
        display: flex;
        flex-direction: column;
        list-style: none;
        margin: 0;
        padding: 0;
        height: 100%;
        position: relative;
        transition: left 250ms cubic-bezier(0.25, 0.1, 0.25, 1);

        li {
          margin: 0;

          &.spacer {
            flex: 1;
          }

          a {
            padding: 0.5rem 0.5rem 0.5rem 1.5rem;
            color: #d7d6d9;
            cursor: pointer;
            display: block;
            position: relative;
            width: 100%;
            text-decoration: none;

            &:hover {
              background-color: rgba(93, 91, 102, 0.4);
              color: #fff;
            }
          }
        }
      }
    }
  }

  ${props => {
    if (props.theme.mobile)
      return {
        flex: "none",
        height: "auto",
        ".header": {
          flex: "none",
          ".logo": {
            height: "48px",
            margin: 0
          }
        },
        ".items-header,.container,.footer": {
          display: "none",
          flex: "none"
        },
        "&.is-open": {
          height: "100%",
          display: "flex",
          ".items-header,.container,.footer": {
            display: "block"
          },

          ".container": {
            flex: "1 100%"
          }
        }
      };
  }};
`;

export const NavItem = styled.a`
  padding: 0.5rem 0.5rem 0.5rem 1.5rem;
  color: #d7d6d9;
  cursor: pointer;
  display: block;
  position: relative;
  width: 100%;
  text-decoration: none;
  box-sizing: border-box;

  &:hover,
  &.is-active {
    background-color: rgba(93, 91, 102, 0.4);
    color: #fff;
  }
`;

export const NavIcon = styled.span`
  position: absolute;
  right: 1rem;
  transition: transform 100ms ease;
`;

export const NavGroup = styled.div`
  left: ${props => (props.theme.mobile ? " 100vw" : "200px")};
  position: absolute;
  top: 0;
  width: 100%;

  > a {
    color: #fff;
    font-weight: 400;
    margin-bottom: 2rem;

    .icon {
      margin-right: 0.75rem;
      vertical-align: top;
    }

    &:hover {
      background-color: #34313f;
      color: #fff;
    }
  }
  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      margin: 0;
    }

    a {
      //padding: .5rem .5rem .5rem 1.5rem;
      padding: 0.5rem 0.5rem 0.5rem 3.5rem;
      color: #d7d6d9;
      cursor: pointer;
      display: block;
      position: relative;
      width: 100%;
      text-decoration: none;

      &.is-active {
        background-color: rgba(35, 33, 43, 0.6);
        color: #fff;
      }
      &:hover {
        background-color: rgba(93, 91, 102, 0.4);
        color: #fff;
      }
    }
  }
`;
