import React from "react";
import styled from "styled-components";

const Div = styled.div`
  background: white;
  width: 100%;
  box-sizing: border-box;
  padding: 0;
  margin-bottom: 1.5rem;
  border: 1px solid rgba(228, 231, 237, 0.8);
  // box-shadow: 0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15);
  border-radius: 2px;

  &.alt {
    background-color: #fbfbfc;
    border: 1px solid rgba(228, 231, 237, 0.4);
  }

  div:last-child {
    &.cms__component--columns {
      margin-bottom: 20px;
    }
  }

  .cms__component--group {
    margin-bottom: 0;
    border: 1px solid white;
  }

  > p {
    margin: 1.5rem;
  }
`;

export default ({ children, style, className }) => {
  return <Div style={style} className={className}>{children}</Div>;
};
