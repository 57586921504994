import { getFirestore } from "../../Boutique/functions";
import moment from "moment";

class Contact {
  static transfertToBoutique(boutique) {
    const batchSandwich = getFirestore().batch();
    let nbPageTransfered = 0;
    return getFirestore()
      .collection("contacts")
      .where("boutique", "==", boutique.id)
      .where("deleted", "==", false)
      .limit(500)
      .get()
      .then(querySnpashot => {
        if (!querySnpashot.empty) {
          let batch = getFirestore(boutique).batch();

          let newRef = undefined,
            data = undefined;
          querySnpashot.docs.forEach(document => {
            nbPageTransfered++;
            newRef = getFirestore(boutique)
              .collection("contacts")
              .doc(document.id);
            data = document.data();
            delete data.boutiqueRef;
            batch.set(newRef, data, { merge: true });
            batchSandwich.set(
              document.ref,
              {
                updatedAt: moment().toISOString(),
                importedAt: moment().toISOString(),
                deleted: true
              },
              { merge: true }
            );
          });

          batchSandwich.commit();
          return batch.commit().then(() => {
            return nbPageTransfered;
          });
        } else {
          return 0;
        }
      });
  }
}

export default Contact;
