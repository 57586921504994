import React, {Component} from 'react';
import { connect } from 'react-redux';

import Data from '../sysext/CMS/components/Data/index';
import resetpassword from '../params/resetpassword.yml';

class ForgetPassword extends Component {
    constructor(props) {
        super(props);

        this.resetPassword = this.resetPassword.bind(this);
    }
    resetPassword = (item) => {
        // TODO ecrire la fonction
    };

    render() {
        const {item, error} = this.props;
        return (
            <div className="no-connect">
                <Data data={resetpassword} item={item} error={error}
                      onSubmit={this.resetPassword}/>
            </div>

        );
    }
}


const mapStateToProps = (globalState, ownProps) => {
    return {
        item: {
            email: null
        }
    };
};

const mapDispatchToProps = (dispatch) => {
    return {}
};

export default connect(mapStateToProps, mapDispatchToProps)(ForgetPassword);
