import React, { Component } from "react";
import StyledRow from "../../../../styles/View/List/Row";
import Button from "../../../../components/Button";
import Field from "../../../../styles/Field";

class Row extends Component {
  state = {
    mustBeLoadedBoutique: false,
    boutiqueRef: null,
    boutiqueData: null
  };

  componentDidMount() {
    if (this.state.mustBeLoadedBoutique) {
      this.loadBoutique(this.state.boutiqueRef);
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.state.mustBeLoadedBoutique) {
      this.loadBoutique(this.state.boutiqueRef);
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (
      props.doc &&
      ((!state.boutiqueRef && props.doc.data().boutiqueRef) ||
        (state.boutiqueRef &&
          state.boutiqueRef.id !== props.doc.data().boutiqueRef.id))
    ) {
      state.mustBeLoadedBoutique = true;
      state.boutiqueRef = props.doc.data().boutiqueRef;
    }

    return state;
  }

  loadBoutique(boutique) {
    boutique.get().then(snapshot => {
      this.setState({
        boutiqueData: snapshot.data(),
        mustBeLoadedBoutique: false
      });
    });
  }

  render() {
    const { doc, onClickDatas, onClickEdit } = this.props;
    const item = doc.data();

    return (
      <StyledRow>
        <div className="infos">
          <span className="name" style={{ flex: 1 }} onClick={onClickEdit}>
            {item.name}
          </span>
          {this.state.boutiqueData && (
            <span>
              {this.state.boutiqueData.libelle
                ? `${this.state.boutiqueData.libelle} - ${
                    this.state.boutiqueData.name
                  }`
                : this.state.boutiqueData.name}
            </span>
          )}
        </div>
        <Field noMarginTop className="field-actions">
          <div className="has-addons">
            {doc.id && (
              <Button
                smallIcon
                id={doc.id}
                type="id"
                className="no-left-radius"
              />
            )}
            <Button smallIcon icon="fa-database" onClick={onClickDatas} />
            <Button smallIcon icon="fa-edit" onClick={onClickEdit} />
          </div>
        </Field>
      </StyledRow>
    );
  }
}
export default Row;
