import React from "react";
import Button from "../../../../../CMS/components/Button";
import List from "../../../../../CMS/containers/Boutique/components/List";
import ActionBar from "../../../../../CMS/components/ActionBar";
import Card from "./Card";
import modele from "../../params.json";
import { initialize, validate } from "../../functions";
import Modele from "../../../../../CMS/datas/Modele.js";
import messaging from "../../../../../../api/messaging";

class ModelesList extends List {
  collectionName = "modeles";
  defaultOrderField = "datetime";
  defaultOrderDirection = "desc";
  listTitle = "Modèles";
  viewCard = Card;
  itemTitle = { type: "attribut", attribut: "name" };
  initializeItem = initialize;
  validateItem = validate;
  itemParams = modele;
  messageNoDocument = "Aucun modèle";

  constructor(props) {
    super(props, {
      defaultViewList: "grid",
      newItemLibelle: "Nouveau modèle",
      tabsArray: [
        {
          label: "Tous les modèles",
          actif: true,
          showInfo: true,
          showBulles: true,
          wheres: {}
        },
        {
          label: "Cachés",
          actif: false,
          showInfo: true,
          showBulles: true,
          wheres: {
            isOnlineStore: false
          }
        },
        {
          label: "En vedette",
          actif: false,
          showBulles: true,
          wheres: {
            isFeatured: true
          }
        }
      ],
      custom: {
        categories: [],
        wheres: {
          category: "",
          sku: ""
        }
      }
    });

    this.handleTransfertToProject = this.handleTransfertToProject.bind(this);
    this.handleSendNotification = this.handleSendNotification.bind(this);

    this.state.form.required = ["name"];

    this.state.list.menu = {};
    this.state.form.menu.left = [
      {
        className: "delete",
        icon: "fa-trash",
        onClick: this.handleDelete
      },
      {
        className: "previous all-grey",
        icon: "fa-chevron-left",
        onClick: this.handlePrevious
      },
      {
        className: "next all-grey",
        icon: "fa-chevron-right",
        onClick: this.handleNext
      }
    ];
    this.state.form.menu.right = [
      {
        icon: "fa-plus",
        onClick: this.handleCreate
      },
      {
        icon: "fa-close",
        onClick: this.handleClose
      }
      // {
      //   text: "Fermer",
      //   submit: true
      // }
    ];
  }

  handleTransfertToProject(e) {
    e.preventDefault();
    if (!this.props.boutique.get("firebaseApiKey")) {
      console.error(
        "Il n'est pas possible de transférer les modèles car le projet n'est pas configuré"
      );
    }

    Modele.transfertToBoutique(this.props.boutique).then(result => {
      console.info("result", result);
    });
  }

  handleSendNotification(e) {
    e.preventDefault();

    if (!messaging) return;

    messaging
      .getToken()
      .then(currentToken => {
        if (currentToken) {
          console.info("currentToken", currentToken);
        } else {
          // Show permission request.
          console.log(
            "No Instance ID token available. Request permission to generate one."
          );
          // Show permission UI.
        }
      })
      .catch(err => {
        console.log("An error occurred while retrieving token. ", err);
      });

    // Notification.requestPermission().then(permission => {
    //   if (permission === "granted") {
    //     console.log("Notification permission granted.");
    //     // TODO(developer): Retrieve an Instance ID token for use with FCM.
    //     // ...
    //   } else {
    //     console.log("Unable to get permission to notify.");
    //   }
    // });
  }

  /**
   * Cette fonction permet d'ajouter un traitement après la création du document
   *
   * @param ref
   * @param item
   */
  handleWhenCreateSuccess(ref, item) {
    // Créer le numéro SKU
    Modele.getNewSku(this.props.boutique, item.publishedAt).then(sku => {
      this.props.changeFormValue("sku", sku);
    });
  }

  /**
   * Cette fonction permet d'ajouter un traitement après la mise à jour du document
   *
   * @param ref
   * @param item
   * @returns {null}
   */
  handleWhenUpdateSuccess(ref, item) {
    // this.setState({
    //   item: null,
    //   index: null,
    //   key: null,
    //   ref: null
    // });
  }

  loadAlternativeFunc(boutique) {}

  addActionBarToRender() {
    let menuClassNames = ["menu-annexe"];
    menuClassNames.push(this.state.openMenu ? "open" : "close");

    return (
      <ActionBar className="action-bar is-clearfix">
        <ul>
          <li className="navbar-item">
            <Button text="Ajouter" onClick={this.handleCreate} />
          </li>
        </ul>

        <ul className="border-left navbar-right">
          <li className="navbar-item nbByRow">
            <input
              type="number"
              value={this.state.nbParLigne}
              onChange={this.handleOnChangeNbParLigne}
            />
          </li>
        </ul>
        <div className={menuClassNames.join(" ")}>
          <Button
            icon="fa-ellipsis-v"
            className="open-menu"
            onClick={this.handleOpenMenuOnMobile}
          />
          <div className="menu-annexe-content">
            <ul className="border-left">
              <li className="navbar-item search">
                <input
                  type="text"
                  value={this.state.q}
                  onChange={this.handleOnChangeSearch}
                  onKeyPress={this.handleOnKeyPressSearch}
                  placeholder="Rechercher"
                />
                {this.state.q && (
                  <button className="empty" onClick={this.handleEmptySearch}>
                    <span className="icon" />
                    <span className="text">Vider</span>
                  </button>
                )}
              </li>
            </ul>
          </div>
        </div>
      </ActionBar>
    );
  }
}

export default ModelesList;
