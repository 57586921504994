import { getFirestore } from "../../Boutique/functions";
import moment from "moment";

class Action {
  /**
   * Cette méthode permet de créer une nouvelle action à traiter coder serveur
   *
   * @param boutique
   * @param type
   * @param data
   * @returns {Promise<firebase.firestore.DocumentReference>}
   */
  static create(boutique, type, data) {
    data.type = type;
    data.boutique = boutique.id;
    data.createdAt = moment().toISOString();
    data.deleted = false;

    return getFirestore(boutique)
      .collection("actions")
      .add(data);
  }
}

export default Action;
