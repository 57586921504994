import React, { Component } from "react";
import StyledField from "../../../styles/Field";
import { Field } from "redux-form";

class Radio extends Component {
  render() {
    const {
      input: { name },
      options,
      orientation,
      icon,
      label,
      className,
      meta: { touched, error },
      ...others
    } = this.props;

    return (
      <StyledField
        className={
          "cms__field radio " +
          (touched && error ? "error " : "") +
          (orientation ? `orientation_${orientation} ` : "") +
          (className ? className : "")
        }
      >
        {icon &&
          false && (
            <span className="icon is-small">
              <i className={"fa " + icon} />
            </span>
          )}

        {options.map((option, index) => {
          return (
            <label key={index} className="radio">
              <Field
                name={name}
                {...others}
                component="input"
                type="radio"
                value={option.value}
              />
              <span>{option.label}</span>
            </label>
          );
        })}

        <label className="label">
          <span>{label}</span>
        </label>
        {touched &&
          error && (
            <div className={"error error_" + error.code}>
              <span className="icon">
                <i className="fa fa-exclamation" />
              </span>
              <span>{error.message}</span>
            </div>
          )}
      </StyledField>
    );
  }
}

export default Radio;
