import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Field, change } from "redux-form";
import ComponentField from "../../../../styles/Field";

// import { list } from "../../actions";
// import { searchInCollection } from "../../../Feature/actions";

class Select extends Component {
  constructor(props) {
    super(props);

    // this.state = {
    //   user: null,
    //   shouldLoad: false,
    //   options: []
    // };

    this.handleChangeValue = this.handleChangeValue.bind(this);
  }

  // static getDerivedStateFromProps(props, state) {
  //   if (!state.user && props.user) {
  //     state.user = props.user;
  //     state.shouldLoad = true;
  //   }
  //
  //   return state;
  // }

  // componentDidMount() {
  //   if (this.state.shouldLoad) {
  //     this.loadList(this.state.user);
  //   }
  // }
  //
  // componentDidUpdate(prevProps, prevState, snapshot) {
  //   if (this.state.shouldLoad) {
  //     this.loadList(this.state.user);
  //   }
  // }

  // loadList(user) {
  //   this.props.meta.dispatch(list(user)).then(action => {
  //       if (action.boutiques.length > 0) {
  //         this.setState(
  //           {
  //             options: action.boutiques,
  //             shouldLoad: false
  //           },
  //           () => {
  //             if (this.input.value) {
  //               this.input.classList.remove("is-empty");
  //             } else {
  //               this.input.classList.add("is-empty");
  //             }
  //
  //             this.input.addEventListener("focus", function(event) {
  //               event.currentTarget.classList.add("is-focused");
  //             });
  //             this.input.addEventListener("blur", function(event) {
  //               event.currentTarget.classList.remove("is-focused");
  //             });
  //             this.input.addEventListener("keyup", function(event) {
  //               if (event.currentTarget.value.length === 0) {
  //                 event.currentTarget.classList.add("is-empty");
  //               } else {
  //                 event.currentTarget.classList.remove("is-empty");
  //               }
  //             });
  //           }
  //         );
  //       }
  //   });
  // }

  handleChangeValue = event => {
    const index = this.props.boutiques.findIndex(
      value => value.id === event.currentTarget.value
    );

    this.props.meta.dispatch(
      change("item", this.props.input.name, event.currentTarget.value)
    );
    if (this.props.fieldType !== "string") {
      this.props.meta.dispatch(
        change(
          "item",
          this.props.input.name + "Ref",
          this.props.boutiques[index].ref
        )
      );
    }
  };

  render() {
    const {
      input: { name, value },
      label,
      horizontal,
      className,
      meta: { touched, error }
    } = this.props;

    return (
      <ComponentField
        className={`field select ${className} ${
          touched && error ? "error" : ""
        } ${horizontal ? "horizontal" : ""}`}
      >
        {this.props.boutiques.length > 0 ? (
          <select
            name={name}
            value={value.id ? value.id : value}
            ref={input => {
              this.input = input;
            }}
            onChange={this.handleChangeValue}
          >
            <option value="">Non spécifié</option>
            {this.props.boutiques.map((item, index) => (
              <option key={index} value={item.id}>
                {item.get("libelle") ? `${item.get("libelle")} - ` : " "}
                {item.get("name")}
              </option>
            ))}
          </select>
        ) : (
          <div>...</div>
        )}
        <label className="label">
          <span>{label}</span>
        </label>
        {touched &&
          error && (
            <div className={"error error_" + error.code}>
              <span className="icon">
                <i className="fa fa-exclamation" />
              </span>
              <span>{error.message}</span>
            </div>
          )}
      </ComponentField>
    );
  }
}

Select.propTypes = {
  label: PropTypes.string
};

const mapStateToProps = globalState => {
  const props = {};
  props.boutiques = globalState.cms.boutiques;

  return props;
};

Select = connect(
  mapStateToProps,
  null
)(Select);

export default props => {
  return <Field {...props} component={Select} />;
};
