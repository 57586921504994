import {
  COMMANDES_LOAD,
  COMMANDE_NEW_NUMERO,
  COMMANDES_RESET
} from "../../actionTypes";
import moment from "moment";
import { createQuery } from "../../functions";
moment.locale("fr");

export function load(boutique) {
  return {
    type: COMMANDES_LOAD,
    payload: createQuery(boutique, "commandes")
      .where("boutique", "==", boutique.id)
      .where("deleted", "==", false)
      .where("isArchived", "==", false)
      .get()
  };
}

export function getNewNumero(boutique, date) {
  const debut = date ? moment(date).format("YYWWE") : moment().format("YYWWE");
  const fin = "" + parseInt(debut, 10) + 1;

  return dispatch => {
    return boutique.ref
      .collection("commandes")
      .where("numero", ">", debut)
      .where("numero", "<", fin)
      .orderBy("numero")
      .get()
      .then(querySnapshot => {
        if (querySnapshot.size > 0) {
          const numero = querySnapshot.docs[querySnapshot.size - 1].data()
            .numero;

          return dispatch({
            type: COMMANDE_NEW_NUMERO,
            numero:
              debut + ("0000" + (parseInt(numero.substr(-3), 10) + 1)).slice(-4)
          });
        }

        return dispatch({
          type: COMMANDE_NEW_NUMERO,
          numero: debut + "0001"
        });
      });
  };
}

export function reset(boutique) {
  return dispatch => {
    const unsubscribe = boutique.ref
      .collection("commandes")
      .onSnapshot(function() {});
    // Stop listening to changes
    unsubscribe();

    dispatch({
      type: COMMANDES_RESET
    });
  };
}
