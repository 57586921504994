import React, { Component } from "react";
import moment from "moment";

class Item extends Component {
  render() {
    const { doc } = this.props;
    const item = doc.data();

    let modeText = "";
    switch (item.mode) {
      case "espece":
        modeText = "Espèce";
        break;
      case "cheque":
        modeText = "Chèque";
        break;
      case "cb":
        modeText = "Carte";
        break;
      case "virement":
        modeText = "Virement";
        break;
      case "stripe":
        modeText = "Stripe";
        break;
      case "paypal":
        modeText = "Paypal";
        break;
      default:
        modeText = "n/a";
    }

    return (
      <li>
        <div className="date">
          <span className="day">{moment(doc.data().date).format("Do")}</span>
          <span className="month">{moment(doc.data().date).format("MMM")}</span>
        </div>
        <div className="infos">
          <ul>
            <li>
              <label>Montant :</label>
              <span className="price value">{item.montant}</span>
            </li>
            <li>
              <label>Mode :</label>
              <span className="value">{modeText}</span>
            </li>
          </ul>
        </div>

        <ul className="mode">
          <li className={item.mode}>{modeText}</li>
        </ul>
      </li>
    );
  }
}

export default Item;
