import React, { Component } from "react";
import PropTypes from "prop-types";
import StyledRow from "../../../../../CMS/styles/View/List/Row";
import { getFirestore } from "../../../../functions";
import moment from "moment";

class Row extends Component {
  constructor(props) {
    super(props);

    this.state = {
      mustBeLoadedContact: false,
      contact: undefined,
      contactData: undefined
    };

    this.loadContact = this.loadContact.bind(this);
  }

  componentDidMount() {
    if (this.state.mustBeLoadedContact) {
      this.loadContact(this.state.contact);
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.state.mustBeLoadedContact) {
      this.loadContact(this.state.contact);
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (
      props.doc &&
      props.doc.get("contact") &&
      (!state.contact || state.contact !== props.doc.get("contact"))
    ) {
      state.mustBeLoadedContact = true;
      state.contact = props.doc.get("contact");
    }

    return state;
  }

  loadContact(contactId) {
    this.setState({
      mustBeLoadedContact: false
    });
    getFirestore(this.props.boutique)
      .collection("contacts")
      .doc(contactId)
      .get()
      .then(snapshot => {
        if (snapshot.exists) {
        }
        this.setState({
          contactData: snapshot.data()
        });
      });
  }

  render() {
    const { doc, onClick } = this.props;
    const item = doc.data();

    let interval;
    switch (item.interval) {
      case "day":
        interval = "jours";
        break;
      case "week":
        interval = "semaines";
        break;
      case "month":
        interval = "mois";
        break;
      case "year":
        interval = "années";
        break;
      default:
        interval = " non défini";
    }
    return (
      <StyledRow>
        {item.startAt && (
          <div className="date">
            <span className="day">{moment(item.startAt).format("Do")}</span>
            <span className="month">{moment(item.startAt).format("MMM")}</span>
          </div>
        )}
        <p className="name" style={{ flex: 1 }}>
          {item.amount}€ tous les{" "}
          {item.intervalCount > 1 ? item.intervalCount : ""} {interval} -{" "}
          {this.state.contactData && this.state.contactData.name}
        </p>
        <p className="control actions">
          <button onClick={onClick} className="edit small">
            <span className="icon" />
            <span className="txt">Edit</span>
          </button>
        </p>
      </StyledRow>
    );
  }
}

Row.propTypes = {
  doc: PropTypes.object.isRequired
};

export default Row;
