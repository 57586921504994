import React, { Component } from "react";
import Field from "../../../../styles/Field";
import Modal from "./Modal";
import Button from "../../../../components/Button/index";
import { DragSource, DropTarget } from "react-dnd";

const ItemTypes = {
  ITEM: "item"
};

/**
 * Implements the drag source contract.
 */
const itemSource = {
  beginDrag(props) {
    return {
      index: props.index,
      id: props.itemRef.id
    };
  }
};

/**
 * Specifies the props to inject into your component.
 */
function collectItem(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging()
  };
}

const Libelle = ({ item, field, handleChangeValue }) => {
  if (field) {
    switch (field.component) {
      case "select":
        return (
          <Field
            className="is-expanded select"
            noMarginRight
            noRightRadius
            noBorderRight
            noMarginBottom
          >
            <select
              value={item.libelle}
              className="select is-expanded"
              onChange={event =>
                handleChangeValue("libelle", event.target.value)
              }
            >
              <option value="">-- veuillez selectionner une valeur --</option>
              {field.options.map((option, index) => (
                <option key={index} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </Field>
        );

      case "input":
      default:
    }
  }

  return (
    <Field
      className="is-expanded"
      noMarginRight
      noRightRadius
      noBorderRight
      noMarginBottom
    >
      <input
        type="text"
        value={item.libelle}
        placeholder="Libelle"
        className="input is-expanded"
        onChange={event => handleChangeValue("libelle", event.target.value)}
      />
      <label className="label">
        <span>Libellé</span>
      </label>
    </Field>
  );
};

class Item extends Component {
  constructor(props) {
    super(props);

    this.state = {
      openModal: false
    };

    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
  }

  handleOpenModal(e) {
    e.preventDefault();

    this.setState({
      openModal: true
    });
  }

  handleCloseModal(e) {
    e.preventDefault();

    this.setState({
      openModal: false
    });
  }

  render() {
    const {
      title,
      item,
      itemRef,
      libelleField,
      customFields,
      modalFields,
      modalDatas,
      handleChangeValue,
      handleUpdate,
      handleRemove,
      connectDragSource,
      isDragging
    } = this.props;

    console.info("render item");

    return connectDragSource(
      <div
        style={{ display: "flex" }}
        className={`${isDragging ? "isDragging" : ""}`}
      >
        {this.state.openModal && (
          <Modal
            title={title}
            item={item}
            itemRef={itemRef}
            customFields={customFields}
            modalFields={modalFields}
            modalDatas={modalDatas}
            handleChangeValue={handleChangeValue}
            handleUpdate={handleUpdate}
            handleClose={this.handleCloseModal}
          />
        )}
        <Libelle
          item={item}
          field={libelleField}
          handleChangeValue={handleChangeValue}
        />
        {customFields &&
          customFields.length > 0 &&
          customFields.map((field, j) => (
            <Field
              key={j}
              className="is-expanded"
              noMarginLeft
              noLeftRadius
              noRightRadius
              noMarginRight
              noBorderRight
              noMarginBottom
            >
              <input
                type={field.type ? field.type : "text"}
                value={item[field.name]}
                placeholder={field.label}
                className="input is-expanded"
                onChange={event =>
                  handleChangeValue(field.name, event.target.value, field.type)
                }
              />
              <label className="label">
                <span>{field.label}</span>
              </label>
            </Field>
          ))}
        <Field noMarginLeft noMarginBottom>
          <div className="has-addons">
            {item.ref && item.ref.id && (
              <Button
                smallIcon
                id={item.ref.id}
                type="id"
                className="no-left-radius"
              />
            )}
            {item.change && (
              <Button
                smallIcon
                icon="fa-save"
                className="button"
                onClick={handleUpdate}
              />
            )}
            {((modalFields && modalFields.length > 0) ||
              (modalDatas && modalDatas.length > 0)) && (
              <Button smallIcon icon="fa-edit" onClick={this.handleOpenModal} />
            )}
            <Button smallIcon icon="fa-trash" onClick={handleRemove} />
          </div>
        </Field>
      </div>
    );
  }
}

Item = DragSource(ItemTypes.ITEM, itemSource, collectItem)(Item);

const positionTarget = {
  drop(props, monitor) {
    const item = monitor.getItem();
    props.handleChangePosition(item, props.index);
  }
};

function collect(connect, monitor) {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver()
  };
}

class ItemPosition extends Component {
  render() {
    const {
      connectDropTarget,
      isOver,
      handleChangePosition,
      ...others
    } = this.props;

    return connectDropTarget(
      <div className={isOver ? "isOver" : ""}>
        <Item handleChangePosition={handleChangePosition} {...others} />
      </div>
    );
  }
}

export default DropTarget(
  ItemTypes.ITEM,
  positionTarget,
  collect
)(ItemPosition);
