import React, { Component } from "react";
import connect from "react-redux/es/connect/connect";
import CMSView from "../../../CMS/components/View";
import CMSForm from "../../../CMS/components/View/Form";
import CMSData from "../../../CMS/components/Data";

import boutique from "./params.json";
import { diff } from "deep-object-diff";
import { validate } from "../../../CMS/containers/Boutique/functions";
import { update } from "../../../CMS/containers/Boutique/actions";

class Params extends Component {
  constructor(props) {
    super(props);

    this.state = {
      boutique: null,
      hasUpdatedFields: false,
      actionInProgess: false
    };

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    if (
      props.boutique &&
      (!state.boutique || state.boutique.id !== props.boutique.id)
    ) {
      state.boutique = props.boutique;
      state.canBeSubmited = false;
    }

    return state;
  }

  handleSubmit(values) {
    if (!this.state.actionInProgess) {
      this.setState(
        {
          actionInProgess: true
        },
        () => {
          this.props.update(this.props.boutique.ref, values).then(action => {
            this.setState({
              hasUpdatedFields: false,
              actionInProgess: false
            });
          });
        }
      );
    }
  }

  render() {
    if (!this.props.boutique) {
      return <div>Chargement en cours de la boutique</div>;
    }

    let propsForm = {
      initialValues: this.props.boutique.data(),
      required: ["libelle"],
      onChange: (values, dispatch, props, previousValues) => {
        if (this.timeoutId) {
          clearTimeout(this.timeoutId);
        }

        console.info("onChange", diff(previousValues, values));
        if (this.state.canBeSubmited) {
          this.setState({
            hasUpdatedFields: true
          });

          this.timeoutId = setTimeout(() => {
            this.handleSubmit(values, false);
          }, 1000);
        } else {
          // Initialisation des valeurs
          this.setState({
            canBeSubmited: true
          });
        }
      }
    };

    return (
      <CMSView className="cms__boutique">
        <CMSForm
          {...propsForm}
          title={{ type: "attribut", attribut: "libelle" }}
          onSubmit={this.handleSubmit}
        >
          <CMSData
            data={boutique}
            itemRef={this.props.boutique.ref}
            user={this.props.user}
          />
        </CMSForm>
      </CMSView>
    );
  }
}

const mapStateToProps = (globalState, ownProps) => {
  return {};
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    update: (ref, item) => dispatch(update(ref, validate(item)))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Params);
