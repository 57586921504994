import React, { Component } from "react";

import CMSList from "../../../../../../../CMS/components/View/List";
import CMSForm from "../../../../../../../CMS/components/View/Form";
import CMSView from "../../../../../../../CMS/components/View/index";
import CMSData from "../../../../../../../CMS/components/Data/index";
import Row from "./Row";

import { initialize, validate } from "../../functions";
import caracteristique from "../../params.json";
import moment from "moment";
// import { diff } from "deep-object-diff";

class List extends Component {
  listTitle = "Caractéristiques";

  constructor(props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCreate = this.handleCreate.bind(this);
    this.handleNext = this.handleNext.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleClose = this.handleClose.bind(this);

    this.state = {
      mustBeLoaded: false,
      boutique: undefined,
      docs: undefined,
      item: null,
      index: 0,
      key: null,
      ref: null,
      canBeSubmited: false,
      list: {
        menu: {
          left: [
            {
              icon: "fa-plus",
              onClick: this.handleCreate
            }
          ]
        }
      },
      form: {
        menu: {
          left: [
            {
              className: "delete",
              icon: "fa-trash",
              onClick: this.handleDelete
            },
            {
              className: "previous",
              icon: "fa-chevron-left",
              onClick: this.handlePrevious
            },
            {
              className: "next",
              icon: "fa-chevron-right",
              onClick: this.handleNext
            }
          ],
          right: [
            {
              icon: "fa-close",
              onClick: this.handleClose
            }
          ]
        }
      }
    };

    this.state.form.onChange = (values, dispatch, props, previousValues) => {
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }

      this.timeoutId = setTimeout(() => {
        if (this.state.canBeSubmited) {
          this.handleSubmit(values, false);
        }
      }, 1000);
    };

    this.watchFeatures = this.watchFeatures.bind(this);
  }

  componentDidMount() {
    if (this.state.mustBeLoaded) {
      this.watchFeatures();
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.state.mustBeLoaded) {
      this.watchFeatures();
    }
  }

  componentWillUnmount() {
    if (this.state.unsubscribe) {
      this.state.unsubscribe();
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (
      props.boutique &&
      (!state.boutique || props.boutique !== state.boutique)
    ) {
      state.boutique = props.boutique;
      state.mustBeLoaded = true;
    }

    return state;
  }

  watchFeatures() {
    const _this = this;
    if (this.state.unsubscribe) {
      this.state.unsubscribe();
    }

    const unsubscribe = this.state.boutique.ref
      .collection("productFeatures")
      .where("deleted", "==", false)
      .orderBy("libelle")
      .onSnapshot(querySnapshot => {
        _this.setState({
          docs: querySnapshot.docs
        });
      });

    this.setState({
      mustBeLoaded: false,
      unsubscribe: unsubscribe
    });
  }

  handleSubmit(item) {
    const validateItem = validate(item);
    if (this.state.ref) {
      this.state.ref
        .update(validateItem)
        .then(action => {
          if (!action.error) {
            this.props.createNotification({
              type: "info",
              message: "Mise à jour réussie de la caractéristique"
            });
          }
        })
        .catch(error => {
          this.props.createNotification({
            type: "error",
            message: `Mise à jour de la caractéristique : ${error.message}`
          });
        });
    } else {
      this.state.boutique.ref
        .collection("productFeatures")
        .add(validateItem)
        .then(documentReference => {
          this.setState(
            {
              validateItem,
              index: 0,
              key: documentReference.id,
              ref: documentReference
            },
            () => {
              this.props.createNotification({
                type: "info",
                message: "Création de la caractéristique réussie"
              });
            }
          );
        })
        .catch(error => {
          this.props.createNotification({
            type: "error",
            message: `Création de la caractéristique : ${error.message}`
          });
          alert("Erreur lors de la création de la caractéristique");
        });
    }
  }

  handleCreate() {
    this.setState(
      {
        item: initialize(caracteristique),
        index: null,
        key: null,
        ref: null,
        canBeSubmited: true
      },
      () => {
        this.props.createNotification({
          type: "info",
          message: `Initialisation d'une nouvelle caractéristique`
        });
      }
    );
  }

  handleNext() {
    this.setState(oldState => {
      const nextIndex = parseInt(oldState.index, 10) + 1;
      if (this.state.docs.length > nextIndex) {
        return {
          index: nextIndex,
          item: this.state.docs[nextIndex].data(),
          key: this.state.docs[nextIndex].id,
          ref: this.state.docs[nextIndex].ref
        };
      }
    });
  }

  handleDelete() {
    if (this.state.index !== null && this.state.index >= 0) {
      const confirmation = window.confirm(
        "Voulez-vous vraiment supprimer cette caractéristique ?"
      );
      if (confirmation) {
        const _this = this;
        this.state.ref
          .update({
            deleted: true,
            updatedAt: moment().toISOString()
          })
          .then(() => {
            _this.setState(
              {
                item: null,
                index: null,
                key: null,
                ref: null,
                canBeSubmited: false
              },
              () => {
                _this.props.createNotification({
                  type: "info",
                  message: `Suppression réussie de la caractéristique`
                });
              }
            );
          });
      }
    } else {
      this.props.createNotification({
        type: "error",
        message: `Impossible de supprimer la caractéristique`
      });
    }
  }

  handleChange(index) {
    this.setState({
      index: index,
      item: this.state.docs[index].data(),
      key: this.state.docs[index].id,
      ref: this.state.docs[index].ref,
      canBeSubmited: true
    });
  }

  handleClose(e) {
    if (e) {
      e.preventDefault();
    }

    this.setState({
      item: null,
      index: null,
      key: null,
      ref: null,
      canBeSubmited: false
    });
  }

  render() {
    const props = {
      list: {
        ...this.state.list,
        title: this.listTitle,
        loading: this.props.loading
      },
      form: {
        ...this.state.form,
        initialValues: this.state.item,
        id: this.state.key
      }
    };

    return (
      <CMSView>
        {this.state.item ? (
          <CMSForm
            {...props.form}
            title={{ type: "attribut", attribut: "libelle" }}
            onSubmit={this.handleSubmit}
          >
            <CMSData
              data={caracteristique}
              id={this.state.key}
              user={this.props.user}
              boutique={this.props.boutique}
              itemRef={this.state.ref}
            />
          </CMSForm>
        ) : (
          <CMSList {...props.list}>
            {this.state.docs ? (
              this.state.docs.length > 0 ? (
                <ol>
                  {this.state.docs.map((doc, index) => (
                    <Row
                      key={index}
                      onClick={event => this.handleChange(index, event)}
                      doc={doc}
                    />
                  ))}
                </ol>
              ) : (
                <div>Aucune catégorie</div>
              )
            ) : (
              <div>Chargement en cours</div>
            )}
          </CMSList>
        )}
      </CMSView>
    );
  }
}

export default List;
