import React, { Component } from "react";
import Section from "../../../../../../components/Data/Section";
import Input from "../../../../../../components/Input";
import Select from "../../../../../../components/Select";

class Titre extends Component {
  options = {
    headerType: [
      {
        value: "h1",
        label: "H1"
      },
      {
        value: "h2",
        label: "H2"
      },
      {
        value: "h3",
        label: "H3"
      },
      {
        value: "h4",
        label: "H4"
      },
      {
        value: "h5",
        label: "H5"
      },
      {
        value: "h6",
        label: "H6"
      },
      {
        value: "hidden",
        label: "Caché"
      }
    ],
    headerAlignement: [
      {
        value: "center",
        label: "Centré"
      },
      {
        value: "left",
        label: "A gauche"
      },
      {
        value: "right",
        label: "A droite"
      },
      {
        value: "justify",
        label: "Justifié"
      }
    ]
  };

  render() {
    const { data, handleOnChange } = this.props;

    return (
      <div>
        <Section title="Titre" group={true}>
          <Input
            type="text"
            value={data.header}
            name="header"
            onChange={handleOnChange}
            label="Titre"
          />
          <Select
            label="Type"
            value={data.headerType}
            options={this.options.headerType}
            name="headerType"
            onChange={handleOnChange}
          />
          <Select
            label="Alignement"
            value={data.headerAlignement}
            options={this.options.headerAlignement}
            name="headerAlignement"
            onChange={handleOnChange}
          />
          <Input
            type="date"
            label="Date"
            value={data.headerDate}
            name="headerDate"
            onChange={handleOnChange}
          />
          <Input
            type="text"
            label="Lien"
            value={data.headerLink}
            name="headerLink"
            onChange={handleOnChange}
          />
          <Input
            type="text"
            label="Sous titre"
            value={data.headerSubTitle}
            name="headerSubTitle"
            onChange={handleOnChange}
          />
        </Section>
      </div>
    );
  }
}

export default Titre;
