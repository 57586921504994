import React from "react";
import styled from "styled-components";

const Columns = styled.div`
  display: flex;
  flex-direction: ${props => (props.theme.mobile ? "column" : "row")};

  > div.cms__component--column + div.cms__component--column {
    margin-left: ${props => (props.theme.mobile ? 0 : "1.5rem")};
  }

  > div.cms__field {
    margin-bottom: 0;
  }
  > div.cms__field + div.cms__field {
    margin-left: ${props => (props.theme.mobile ? "1.5rem" : 0)};

    &.is-expanded {
      flex: 1;
    }
  }
`;

export default props => (
  <Columns className="cms__component--columns">{props.children}</Columns>
);
