import firebase from "./firebase";
import "firebase/messaging";

let messaging = null;
if (firebase.messaging.isSupported()) {
  messaging = firebase.messaging();
  messaging.usePublicVapidKey(
    "BP-qOBb9s6-Mz-qsX8un0Jftol7doeO4lEiWX9jqGuPpGylnLTze-nV94c0e8mB339hVAfIZxpMaD6qklzg5118"
  );
}

export default messaging;
