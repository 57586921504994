import moment from "moment";
import * as functions from "../../../CMS/functions";

export function initialize(data, boutique) {
  const objet = functions.initialize(data);
  return {
    ...objet,
    isDraft: true,
    boutique: boutique.id,
    datetime: parseInt(moment().format("x")),
  };
}
