import styled from "styled-components";

export default styled.div`
  -webkit-overflow-scrolling: touch;

  ol {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    margin: 1.5rem 0 0 -1.5rem;
    padding: 0;
    box-sizing: content-box;
  }
`;
