import moment from "moment";
import * as functions from "../../../CMS/functions";

// L'ordre des produits peut être différent de la date de publication
// On peut publier un produit dans 2 jours et changer l'ordre d'affichage des produits
// Il faut bien que cela soit 2 choses différentes
export function initialize(data, boutique) {
  const objet = functions.initialize(data);
  return {
    ...objet,
    lang: 'fr',
    isDraft: true,
    boutique: boutique.id,
    datetime: parseInt(moment().format("x")), // Pour l'ordre des produits
    publishedAt: moment().format("YYYY-MM-DDTHH:mm"), // Pour connaitre la date de publication
    isOnlineStore: true,
    isInStore: true,
    hasInventory: true,
    qtyAvailable: 1,
    inStock: true,
    onSale: true,
    lowStockLevel: "",
    availability: "",
    negativeStock: false,
    isInPOS: Boolean(boutique.data().isInStoreByDefault),
    isReserved: false,
    productType: "classic",
    condition: boutique.data().conditionByDefault
      ? boutique.data().conditionByDefault
      : "",
    minQty: 1,
    byNumberQty: 1,
    isFeatured: false,
    optionPrice: boutique.data().optionPriceByDefault
      ? boutique.data().optionPriceByDefault
      : "",
    unitPrice: "piece",
    freeShipping: boutique.data().freeShippingByDefault
      ? boutique.data().freeShippingByDefault
      : false,
    deliveryIncluded: boutique.data().deliveryIncludedByDefault
      ? boutique.data().deliveryIncludedByDefault
      : false,
    isCustomMaded: boutique.data().isCustomMadedByDefault
      ? boutique.data().isCustomMadedByDefault
      : false,
    updatedAt: "",
    height: "",
    width: "",
    depth: "",
    weight: "",
    diametre: "",
    type: ""
  };
}

export function validate(product) {
  product.updatedAt = moment().toISOString();
  product.datetime = product.datetime
    ? parseInt(product.datetime, 10)
    : parseInt(moment().format("x"));
  product.deliveryIncluded = Boolean(product.deliveryIncluded);
  product.freeShipping = Boolean(product.freeShipping);
  product.price = product.price ? parseFloat(product.price) : "";
  product.isOnlineStore = Boolean(product.isOnlineStore);
  product.isInPOS = Boolean(product.isInPOS);
  product.isReserved = Boolean(product.isReserved);
  product.height = product.height ? parseFloat(product.height) : "";
  product.width = product.width ? parseFloat(product.width) : "";
  product.depth = product.depth ? parseFloat(product.depth) : "";
  product.diametre = product.diametre ? parseFloat(product.diametre) : "";
  if (product.name && product.photos && product.photos.length > 0) {
    product.isDraft = false;
  }

  if (product.productType === "variants") {
    product.qtyAvailable = 0;
    product.hasInventory = false;
  } else {
    product.hasInventory = Boolean(product.hasInventory);
    product.qtyAvailable =
      product.hasInventory && product.qtyAvailable
        ? parseInt(product.qtyAvailable, 10)
        : 0;
  }
  product.inStock = Boolean(product.qtyAvailable);

  product.onSale = !product.hasInventory || product.inStock;

  // TODO faire la conversion des différents champs numériques
  return product;
  // return {
  //     name: product.name,
  //     resume: product.resume,
  //     description: product.description,
  //     publishedAt: product.publishedAt,
  //     photos: product.photos ? product.photos : [],
  //
  //     price: product.price ? parseFloat(product.price) : null,
  //     retailPrice: product.retailPrice ? parseFloat(product.retailPrice) : null,
  //     hasInventory: !!product.hasInventory,
  //     negativeStock: !!product.negativeStock,
  //     qtyAvailable: product.qtyAvailable ? parseInt(product.qtyAvailable, 10) : null,
  //     qtyIncoming: product.qtyIncoming ? parseInt(product.qtyIncoming, 10) : null,
  //     delay: product.delay,
  //     limitQty: product.limitQty ? parseInt(product.limitQty, 10) : null,
  //     limitOptions: product.limitOptions,
  //     features: product.features ? product.features : [],
  //     width: product.width ? parseFloat(product.width) : null,
  //     depth: product.depth ? parseFloat(product.depth) : null,
  //     height: product.height ? parseFloat(product.height) : null,
  //     weight: product.weight ? parseFloat(product.weight) : null,
  //     dimensions: product.dimensions ? product.dimensions : [],
  //     deliveryRequired: !!product.deliveryRequired,
  //     additionalCostsValue: product.additionalCostsValue ? parseFloat(product.additionalCostsValue) : null,
  //     isOnlineStore: !!product.isOnlineStore,
  //     isInPOS: !!product.isInPOS,
  //     isOnFacebook: !!product.isOnFacebook,
  //     sku: product.sku,
  //     fournisseur: product.fournisseur ? product.fournisseur : null,
  //     showDiscount: product.showDiscount,
  //     condition: product.condition,
  //     fabricable: product.fabricable,
  //     comments: product.comments ? product.comments : [],
  // };
}
