import React from 'react';
import styled from 'styled-components'

const Rows = styled.div`
  display: flex;
  flex-direction: column;
  
  > div, > input {
    flex: 1;
  }
`


export default (props) => (
    <Rows className="cms__component--rows">
        {props.children}
    </Rows>
);