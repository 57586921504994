import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import cms from '../sysext/CMS/reducer';
import boutique from '../sysext/Boutique/reducer';
import gtd from '../sysext/Gtd/reducer';

const rootReducer = combineReducers({
    cms,
    form: formReducer,
    boutique,
    gtd
});

export default rootReducer
