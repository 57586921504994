import { getFirestore, getStorage } from "../../Boutique/functions";
import moment from "moment";
import uuidv4 from "uuid/v4";
import { STORAGE_TYPE_PHOTO } from "../../Boutique/actionTypes";
import Action from "./Action";

class Media {
  /**
   * Cette méthode permet de créer un nouveau média
   *
   * @param boutique
   * @param data
   * @param type
   * @returns {Promise<firebase.firestore.DocumentReference>}
   */
  static create(boutique, data, type) {
    data.type = type;
    data.boutique = boutique.id;
    data.createdAt = moment().toISOString();
    data.deleted = false;

    return getFirestore(boutique)
      .collection("medias")
      .add(data);
  }

  /**
   * Cette méthode permet d'ajouter une photo dans un bucket
   * Elle retour l'image enregistré dans la collection medias
   *
   * @param boutique
   * @param image
   * @param type
   * @param id
   * @returns {Promise<firebase.firestore.DocumentSnapshot>}
   */
  static uploadPhoto(boutique, image, type, id) {
    if (!boutique) {
      throw new Error("la boutique est obligatoire");
    }
    if (!boutique.get("storageBucketPath")) {
      throw new Error(
        "le storageBucketPath pour cette boutique est obligatoire"
      );
    }
    // if (!type) {
    //   throw new Error("type obligatoire");
    // }
    // if (!id) {
    //   throw new Error("id obligatoire");
    // }

    console.info("image", image);
    const storageBucketPath = boutique.get("storageBucketPath");
    // const ref = `${storageBucketPath}/${type}s/${id}/${uuidv4()}`;
    const ref = `${storageBucketPath}/${uuidv4()}-${image.name}`;

    return getStorage(boutique)
      .ref(ref)
      .put(image)
      .then(snapshot => {
        const metadata = {};
        Object.keys(snapshot.metadata).forEach(key => {
          metadata[key] = snapshot.metadata[key] ? snapshot.metadata[key] : "";
        });

        const doc = {
          deleted: false,
          createdAt: moment().toISOString(),
          type: STORAGE_TYPE_PHOTO,
          boutique: boutique.id,
          docType: type ? type : "",
          docId: id ? id : "",
          name: image.name,
          fullPath: snapshot.metadata.fullPath,
          title: "",
          description: "",
          altText: "",
          metadata: metadata,
          lastModified: image.lastModified
        };

        return getFirestore(boutique)
          .collection("medias")
          .add(doc);
      })
      .then(imageRef => {
        return imageRef.get();
      });
  }

  /**
   * Cette méthode permet de supprimer une photo
   *
   * @param boutique
   * @param fullPath
   * @returns {Promise<firebase.firestore.QuerySnapshot>}
   */
  static deletePhoto(boutique, fullPath) {
    // TODO voir pour ajouter une action qui supprime le fichier sur le serveur
    return getFirestore(boutique)
      .collection("medias")
      .where("boutique", "==", boutique.id)
      .where("fullPath", "==", fullPath)
      .get()
      .then(querySnapshot => {
        if (!querySnapshot.empty && querySnapshot.size === 1) {
          querySnapshot.docs[0].ref.delete();
        }
      });
  }

  /**
   * Cette méthode permet de retourner la photo de angle degré
   *
   * @param boutique
   * @param fullPath
   * @param angle
   * @returns {Promise<unknown>}
   */
  static rotatePhoto(boutique, fullPath, angle = 90) {
    return Action.create(boutique, "media-rotate", {
      fullPath,
      angle
    }).then(documentReference => {
      return new Promise(resolve => {
        const unsubscribe = documentReference.onSnapshot(documentSnapshot => {
          if (
            documentSnapshot.exists &&
            documentSnapshot.get("destinationPath")
          ) {
            const destinationPath = documentSnapshot.get("destinationPath");
            documentSnapshot.ref.delete();
            unsubscribe();
            resolve(destinationPath);
          }
        });
      });
    });
  }
}

export default Media;
