const marketingMenu = [
  [
    {
      name: "Bannières",
      type: "link",
      to: "/website/banners"
    },
    {
      name: "Coupons",
      type: "link",
      to: "/website/coupons"
    },
    {
      name: "Paniers",
      type: "link",
      to: "/website/cart"
    }
  ]
];

export default [
  {
    state: "website-pages",
    name: "Pages",
    type: "link",
    className: "separator",
    to: "/website/pages",
    profil: "website-pages",
    acl: {
      admin: true
    }
  },
  {
    state: "website-articles",
    name: "Articles",
    type: "link",
    className: "separator",
    to: "/website/articles",
    profil: "website-articles",
    acl: {
      admin: true
    }
  },
  {
    state: "website-memos",
    name: "Mémos",
    type: "link",
    className: "separator",
    to: "/website/memos",
    profil: "website-memos",
    acl: {
      admin: true
    }
  },
  {
    type: "separator",
    profil: "website-marketing",
    acl: {
      admin: true
    }
  },
  {
    state: "website-marketing",
    name: "Marketing",
    type: "link",
    className: "separator",
    profil: "website-marketing",
    to: "/website/banners",
    menu: marketingMenu,
    acl: {
      admin: true
    }
  },
  {
    state: "analytics",
    name: "Statistiques",
    type: "link",
    className: "separator",
    profil: "analytics",
    to: "/website/analytics",
    acl: {
      admin: true
    }
  },
  {
    type: "spacer",
    acl: {
      "*": true
    }
  },
  {
    state: "website-params",
    name: "Paramètres",
    type: "link",
    className: "separator",
    to: "/website/params",
    profil: "website-params",
    acl: {
      admin: true
    }
  }
];
