import { FOURNISSEURS_LOAD } from "../../actionTypes";
import { createQuery } from "../../functions";

export function load(boutique) {
  return {
    type: FOURNISSEURS_LOAD,
    payload: createQuery(boutique, "fournisseurs")
      .where("boutique", "==", boutique.id)
      .where("deleted", "==", false)
      .orderBy("nom")
      .get()
  };
}
