import {
  CONTACTS_LOAD,
  CONTACTS_ERROR,
  CONTACTS_LOADING,
  CONTACTS_SUCCESS
} from "../../actionTypes";
import { createQuery } from "../../functions";
import algoliasearch from "algoliasearch";

function createAlgoliaIndex(index) {
  const client = algoliasearch(
    process.env.REACT_APP_ALGOLIA_APP_ID,
    process.env.REACT_APP_ALGOLIA_SEARCH_KEY
  );

  return client.initIndex(index);
}

export function load(boutique) {
  return {
    type: CONTACTS_LOAD,
    payload: createQuery(boutique, "contacts")
      .where("boutique", "==", boutique.id)
      .where("deleted", "==", false)
      .orderBy("name")
      .get()
  };
}

export function search(
  boutique,
  wheres,
  q = "",
  hitsPerPage = 40,
  page = 0,
  params = {}
) {
  return dispatch => {
    dispatch({
      type: CONTACTS_LOADING
    });

    let filters = "";
    Object.keys(wheres).forEach(key => {
      let operator = ":";
      let value = wheres[key];
      if (Array.isArray(wheres[key])) {
        wheres[key].forEach(filtre => {
          if (typeof filtre.value === "boolean") {
            value = Boolean(filtre.value);
            operator = ":";
          }
          if (Number.isInteger(filtre.value)) {
            value = parseInt(filtre.value, 10);
            switch (filtre.operator) {
              case "<":
                operator = "<";
                break;

              case ">":
                operator = ">";
                break;

              case "==":
              default:
                operator = "=";
            }
          }
          filters += `${filters ? " AND " : ""}${key}${operator}${value}`;
        });
      } else {
        if (Number.isInteger(value)) {
          operator = "=";
        }
        filters += `${filters ? " AND " : ""}${key}${operator}${value}`;
      }
    });

    filters += `${filters ? " AND " : ""}boutique:${boutique.id}`;

    console.info("filters", filters);

    const index = `contacts_${
      boutique.get("firebaseProjectId")
        ? boutique.get("firebaseProjectId").toUpperCase()
        : boutique.get("indexAlgolia").toUpperCase()
    }`;

    console.info("search contact index", index);

    return createAlgoliaIndex(index)
      .search(q, {
        hitsPerPage: hitsPerPage,
        page: page,
        filters: filters,
        ...params
      })
      .then(action => {
        return dispatch({
          type: CONTACTS_SUCCESS,
          ...action
        });
      })
      .catch(error => {
        return dispatch({
          type: CONTACTS_ERROR,
          error: true,
          message: error.message
        });
      });
  };
}
