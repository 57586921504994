import React, { Component } from "react";
import Section from "../../../../../CMS/components/Data/Section";
import Information from "../../../../../CMS/components/Information";
import Textarea from "../../../../../CMS/components/Data/Textarea";

class Reponse extends Component {
  render() {
    return (
      <div>
        <Section title="Réponse">
          {/*<Group>*/}
          <Textarea
            name={this.props.name}
            placeholder="Veuillez saisir votre réponse personnalisé ici"
            style={{ margin: 0 }}
          />
          <Information
            text="→ Vous avez la possibilité d'ajouter une réponse personnalisée a cette demande.
                            Le cas échéance, la réponse automatique de la boutique sera affichée."
          />
          {/*</Group>*/}
        </Section>
      </div>
    );
  }
}

export default Reponse;
