import { getFirestore } from "../../Boutique/functions";

class Fournisseur {
  static transfertToBoutique(boutique) {
    const batchSandwich = getFirestore().batch();
    let nbPageTransfered = 0;
    return getFirestore()
      .collection("fournisseurs")
      .where("boutique", "==", boutique.id)
      .limit(500)
      .get()
      .then(querySnpashot => {
        if (!querySnpashot.empty) {
          let batch = getFirestore(boutique).batch();

          let newRef = undefined,
            data = undefined;
          querySnpashot.docs.forEach(document => {
            nbPageTransfered++;
            newRef = getFirestore(boutique)
              .collection("fournisseurs")
              .doc(document.id);
            data = document.data();
            delete data.boutiqueRef;
            delete data.typeRef;
            batch.set(newRef, data, { merge: true });
            batchSandwich.delete(document.ref);
          });

          batchSandwich.commit();
          return batch.commit().then(() => {
            return nbPageTransfered;
          });
        } else {
          return 0;
        }
      });
  }

  /**
   * Recherche un fournisseur par l'intermédiaire de l'id
   *
   * @param boutique
   * @param id
   * @returns {Promise<unknown>}
   */
  static findById(boutique, id) {
    return getFirestore(boutique)
      .collection("fournisseurs")
      .doc(id)
      .get()
      .then(documentSnapshot => {
        if (!documentSnapshot.exists) {
          throw new Error("Le fournisseur n'existe pas");
        }

        if (documentSnapshot.get("boutique") !== boutique.id) {
          throw new Error("Ce fournisseur n'appartient pas à cette boutique");
        }

        if (documentSnapshot.get("deleted")) {
          throw new Error("Ce fournisseur a été supprimé");
        }

        return documentSnapshot;
      });
  }
}

export default Fournisseur;
