import db from "../../../../api/firestore";
import {
  PAIEMENTS_BY_COMMANDE_WATCH,
  PAIEMENTS_BY_COMMANDE_LOAD,
  PAIEMENT_LOAD,
  PAIEMENT_CREATE,
  PAIEMENT_UPDATE,
  PAIEMENTS_RESET,
  PAIEMENTS_REMOVE_BY_COMMANDE,
  API_ERROR,
  API_SUCCESS
} from "../../actionTypes";
import moment from "moment/moment";

export function load(id) {
  return {
    type: PAIEMENT_LOAD,
    payload: db
      .collection("ventes")
      .doc(id)
      .get()
  };
}

export function watchByCommande(ref) {
  return dispatch => {
    db.collection("ventes")
      .where("commande", "==", ref.id)
      .onSnapshot(function(querySnapshot) {
        dispatch({
          type: PAIEMENTS_BY_COMMANDE_WATCH,
          paiements: querySnapshot.docs
        });
      });
  };
}

export function loadByCommandeId(id) {
  return {
    type: PAIEMENTS_BY_COMMANDE_LOAD,
    payload: db
      .collection("ventes")
      .where("commande", "==", id)
      .get()
  };
}

export function create(item, commandeRef) {
  return {
    type: PAIEMENT_CREATE,
    payload: db.collection("ventes").add({
      ...item,
      commande: commandeRef.id,
      commandeRef: commandeRef
    })
  };
}

export function update(ref, item) {
  return {
    type: PAIEMENT_UPDATE,
    payload: ref.update(item)
  };
}

export function removeAllByCommande(commande) {
  return dispatch => {
    const promises = [];
    return db
      .collection("ventes")
      .where("commande", "==", commande.id)
      .get()
      .then(function(querySnapshot) {
        querySnapshot.docs.forEach(doc => {
          if (process.env.REACT_APP_SOFT_DELETE === "no") {
            promises.push(doc.ref.delete());
          } else {
            promises.push(
              doc.ref.update({
                deleted: true,
                updatedAt: moment().toISOString()
              })
            );
          }
        });

        return dispatch({
          type: PAIEMENTS_REMOVE_BY_COMMANDE,
          payload: Promise.all(promises)
        });
      });
  };
}

// TODO revoir le reset car cela ne fonctionne pas comme ca
export function resetPaiement() {
  return dispatch => {
    const unsubscribe = db.collection("ventes").onSnapshot(function() {});
    // Stop listening to changes
    unsubscribe();

    dispatch({
      type: PAIEMENTS_RESET
    });
  };
}

export function reset() {
  return dispatch => {
    const unsubscribe = db.collection("paiements").onSnapshot(function() {});
    // Stop listening to changes
    unsubscribe();

    dispatch({
      type: PAIEMENTS_RESET
    });
  };
}

export function capture(paiementId, commandeId, boutique) {
  return dispatch => {
    return fetch(process.env.REACT_APP_URL_API, {
      method: "POST",
      body: JSON.stringify({
        type: "capture",
        collection: "commandes",
        paiementId: paiementId,
        commandeId: commandeId
      }),
      headers: {
        "X-Application-Id": boutique.data().applicationId
      }
    })
      .then(function(response) {
        if (response.status === 200) {
          return dispatch({
            type: API_SUCCESS,
            payload: response.json()
          });
        }
        return dispatch({
          type: API_ERROR,
          error: true,
          payload: response.text()
        });
      })
      .catch(error => {
        return dispatch({
          type: API_ERROR,
          error: true,
          payload: new Promise(() => error.message)
        });
      });
  };
}
