import {
    NOTIFICATION_CREATE,
    NOTIFICATION_REMOVE
} from '../../actionTypes';
// import firestore from '../../../../api/firestore';

export function create(notification) {
    return {
        type: NOTIFICATION_CREATE,
        notification: notification
    };
}

export function remove(key) {
    return {
        type: NOTIFICATION_REMOVE,
        key: key
    }
}