import React, { PureComponent } from "react";
import { Field } from "redux-form";

class Traductions extends PureComponent {
  render() {
    const traductions = {
      en: "Anglais",
      es: "Espagnol",
      de: "Allemand",
      it: "Italien"
    };
    return (
      <>
        {this.props.traductions &&
          Object.keys(this.props.traductions).length > 0 &&
          Object.keys(this.props.traductions).map((key, index) => (
            <div key={index}>
              <Field
                {...this.props}
                label={`${this.props.label} - ${traductions[key]}`}
                name={`traductions.${key}.${this.props.name}`}
                language={key}
              />
            </div>
          ))}
      </>
    );
  }
}

export default Traductions;
