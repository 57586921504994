import styled from "styled-components";

export default styled.ul`
  background: white;
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 1.5rem;
  border: 1px solid rgba(228, 231, 237, 0.5);
  border-radius: 2px;
  list-style: none;
  margin-left: 0;
  padding: 1.5rem;

  .item {
    display: flex;
    flex-direction: row;
    margin-bottom: 0.75rem;

    &:last-child {
      margin: 0;
    }
    .input,
    .select {
      flex: 1;
    }
  }
`;
