import React, { Component } from "react";
import { FieldArray } from "redux-form";
import Section from "../../../../components/Data/Section";
import StyledList from "../../../../styles/List";
import Field from "../../../../styles/Field";
import Button from "../../../../components/Button/index";
import Select from "./Select";

class List extends Component {
  constructor(props) {
    super(props);

    this.handleAdd = this.handleAdd.bind(this);
    this.handleRemove = this.handleRemove.bind(this);
  }

  handleAdd(event) {
    this.props.fields.push("");
  }

  handleRemove(index) {
    this.props.fields.remove(index);
  }

  render() {
    const { fields, title, user, placeholder, add, nothing, label } = this.props;

    return (
      <Section
        title={title}
        button={{
          text: add,
          onClick: this.handleAdd
        }}
      >
        <StyledList className="cms__component--list">
          {fields.length > 0 ? (
            fields.map((item, index) => (
              <li className="item" key={index}>
                <Select
                  className="select is-expanded no-margin no-right-radius no-border-right"
                  name={item}
                  placeholder={placeholder}
                  label={label}
                  fieldType="string"
                  user={user}
                />
                <Field noMargin>
                  <div className="has-addons">
                    <Button
                      smallIcon
                      icon="fa-close"
                      onClick={event => this.handleRemove(index)}
                      className="no-left-radius"
                    />
                  </div>
                </Field>
              </li>
            ))
          ) : (
            <div style={{ margin: "1.5rem" }}>{nothing}</div>
          )}
        </StyledList>
      </Section>
    );
  }
}

export default ({ prefix, name, ...others }) => {
  if (prefix && name) {
    name = prefix + "." + name;
  }

  return <FieldArray name={name} {...others} component={List} />;
};
