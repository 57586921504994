import React from 'react'

/**
 * Ce composant permet d'afficher un intra dans la liste des intras
 *
 * @param doc
 * @param onClick
 * @returns {*}
 */
export default ({doc, onClick}) => {
    const item = doc.data();

    return (
        <li onClick={onClick}>
            <div className="detail">
                <strong className="libelle">{item.libelle}</strong>
                {item.issuFavorable && (<span className='issu'>{item.issuFavorable}</span>)}
            </div>
        </li>
    )
}