import moment from "moment";
import * as functions from "../../../CMS/functions";
import { stateToMarkdown } from "draft-js-export-markdown";
import { convertFromRaw } from "draft-js";

export function initialize(data, boutique) {
  const objet = functions.initialize(data);
  return {
    ...objet,
    boutique: boutique.id,
    isVisible: true,
    publishedAt: moment().format("YYYY-MM-DDTHH:mm") // Pour connaitre la date de publication
  };
}

export function validate(item) {
  item.updatedAt = moment().toISOString();

  if (item.body) {
    item.bodyMarkdown = stateToMarkdown(convertFromRaw(item.body));
  }

  return item;
}
