import { connect } from "react-redux";
import List from "../../../../../CMS/containers/Boutique/components/List";
import Row from "./Row";
import abonnement from "../../params.json";

class AbonnementsList extends List {
  collectionName = "abonnements";
  defaultOrderField = "name";
  defaultOrderDirection = "desc";
  listTitle = "Abonnements";
  viewList = Row;
  itemTitle = { type: "attribut", attribut: "name" };
  itemParams = abonnement;
  messageNoDocument = "Aucun abonnement";

  constructor(props) {
    super(props, {
      defaultViewList: "list"
    });

    this.state.form.newItemLibelle = "Nouvel abonnement";
    // this.state.form.required = ["name"];

    this.state.form.menu.right = [
      {
        text: "Créer une facture",
        onClick: this.handleCreateBill
      },
      {
        text: "Fermer",
        onClick: this.handleClose,
        primary: true
      }
    ];

    this.handleCreateBill = this.handleCreateBill.bind(this);
  }

  handleCreateBill(e) {
    e.preventDefault();
    console.info("Création d'une facture");
  }
}

const mapStateToProps = (globalState, ownProps) => {
  const props = {};
  props.docs = globalState.boutique.docs;

  return props;
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AbonnementsList);
