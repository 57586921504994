import styled from "styled-components";

export default styled.button`
  position: relative;
  cursor: pointer;
  height: 34px;
  box-sizing: border-box;
  display: inline-block;
  vertical-align: top;
  padding: 0;
  background: #fff;
  border: 1px solid #b7c6fe;
  box-shadow: none !important;
  color: #4b71fc;
  font-weight: 300;
  line-height: 1.3334;
  border-right-width: 0;
  border-radius: 0;

  font-size: 13px;

  &:first-child {
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
  }

  &:last-child {
    border-right-width: 1px;
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
  }

  span.text {
    height: 34px;
    box-sizing: border-box;
    display: block;
    padding: 7px 15px;
  }

  &:hover {
    color: #4b71fc;
    //border-color: #4b71fc;
  }

  &.delete {
    border-color: transparent;
    background: none;
    color: #ee6b50;

    &:hover {
      color: #ec5b3e;
    }
  }

  &.previous,
  &.next {
    border-color: transparent;
    background: none;
    color: #aeadb3;
    &:hover {
      color: #5d5b66;
    }
  }

  &.all-grey {
    background: none;
    border-color: transparent;
    color: #aeadb3;

    &:hover {
      color: #5d5b66;
    }
  }

  &.active {
    background: none;
    border-color: transparent;
    color: #5d5b66;
  }

  span {
    display: inline-block;
  }

  .fa {
    font-size: 20px;
    text-align: center;
    height: 34px;
    width: 34px;

    &:before {
      padding: 0 7px;
      line-height: 32px;
      display: inline-block;
      vertical-align: middle;
    }
  }

  &.small-icon .fa {
    font-size: 14px;
  }

  ${props => {
    if (props.primary) {
      return {
        background: "#4b71fc",
        color: "white",
        borderColor: "#4b71fc",
        outline: "none",
        "&:hover": {
          color: "#fff",
          background: "rgb(111, 141, 253)"
        }
      };
    }
  }};

  &.no-left-radius {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
`;
