import React, { Component } from "react";
import { reduxForm } from "redux-form";
import ViewForm from "../../styles/View/Form";
import Header from "../Header";

const validate = (values, props) => {
  const errors = {};

  if (props.required) {
    props.required.forEach((key, index) => {
      if (!values[key] || values[key] === "n/a") {
        errors[key] = {
          code: 1,
          message: "Champs obligatoire"
        };
      }
    });
  }

  return errors;
};

class Form extends Component {
  render() {
    const { handleSubmit } = this.props;
    return (
      <ViewForm onSubmit={handleSubmit}>
        <Header
          title={this.props.id ? this.props.title : this.props.newItemLibelle}
          menu={this.props.menu}
          docIndex={this.props.docIndex}
        />
        {this.props.loading ? (
          <section className="section">Chargement en cours</section>
        ) : (
          this.props.children
        )}
      </ViewForm>
    );
  }
}

export default reduxForm({
  form: "item",
  validate,
  enableReinitialize: true
})(Form);
