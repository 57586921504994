import React, { Component } from "react";
import Field from "../../../../CMS/styles/Field";
import Button from "../../../../CMS/components/Button/index";

class Item extends Component {
  constructor(props) {
    super(props);

    this.state = {
      openModal: false
    };

    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
  }

  handleOpenModal(e) {
    e.preventDefault();

    this.setState({
      openModal: true
    });
  }

  handleCloseModal(e) {
    e.preventDefault();

    this.setState({
      openModal: false
    });
  }

  render() {
    const {
      item,
      customFields,
      modalDatas,
      handleChangeValue,
      handleUpdate,
      handleRemove
    } = this.props;

    return (
      <div style={{ display: "flex" }}>
        {this.state.openModal && <div />}
        <Field
          className="is-expanded"
          noMarginRight
          noRightRadius
          noBorderRight
          noMarginBottom
        >
          <input
            type="text"
            value={item.libelle}
            placeholder="Libelle"
            className="input is-expanded"
            onChange={event => handleChangeValue("libelle", event.target.value)}
          />
          <label className="label">
            <span>Libellé</span>
          </label>
        </Field>
        {customFields &&
          customFields.length > 0 &&
          customFields.map((field, j) => (
            <Field
              key={j}
              className="is-expanded"
              noMarginLeft
              noLeftRadius
              noRightRadius
              noMarginRight
              noBorderRight
              noMarginBottom
            >
              <input
                type={field.type ? field.type : "text"}
                value={item[field.name]}
                placeholder={field.label}
                className="input is-expanded"
                onChange={event =>
                  handleChangeValue(field.name, event.target.value, field.type)
                }
              />
              <label className="label">
                <span>{field.label}</span>
              </label>
            </Field>
          ))}
        <Field noMarginLeft noMarginBottom>
          <div className="has-addons">
            {item.ref && item.ref.id && (
              <Button
                smallIcon
                id={item.ref.id}
                type="id"
                className="no-left-radius"
              />
            )}
            {item.change && (
              <Button
                smallIcon
                icon="fa-save"
                className="button"
                onClick={handleUpdate}
              />
            )}
            {modalDatas && modalDatas.length > 0 && (
              <Button smallIcon icon="fa-edit" onClick={this.handleOpenModal} />
            )}
            <Button smallIcon icon="fa-trash" onClick={handleRemove} />
          </div>
        </Field>
      </div>
    );
  }
}

export default Item;
