import React, { Component } from "react";
import Produit from "../../../../../CMS/datas/Produit";
import { getDomainAssetsURL } from "../../../../functions";
import { ProduitsList as StyledProduitsList } from "./styles";
import ActionBar from "../../../../../CMS/components/ActionBar";
import Item from "./Item";

class ProduitCRUD extends Component {
  state = {
    searchProduits: undefined,
    error: false,
    message: "",
    q: ""
  };

  constructor(props) {
    super(props);

    this.loadSearchProduits = this.loadSearchProduits.bind(this);
    this.handleOnChangeSearch = this.handleOnChangeSearch.bind(this);
    this.handleOnKeyPressSearch = this.handleOnKeyPressSearch.bind(this);
    this.handleEmptySearch = this.handleEmptySearch.bind(this);
    this.handleSelectThisProduct = this.handleSelectThisProduct.bind(this);
    this.handleRemoveProduct = this.handleRemoveProduct.bind(this);
  }

  loadSearchProduits() {
    Produit.search(this.props.boutique, {
      q: this.state.q
    })
      .then(produits => {
        this.setState({
          error: false,
          message: "",
          searchProduits: produits
        });
      })
      .catch(error => {
        console.error(error.message);
        this.setState({
          error: true,
          message: error.message
        });
      });
  }

  handleOnChangeSearch(e) {
    e.preventDefault();

    const value = e.currentTarget.value;

    this.setState({
      q: value
    });
  }

  handleOnKeyPressSearch(e) {
    if (e.which === 13) {
      e.preventDefault();
      this.loadSearchProduits();
    }
  }

  handleEmptySearch(e) {
    e.preventDefault();
    this.setState({
      searchProduits: undefined,
      q: ""
    });
  }

  handleSelectThisProduct(e) {
    e.preventDefault();
    const id = e.currentTarget.getAttribute("data-id");
    const produits =
      this.props.items && this.props.items.length > 0
        ? this.props.items.slice(0)
        : [];
    produits.push(id);
    this.props.updateItems(produits);
    this.setState({
      searchProduits: undefined,
      q: ""
    });
  }

  handleRemoveProduct(e) {
    e.preventDefault();
    const id = e.currentTarget.getAttribute("data-id");
    const produits =
      this.props.items && this.props.items.length > 0
        ? this.props.items.slice(0)
        : [];

    const index = produits.findIndex(produitId => produitId === id);
    produits.splice(index, 1);
    this.props.updateItems(produits);
  }

  render() {
    if (this.state.error) {
      return (
        <div>
          <h1>Erreur</h1>
          <p>{this.state.message}</p>
        </div>
      );
    }

    return (
      <div>
        <ActionBar className="action-bar secondary is-clearfix">
          <div className="menu-annexe">
            <ul>
              <li className="navbar-item search">
                <input
                  type="text"
                  value={this.state.q}
                  onChange={this.handleOnChangeSearch}
                  onKeyPress={this.handleOnKeyPressSearch}
                  placeholder="Rechercher"
                />
                {this.state.q && (
                  <button className="empty" onClick={this.handleEmptySearch}>
                    <span className="icon" />
                    <span className="text">Vider</span>
                  </button>
                )}
              </li>
            </ul>
          </div>

          {this.state.searchProduits &&
            this.state.searchProduits.length > 0 && (
              <div className="search-results">
                {this.state.searchProduits.map((produit, index) => (
                  <div
                    className="search-results-item"
                    key={index}
                    data-id={produit.objectID}
                    onClick={this.handleSelectThisProduct}
                  >
                    {produit.photos && (
                      <div className="search-results-item-image">
                        <figure className="aspect-ratio aspect-ratio--square">
                          <img
                            src={`${getDomainAssetsURL(this.props.boutique)}/${
                              produit.photos[0]
                            }?h=64&w=64&c=max`}
                            alt={produit.name}
                            className="aspect-ratio__content"
                          />
                        </figure>
                      </div>
                    )}

                    <h3>{produit.name}</h3>
                  </div>
                ))}
              </div>
            )}
        </ActionBar>
        {this.props.items &&
          this.props.items.length > 0 && (
            <StyledProduitsList>
              {this.props.items.map((produitId, index) => (
                <Item
                  itemId={produitId}
                  boutique={this.props.boutique}
                  handleRemoveProduct={this.handleRemoveProduct}
                  key={index}
                  mode="edit"
                  showName={true}
                />
              ))}
            </StyledProduitsList>
          )}
      </div>
    );
  }
}

export default ProduitCRUD;
