import React from "react";
import PropTypes from "prop-types";
import PageSelect from "../containers/Page/components/Data/Select";
import UrlInput from "../Url/components/Data/Input";
import UrlLibelle from "../Url/components/Data/Libelle";
import UrlPathnames from "../Url/components/Data/Pathnames";

const Data = props => {
  const { type, ...others } = props;
  delete others.component;

  switch (type) {
    case "page/select":
      return <PageSelect {...others} />;
    case "url/libelle":
      return <UrlLibelle {...others} />;

    case "url/input":
      return <UrlInput {...others} />;

    case "url/pathnames":
      return <UrlPathnames {...others} />;

    default:
  }

  return <div>Ce type de composant n'existe pas : {type}</div>;
};

Data.propTypes = {
  type: PropTypes.string.isRequired
};

export default Data;
