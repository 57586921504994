import React, { Component } from "react";
import Select from "../../../../../../CMS/components/Select";
import Field from "../../../../../../CMS/styles/Field";
import Button from "../../../../../../CMS/components/Button";

class Traduction extends Component {
  state = {
    openPopup: false
  };

  constructor(props) {
    super(props);

    this.handleOpenPopup = this.handleOpenPopup.bind(this);
  }

  handleOpenPopup(e) {
    e.preventDefault();
  }

  render() {
    const { value, handleChange, handleRemove } = this.props;

    const langues = [
      {
        value: "en",
        label: "Anglais"
      }
      // {
      //   value: "es",
      //   label: "Espagnol"
      // },
      // {
      //   value: "de",
      //   label: "Allemand"
      // },
      // {
      //   value: "it",
      //   label: "Italien"
      // }
    ];
    return (
      <div style={{ display: "flex" }} className="cms__component--columns">
        <Select
          label="Langue"
          value={value}
          options={langues}
          className="is-expanded no-margin-bottom"
          onChange={handleChange}
        />
        <Field noMarginLeft noMarginBottom>
          <div className="has-addons">
            {/*<Button smallIcon icon="fa-edit" onClick={this.handleOpenPopup} />*/}
            <Button smallIcon icon="fa-trash" onClick={handleRemove} />
          </div>
        </Field>
      </div>
    );
  }
}

export default Traduction;
