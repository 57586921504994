import React, { Component } from "react";
import PropTypes from "prop-types";
import { DragSource, DropTarget } from "react-dnd";
import storage from "../../../api/storage";
import nextPhoto from "../../../images/next-photo.svg";
import { create } from "../../CMS/containers/Notification/actions";
import { getDomainAssetsURL } from "../functions";

const ItemTypes = {
  PHOTO: "photo"
};

const PhotoSource = {
  beginDrag: props => {
    return {
      index: props.index,
      photo: props.photo
    };
  }
};

function collectPhoto(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging()
  };
}

class Photo extends Component {
  render() {
    const { connectDragSource, isDragging } = this.props;

    let photo;
    try {
      if (this.props.photo) {
        if (this.props.photo.split(".").pop() === "svg") {
          photo = `${getDomainAssetsURL(this.props.boutique)}/${
            this.props.photo
          }`;
        } else {
          photo = `${getDomainAssetsURL(this.props.boutique)}/${
            this.props.photo
          }?w=320&h=320&c=max`;
        }
      } else {
        photo = nextPhoto;
      }
    } catch (e) {
      photo = nextPhoto;
    }
    return connectDragSource(
      <div
        className={isDragging ? " isDragging" : ""}
        style={{ position: "relative" }}
      >
        <figure className={"aspect-ratio aspect-ratio--square"}>
          <img src={photo} alt="chargement" className="aspect-ratio__content" />
        </figure>
        <div className="hover-overlay controls">
          <button
            className="voir"
            onClick={e => this.props.handleOnShowPhoto(e, this.props.index)}
          >
            <span>Voir</span>
          </button>
          <button
            className="rotate"
            onClick={e => this.props.handleOnRotate(e, this.props.index)}
          >
            <span>Voir</span>
          </button>
          <button
            className="supprimer"
            onClick={e => this.props.handleRemove(e, this.props.index)}
          >
            <span>Supprimer</span>
          </button>
        </div>
      </div>
    );
  }
}

Photo.propTypes = {
  index: PropTypes.number.isRequired
  // connectDragSource: PropTypes.func.isRequired,
  // isDragging: PropTypes.bool.isRequired
};

Photo = DragSource(ItemTypes.PHOTO, PhotoSource, collectPhoto)(Photo);

const positionTarget = {
  hover(props, monitor, component) {
    if (!component) {
      return null;
    }
  },
  drop(props, monitor) {
    const item = monitor.getItem();

    if (props.index !== item.index) {
      if (props.index < item.index) {
        // La nouvelle position est avant
        // On ajout la nouvelle photo
        // On supprime l'ancien position + 1
        props.fields.insert(props.index, item.photo);
        props.fields.remove(item.index + 1);
      } else {
        // la nouvelle position est après
        props.fields.remove(item.index);
        props.fields.insert(props.index, item.photo);
      }
    }
  }
};

function collect(connect, monitor) {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver()
  };
}

class PhotoPosition extends Component {
  static contextTypes = {
    store: PropTypes.object
  };

  constructor(props) {
    super(props);

    this.handleRemove = this.handleRemove.bind(this);
  }

  handleRemove = (e, index) => {
    e.preventDefault();

    if (window.confirm("Voulez-vous vraiment supprimer cette photo ?")) {
      const _this = this;
      let file = this.props.fields.get(index);
      if (file) {
        // Remove photo du bucket
        const fields = this.props.fields;
        if (file.fullPath) {
          file = file.fullPath;
        }

        storage
          .ref(file)
          .delete()
          .then(() => {
            fields.remove(index);
            // TODO Supprimer la photo dans la banque d'images de la boutique
            _this.context.store.dispatch(
              create({
                type: "info",
                message: "Suppression de l'image réussi"
              })
            );
          })
          .catch(function(error) {
            if (error.code === "storage/object-not-found") {
              fields.remove(index);
              _this.context.store.dispatch(
                create({
                  type: "error",
                  message: "L'image n'existe pas"
                })
              );
            } else {
              _this.context.store.dispatch(
                create({
                  type: "error",
                  message: "Erreur lors de la suppression de l'image" + error
                })
              );
            }
          });
      }
    }

    return false;
  };

  render() {
    const {
      boutique,
      handleOnShowPhoto,
      handleOnRotate,
      photo,
      index,
      connectDropTarget,
      isOver
    } = this.props;

    return connectDropTarget(
      <li className={isOver ? "isOver" : ""}>
        <Photo
          photo={photo}
          index={index}
          boutique={boutique}
          handleRemove={(e, index) => this.handleRemove(e, index)}
          handleOnShowPhoto={(e, index) => handleOnShowPhoto(e, index)}
          handleOnRotate={(e, index) => handleOnRotate(e, index)}
        />
      </li>
    );
  }
}

PhotoPosition.propTypes = {
  index: PropTypes.number.isRequired,
  isOver: PropTypes.bool.isRequired
};

export default DropTarget(
  ItemTypes.PHOTO,
  positionTarget,
  collect
)(PhotoPosition);
