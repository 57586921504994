import React from "react";

export default () => <div>Assistant</div>;

// import { connect } from 'react-redux';
//
//
// class Assistant extends Component {
//
//     constructor(props) {
//         super(props);
//
//         if(props.user) {
//             this.props.load(props.user.objectId);
//         }
//
//         this.handleCreate = this.handleCreate.bind(this);
//         this.handleUpdate = this.handleUpdate.bind(this);
//     }
//
//     componentWillReceiveProps(nextProps) {
//         if(!this.props.user && nextProps.user) {
//             this.props.load(nextProps.user.objectId);
//         }
//     }
//
//     handleCreate(item) {
//         item.userId = this.props.user.objectId;
//         this.props.create(item);
//     }
//
//     handleUpdate(item) {
//         this.props.update(item);
//     }
//
//     render() {
//         const {items, path, params, error, info} = this.props;
//         switch(path) {
//             case '/assistant/abonnement/new':
//                 return null; // C'était <Data />
//
//             case '/assistant/abonnement/:id':
//                 if(!items) {
//                     return (<div>Chargement en cours</div>);
//                 }
//
//                 const item = Object.assign({}, items.find(
//                     item => item.objectId === params.id)
//                 );
//
//                 return null; // C'était <Data />
//
//             case '/assistant/abonnements':
//             default:
//         }
//
//         return null;
//     }
// }
//
// const mapStateToProps = (globalState, ownProps) => {
//     const props = globalState.cms;
//     props.path = ownProps.routes[ownProps.routes.length-1].path;
//
//     return props;
// };
//
// const getValideAbonnement = (item) => {
//     return {
//         libelle: item.libelle,
//         description: item.description,
//         lundi: item.lundi,
//         mardi: item.mardi,
//         mercredi: item.mercredi,
//         jeudi: item.jeudi,
//         vendredi: item.vendredi,
//         samedi: item.samedi,
//         dimanche: item.dimanche,
//         horaire: item.horaire
//     }
// };
//
// const mapDispatchToProps = (dispatch) => {
//     return {
//         create: (item) => {
//             // dispatch(create('Abonnement', item));
//         },
//         update: (item) => {
//             const validItem = getValideAbonnement(item);
//             // dispatch(update('Abonnement', item.objectId, validItem))
//         },
//         load: (userId) => {
//             // dispatch(loadByUserId('Abonnement', userId));
//         },
//     }
// };
//
// export default connect(mapStateToProps, mapDispatchToProps)(Assistant);
