import React from 'react'
import PropTypes from 'prop-types'
import Fields from './Data/Fields'
import Select from './Data/Select'
import List from './Data/List'

const Data = (props) => {
    const { type, ...others } = props;
    delete others.component;

    switch(type) {
        case 'list':
            return (<List {...others}/>);

        case 'fields':
            return (<Fields {...others}/>);

        case 'select':
            return (<Select {...others}/>);

        default:
    }

    return (
        <div>Schema</div>
    );
};


Data.propTypes = {
    type: PropTypes.string.isRequired
};

export default Data;