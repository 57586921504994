import moment from "moment";
import { getFirestore, createWhereClauses } from "./functions";
import { search as searchProduits } from "./containers/Produit/actions";
import { search as searchContacts } from "./containers/Contact/actions";
import { WATCH_LOADING, WATCH_ERROR, WATCH_UNSUBSCRIBE } from "./actionTypes";

export function watchCollection(
  boutique,
  type,
  collectionName,
  collectionType,
  q,
  wheres,
  orderField,
  orderDirection,
  nbParPage = 40,
  pageIndex,
  lastDocument
) {
  return dispatch => {
    if (!boutique) {
      return dispatch({
        type: WATCH_ERROR,
        message: "La boutique est obligatoire"
      });
    }

    dispatch({
      type: WATCH_LOADING,
      pageIndex,
      orderField,
      orderDirection
    });

    let unsubscribe = null;

    let query = getFirestore(boutique).collection(collectionName);

    query = query.where("boutique", "==", boutique.id);
    if (collectionType) {
      query = query.where("type", "==", collectionType);
    }

    // Recherche
    if (q && (collectionName === "produits" || collectionName === "contacts")) {
      // recherche
      switch (collectionName) {
        case "produits":
          return dispatch(
            searchProduits(boutique, wheres, q, nbParPage, pageIndex)
          ).then(action => {
            if (action.nbHits) {
              dispatch({
                type: WATCH_LOADING,
                nbHits: action.nbHits,
                orderField: undefined
              });
            }
            const docs = [];
            if (action.hits && action.hits.length > 0) {
              action.hits.forEach(hit => {
                docs.push(
                  getFirestore(boutique)
                    .collection(collectionName)
                    .doc(hit.objectID)
                    .onSnapshot(document => {
                      dispatch({
                        type: type,
                        docs: [document]
                      });
                    })
                );
              });
            } else {
              return dispatch({
                type: type,
                docs: []
              });
            }

            Promise.all(docs).then(unsubscribes => {
              return dispatch({
                type: WATCH_UNSUBSCRIBE,
                unsubscribe: unsubscribes
              });
            });
          });

        case "contacts":
          return dispatch(
            searchContacts(boutique, wheres, q, nbParPage, pageIndex)
          ).then(action => {
            if (action.nbHits) {
              dispatch({
                type: WATCH_LOADING,
                nbHits: action.nbHits,
                orderField: undefined
              });
            }
            const docs = [];
            if (action.hits && action.hits.length > 0) {
              action.hits.forEach(hit => {
                docs.push(
                  getFirestore(boutique)
                    .collection(collectionName)
                    .doc(hit.objectID)
                    .onSnapshot(document => {
                      dispatch({
                        type: type,
                        docs: [document]
                      });
                    })
                );
              });
            } else {
              return dispatch({
                type: type,
                docs: []
              });
            }

            Promise.all(docs).then(unsubscribes => {
              return dispatch({
                type: WATCH_UNSUBSCRIBE,
                unsubscribe: unsubscribes
              });
            });
          });

        default:
          return dispatch({
            type: WATCH_ERROR,
            message: `La recherche n'est pas configurée pour la collection ${collectionName}`
          });
      }
    } else {
      query = createWhereClauses(query, wheres, orderField, orderDirection);
      if (lastDocument) {
        query = query.startAfter(lastDocument);
      }
      query = query.limit(nbParPage);

      unsubscribe = query.onSnapshot(querySnapshot => {
        const docsAdded = [];
        const docsModified = [];
        const docsRemoved = [];
        querySnapshot.docChanges().forEach(function(change) {
          switch (change.type) {
            case "added":
              docsAdded.push(change.doc);
              break;
            case "modified":
              docsModified.push(change.doc);
              break;
            case "removed":
              docsRemoved.push(change.doc);
              break;
            default:
          }
        });

        return dispatch({
          type: type,
          docsAdded: docsAdded,
          docsModified: docsModified,
          docsRemoved: docsRemoved
        });
      });
    }

    dispatch({
      type: WATCH_UNSUBSCRIBE,
      unsubscribe: unsubscribe
    });
  };
}

export function createDocumentInCollection(
  type,
  boutique,
  collectionName,
  collectionType,
  item
) {
  let data = item;
  if (collectionType) {
    data = Object.assign({ type: collectionType }, item);
  }

  return {
    type: type,
    payload: getFirestore(boutique)
      .collection(collectionName)
      .add(data)
  };
}

export function updateDocumentByRef(type, ref, item) {
  return {
    type: type,
    payload: ref.update(item)
  };
}

export function updateDocumentById(type, boutique, collectionName, id, item) {
  return {
    type: type,
    payload: getFirestore(boutique)
      .collection(collectionName)
      .doc(id)
      .update(item)
  };
}

export function removeDocumentByRef(type, ref) {
  // TODO voir  pour améliorer la suppression
  // Je vais supprimer la suppression en direct
  // Il y aura un traitement qui le fera par la suite
  if (process.env.REACT_APP_SOFT_DELETE === "no") {
    return {
      type: type,
      payload: ref.delete()
    };
  } else {
    return {
      type: type,
      payload: ref.update({
        deleted: true,
        updatedAt: moment().toISOString()
      })
    };
  }
}

export function removeDocumentById(type, boutique, collectionName, id) {
  // TODO voir  pour améliorer la suppression
  // Je vais supprimer la suppression en direct
  // Il y aura un traitement qui le fera par la suite
  if (process.env.REACT_APP_SOFT_DELETE === "no") {
    return {
      type: type,
      payload: getFirestore(boutique)
        .collection(collectionName)
        .doc(id)
        .delete()
    };
  } else {
    return {
      type: type,
      payload: getFirestore(boutique)
        .collection(collectionName)
        .doc(id)
        .update({
          deleted: true,
          updatedAt: moment().toISOString()
        })
    };
  }
}

export function resetCollection(type, unsubscribe) {
  return dispatch => {
    // Stop listening to changes
    if (unsubscribe) {
      if (Array.isArray(unsubscribe) && unsubscribe.length > 0) {
        unsubscribe.forEach(unsub => {
          unsub();
        });
      } else {
        unsubscribe();
      }
    }

    dispatch({
      type: type
    });
  };
}

/**
 * Téléchargement de l'image dans le bucket de la boutique
 *
 * @param boutique
 * @param image
 * @param type
 * @param id
 * @returns {Function}
 */
export function uploadPhoto(boutique, image, type, id) {
  return dispatch => {};
}
