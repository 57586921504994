import { getFirestore } from "../../Boutique/functions";
import moment from "moment";

class Commande {
  static transfertToBoutique(boutique) {
    const batchSandwich = getFirestore().batch();
    let nbPageTransfered = 0;
    return getFirestore()
      .collection("commandes")
      .where("boutique", "==", boutique.id)
      .where("deleted", "==", false)
      .limit(500)
      .get()
      .then(querySnpashot => {
        if (!querySnpashot.empty) {
          let batch = getFirestore(boutique).batch();

          let newRef = undefined,
            data = undefined;
          querySnpashot.docs.forEach(document => {
            nbPageTransfered++;
            newRef = getFirestore(boutique)
              .collection("commandes")
              .doc(document.id);
            data = document.data();
            delete data.boutiqueRef;
            delete data.contactRef;
            batch.set(newRef, data, { merge: true });
            batchSandwich.set(
              document.ref,
              {
                updatedAt: moment().toISOString(),
                importedAt: moment().toISOString(),
                deleted: true
              },
              { merge: true }
            );
          });

          batchSandwich.commit();
          return batch.commit().then(() => {
            return nbPageTransfered;
          });
        } else {
          return 0;
        }
      });
  }

  /**
   * Recherche une commande par l'intermédiaire de l'id
   *
   * @param boutique
   * @param id
   * @returns {Promise<unknown>}
   */
  static findById(boutique, id) {
    return getFirestore(boutique)
      .collection("commandes")
      .doc(id)
      .get()
      .then(documentSnapshot => {
        if (!documentSnapshot.exists) {
          throw new Error("La commande n'existe pas");
        }

        if (documentSnapshot.get("boutique") !== boutique.id) {
          throw new Error("Cette commande n'appartient pas à cette boutique");
        }

        if (documentSnapshot.get("deleted")) {
          throw new Error("Cette commande a été supprimé");
        }

        return documentSnapshot;
      });
  }
}

export default Commande;
