import React, { Component } from "react";
import { FieldArray } from "redux-form";
import Input from "./Input/index";
import Select from "./Select";
import Section from "./Section";
import StyledList from "../../styles/List";
import Field from "../../styles/Field";
import Button from "../Button";

const Item = ({ type, ...others }) => {
  switch (type) {
    case "select":
      return (
        <Select
          {...others}
          className="input no-margin no-right-radius no-border-right"
        />
      );

    case "input":
    default:
      return (
        <Input
          {...others}
          className="input no-margin no-right-radius no-border-right"
        />
      );
  }
};

class List extends Component {
  constructor(props) {
    super(props);

    this.handleAdd = this.handleAdd.bind(this);
    this.handleRemove = this.handleRemove.bind(this);
  }

  handleAdd = event => {
    this.props.fields.push("");
  };

  handleRemove = index => {
    this.props.fields.remove(index);
  };

  render() {
    const {
      fields,
      title,
      placeholder,
      add,
      nothing,
      label,
      options,
      type
    } = this.props;

    return (
      <Section
        title={title}
        button={{
          text: add,
          onClick: this.handleAdd
        }}
      >
        <StyledList className="cms__component--list">
          {fields.length > 0 ? (
            fields.map((item, index) => (
              <li className="item" key={index}>
                <Item
                  placeholder={placeholder}
                  label={label}
                  options={options}
                  name={item}
                  type={type}
                />
                <Field noMargin>
                  <div className="has-addons">
                    <Button
                      smallIcon
                      icon="fa-close"
                      onClick={event => this.handleRemove(index)}
                      className="no-left-radius"
                    />
                  </div>
                </Field>
              </li>
            ))
          ) : (
            <div>{nothing}</div>
          )}
        </StyledList>
      </Section>
    );
  }
}

export default ({ prefix, name, ...others }) => {
  if (prefix && name) {
    name = prefix + "." + name;
  }

  return <FieldArray name={name} {...others} component={List} />;
};
