import React, { Component } from "react";
import Modal from "../../../../components/View/Modal";
import Field from "../../../../styles/Field";
import Columns from "../../../../components/Data/Columns";
import Data from "../../../../components/Data";

const Row = ({ field, item, handleChangeValue }) => {
  if (Array.isArray(field)) {
    return (
      <Columns>
        {field.map((tmp, index) => (
          <Row
            key={index}
            item={item}
            handleChangeValue={handleChangeValue}
            field={tmp}
          />
        ))}
      </Columns>
    );
  } else {
    return (
      <Field>
        <input
          type={field.type ? field.type : "text"}
          value={item[field.name]}
          placeholder={field.label}
          className="input is-expanded"
          onChange={event =>
            handleChangeValue(field.name, event.target.value, field.type)
          }
        />
        <label className="label">
          <span>{field.label}</span>
        </label>
      </Field>
    );
  }
};

class FeatureModal extends Component {
  render() {
    const {
      title,
      handleClose,
      handleUpdate,
      handleChangeValue,
      item,
      itemRef,
      modalFields,
      modalDatas
    } = this.props;

    return (
      <Modal
        title={title}
        handleClose={handleClose}
        buttons={[
          { onClick: handleClose, text: "Fermer", className: "all-grey" },
          { onClick: handleUpdate, text: "Mettre à jour", primary: true }
        ]}
      >
        <Field>
          <input
            type="text"
            value={item.libelle}
            placeholder="Libellé"
            className="input is-expanded"
            onChange={event => handleChangeValue("libelle", event.target.value)}
          />
          <label className="label">
            <span>Libellé</span>
          </label>
        </Field>
        {modalDatas && modalDatas.length > 0 && (
          <Data data={modalDatas} item={item} itemRef={itemRef} />
        )}
        {modalFields &&
          modalFields.length > 0 &&
          modalFields.map((field, j) => (
            <Row
              field={field}
              item={item}
              handleChangeValue={handleChangeValue}
              key={j}
            />
          ))}
      </Modal>
    );
  }
}

export default FeatureModal;
