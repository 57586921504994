import React, { Component } from "react";
import Input from "../../../../../CMS/components/Data/Input";
import { change, formValueSelector } from "redux-form";
import { connect } from "react-redux";
import moment from "moment";

class IsReserved extends Component {
  state = {
    isReserved: null,
    updateDate: false,
    reservedAt: null
  };

  static getDerivedStateFromProps(props, state) {
    if (typeof state.isReserved === "boolean") {
      if (props.isReserved && !state.isReserved) {
        state.updateDate = true;
        state.isReserved = true;
        state.reservedAt = moment().format("YYYY-MM-DDTHH:mm");
      }
    } else {
      state.isReserved = props.isReserved;
    }

    return state;
  }

  componentDidMount() {
    if (this.state.updateDate) {
      this.setState(
        {
          updateDate: false
        },
        () => {
          this.props.change(this.state.reservedAt);
        }
      );
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.state.updateDate) {
      this.setState(
        {
          updateDate: false
        },
        () => {
          this.props.change(this.state.reservedAt);
        }
      );
    }
  }

  render() {
    const { name } = this.props;

    return (
      <Input
        name={name}
        type="checkbox"
        label="Réservé"
        description="Est réservé ?"
      />
    );
  }
}

const selector = formValueSelector("item");

const mapStateToProps = (globalState, ownProps) => {
  return {
    isReserved: selector(globalState, ownProps.name)
  };
};

const mapDispatchToProps = dispatch => {
  return {
    change: date => {
      dispatch(change("item", "reservedAt", date));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(IsReserved);
