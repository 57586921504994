import React, { Component } from "react";
import Url from "../../../../CMS/datas/Url";
import styled from "styled-components";
import { getFirestore } from "../../../../Boutique/functions";
import { change, formValueSelector } from "redux-form";
import { connect } from "react-redux";

const Div = styled.div`
  padding: 0 1.5rem;
  h3 {
    font-size: 11px;
    font-weight: 100;
    margin-top: 0;
    margin-bottom: 0.375rem;
  }
  ul {
    margin-top: 0;
    padding: 0;
    list-style: circle;
    li {
      display: flex;
      justify-content: space-between;
    }
  }
`;

class Pathnames extends Component {
  state = {
    id: undefined,
    urls: undefined,
    pathname: undefined
  };

  constructor(props) {
    super(props);

    this.loadPathnames = this.loadPathnames.bind(this);
    this.handleDeleteUrl = this.handleDeleteUrl.bind(this);
    this.handleSetDefaultUrl = this.handleSetDefaultUrl.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    if (props.id && (!state.id || props.id !== state.id)) {
      state.mustBeLoad = true;
      state.id = props.id;
      state.urls = undefined;
    }

    return state;
  }

  componentDidMount() {
    if (this.state.mustBeLoad) {
      this.loadPathnames();
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.state.mustBeLoad) {
      this.loadPathnames();
    }
  }

  loadPathnames() {
    this.setState({
      mustBeLoad: false
    });
    const _this = this;
    const pathname = this.props.pathname;

    Url.watch(
      this.props.boutique,
      {
        docId: this.state.id
      },
      querySnapshot => {
        _this.setState({
          pathname,
          urls: querySnapshot.docs
        });

        if (
          querySnapshot.docs.length === 1 &&
          pathname !== querySnapshot.docs[0].get("pathname")
        ) {
          _this.props.change(querySnapshot.docs[0].get("pathname"));
        }
      }
    );
  }

  handleDeleteUrl(e) {
    e.preventDefault();

    if (window.confirm("Voulez-vous vraiment supprimer cette adresse ?")) {
      const index = e.currentTarget.getAttribute("data-index");
      Url.delete(this.state.urls[index].ref).then(() => {
        console.info("l'adresse a été supprimé");
      });
    }
  }

  handleSetDefaultUrl(e) {
    e.preventDefault();

    const index = e.currentTarget.getAttribute("data-index");
    if (this.state.urls[index]) {
      const docRef = this.state.urls[index].get("docRef")
        ? this.state.urls[index].get("docRef")
        : getFirestore(this.props.boutique)
            .collection(`${this.state.urls[index].get("type")}s`)
            .doc(this.state.urls[index].get("docId"));

      docRef
        .update({
          pathname: this.state.urls[index].get("pathname")
        })
        .then(() => {
          console.info(
            `mise à jour de l'url pour le ${this.state.urls[index].get(
              "type"
            )}`,
            this.state.urls[index].get("docId")
          );
        });
    } else {
      console.error("L'url n'existe pas pour l'index", index);
    }
  }

  render() {
    if (!this.state.urls) return null;
    return (
      <Div>
        <h3>Liste des adresses associées :</h3>
        <ul>
          {this.state.urls.map((url, index) => (
            <li key={index}>
              <span className="libelle">{url.get("pathname")}</span>
              <span className="actions">
                {this.state.urls.length > 1 && (
                  <button data-index={index} onClick={this.handleDeleteUrl}>
                    supprimer
                  </button>
                )}
                {this.state.pathname !== url.get("pathname") && (
                  <button data-index={index} onClick={this.handleSetDefaultUrl}>
                    url par défaut
                  </button>
                )}
              </span>
            </li>
          ))}
        </ul>
      </Div>
    );
  }
}

const selector = formValueSelector("item");

export default connect(
  (globalState, ownProps) => {
    return {
      pathname: selector(globalState, "pathname")
    };
  },
  (dispatch, ownProps) => {
    return {
      change: pathname => {
        dispatch(change("item", "pathname", pathname));
      }
    };
  }
)(Pathnames);
