import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { formValueSelector } from "redux-form";
import { load } from "../../actions";
import Group from "../../../../../CMS/components/Data/Group";

class Stripe extends Component {
  constructor(props) {
    super(props);

    this.state = {
      charge: null,
      loading: true
    };
  }
  componentWillMount() {
    if (this.props.show) {
      // this.props.id === paiementId
      // Il faut commencer par récupérer le paiement puis la charge
      this.props.load(this.props.id).then(action => {
        if (action.payload.exists && action.payload.data().charge) {
          this.setState({
            charge: action.payload.data().charge,
            loading: false
          });
        } else {
          this.setState({
            loading: false
          });
        }
      });
    }
  }

  render() {
    if (!this.props.show) {
      return null;
    }

    // TODO gérer un bel affichage
    if (this.state.loading) {
      return <div>Chargement en cours ...</div>;
    }

    if (!this.state.charge) {
      return <div>Erreur lors du chargement de la charge</div>;
    }
    return (
      <Group className="boutique__paiement__mode--stripe">
        <ol>
          <li>
            <label>Amount</label>
            <span className="value">{this.state.charge.amount}</span>
          </li>
          <li>
            <label>Amount refunded</label>
            <span className="value">{this.state.charge.amount_refunded}</span>
          </li>
          <li>
            <label>Application</label>
            <span className="value">{this.state.charge.application}</span>
          </li>
          <li>
            <label>Application fee</label>
            <span className="value">{this.state.charge.application_fee}</span>
          </li>
          <li>
            <label>balance transaction</label>
            <span className="value">
              {this.state.charge.balance_transaction}
            </span>
          </li>
          <li>
            <label>Captured</label>
            <span className="value">{this.state.charge.captured}</span>
          </li>
          <li>
            <label>Created</label>
            <span className="value">{this.state.charge.created}</span>
          </li>
          <li>
            <label>Currency</label>
            <span className="value">{this.state.charge.currency}</span>
          </li>
          <li>
            <label>Customer</label>
            <span className="value">{this.state.charge.customer}</span>
          </li>
          <li>
            <label>Description</label>
            <span className="value">{this.state.charge.description}</span>
          </li>
          <li>
            <label>Destination</label>
            <span className="value">{this.state.charge.destination}</span>
          </li>
          <li>
            <label>Dispute</label>
            <span className="value">{this.state.charge.dispute}</span>
          </li>
          <li>
            <label>Failure code</label>
            <span className="value">{this.state.charge.failure_code}</span>
          </li>
          <li>
            <label>Failure message</label>
            <span className="value">{this.state.charge.failure_message}</span>
          </li>
          {/*<li><label>Failure details</label><span className='value'>{this.state.charge.fraud_details}</span></li>*/}
          <li>
            <label>Id</label>
            <span className="value">{this.state.charge.id}</span>
          </li>
          <li>
            <label>Invoice</label>
            <span className="value">{this.state.charge.invoice}</span>
          </li>
          <li>
            <label>Id</label>
            <span className="value">{this.state.charge.id}</span>
          </li>
          <li>
            <label>Live mode</label>
            <span className="value">{this.state.charge.livemode}</span>
          </li>
          {/*<li><label>Metadata</label><span className='value'>{this.state.charge.metadata}</span></li>*/}
          <li>
            <label>Object</label>
            <span className="value">{this.state.charge.object}</span>
          </li>
          <li>
            <label>On behalf of</label>
            <span className="value">{this.state.charge.on_behalf_of}</span>
          </li>
          <li>
            <label>Order</label>
            <span className="value">{this.state.charge.order}</span>
          </li>
          {/*<li><label>Outcome</label><span className='value'>{this.state.charge.outcome}</span></li>*/}
          <li>
            <label>Paid</label>
            <span className="value">{this.state.charge.paid}</span>
          </li>
          <li>
            <label>Receipt email</label>
            <span className="value">{this.state.charge.receipt_email}</span>
          </li>
          <li>
            <label>Receipt number</label>
            <span className="value">{this.state.charge.receipt_number}</span>
          </li>
          <li>
            <label>Refunded</label>
            <span className="value">{this.state.charge.refunded}</span>
          </li>
          {/*<li><label>Refunds</label><span className='value'>{this.state.charge.refunds}</span></li>*/}
          <li>
            <label>Review</label>
            <span className="value">{this.state.charge.review}</span>
          </li>
          <li>
            <label>Shipping</label>
            <span className="value">{this.state.charge.shipping}</span>
          </li>
          {/*<li><label>Source</label><span className='value'>{this.state.charge.source}</span></li>*/}
          <li>
            <label>Source transfer</label>
            <span className="value">{this.state.charge.source_transfer}</span>
          </li>
          <li>
            <label>Statement descriptor</label>
            <span className="value">
              {this.state.charge.statement_descriptor}
            </span>
          </li>
          <li>
            <label>Status</label>
            <span className="value">{this.state.charge.status}</span>
          </li>
          <li>
            <label>Transfer group</label>
            <span className="value">{this.state.charge.transfer_group}</span>
          </li>
        </ol>
      </Group>
    );
  }
}

Stripe.propTypes = {
  id: PropTypes.string.isRequired
};

const selector = formValueSelector("item");

const mapStateToProps = (globalState, ownProps) => {
  const props = {};

  props.show =
    selector(globalState, "mode") && selector(globalState, "mode") === "stripe";

  return props;
};

const mapDispatchToProps = dispatch => {
  return {
    load: id => dispatch(load(id))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Stripe);
