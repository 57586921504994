import React, { PureComponent, Component } from "react";
import { Field, formValueSelector } from "redux-form";
import StyledField from "../../styles/Field";
import { connect } from "react-redux";
import Traductions from "./Traductions";

class TextareaField extends Component {
  delayedResize = event => {
    window.setTimeout(this.resize, 0);
  };

  state = {
    minRows: 1,
    maxRows: 20
  };

  resize = event => {
    this.textarea.style.height = "auto";
    //this.textarea.style.height = this.textarea.scrollHeight + "px";

    const textareaLineHeight = 24;
    const { minRows, maxRows } = this.state;
    this.textarea.rows = minRows; // reset number of rows in textarea

    const currentRows = ~~(this.textarea.scrollHeight / textareaLineHeight);

    const rows =
      currentRows < maxRows
        ? currentRows > minRows
          ? currentRows
          : minRows
        : maxRows;
    if (currentRows >= maxRows) {
      this.textarea.scrollTop = this.textarea.scrollHeight;
    }

    this.textarea.rows = rows;

    // this.setState({
    //   value: event.target.value,
    //   rows: currentRows < maxRows ? currentRows : maxRows
    // });
  };

  componentDidMount() {
    this.resize();
    this.textarea.style.overflow = "hidden";

    if (this.textarea.value) {
      this.textarea.classList.remove("is-empty");
    } else {
      this.textarea.classList.add("is-empty");
    }

    this.textarea.addEventListener("focus", function(event) {
      event.currentTarget.classList.add("is-focused");
    });

    this.textarea.addEventListener("blur", function(event) {
      event.currentTarget.classList.remove("is-focused");
    });

    // this.textarea.addEventListener("change", this.resize);
    // this.textarea.addEventListener("cut", this.delayedResize);
    // this.textarea.addEventListener("paste", this.delayedResize);
    // this.textarea.addEventListener("drop", this.delayedResize);
    // this.textarea.addEventListener("keydown", this.delayedResize);

    // this.textarea.addEventListener("keyup", event => {
    //   this.resize();
    //   if (event.currentTarget.value.length === 0) {
    //     event.currentTarget.classList.add("is-empty");
    //   } else {
    //     event.currentTarget.classList.remove("is-empty");
    //   }
    // });
  }

  render() {
    const {
      input,
      label,
      horizontal,
      className,
      rows,
      style,
      language,
      meta: { touched, error },
      ...others
    } = this.props;

    let classNames = ["cms__field", "textarea"];
    if (touched && error) classNames.push("error");
    if (horizontal) classNames.push("horizontal");
    if (language)
      classNames = classNames.concat(["language", `lang_${language}`]);
    if (className) classNames = classNames.concat(className.split(" "));

    return (
      <StyledField style={style} className={[...new Set(classNames)].join(" ")}>
        <textarea
          rows={rows ? rows : 1}
          {...input}
          {...others}
          ref={textarea => {
            this.textarea = textarea;
          }}
        />
        <label className="label">
          <span>{label}</span>
        </label>
        {touched && error && (
          <div className={"error error_" + error.code}>
            <span className="icon">
              <i className="fa fa-exclamation" />
            </span>
            <span>{error.message}</span>
          </div>
        )}
      </StyledField>
    );
  }
}

class Textarea extends PureComponent {
  // shouldComponentUpdate(nextProps, nextState) {
  //   return Object.keys(diff(this.props, nextProps)).length > 0;
  // }

  render() {
    let {
      prefix,
      disabled,
      onBlur,
      className,
      description,
      label,
      placeholder,
      icon,
      rows,
      horizontal,
      name,
      style,
      isTranslatable,
      traductions,
      ...others
    } = this.props;

    delete others.user;
    delete others.boutique;
    delete others.id;
    delete others.itemRef;

    if (prefix && name) {
      name = prefix + "." + name;
    }

    const props = {
      disabled,
      onBlur,
      className,
      description,
      label,
      placeholder,
      icon,
      rows,
      horizontal,
      name,
      style,
      component: TextareaField
    };

    return (
      <>
        <Field {...props} />
        {isTranslatable && traductions && (
          <Traductions {...props} traductions={traductions} />
        )}
      </>
    );
  }
}

const selector = formValueSelector("item");

export default connect(globalState => {
  return {
    traductions: selector(globalState, "traductions")
  };
})(Textarea);
