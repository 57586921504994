import React, { Component } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { remove } from "../Notification/actions";

const Div = styled.div`
  line-height: 34px;
  float: right;
`;

class Notification extends Component {
  state = {
    key: undefined,
    mustInitRemove: false
  };

  constructor(props) {
    super(props);

    this.initRemove = this.initRemove.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    if (props.notifications && Object.keys(props.notifications).length > 0) {
      if (
        !state.key ||
        Object.keys(props.notifications)[
          Object.keys(props.notifications).length - 1
        ] !== state.key
      ) {
        state.key = Object.keys(props.notifications)[
          Object.keys(props.notifications).length - 1
        ];
        state.mustInitRemove = true;
      }
    }

    return state;
  }

  componentDidMount() {
    if (this.state.mustInitRemove) {
      this.initRemove();
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.state.mustInitRemove) {
      this.initRemove();
    }
  }

  initRemove() {
    this.setState({
      mustInitRemove: false
    });
    setTimeout(() => {
      this.props.remove(this.state.key);
    }, 3000);
  }

  render() {
    if (
      !this.props.notifications ||
      Object.keys(this.props.notifications).length === 0
    ) {
      return null;
    }

    const key = Object.keys(this.props.notifications)[
      Object.keys(this.props.notifications).length - 1
    ];

    return <Div>{this.props.notifications[key].message}</Div>;
  }
}

const mapStateToProps = (globalState, ownProps) => {
  return {
    notifications: globalState.cms.notifications
  };
};

const mapDispatchToProps = dispatch => {
  return {
    remove: key => dispatch(remove(key))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Notification);
