import List from "../../../CMS/containers/Boutique/components/List";
import Item from "./Item";
import page from "./params.json";

import { initialize, validate } from "./functions";
import Page from "../../../CMS/datas/Page";

class PageList extends List {
  collectionName = "pages";
  defaultOrderField = "name";
  defaultOrderDirection = "asc";
  defaultWheres = { parent: "" };
  listTitle = "Pages";
  viewList = Item;
  itemTitle = { type: "attribut", attribut: "name" };
  initializeItem = initialize;
  validateItem = validate;
  itemParams = page;
  messageNoDocument = "Aucune page";

  constructor(props) {
    super(props, {
      newItemLibelle: "Nouvelle page"
    });

    this.handleTransfertToProject = this.handleTransfertToProject.bind(this);
    this.handleViewPage = this.handleViewPage.bind(this);

    this.state.form.required = ["name"];

    this.state.list.menu.left = [
      {
        text: "Ajouter",
        onClick: this.handleCreate
      },
      {
        text: "Transférer",
        onClick: this.handleTransfertToProject
      }
    ];

    this.state.form.menu.right = [
      { icon: "fa-eye", onClick: this.handleViewPage },
      { icon: "fa-close", onClick: this.handleClose },
      { icon: "fa-save", submit: true }
    ];
  }

  handleTransfertToProject(e) {
    e.preventDefault();
    if (!this.props.boutique.get("firebaseApiKey")) {
      console.error(
        "Il n'est pas possible de transférer les pages car le projet n'est pas configuré"
      );
    }

    Page.transfertToBoutique(this.props.boutique).then(result => {
      console.info("result", result);
    });
  }

  handleViewPage(e) {
    const url = `${this.props.boutique.get("websiteURL")}/${
      this.state.item.pathname
    }`;
    window.open(url, "_blank");
  }
}

export default PageList;
