import React, { Component } from "react";
import Section from "../../../../../../CMS/components/Data/Section";
import Classique from "./Classique";
import Specifique from "./Specifique";

import { FieldArray, formValueSelector } from "redux-form";
import { connect } from "react-redux";

class Dimensions extends Component {
  state = {
    category: undefined,
    mustBeUpdated: false,
    isLoading: false
  };

  constructor(props) {
    super(props);

    this.loadProductCategoriesDimensions = this.loadProductCategoriesDimensions.bind(
      this
    );
  }

  static getDerivedStateFromProps(props, state) {
    // Le composant est affiché uniquement lorsque hasInventory = true
    if (
      props.category &&
      (state.category === undefined || props.category !== state.category)
    ) {
      state.mustBeUpdated = true;
      state.category = props.category;
    }

    return state;
  }

  componentDidMount() {
    if (this.state.mustBeUpdated && !this.state.isLoading) {
      this.loadProductCategoriesDimensions();
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.state.mustBeUpdated && !this.state.isLoading) {
      this.loadProductCategoriesDimensions();
    }
  }

  loadProductCategoriesDimensions() {
    this.setState({
      isLoading: true
    });
    const dimensions = this.props.fields.getAll();
    this.props.boutique.ref
      .collection("productCategories")
      .doc(this.state.category)
      .get()
      .then(snapshot => {
        if (snapshot.exists) {
          // On récupére toutes les dimensions
          snapshot.ref
            .collection("dimensions")
            .where("deleted", "==", false)
            .get()
            .then(querySnapshot => {
              if (querySnapshot.size > 0) {
                querySnapshot.forEach(doc => {
                  const index =
                    dimensions && dimensions.length > 0
                      ? dimensions.findIndex(
                          dimension => dimension.id === doc.id
                        )
                      : -1;
                  if (index < 0) {
                    this.props.fields.push({
                      id: doc.id,
                      libelle: doc.data().libelle,
                      valeur: ""
                    });
                  }
                });
              }

              this.setState({
                isLoading: false,
                mustBeUpdated: false
              });
            });
        }
      });
  }

  render() {
    const { fields } = this.props;

    return (
      <div>
        <Classique />
        <Section
          title="Dimensions spécifiques"
          button={{
            text: "Ajouter une dimension",
            onClick: () => fields.push({})
          }}
          group={true}
        >
          {fields.length > 0 ? (
            fields.map((dimension, idx, dimensions) => (
              <Specifique
                key={idx}
                name={dimension}
                data={dimensions.get(idx)}
                handleRemove={() => fields.remove(idx)}
                dispatch={this.props.dispatch}
              />
            ))
          ) : (
            <p>Aucune dimension spécifique</p>
          )}
        </Section>
      </div>
    );
  }
}

const selector = formValueSelector("item");

export default connect(state => {
  return {
    category: selector(state, "category"),
    productType: selector(state, "productType")
  };
})(props => <FieldArray {...props} component={Dimensions} />);
