import React from "react";
import Id from "./Id";
import Date from "./Date";
import Default from "./Default";
import { Link } from "@reach/router";

export default ({ type, ...others }) => {
  switch (type) {
    case "id":
      return <Id {...others} />;

    case "date":
      return <Date {...others} />;

    case "link":
      return <Link to={others.link} />;

    default:
      return <Default {...others} />;
  }
};
