import React, { Component } from "react";
import PropTypes from "prop-types";
import StyledRow from "../../../../../CMS/styles/View/List/Row";
import moment from "moment";
import Fournisseur from "../../../../../CMS/datas/Fournisseur";
import Feature from "../../../../../CMS/datas/Feature";

class Row extends Component {
  constructor(props) {
    super(props);

    this.state = {
      mustBeLoadedFournisseur: false,
      fournisseur: null,
      fournisseurData: null,
      mustBeLoadedType: false,
      type: null,
      typeData: null
    };

    this.loadFournisseur = this.loadFournisseur.bind(this);
    this.loadType = this.loadType.bind(this);
  }

  componentDidMount() {
    if (this.state.mustBeLoadedFournisseur) {
      this.loadFournisseur(this.state.fournisseur);
    }
    if (this.state.mustBeLoadedType) {
      this.loadType(this.state.type);
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.state.mustBeLoadedFournisseur) {
      this.loadFournisseur(this.state.fournisseur);
    }
    if (this.state.mustBeLoadedType) {
      this.loadType(this.state.type);
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (
      props.doc &&
      props.doc.get("fournisseur") &&
      (!state.fournisseurRef ||
        state.fournisseur !== props.doc.get("fournisseur"))
    ) {
      state.mustBeLoadedFournisseur = true;
      state.fournisseur = props.doc.get("fournisseur");
    }
    if (
      props.doc &&
      props.doc.get("type") &&
      (!state.type || state.type !== props.doc.get("type"))
    ) {
      state.mustBeLoadedType = true;
      state.type = props.doc.get("type");
    }

    return state;
  }

  loadFournisseur(fournisseur) {
    Fournisseur.findById(this.props.boutique, fournisseur).then(snapshot => {
      this.setState({
        fournisseurData: snapshot.data(),
        mustBeLoadedFournisseur: false
      });
    });
  }

  loadType(typeId) {
    Feature.findByTypeAndId(this.props.boutique, "depenseTypes", typeId)
      .then(snapshot => {
        this.setState({
          typeData: snapshot.data(),
          mustBeLoadedType: false
        });
      })
      .catch(error => {
        console.error("loadType", error.message);
      });
  }

  render() {
    const { doc, onClick } = this.props;

    const item = doc.data();

    return (
      <StyledRow>
        <div className="date">
          <span className="day">{moment(item.billingDate).format("Do")}</span>
          <span className="month">
            {moment(item.billingDate).format("MMM")}
          </span>
        </div>
        <p className="fournisseur" style={{ flex: 1 }}>
          {this.state.fournisseurData && this.state.fournisseurData.nom}
        </p>
        <div className="options">
          <p className="type">
            {this.state.typeData && this.state.typeData.libelle}
          </p>
        </div>
        <p className="price" style={{ fontWeight: "bold" }}>
          {item.montant}
        </p>
        <p className="control actions">
          <button onClick={onClick} className="edit small">
            <span className="icon" />
            <span className="txt">Edit</span>
          </button>
        </p>
      </StyledRow>
    );
  }
}

Row.propTypes = {
  doc: PropTypes.object.isRequired
};

export default Row;
