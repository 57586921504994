import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { formValueSelector, change } from "redux-form";
import Input from "../../../../CMS/components/Data/Input/index";
import Field from "../../../../CMS/styles/Field";
import Button from "../../../../CMS/components/Button/index";
import Url from "../../../../CMS/datas/Url";

class InputUrl extends Component {
  handleClear(e) {
    e.preventDefault();

    Url.clear(
      this.props.boutique,
      this.props.libelle,
      this.props.urlType,
      this.props.id,
      this.props.parentId
    ).then(url => {
      this.props.change(url.get("pathname"));
    });
  }

  render() {
    const { name, label, placeholder } = this.props;

    return (
      <div style={{ display: "flex" }}>
        <Input
          type="text"
          name={name}
          label={label}
          placeholder={placeholder}
          className="is-expanded no-margin-right no-right-radius no-border-right"
          onBlur={this.handleBlur}
        />
        <Field noMarginLeft>
          <div className="has-addons">
            <Button
              onClick={this.handleClear.bind(this)}
              smallIcon
              icon="fa-eraser"
              className="no-left-radius"
            />
          </div>
        </Field>
      </div>
    );
  }
}

InputUrl.propTypes = {
  urlType: PropTypes.string.isRequired
};

const selector = formValueSelector("item");

const mapStateToProps = (globalState, ownProps) => {
  return {
    libelle: selector(globalState, ownProps.field),
    parentId: selector(globalState, "parent")
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    change: pathname => {
      dispatch(change("item", "pathname", pathname));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InputUrl);
