import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { formValueSelector } from "redux-form";

import { change } from "redux-form";
import Input from "../../../../CMS/components/Data/Input/index";
import Text from "../../../../CMS/components/Data/Input/Text";
import Url from "../../../../CMS/datas/Url";
import Traductions from "../../../../CMS/components/Data/Traductions";

class Libelle extends PureComponent {
  handleBlur(event) {
    const value = event.target.value;

    if (this.props.id && value) {
      Url.clear(
        this.props.boutique,
        value,
        this.props.urlType,
        this.props.id,
        this.props.parentId
      ).then(url => {
        this.props.change(url.get("pathname"));
      });
    }
  }

  render() {
    const {
      name,
      label,
      placeholder,
      isTranslatable,
      traductions
    } = this.props;
    if (isTranslatable) {
      console.info(`Le champs ${name} est traduisible`);
    }

    const props = {
      name,
      label,
      placeholder,
      className: "boutique__url__component--libelle",
      type: "text"
    };

    return (
      <>
        <Input {...props} onBlur={this.handleBlur.bind(this)} />

        {isTranslatable && traductions && (
          <Traductions {...props} component={Text} traductions={traductions} />
        )}
      </>
    );
  }
}

Libelle.propTypes = {
  urlType: PropTypes.string.isRequired
};

const selector = formValueSelector("item");

export default connect(
  (globalState, ownProps) => {
    return {
      pathname: selector(globalState, "pathname"),
      parentId: selector(globalState, "parent"),
      traductions: selector(globalState, "traductions")
    };
  },
  (dispatch, ownProps) => {
    return {
      change: pathname => {
        dispatch(change("item", "pathname", pathname));
      }
    };
  }
)(Libelle);
