import {
    INTRAS_WATCH,
    INTRA_CREATE,
    INTRA_UPDATE,
    INTRA_REMOVE,
    INTRAS_RESET
} from "../actionTypes";

export function watch(user, wheres) {
    return dispatch => {

        let query = user.ref.collection("intras");
        let tmp;
        let orderByField = 'date';
        if(wheres && Object.keys(wheres).length>0) {

            Object.keys(wheres).forEach(key => {
                if(wheres[key]) {
                    if(!Array.isArray(wheres[key])) {
                        if(typeof wheres[key] === 'string' || wheres[key] instanceof String) {
                            tmp = {
                                operator: '==',
                                value: wheres[key]
                            }
                        } else {
                            tmp = Object.assign({}, wheres[key]);
                            tmp.operator = tmp.operator ? tmp.operator : '==';
                        }
                        wheres[key] = [];
                        wheres[key].push({
                            operator: tmp.operator,
                            value: tmp.value
                        });
                    }

                    wheres[key].forEach(where => {
                        const operator = where.operator;
                        if(where.operator!=='==') {
                            orderByField = key;
                        }

                        query = query.where(String(key), operator, String(where.value))
                    });
                }
            });
        }

        query = query.orderBy(orderByField, 'asc');
        query.onSnapshot(function(querySnapshot) {
            dispatch({
                type: INTRAS_WATCH,
                docs: querySnapshot.docs
            });
        });
    }
}

export function create(item, user) {
    return {
        type: INTRA_CREATE,
        payload: user.ref.collection('intras').add(item)
    };
}

export function update(ref, data) {
    return {
        type: INTRA_UPDATE,
        payload: ref.update(data)
    };
}

export function remove(ref) {
    return {
        type: INTRA_REMOVE,
        payload: ref.delete()
    };
}

export function reset(user) {
    return dispatch => {
        const unsubscribe = user.ref.collection("intras").onSnapshot(function () {});
        // Stop listening to changes
        unsubscribe();

        dispatch({
            type: INTRAS_RESET
        });
    }
}