import React, { Component } from "react";
import Input from "../../../../../CMS/components/Data/Input/index";
import Button from "../../../../../CMS/components/Button/index";
import Field from "../../../../../CMS/styles/Field";
import { connect } from "react-redux";
import { formValueSelector } from "redux-form";
import { change } from "redux-form";
import Modele from "../../../../../CMS/datas/Modele";

class Sku extends Component {
  state = {
    mustBeGenerated: false,
    inProgress: false,
    date: undefined
  };

  constructor(props) {
    super(props);

    this.generateSku = this.generateSku.bind(this);
  }

  handleGenerate(event) {
    event.preventDefault();
    this.generateSku();
  }

  generateSku() {
    if (!this.state.inProgress) {
      this.setState({
        inProgress: true
      });

      Modele.getNewSku(this.props.boutique, this.state.date).then(sku => {
        this.props.change(sku);
        this.setState({
          sku: sku,
          inProgress: false,
          mustBeGenerated: false
        });
      });
    }
  }

  render() {
    const { name, label, placeholder } = this.props;

    return (
      <div style={{ display: "flex" }}>
        <Input
          type="text"
          name={name}
          label={label}
          placeholder={placeholder}
          className="no-border-right no-right-radius no-margin-right no-margin-bottom is-expanded"
        />
        <Field noMarginLeft noMarginBottom>
          <div className="has-addons">
            <Button
              smallIcon
              icon="fa-repeat"
              onClick={this.handleGenerate.bind(this)}
              className="no-left-radius"
            />
          </div>
        </Field>
      </div>
    );
  }
}

const selector = formValueSelector("item");

const mapStateToProps = globalState => {
  return {
    sku: selector(globalState, "sku"),
    date: selector(globalState, "publishedAt")
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    change: sku => dispatch(change("item", "sku", sku))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Sku);
