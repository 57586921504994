import moment from 'moment';
import * as functions from '../../CMS/functions';

export function initialize(data) {
    const objet = functions.initialize(data);
    delete objet.deleted;

    return {
        ...objet,
        date: moment().format('YYYY-MM-DDTHH:mm')
    }
}

export function validate(data) {
    data.updatedAt = moment().toISOString();

    return data;
}