import React, { Component } from "react";
import PropTypes from "prop-types";
import StyledRow from "../../../../../CMS/styles/View/List/Row";
import moment from "moment";
import Commande from "../../../../../CMS/datas/Commande";

class Row extends Component {
  state = {
    commande: undefined,
    commandeData: undefined,
    commandeMustBeLoaded: false
  };

  componentDidMount() {
    if (this.state.commandeMustBeLoaded) {
      this.loadCommande(this.state.commande);
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.state.commandeMustBeLoaded) {
      this.loadCommande(this.state.commande);
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (
      props.doc &&
      props.doc.get("commande") &&
      (!state.commande || state.commande !== props.doc.get("commande"))
    ) {
      state.commandeMustBeLoaded = true;
      state.commande = props.doc.get("commande");
    }

    return state;
  }

  loadCommande(commandeId) {
    this.setState({
      commandeMustBeLoaded: false
    });
    Commande.findById(this.props.boutique, commandeId)
      .then(snapshot => {
        this.setState({
          commandeData: snapshot.data(),
          commandeMustBeLoaded: false
        });
      })
      .catch(error => {
        console.error(error.message);
        this.setState({
          commandeData: null,
          commandeError: error.message
        });
      });
  }

  render() {
    const { doc, onClick } = this.props;

    const item = doc.data();

    let name = undefined;

    if (item.origine === "commande") {
      if (this.state.commandeError) {
        name = `Erreur avec la commande : ${this.state.commandeError}`;
      } else {
        if (this.state.commandeData) {
          name = `Commande n°${this.state.commandeData.numero} par ${
            this.state.commandeData.contact_firstname
          } ${
            this.state.commandeData.contact_lastname
          }`;
        }
      }
    } else {
      if (item.client_name) {
        name = item.client_name;
      } else {
        if (item.client_email) {
          name = item.client_email;
        } else {
          if (item.client_phone) {
            name = item.client_phone;
          }
        }
      }
    }
    return (
      <StyledRow>
        <div className="date">
          <span className="day">{moment(item.saleDate).format("Do")}</span>
          <span className="month">{moment(item.saleDate).format("MMM")}</span>
        </div>
        <p className="" style={{ flex: 1 }}>
          {name ? (
            <span className="name">{name}</span>
          ) : (
            item.commentaire && (
              <span className="description">
                {item.commentaire.substr(0, 60)}
              </span>
            )
          )}
        </p>
        <p>{item.mode}</p>
        <p className="price">{item.montant}</p>
        <p className="control actions">
          <button onClick={onClick} className="edit small">
            <span className="icon" />
            <span className="txt">Edit</span>
          </button>
        </p>
      </StyledRow>
    );
  }
}

Row.propTypes = {
  doc: PropTypes.object.isRequired
};

export default Row;
