import React from "react";
import Split from "./Split";
import styled from "styled-components";
import ViewTitle from "../../styles/View/Title";

const Div = styled.div`
  margin: ${props => (props.theme.mobile ? "1.5rem 0.75rem" : "1.5rem 2rem")};
`;

const View = props => {
  const { type, title, className } = props;

  switch (type) {
    case "split":
      if (props.children.length > 1) {
        return <Split {...props} />;
      } else {
        return (
          <Div className="help error">
            Il manque un enfant pour afficher cette vue
          </Div>
        );
      }
    default:
      return (
        <Div className={className}>
          {title && <ViewTitle>{title}</ViewTitle>}
          {props.children}
        </Div>
      );
  }
};

export default View;
