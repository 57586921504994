import React, { Component } from "react";
import Button from "../../../../../../CMS/components/Button";
import Input from "../../../../../../CMS/components/Data/Input";
import Field from "../../../../../../CMS/styles/Field";

class Produit extends Component {
  render() {
    const { index, produits, handleEdit } = this.props;
    const produit = produits.get(index);

    const subTotal = parseInt(produit.qty, 10) * parseFloat(produit.price);
    let remise = 0;
    if (produit.discount) {
      switch (produit.discountType) {
        case "pourcentage":
          remise = Math.round((parseFloat(produit.discount) * subTotal) / 100);
          break;

        default:
          remise = parseFloat(produit.discount);
      }
    }

    const total = subTotal - remise;

    //TODO modifier pour prendre en compte les variants

    return (
      <li className="produit">
        {/*<td><img src={produit.imageUrl}/></td>*/}
        <div className="infos">
          <div className="name">
            <strong>{produit.name}</strong>
          </div>
          <div className="tarif">
            {remise > 0 && (
              <span className="remise">
                Une remise de {remise}
                {produit.discountType === "pourcentage" ? "%" : "€"} est
                appliquée sur le tarif
              </span>
            )}
            <p className="prix">
              {remise > 0 && (
                <span style={{ textDecoration: "line-through" }}>
                  {subTotal} €
                </span>
              )}
              <span>{total} €</span>
            </p>
          </div>
          <div className="sku">{produit.sku}</div>
          <div className="dimensions" style={{ fontSize: "90%" }}>
            {produit.dimensions}
          </div>
          <div
            className="description"
            style={{ fontSize: "85%", fontStyle: "italic" }}
          >
            {produit.description}
          </div>
        </div>

        <Input
          placeholder="Quantité"
          name={`${this.props.name}.qty`}
          type="number"
          value={produit.qty}
          style={{ width: "50px" }}
          className="no-margin-top no-margin-bottom no-margin-left no-margin-right no-border-right no-right-radius"
        />

        <Input
          component="input"
          icon="fa-euro"
          placeholder="Prix"
          name={`${this.props.name}.price`}
          type="number"
          value={produit.price}
          style={{ width: "70px" }}
          className="no-margin-top no-margin-bottom no-margin-left no-margin-right no-border-right no-right-radius no-left-radius"
        />

        <Field
          noMarginTop
          noMarginRight
          noMarginLeft
          noMarginBottom
          noBorderLeft
          noLeftRadius
        >
          <Button smallIcon onClick={handleEdit} icon="fa-edit" />
          <Button
            smallIcon
            onClick={e => produits.remove(index)}
            icon="fa-trash"
          />
        </Field>
      </li>
    );
  }
}

export default Produit;
