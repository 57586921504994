import React, { Component } from "react";
import PropTypes from "prop-types";
import { Field, change } from "redux-form";
import ComponentField from "../../../../../CMS/styles/Field";

import { load } from "../../actions";

class Select extends Component {
  constructor(props) {
    super(props);

    this.state = {
      options: []
    };

    this.handleChangeValue = this.handleChangeValue.bind(this);
    this.loadContacts = this.loadContacts.bind(this);
  }

  componentWillMount() {
    if (this.props.boutique) {
      this.loadContacts(this.props.boutique);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (!this.props.boutique && nextProps.boutique) {
      console.info("props contact select", this.props);
      this.loadContacts(nextProps.boutique);
    }
  }

  loadContacts(boutique) {
    this.props.meta.dispatch(load(boutique)).then(action => {
      if (!action.error) {
        if (action.payload.size > 0) {
          this.setState(
            {
              options: action.payload.docs
            },
            () => {
              if (this.input.value) {
                this.input.classList.remove("is-empty");
              } else {
                this.input.classList.add("is-empty");
              }

              this.input.addEventListener("focus", function(event) {
                event.currentTarget.classList.add("is-focused");
              });
              this.input.addEventListener("blur", function(event) {
                event.currentTarget.classList.remove("is-focused");
              });
              this.input.addEventListener("keyup", function(event) {
                if (event.currentTarget.value.length === 0) {
                  event.currentTarget.classList.add("is-empty");
                } else {
                  event.currentTarget.classList.remove("is-empty");
                }
              });
            }
          );
        }
      } else {
        console.error(action.payload.message);
      }
    });
  }

  handleChangeValue = event => {
    this.props.meta.dispatch(
      change("item", this.props.input.name, event.currentTarget.value)
    );
  };

  render() {
    const {
      input: { name, value },
      label,
      horizontal,
      className,
      meta: { touched, error }
    } = this.props;

    return (
      <ComponentField
        className={
          "field select " +
          (touched && error ? "error " : "") +
          (horizontal ? "horizontal " : "") +
          (className ? className : "")
        }
      >
        {this.state.options.length > 0 ? (
          <select
            name={name}
            value={value}
            ref={input => {
              this.input = input;
            }}
            onChange={this.handleChangeValue}
          >
            <option value="">Non spécifié</option>
            {this.state.options.map((item, index) => {
              return (
                <option key={index} value={item.id}>
                  {item.data().name}
                </option>
              );
            })}
          </select>
        ) : (
          <div>...</div>
        )}
        <label className="label">
          <span>{label}</span>
        </label>
        {touched &&
          error && (
            <div className={"error error_" + error.code}>
              <span className="icon">
                <i className="fa fa-exclamation" />
              </span>
              <span>{error.message}</span>
            </div>
          )}
      </ComponentField>
    );
  }
}

Select.propTypes = {
  label: PropTypes.string
};

export default props => <Field {...props} component={Select} />;
