export default path => {
  // TODO expérimental - voir pour améliorer le router
  if (
    path.substr(0, 18) === "/boutique/contacts" ||
    path.substr(0, 17) === "/boutique/groupes" ||
    path.substr(0, 19) === "/boutique/campagnes"
  ) {
    return "boutique-contacts";
  }

  if (
    path.substr(0, 18) === "/boutique/produits" ||
    path.substr(0, 18) === "/boutique/services" ||
    path.substr(0, 26) === "/boutique/caracteristiques" ||
    path.substr(0, 20) === "/boutique/categories" ||
    path.substr(0, 17) === "/boutique/modeles"
  ) {
    return "boutique-catalogue";
  }

  if (path.substr(0, 21) === "/boutique/abonnements") return "boutique-abonnements";
  if (path.substr(0, 19) === "/boutique/commandes") return "boutique-commandes";

  if (
    path.substr(0, 13) === "/boutique/tdb" ||
    path.substr(0, 18) === "/boutique/depenses" ||
    path.substr(0, 16) === "/boutique/ventes"
  ) {
    return "boutique-compta";
  }

  if (path.substr(0, 18) === "/boutique/demandes") return "boutique-demandes";
  if (path.substr(0, 14) === "/website/pages") return "website-pages";
  if (path.substr(0, 17) === "/website/articles") return "website-articles";
  if (path.substr(0, 14) === "/website/memos") return "website-memos";

  if (
    path.substr(0, 16) === "/website/banners" ||
    path.substr(0, 16) === "/website/coupons" ||
    path.substr(0, 13) === "/website/cart"
  ) {
    return "website-marketing";
  }

  if (
    path.substr(0, 13) === "/planning/tdb" ||
    path.substr(0, 16) === "/planning/fiches" ||
    path.substr(0, 17) === "/planning/projets"
  ) {
    return "planning";
  }

  if (
    path.substr(0, 7) === "/carnet"
  ) {
    return "carnet";
  }

  if (
    path.substr(0, 12) === "/admin/users" ||
    path.substr(0, 14) === "/admin/schemas" ||
    path.substr(0, 16) === "/admin/boutiques" ||
    path.substr(0, 17) === "/admin/categories"
  ) {
    return "admin";
  }

  switch (path) {
    case "/boutique/params":
    case "/boutique/fournisseurs":
      return "boutique-params";

    // apps
    case "/apps":
    case "/app/instagram":
      return "application";

    // organisation
    case "/organisation/tdb":
    case "/organisation/intras":
    case "/organisation/projets":
    case "/organisation/references":
      return "organisation";

    // statistiques
    case "/boutique/statistiques":
      return "analytics";

    case "/assistant/abonnements":
    case "/assistant/taches":
      return "assistant";

    case "/":
    default:
      return "home";
  }
};
