import moment from "moment";
import {
  USER_LOAD_REQUEST,
  USER_LOAD_SUCCESS,
  USER_LOAD_ERROR,
  BOUTIQUE_CHANGE,
  BOUTIQUES_LOAD_REQUEST,
  BOUTIQUES_LOAD_SUCCESS,
  BOUTIQUES_WATCH,
  BOUTIQUES_RESET,
  USERS_WATCH,
  USERS_RESET,
  ESPACES_WATCH,
  SCHEMAS_WATCH,
  DOCS_RESET,
  SCHEMAS_RESET,
  USER_IS_LOGOUT,
  ESPACE_LOAD_BY_ID,
  NOTIFICATION_CREATE,
  NOTIFICATION_REMOVE,
  RESET,
  IS_MOBILE,
  IS_WEBAPP
} from "./actionTypes";

const initialState = {
  loadingUser: true,
  isLogged: false,
  user: null,
  users: null,
  loadingBoutiques: true,
  indexBoutique: null,
  boutiques: null,
  error: null,
  info: null,
  notifications: {},

  espaces: null,
  docs: null,
  schemas: null
};

export default function cmsReducer(state = initialState, action) {
  let notifications;

  switch (action.type) {
    case IS_MOBILE:
      return { ...state, mobile: action.test };

    case IS_WEBAPP:
      return { ...state, webapp: action.test };

    case USER_LOAD_REQUEST:
      return { ...state, loadingUser: true, isLogged: false, user: null };

    case USER_LOAD_SUCCESS:
      if (action.user.exists) {
        return {
          ...state,
          isLogged: true,
          loadingUser: false,
          user: action.user,
          error: null,
          info: null
        };
      }

      return {
        ...state,
        isLogged: false,
        loadingUser: false,
        user: null,
        error: "L'utilisateur n'existe pas",
        info: null
      };

    case USER_LOAD_ERROR:
      return {
        ...state,
        isLogged: false,
        loadingUser: false,
        user: null,
        error: action.message
      };

    case USER_IS_LOGOUT:
      return {
        ...state,
        isLogged: false,
        loadingUser: false,
        user: null,
        error: null,
        info: "L'utilisateur est déconnecté"
      };

    case BOUTIQUES_LOAD_REQUEST:
      return { ...state, loadingBoutiques: true, boutiques: null };

    case BOUTIQUES_LOAD_SUCCESS:
    case BOUTIQUES_WATCH:
      const indexBoutique = state.indexBoutique
        ? state.indexBoutique
        : action.indexBoutique;
      return {
        ...state,
        loadingBoutiques: false,
        boutiques: action.boutiques,
        indexBoutique: indexBoutique
      };

    case BOUTIQUES_RESET:
      return { ...state, loadingBoutiques: false, boutiques: null };

    case BOUTIQUE_CHANGE:
      return { ...state, indexBoutique: action.index };

    case USERS_WATCH:
      return { ...state, users: action.docs };

    case USERS_RESET:
      return { ...state, users: null };

    // OLD
    case ESPACES_WATCH:
      return { ...state, docs: action.docs };

    case SCHEMAS_WATCH:
      return { ...state, schemas: action.docs };

    case ESPACE_LOAD_BY_ID:
      if (action.error) {
        return { ...state, error: "Erreur lors du chargement de l'espace" };
      }

      return { ...state, espace: action.espace };

    case DOCS_RESET:
      return { ...state, docs: null };

    case SCHEMAS_RESET:
      return { ...state, schemas: null };

    case NOTIFICATION_CREATE:
      notifications = Object.assign({}, state.notifications);
      const date = moment().format("x");
      notifications[date] = { ...action.notification, key: date };

      return { ...state, notifications: notifications };

    case NOTIFICATION_REMOVE:
      notifications = Object.assign({}, state.notifications);
      delete notifications[action.key];

      return { ...state, notifications: notifications };

    case RESET:
      return initialState;

    default:
  }

  return state;
}
