import styled from "styled-components";

export default styled.div`
  > ul {
    border-bottom: 1px solid #e4e7ed !important;
    font-size: 14px !important;
    height: auto !important;
    line-height: 1.3334 !important;
    margin: 0;
    margin-bottom: 30px !important;
    padding: 0 !important;
    list-style: none;
    display: flex;
    flex-wrap: ${props => (props.theme.mobile ? "nowrap" : "wrap")};
    overflow-x: ${props => (props.theme.mobile ? "auto" : "hidden")};
    overflow-y: hidden;

    li {
      margin-right: 10px;
      button {
        background: none;
        border-top: 0;
        border-right: 0;
        border-bottom: 4px solid transparent;
        border-left: 0;
        color: #86848c;
        display: block;
        float: none;
        font-size: 14px;
        margin-bottom: -1px !important;
        padding: 8px 10px;
        white-space: nowrap;
      }

      &.is-active button {
        background: 0 0;
        border-bottom-color: #4b71fc;
        border-radius: 0;
        color: #34313f;
        font-size: 14px;
        font-weight: 400;
        margin: 0;
        padding: 8px 10px;
      }
    }
  }
`;
