import React, { Component } from "react";
import { change, formValueSelector } from "redux-form";
import { connect } from "react-redux";
import moment from "moment";

class Infos extends Component {
  render() {
    let diffJours, soldoutAt;
    if (this.props.isSold) {
      if (this.props.soldoutAt) {
        soldoutAt = moment(this.props.soldoutAt);
        diffJours = soldoutAt.diff(moment(this.props.publishedAt), "days");
      }
    } else {
      diffJours = moment().diff(moment(this.props.publishedAt), "days");
    }

    if (this.props.isDraft) {
      return <div style={{ padding: "1.5rem" }}>Brouillon</div>;
    }
    return (
      <div style={{ padding: "1.5rem" }}>
        {this.props.isSold ? (
          <span>
            Ce produit a été vendu{" "}
            {soldoutAt &&
              `le ${soldoutAt.format("DD/MM/YY")} en ${diffJours} jours`}
          </span>
        ) : (
          <span>Ce produit est en vente depuis {diffJours} jours</span>
        )}
      </div>
    );
  }
}

const selector = formValueSelector("item");

const mapStateToProps = (globalState, ownProps) => {
  const isDraft = selector(globalState, "isDraft");
  const qtyAvailable = selector(globalState, "qtyAvailable");
  const hasInventory = selector(globalState, "hasInventory");
  const isSold = !isDraft && hasInventory && parseInt(qtyAvailable, 10) <= 0;

  return {
    isDraft,
    qtyAvailable,
    hasInventory,
    isSold,
    publishedAt: selector(globalState, "publishedAt"),
    updatedAt: selector(globalState, "updatedAt"),
    soldoutAt: selector(globalState, "soldoutAt")
  };
};

const mapDispatchToProps = dispatch => {
  return {
    change: date => dispatch(change("item", date))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Infos);
