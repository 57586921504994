import React, { Component } from "react";
import PropTypes from "prop-types";
import { DragSource, DropTarget } from "react-dnd";
import nextPhoto from "../../../../images/next-photo.svg";
import { getDomainAssetsURL } from "../../../Boutique/functions";

const ItemTypes = {
  PHOTO: "photo"
};

const PhotoSource = {
  beginDrag: props => {
    return {
      index: props.index,
      photo: props.photo
    };
  }
};

function collectPhoto(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging()
  };
}

class Photo extends Component {
  render() {
    const { connectDragSource, isDragging } = this.props;

    let photo;
    try {
      if (this.props.photo) {
        photo = `${getDomainAssetsURL(this.props.boutique)}/${
          this.props.photo.fullPath
        }?w=320&h=320&c=max`;
      } else {
        photo = nextPhoto;
      }
    } catch (e) {
      photo = nextPhoto;
    }
    return connectDragSource(
      <div className={isDragging ? " isDragging" : ""}>
        <figure className="aspect-ratio aspect-ratio--square">
          <img src={photo} alt="chargement" className="aspect-ratio__content" />
        </figure>
        <div className="hover-overlay controls">
          <button
            className="voir"
            onClick={e => this.props.handleOnShowPhoto(e, this.props.index)}
          >
            <span>Voir</span>
          </button>
          <button
            className="supprimer"
            onClick={e => this.props.handleRemove(e, this.props.index)}
          >
            <span>Supprimer</span>
          </button>
        </div>
      </div>
    );
  }
}

Photo.propTypes = {
  index: PropTypes.number.isRequired
  // connectDragSource: PropTypes.func.isRequired,
  // isDragging: PropTypes.bool.isRequired
};

Photo = DragSource(ItemTypes.PHOTO, PhotoSource, collectPhoto)(Photo);

const positionTarget = {
  hover(props, monitor, component) {
    if (!component) {
      return null;
    }
  },
  drop(props, monitor) {
    const item = monitor.getItem();
    if (props.index !== item.index) {
      props.handleChangePosition(props.index, item.index);
    }
  }
};

function collect(connect, monitor) {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver()
  };
}

class PhotoPosition extends Component {
  static contextTypes = {
    store: PropTypes.object
  };

  constructor(props) {
    super(props);

    this.handleRemove = this.handleRemove.bind(this);
  }

  handleRemove = (e, index) => {
    e.preventDefault();

    if (window.confirm("Voulez-vous vraiment supprimer cette photo ?")) {
      this.props.handleRemoveItem(index);
    }

    return false;
  };

  render() {
    const {
      boutique,
      handleOnShowPhoto,
      photo,
      index,
      connectDropTarget,
      isOver
    } = this.props;

    return connectDropTarget(
      <li className={isOver ? "isOver" : ""}>
        <Photo
          photo={photo}
          index={index}
          boutique={boutique}
          handleRemove={(e, index) => this.handleRemove(e, index)}
          handleOnShowPhoto={(e, index) => handleOnShowPhoto(e, index)}
        />
      </li>
    );
  }
}

PhotoPosition.propTypes = {
  index: PropTypes.number.isRequired,
  isOver: PropTypes.bool.isRequired
};

export default DropTarget(ItemTypes.PHOTO, positionTarget, collect)(
  PhotoPosition
);
