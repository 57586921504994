import { connect } from "react-redux";
import moment from "moment";
import List from "../../../../../CMS/containers/Boutique/components/List";
import Row from "./Row";
import vente from "../../params.json";

import { initialize, validate } from "../../functions";
import { load as loadFournisseurs } from "../../../Fournisseur/actions";

import ActionBar from "../../../../../CMS/components/ActionBar";
import Button from "../../../../../CMS/components/Button";
import React from "react";
import Depense from "../../../../../CMS/datas/Depense";
import Boutique from "../../../../../CMS/datas/Boutique";

class DepensesList extends List {
  collectionName = "depenses";
  defaultOrderField = "billingDate";
  defaultOrderDirection = "desc";
  listTitle = "Dépenses";
  viewList = Row;
  itemTitle = { type: "attribut", attribut: "montant" };
  initializeItem = initialize;
  validateItem = validate;
  itemParams = vente;
  messageNoDocument = "Aucune dépense";

  constructor(props) {
    super(props);

    this.handleTransfertToProject = this.handleTransfertToProject.bind(this);

    this.state.list.menu = null;
    this.state.form.newItemLibelle = "Nouvelle dépense";
    this.state.form.required = ["montant"];
    this.state.defaultValues = {
      wheres: {
        billingDate: [
          {
            operator: ">",
            value: moment()
              .subtract(1, "months")
              .format("YYYY-MM-DD")
          },
          {
            operator: "<=",
            value: moment().format("YYYY-MM-DD")
          }
        ]
      }
    };
    this.state.custom = {
      wheres: {
        billingDate: [
          {
            operator: ">",
            value: moment()
              .subtract(1, "months")
              .format("YYYY-MM-DD")
          },
          {
            operator: "<=",
            value: moment().format("YYYY-MM-DD")
          }
        ],
        fournisseur: "",
        type: ""
      }
    };

    this.handleChangeDate = this.handleChangeDate.bind(this);
  }

  handleTransfertToProject(e) {
    e.preventDefault();
    if (!this.props.boutique.get("firebaseApiKey")) {
      console.error(
        "Il n'est pas possible de transférer les dépenses car le projet n'est pas configuré"
      );
    }

    Depense.transfertToBoutique(this.props.boutique).then(result => {
      console.info("result", result);
    });
  }

  handleChangeDate(index, value) {
    this.setState(
      oldState => {
        oldState.custom.wheres.billingDate[index].value = value;
        oldState.openMenu = false;
        return oldState;
      },
      () => {
        this.showDocs(this.props.boutique);
      }
    );
  }

  loadAlternativeFunc(boutique) {
    this.props.searchFournisseurs(boutique).then(action => {
      this.setState(oldState => {
        oldState.custom.fournisseurs = action.payload.docs;
        return oldState;
      });
    });

    Boutique.searchFeatures(boutique, "depenseTypes")
      .then(docs => {
        this.setState(oldState => {
          oldState.custom.types = docs;
          return oldState;
        });
      })
      .catch(error => {
        console.error(error.message);
      });
  }

  addActionBarToRender() {
    const dateBefore =
      this.state.custom.wheres.billingDate &&
      this.state.custom.wheres.billingDate[0]
        ? this.state.custom.wheres.billingDate[0].value
        : "";

    const dateAfter =
      this.state.custom.wheres.billingDate &&
      this.state.custom.wheres.billingDate[1]
        ? this.state.custom.wheres.billingDate[1].value
        : "";

    let menuClassNames = ["menu-annexe"];
    menuClassNames.push(this.state.openMenu ? "open" : "close");

    return (
      <ActionBar className="action-bar is-clearfix">
        <ul>
          <li className="navbar-item">
            <Button icon="fa-plus" onClick={this.handleCreate} />
          </li>
          <li className="navbar-item">
            <Button
              icon="fa-download"
              onClick={this.handleTransfertToProject}
            />
          </li>
        </ul>
        <div className={menuClassNames.join(" ")}>
          <Button
            icon="fa-ellipsis-v"
            className="open-menu"
            onClick={this.handleOpenMenuOnMobile}
          />
          <div className="menu-annexe-content">
            <ul className="border-left">
              <li className="navbar-item">
                <input
                  type="date"
                  value={dateBefore}
                  onChange={e =>
                    this.handleChangeDate(0, e.currentTarget.value)
                  }
                />
              </li>
              <li className="navbar-item">
                <input
                  type="date"
                  value={dateAfter}
                  onChange={e =>
                    this.handleChangeDate(1, e.currentTarget.value)
                  }
                />
              </li>
            </ul>

            {this.state.custom.fournisseurs &&
              this.state.custom.fournisseurs.length > 0 && (
                <ul className="border-left">
                  <li className="navbar-item feature select">
                    <select
                      onChange={e =>
                        this.handleChangeCustomWheres(e, "fournisseur")
                      }
                      value={this.state.custom.wheres.fournisseur}
                    >
                      <option value="">-- Choisir un fournisseur --</option>
                      {this.state.custom.fournisseurs.map(
                        (fournisseur, index) => (
                          <option key={index} value={fournisseur.id}>
                            {fournisseur.data().nom}
                          </option>
                        )
                      )}
                    </select>
                  </li>
                </ul>
              )}
            {this.state.custom.types &&
              this.state.custom.types.length > 0 && (
                <ul className="border-left">
                  <li className="navbar-item feature select">
                    <select
                      onChange={e => this.handleChangeCustomWheres(e, "type")}
                      value={this.state.custom.wheres.type}
                    >
                      <option value="">-- Choisir un type --</option>
                      {this.state.custom.types.map((type, index) => (
                        <option key={index} value={type.id}>
                          {type.data().libelle}
                        </option>
                      ))}
                    </select>
                  </li>
                </ul>
              )}
          </div>
        </div>
      </ActionBar>
    );
  }

  addChildrenBeforeToRender() {
    if (!this.props.docs || this.props.docs.length === 0) return null;
    const total = this.props.docs.reduce((total, depense) => {
      return parseFloat(depense.data().montant) + total;
    }, 0);
    return <div>Total des dépenses : {Math.round(total * 100) / 100} €</div>;
  }
}

const mapStateToProps = (globalState, ownProps) => {
  const props = {};
  props.docs = globalState.boutique.docs;

  return props;
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    searchFournisseurs: boutique => dispatch(loadFournisseurs(boutique))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DepensesList);
