import { connect } from 'react-redux';
import { login } from '../actions/auth';
import Login from '../components/Login';

const mapStateToProps = (globalState, ownProps) => {
    const props = globalState.cms;

    return props;
};

const mapDispatchToProps = (dispatch) => {
    return {
        login: (email, password) => {
           return dispatch(login(email, password));
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
