import React, { Component } from "react";
import View from "../../../CMS/components/View";
import Group from "../../../CMS/components/Data/Group";
import Field from "../../../CMS/components/Input";

class Planches extends Component {
  state = {
    prixVolume: [37500, 72000, 106500],
    prixSurface: [950, 1400, 1850],
    prixEmballage: 10,
    largeur: "",
    hauteur: "",
    profondeur: "",
    nbCollage: 0,
    prixFinal: null
  };

  constructor(props) {
    super(props);

    this.handleChangeValue = this.handleChangeValue.bind(this);
    this.calculPrix = this.calculPrix.bind(this);
  }

  handleChangeValue(e) {
    e.preventDefault();
    const value = e.currentTarget.value;
    const name = e.currentTarget.name;
    this.setState(
      {
        [name]: value
      },
      () => {
        this.calculPrix();
      }
    );
  }

  calculPrix() {
    if (
      this.state.largeur &&
      this.state.hauteur &&
      this.state.profondeur &&
      this.state.nbCollage
    ) {
      const volume =
        (parseFloat(this.state.largeur) *
          parseFloat(this.state.hauteur) *
          parseFloat(this.state.profondeur)) /
        1000000;

      const surface =
        (parseFloat(this.state.largeur) * parseFloat(this.state.hauteur)) /
        10000;

      const indexPrix = this.state.nbCollage > 0 ? this.state.nbCollage : 0;
      const prixVolume = volume * this.state.prixVolume[indexPrix];
      const prixSurface = surface * this.state.prixSurface[indexPrix];
      this.setState({
        prixFinal: Math.round(
          (prixVolume + prixSurface) / 2 + this.state.prixEmballage
        )
      });
    }
  }
  render() {
    return (
      <View title="Calcul du prix d'une planche à découper">
        <form>
          <Group>
            <Field
              label="Largeur (en cm)"
              type="number"
              step="0.1"
              name="largeur"
              onChange={this.handleChangeValue}
              value={this.state.largeur}
            />
            <Field
              label="Hauteur (en cm)"
              type="number"
              step="0.1"
              name="hauteur"
              onChange={this.handleChangeValue}
              value={this.state.hauteur}
            />
            <Field
              label="Profondeur (en cm)"
              type="number"
              step="0.1"
              name="profondeur"
              onChange={this.handleChangeValue}
              value={this.state.profondeur}
            />
            <Field
              label="Nombre de collage"
              type="number"
              step="1"
              name="nbCollage"
              onChange={this.handleChangeValue}
              value={this.state.nbCollage}
            />
          </Group>
          {this.state.prixFinal && (
            <Group>
              <p>Le prix de vente est de {this.state.prixFinal} €</p>
            </Group>
          )}
        </form>
      </View>
    );
  }
}
export default Planches;
