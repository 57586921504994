import React, { Component } from "react";
import Button from "./Button/index";
import ActionBar from "../components/ActionBar";
import Notification from "../containers/Backoffice/Notification";

const NavBar = ({ position, menu, docIndex }) => {
  if (!menu || menu.length === 0) return null;

  return (
    <ul className={"navbar-" + position}>
      {menu.map((navItem, index) => {
        switch (navItem.type) {
          case "filter":
            return (
              <li key={index} className="navbar-item filter">
                Filtre :
                {navItem.buttons &&
                  navItem.buttons.length > 0 &&
                  navItem.buttons.map((button, k) => {
                    return (
                      <Button
                        key={k}
                        {...button}
                        onClick={e => navItem.onClick(e, button.wheres)}
                      />
                    );
                  })}
              </li>
            );

          case "navigation":
            if (
              docIndex !== undefined &&
              ((docIndex > 0 &&
                navItem.className
                  .split(" ")
                  .find(name => name === "previous")) ||
                navItem.className.split(" ").find(name => name === "next"))
            ) {
              return (
                <li key={index} className="navbar-item">
                  <Button {...navItem} />
                </li>
              );
            }
            return null;

          // case "feature":
          //   switch (navItem.component) {
          //     case "select":
          //     default:
          //       return (
          //         <li key={index} className="navbar-item feature">
          //           <DataFeatureSelect {...navItem}/>
          //         </li>
          //       );
          //   }
          //
          // case "search":
          //   return (
          //     <li key={index} className="navbar-item search">
          //       <input {...navItem} type="text" />
          //     </li>
          //   );

          default:
        }
        if (navItem.submit) {
          return (
            <li key={index} className="navbar-item">
              <Button {...navItem} primary />
            </li>
          );
        }
        if (navItem.link) {
          return (
            <li key={index} className="navbar-item">
              <Button {...navItem} type="link" />
            </li>
          );
        }
        return (
          <li key={index} className="navbar-item">
            <Button {...navItem} />
          </li>
        );
      })}
    </ul>
  );
};

class Menu extends Component {
  render() {
    const { menu } = this.props;

    if (!menu || Object.keys(menu).length === 0) {
      return null;
    }

    return (
      <ActionBar className="action-bar is-clearfix">
        {menu.left && (
          <NavBar
            position="left"
            menu={menu.left}
            docIndex={this.props.docIndex}
          />
        )}
        {menu.right && (
          <NavBar
            position="right"
            menu={menu.right}
            docIndex={this.props.docIndex}
          />
        )}
        <Notification />
      </ActionBar>
    );
  }
}

export default Menu;
