import React from "react";
import moment from "moment";
import styled from "styled-components";
import { getDomainAssetsURL } from "../../../../functions";

const Ul = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;

  display: flex !important;
  flex-wrap: wrap;

  li {
    margin-top: 0.75rem;
    img {
      margin-right: 0.75rem;
    }
  }
`;

/**
 * Ce composant permet d'afficher un devis dans la liste des devis
 *
 * @param doc
 * @param boutique
 * @param onClick
 * @returns {*}
 */
export default ({ doc, boutique, onClick }) => {
  const item = doc.data();

  return (
    <li onClick={onClick}>
      <div className="date">
        <span className="day">{moment(item.createdAt).format("Do")}</span>
        <span className="month">{moment(item.createdAt).format("MMM")}</span>
      </div>
      <div className="devis">
        <strong className="client">
          {doc.data().contact_email
            ? doc.data().contact_email
            : doc.data().contact_phone}
        </strong>
        <div className="nb-products">
          {item.requests
            ? item.requests.length > 1
              ? item.requests.length + " demandes"
              : "1 demande"
            : "Aucune demande"}

          {item.requests && (
            <Ul>
              {item.requests.map((request, index) => (
                <li key={index}>
                  {request.produit_photo && (
                    <img
                      className="photo"
                      alt={request.produit_name}
                      src={`${getDomainAssetsURL(boutique)}/${
                        request.produit_photo
                      }`}
                      height={60}
                    />
                  )}
                </li>
              ))}
            </Ul>
          )}
        </div>
      </div>
    </li>
  );
};
