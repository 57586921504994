import { createStore, applyMiddleware } from "redux";
import promiseMiddleware from "redux-promise";
import thunkMiddleware from "redux-thunk";
import { createLogger } from "redux-logger";
import rootReducer from "./reducers/index";

const loggerMiddleware = createLogger();
const middleware = [promiseMiddleware, thunkMiddleware];
if (process.env.NODE_ENV !== "production") {
  middleware.push(loggerMiddleware);
}

export default function configureStore(preloadedState) {
  return createStore(
    rootReducer,
    preloadedState,
    applyMiddleware(...middleware)
  );
}
