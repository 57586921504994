const applicationMenu = [
  [
    {
      name: "Instagram",
      type: "link",
      to: "/app/instagram"
    }
  ]
];

const adminMenu = [
  [
    {
      name: "Utilisateurs",
      type: "link",
      to: "/admin/users"
    },
    {
      name: "Boutiques",
      type: "link",
      to: "/admin/boutiques"
    },
    {
      name: "Schemas",
      type: "link",
      to: "/admin/schemas"
    }
  ]
];

export default [
  {
    state: "medias",
    name: "Médias",
    type: "link",
    className: "separator",
    to: "/medias",
    profil: "medias",
    acl: {
      admin: true
    }
  },
  {
    type: "separator",
    profil: "application",
    acl: {
      admin: true
    }
  },
  {
    state: "application",
    name: "Applications",
    type: "link",
    className: "separator",
    profil: "application",
    to: "/apps",
    menu: applicationMenu,
    acl: {
      admin: true
    }
  },
  // {
  //   state: "analytics",
  //   name: "Statistiques",
  //   type: "link",
  //   className: "separator",
  //   profil: "analytics",
  //   to: "/analytics",
  //   acl: {
  //     admin: true
  //   }
  // },
  {
    type: "spacer",
    acl: {
      "*": true
    }
  },
  {
    state: "admin",
    name: "Administrateur",
    className: "separator",
    type: "link",
    to: "/admin/users",
    menu: adminMenu,
    acl: {
      admin: true
    }
  }
];
