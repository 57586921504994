import React from "react";
import Columns from "../../../../../../CMS/components/Data/Columns";
import Input from "../../../../../../CMS/components/Data/Input/index";
import Section from "../../../../../../CMS/components/Data/Section";

export default () => (
  <Section title="Dimensions standards" group={true}>
    <Columns>
      <Input
        className="is-empty is-expanded"
        name="width"
        type="number"
        placeholder="en cm"
        step="0.1"
        label="Largeur"
        icon="fa-arrows-h"
      />

      <Input
        className="is-empty is-expanded"
        name="depth"
        type="number"
        step="0.1"
        placeholder="en cm"
        label="Profondeur"
        icon="fa-expand"
      />

      <Input
        className="is-empty is-expanded"
        name="height"
        type="number"
        step="0.1"
        placeholder="en cm"
        label="Hauteur"
        icon="fa-arrows-v"
      />

      <Input
        className="is-empty is-expanded"
        name="diametre"
        step="0.1"
        type="number"
        placeholder="en cm"
        label="Diamètre"
        icon="fa-circle"
      />
    </Columns>
    <Columns>
      <Input
        className="is-empty"
        name="weight"
        step="0.01"
        type="number"
        placeholder="en kg"
        label="Poids"
        icon="fa-anchor"
      />
      <Input
        className="is-empty"
        name="fragile"
        type="checkbox"
        label="Fragile"
        description="Produit fragile ?"
      />
    </Columns>
  </Section>
);
