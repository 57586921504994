import React from "react";

/**
 * Ce composant permet d'afficher une page dans la liste des pages
 *
 * @param doc
 * @param onClick
 * @returns {*}
 */
export default ({ doc, onClick }) => {
  const item = doc.data();

  return (
    <li onClick={onClick}>
      <div className="page">
        <strong className="title">{item.description}</strong>
      </div>
    </li>
  );
};
