import React, { Component } from "react";
import StyledField from "../styles/Field";

class Textarea extends Component {
  delayedResize = event => {
    window.setTimeout(this.resize, 0);
  };

  resize = event => {
    // this.textarea.style.height = 'auto';
    // this.textarea.style.height = this.textarea.scrollHeight+'px';
  };

  componentDidMount() {
    // this.resize(this.textarea);
    // this.textarea.style.overflow = 'hidden';
    //
    // if(this.textarea.value) {
    //     this.textarea.classList.remove('is-empty');
    // } else {
    //     this.textarea.classList.add('is-empty');
    // }
    //
    // this.textarea.addEventListener('focus', function(event) {
    //     event.currentTarget.classList.add('is-focused');
    // });
    // this.textarea.addEventListener('blur', function(event) {
    //     event.currentTarget.classList.remove('is-focused');
    // });
    // this.textarea.addEventListener('change', this.resize);
    // this.textarea.addEventListener('cut', this.delayedResize);
    // this.textarea.addEventListener('paste', this.delayedResize);
    // this.textarea.addEventListener('drop', this.delayedResize);
    // this.textarea.addEventListener('keydown', this.delayedResize);
    //
    // this.textarea.addEventListener('keyup', (event) => {
    //     if (event.currentTarget.value.length === 0) {
    //         event.currentTarget.classList.add('is-empty');
    //     } else {
    //         event.currentTarget.classList.remove('is-empty');
    //     }
    // });
  }

  render() {
    const { label, ...others } = this.props;
    delete others.boutique;

    return (
      <StyledField className="textarea">
        <textarea
          rows="1"
          {...others}
          ref={textarea => {
            this.textarea = textarea;
          }}
        />
        <label className="label">
          <span>{label}</span>
        </label>
      </StyledField>
    );
  }
}

export default Textarea;
