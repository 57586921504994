import React, { Component } from "react";
import ContentData from "../../../../../datas/Content";
import Content from "./Content";
import Item from "./Item";
import HOCSection from "../../../../../components/HOC/Section";

class List extends Component {
  state = {
    index: undefined,
    itemRef: undefined,
    mustBeLoad: false,
    contents: undefined
  };
  constructor(props) {
    super(props);

    this.loadContent = this.loadContent.bind(this);
    this.handleAdd = this.handleAdd.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
    this.handleUp = this.handleUp.bind(this);
    this.handleDown = this.handleDown.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleOnChangeField = this.handleOnChangeField.bind(this);
    this.handleOnBlur = this.handleOnBlur.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    if (
      props.itemRef &&
      (!state.itemRef || state.itemRef.id !== props.itemRef.id)
    ) {
      state.itemRef = props.itemRef;
      state.mustBeLoad = true;
    }

    return state;
  }

  componentDidMount() {
    if (this.state.mustBeLoad) {
      this.loadContent(this.state.itemRef);
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.state.mustBeLoad) {
      this.loadContent(this.state.itemRef);
    }
  }

  loadContent(itemRef) {
    this.setState({
      mustBeLoad: false
    });

    const _this = this;

    itemRef
      .collection("contents")
      .orderBy("order", "asc")
      .onSnapshot(querySnapshot => {
        _this.setState({
          contents: querySnapshot.docs
        });
      });
  }

  handleAdd(e) {
    e.preventDefault();
    ContentData.insertIntoDocument(this.state.itemRef, {
      header: "",
      headerType: "",
      headerAlignement: "",
      headerDate: "",
      headerLink: "",
      headerSubTitle: "",
      medias: [],
      mediasPosition: "beside-text-left",
      mediasNbColumns: 1,
      mediasClickToEnlarge: false,
      order: this.state.contents ? this.state.contents.length : 0
    });
  }

  handleEdit(e, index) {
    e.preventDefault();

    this.setState({
      index
    });
  }

  handleUp(e, index) {
    index = parseInt(index, 10);
    e.preventDefault();
    if (index > 0) {
      // On inverse le index et le index-1
      const contentMinus = this.state.contents[index - 1];
      this.state.contents[index].ref.update({
        order: index - 1
      });
      contentMinus.ref.update({ order: index });
    }
  }

  handleDown(e, index) {
    index = parseInt(index, 10);
    e.preventDefault();
    if (index < this.state.contents.length - 1) {
      // On inverse le index et le index-1
      const contentPlus = this.state.contents[index + 1];
      this.state.contents[index].ref.update({
        order: index + 1
      });
      contentPlus.ref.update({ order: index });
    }
  }

  handleClose(e) {
    e.preventDefault();

    this.setState({
      index: undefined
    });
  }

  handleDelete(e, index) {
    e.preventDefault();
    if (window.confirm("Confirmation de la suppression du contenu")) {
      const document = this.state.contents[index];
      ContentData.delete(document);
    }
  }

  handleOnBlur(event) {
    event.preventDefault();
    let value = "";
    const name = event.currentTarget.name;

    switch (event.currentTarget.type) {
      case "number":
        value = parseInt(event.currentTarget.value, 10);
        break;

      case "checkbox":
        value = event.currentTarget.checked;
        break;

      case "text":
      default:
        value = event.currentTarget.value;
    }

    this.state.contents[this.state.index].ref.update({
      [name]: value
    });
  }

  handleOnChangeField(field, value) {
    ContentData.update(this.state.contents[this.state.index], {
      [field]: value
    }).catch(error => {
      console.error(error);
    });
  }

  render() {
    return (
      <HOCSection
        title={this.props.title}
        addText={this.props.addText}
        handleAdd={this.handleAdd}
      >
        {this.state.index !== undefined && (
          <Content
            item={this.state.contents[this.state.index]}
            boutique={this.props.boutique}
            docId={this.props.id}
            handleClose={this.handleClose}
            handleOnBlur={this.handleOnBlur}
            handleOnChangeField={this.handleOnChangeField}
          />
        )}
        {!this.state.contents ? (
          <p style={{ padding: "1.5rem", marginBottom: 0 }}>
            Chargement en cours
          </p>
        ) : this.state.contents.length === 0 ? (
          <p style={{ padding: "1.5rem", marginBottom: 0 }}>Aucun contenu</p>
        ) : (
          <ul style={{ margin: "1.5rem 1.5rem 0.75rem 1.5rem" }}>
            {this.state.contents.map((content, index) => (
              <Item
                key={index}
                document={content}
                index={index}
                nbContent={this.state.contents.length}
                boutique={this.props.boutique}
                handleEdit={e => this.handleEdit(e, index)}
                handleUp={e => this.handleUp(e, index)}
                handleDown={e => this.handleDown(e, index)}
                handleDelete={e => this.handleDelete(e, index)}
              />
            ))}
          </ul>
        )}
      </HOCSection>
    );
  }
}

export default List;
