import React, { Component } from "react";
import { connect } from "react-redux";
import Instagram from "./containers/Instagram/containers/Post/components/List";
import Newsletters from "./containers/Newsletter/components/List";
import { compose } from "redux";
import {
  HOC,
  mapStateToProps,
  mapDispatchToProps
} from "../CMS/containers/Boutique/components/HOC";

class Communication extends Component {
  render() {
    const { user } = this.props;

    if (!user) {
      return <div>Chargement en cours de l'utilisateur</div>;
    }

    // TODO experimental - voir pour améliorer le router
    if (this.props["*"].substr(0, 9) === "instagram") {
      return <Instagram {...this.props} />;
    }

    if (this.props["*"].substr(0, 11) === "newsletters") {
      return <Newsletters {...this.props} />;
    }

    return (
      <div>
        Problème de configuration, le type '{this.props["*"]}' n'existe pas
      </div>
    );
  }
}

const enhance = compose(
  HOC,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
);

export default enhance(Communication);
