import React from "react";
import styled from "styled-components";
import moment from "moment";
import Button from "../../../../../../CMS/components/Button";
import Field from "../../../../../../CMS/styles/Field";
import { getDomainAssetsURL } from "../../../../../../Boutique/functions";
import { Link } from "@reach/router";

const Div = styled.div`
  display: flex;
  padding: 0.75rem;
  margin: 0;
  margin-bottom: 0.375rem;

  background: white;
  box-sizing: border-box;
  border: 1px solid rgba(228, 231, 237, 0.8);
  border-radius: 2px;

  &:last-child {
    margin-bottom: 0;
  }

  ul.requests {
    margin: 0;
    padding: 0;
    list-style: none;
    flex: 1;

    li {
      display: flex;
      margin-bottom: 0.375rem;

      &:last-child {
        margin-bottom: 0;
      }

      .photo {
        width: 60px;
      }

      .name {
        flex: 1;
        margin: 0 1.5rem;
      }
      .prix {
        &:after {
          content: "€";
        }
        margin-right: 1.5rem;
      }
    }
  }
`;

export default ({ demande, boutique, onClick }) => {
  const data = demande.data();

  console.info("demande", data);

  return (
    <Div>
      {data.hasResponse ? (
        <ul className="requests">
          {data.requests.map((request, index) => (
            <li key={index}>
              {request.produit_id ? (
                <div
                  className="demande-prix-produit"
                  style={{ display: "flex" }}
                >
                  {request.produit_photo && (
                    <img
                      className="photo"
                      alt={request.produit_name}
                      src={`${getDomainAssetsURL(boutique)}/${
                        request.produit_photo
                      }`}
                    />
                  )}
                  <span className="name">{request.produit_name}</span>
                  <span className="prix">{request.produit_prixDeVente}</span>
                </div>
              ) : (
                <div className="demande-contact">{request.content}</div>
              )}
            </li>
          ))}
        </ul>
      ) : (
        <span style={{ lineHeight: "34px", flex: 1 }}>
          Cette demande n'a pas encore de réponse
        </span>
      )}

      <Field noMargin>
        <div className="has-addons">
          {demande.id && (
            <Button
              smallIcon
              value={`Réalisée le ${moment(data.createdAt).format(
                "D MMM YYYY"
              )}`}
              type="date"
              className=""
            />
          )}
          <Button
            smallIcon
            icon="fa-edit"
            as={Link}
            to={`/boutique/demandes/${demande.id}`}
          />
        </div>
      </Field>
    </Div>
  );
};
