import React, { Component } from "react";
import { Link, navigate, Match } from "@reach/router";
import { connect } from "react-redux";
import { NavBase, Nav } from "./styles";

import Boutiques from "./Boutiques";
import MenuItem from "./MenuItem";
import menu from "../../constants/menu";
import boutique from "../../constants/menu/boutique";
import website from "../../constants/menu/website";
import organisation from "../../constants/menu/organisation";
import calculatrices from "../../constants/menu/calculatrices";
import findStateByPath from "../../constants/stateByPath";
import { logout } from "../../../../actions/auth";

class Navigation extends Component {
  menu = menu;

  constructor(props) {
    super(props);

    this.state = {
      top: false,
      openUser: false,
      openMenu: false
    };

    this.handleGoTopMenu = this.handleGoTopMenu.bind(this);
    this.handleChangePath = this.handleChangePath.bind(this);
    this.handleToggleUser = this.handleToggleUser.bind(this);
    this.handleToggleMenu = this.handleToggleMenu.bind(this);
  }

  handleGoTopMenu = () => {
    this.setState({
      top: true
    });
  };

  handleChangePath = (to, hasMenu) => {
    this.setState({
      top: !hasMenu
    });

    navigate(to);
  };

  handleToggleUser = () => {
    this.setState(old => {
      return {
        openUser: !old.openUser
      };
    });
  };

  handleToggleMenu = e => {
    e.preventDefault();

    this.setState(oldState => {
      return {
        openMenu: !oldState.openMenu
      };
    });

    return null;
  };

  handleClose = () => {
    if (this.props.show === false) {
      this.props.handleOpenNavigation(true);
    }
  };

  // TODO faire le changer des liens du menu pour activé is-active
  render() {
    const { pathname, user } = this.props;
    // TODO recupérer le pathname
    const etat = findStateByPath(pathname);

    if (!user) {
      return <div>No log</div>;
    }

    if (this.props.pathname.substr(0, 9) === "/boutique") {
      this.menu = boutique;
    } else {
      if (this.props.pathname.substr(0, 8) === "/website") {
        this.menu = website;
      } else {
        if (this.props.pathname.substr(0, 14) === "/calculatrices") {
          if (this.props.boutique && this.props.boutique.get("calculatrices")) {
            this.menu = calculatrices.filter(data =>
              this.props.boutique.get("calculatrices").includes(data.state)
            );
          } else {
            this.menu = [];
          }
        } else {
          if (
            this.props.pathname.substr(0, 13) === "/organisation" ||
            this.props.pathname.substr(0, 9) === "/planning" ||
            this.props.pathname.substr(0, 7) === "/carnet"
          ) {
            this.menu = organisation;
          } else {
            this.menu = menu;
          }
        }
      }
    }

    let showBoutiques = this.props.boutiques && this.props.boutiques.length > 1;
    if (
      this.props.pathname.substr(0, 13) === "/organisation" ||
      this.props.pathname.substr(0, 9) === "/planning" ||
      this.props.pathname.substr(0, 7) === "/carnet" ||
      this.props.pathname.substr(0, 6) === "/admin"
    ) {
      showBoutiques = false;
    }

    return (
      <Nav className={`sandwich__side ${this.state.openMenu ? "is-open" : ""}`}>
        <header className="header">
          <span
            onClick={this.handleToggleMenu}
            className={`hamburger ${this.state.openMenu ? "is-open" : ""}`}
          >
            <span className="hamburger-top">
              <span />
            </span>
            <span className="hamburger-bottom">
              <span />
            </span>
          </span>
          <Link className="logo" to="/dashboard">
            <span id="logo" />
          </Link>
          <div className="items-header">
            <Match path="/">
              {props => (
                <Link
                  to="/dashboard"
                  className={props.match ? "home is-active" : "home"}
                >
                  <svg width="17px" height="100%" viewBox="0 0 17 17">
                    <g transform="matrix(0.0199186,0,0,0.0199186,-1.69691,-1.27338)">
                      <path d="M426.667,853.333L426.667,597.333L597.334,597.333L597.334,853.333L810.667,853.333L810.667,512L938.667,512L512,128L85.333,512L213.333,512L213.333,853.333L426.667,853.333Z" />
                    </g>
                  </svg>
                  <span>Tableau de bord</span>
                </Link>
              )}
            </Match>
            {(user.data().type === "admin" || user.data().hasAccessToStore) && (
              <Match path="/boutique">
                {props => (
                  <Link
                    to="/boutique"
                    className={`boutique ${
                      props.location.pathname.substr(0, 9) === "/boutique"
                        ? "is-active"
                        : ""
                    }`}
                  >
                    <span className="icon" />
                    <span>Commerce</span>
                  </Link>
                )}
              </Match>
            )}

            {/*{(user.data().type === "admin" || user.data().hasAccessToClub) && (*/}
            {/*<Match path="/club">*/}
            {/*{props => (*/}
            {/*<Link*/}
            {/*to="/club"*/}
            {/*className={`club ${*/}
            {/*props.location.pathname.substr(0, 5) === "/club"*/}
            {/*? "is-active"*/}
            {/*: ""*/}
            {/*}`}*/}
            {/*>*/}
            {/*<span className="icon" />*/}
            {/*<span>Club</span>*/}
            {/*</Link>*/}
            {/*)}*/}
            {/*</Match>*/}
            {/*)}*/}

            {(user.data().type === "admin" ||
              user.data().hasAccessToWebsite) && (
              <Match path="/website">
                {props => (
                  <Link
                    to="/website"
                    className={`website ${
                      props.location.pathname.substr(0, 8) === "/website"
                        ? "is-active"
                        : ""
                    }`}
                  >
                    <span className="icon" />
                    <span>Site web</span>
                  </Link>
                )}
              </Match>
            )}

            {(user.data().type === "admin" ||
              user.data().hasAccessToCalculatrices) && (
              <Match path="/calculatrices">
                {props => (
                  <Link
                    to="/calculatrices"
                    className={`calculatrices ${
                      props.location.pathname.substr(0, 14) === "/calculatrices"
                        ? "is-active"
                        : ""
                    }`}
                  >
                    <span className="icon" />
                    <span>Calculatrices</span>
                  </Link>
                )}
              </Match>
            )}

            {(user.data().type === "admin" ||
              user.data().hasAccessToOrganisation) && (
              <Match path="/organisation">
                {props => (
                  <Link
                    to="/organisation"
                    className={`organisation ${
                      props.location.pathname.substr(0, 13) ===
                        "/organisation" ||
                      props.location.pathname.substr(0, 9) === "/planning"
                        ? "is-active"
                        : ""
                    }`}
                  >
                    <span className="icon" />
                    <span>Organisation</span>
                  </Link>
                )}
              </Match>
            )}
          </div>
        </header>
        {showBoutiques && <Boutiques />}
        <section className={`container`}>
          <NavBase
            top={
              etat === "home" ||
              etat === "boutique-abonnements" ||
              etat === "boutique-commandes" ||
              etat === "boutique-demandes" ||
              etat === "website-pages" ||
              etat === "website-articles" ||
              etat === "website-memos" ||
              etat === "website-analytics" ||
              etat === "website-params" ||
              this.state.top
            }
          >
            {this.menu.map((item, index) => (
              <MenuItem
                key={index}
                item={item}
                etat={etat}
                user={user}
                handleClose={this.handleClose}
                handleGoTopMenu={this.handleGoTopMenu}
                handleChangePath={(to, hasMenu) =>
                  this.handleChangePath(to, hasMenu)
                }
              />
            ))}
          </NavBase>
        </section>

        <footer className={`footer`}>
          <div
            className={"user-profile " + (this.state.openUser ? "is-open" : "")}
          >
            <button className="user-toogle" onClick={this.handleToggleUser}>
              <div className="user-info">
                <span>
                  {user && user.data().displayname
                    ? user.data().displayname
                    : "Utilisateur"}
                </span>
              </div>

              {this.state.openUser ? (
                <span className="icon is-small">
                  <i className="fa fa-angle-up" />
                </span>
              ) : (
                <span className="icon">
                  <i className="fa fa-angle-down" />
                </span>
              )}
            </button>

            {this.state.openUser && (
              <div className="user-body">
                <ul className="menu-list">
                  <li>
                    <Link to="/edit">Profil</Link>
                  </li>
                  <li>
                    <button onClick={this.props.logout}>Déconnexion</button>
                  </li>
                </ul>
              </div>
            )}
          </div>
        </footer>
      </Nav>
    );
  }
}

const mapStateToProps = globalState => {
  return {
    user: globalState.cms.user,
    show: globalState.cms.showNavigation,
    open: globalState.cms.openNavigation,
    boutiques: globalState.cms.boutiques,
    boutique:
      globalState.cms.boutiques && globalState.cms.boutiques.length > 0
        ? globalState.cms.boutiques[globalState.cms.indexBoutique]
        : null
  };
};

const mapDispatchToProps = dispatch => {
  return {
    logout: () => dispatch(logout())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Navigation);
