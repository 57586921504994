import React, {Component} from "react";
import StyledTabs from "../styles/Tabs";

class Tabs extends Component {
  state = {
    index: 0
  };

  constructor(props) {
    super(props);

    this.handleSetTab = this.handleSetTab.bind(this);
  }

  handleSetTab(e) {
    e.preventDefault();
    const index = e.currentTarget.getAttribute("data-index");
    this.setState({
      index: parseInt(index, 10)
    });
  }

  render() {
    return (
      <StyledTabs>
        <ul>
          {this.props.items.map((tab, index) => (
            <li
              key={index}
              className={index === this.state.index ? "is-active" : ""}
            >
              <button data-index={index} onClick={this.handleSetTab}>
                {tab.name}
              </button>
            </li>
          ))}
        </ul>
        <div className="tab">{this.props.items[this.state.index].content}</div>
      </StyledTabs>
    );
  }
}

export default Tabs;