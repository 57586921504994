import React, { Component } from "react";
import { connect } from "react-redux";
import { change } from "redux-form";
import { Link } from "@reach/router";
import styled from "styled-components";

import moment from "moment";
import Content from "../styles/Content";

import book from "../images/font-awesome/solid/book.svg";
import addressBook from "../images/font-awesome/solid/address-book.svg";
import commentAlt from "../images/font-awesome/solid/comment-alt.svg";
import newspaper from "../images/font-awesome/solid/newspaper.svg";
import quoteRight from "../images/font-awesome/solid/quote-right.svg";

const StyledApp = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  a {
    margin: 0 1.5rem 1.5rem 0;

    &:nth-child(2n) {
      margin-right: ${props => (props.theme.mobile ? 0 : "1.5rem")};
    }
    text-align: center;
    text-decoration: none;
    color: inherit;
    .icon {
      background: #fff;
      width: 120px;
      height: 120px;
      border-radius: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 0.375rem;
      border: 1px solid #e4e7ed;

      span {
        mask-image: url(${book});
        //background-color: #273a8a;
        background-color: #86848c;
        mask-repeat: no-repeat;
        width: 60px;
        height: 60px;
        display: inline-block;
        mask-position: center;
        &.icon-contacts {
          mask-image: url(${addressBook});
        }

        &.icon-demandes {
          mask-image: url(${commentAlt});
        }

        &.icon-articles {
          mask-image: url(${newspaper});
        }
        &.icon-memos {
          mask-image: url(${quoteRight});
        }
      }
    }
  }
`;

// import Notification from './Notification';
// import Header from '../containers/Header';

// class Assistant extends Component {
//
//     constructor(props) {
//         super(props);
//
//         this.state = {
//             showMore: false,
//             showNote: false,
//             textareaHeight: 20
//         };
//
//         this.handleClickMore = this.handleClickMore.bind(this);
//         this.handleShowKeyboard = this.handleShowKeyboard.bind(this);
//         this.handleCreateNote = this.handleCreateNote.bind(this);
//         this.handleOnChangeQ = this.handleOnChangeQ.bind(this);
//         this.handleSendMessage = this.handleSendMessage.bind(this);
//     }
//
//     handleClickMore(event) {
//         this.setState({
//             showMore: true
//         });
//     }
//
//     handleShowKeyboard(event) {
//         this.setState({
//             showMore: false,
//             showNote: false
//         });
//     }
//
//     handleCreateNote(event) {
//         // Ajout du message de création d'une note
//
//         this.setState({
//             showNote: true
//         })
//     }
//
//     handleOnChangeQ(event) {
//         const hei = (parseInt(event.target.value.replace(/[^\n]+/g, '').length, 10)+1) * 20;
//
//         this.setState({
//             textareaHeight: hei
//         });
//     }
//
//     handleSendMessage(event) {
//         this.setState({
//             textareaHeight: 20
//         });
//     }
//
//     render() {
//         const {handleSubmit} = this.props;
//
//         return(
//             <form onSubmit={handleSubmit} className="body-content">
//                 <Header title="CMS Innedit"/>
//                 {(this.props.error || this.props.info) && <Notification error={this.props.error} info={this.props.info}/>}
//                 <div className="home-container">
//                     <div className="conversation-bubbles">
//                         {this.props.messages && this.props.messages.map((message, index) =>
//                             <div key={index} className="conversation-bubble">
//                                 <div className="bubble">
//                                     <span className="message">{message.text}</span>
//                                 </div>
//                             </div>
//                         )}
//                     </div>
//
//                     {this.state.showNote && (
//                         <div className="more-note modal is-active">
//                             <div className="modal-background"></div>
//                             <div className="modal-content">
//                                 <Field name="note" component="textarea" className="textarea" placeholder="Saisi ta note"/>
//                             </div>
//                             <button className="modal-close" onClick={this.handleShowKeyboard}></button>
//                         </div>
//                     )}
//
//                     <div className={'conversation-more' + (this.state.showMore ? ' show' : '')}>
//                         <a onClick={this.handleShowKeyboard}>
//                             <i className="fa fa-keyboard-o"></i></a>
//                         <a onClick={this.handleCreateNote}
//                             className={this.state.showNote ? 'open':''}>
//                             <i className="fa fa-sticky-note"></i><span className="text">Note</span></a>
//                         <a><i className="fa fa-camera"></i><span className="text">Photo</span></a>
//                         <a><i className="fa fa-calendar"></i><span className="text">Evénement</span></a>
//                         <a><i className="fa fa-comment"></i><span className="text">Commentaire</span></a>
//                     </div>
//
//                     {/*<div className={'conversation-quest' + (this.state.showMore ? ' hide' : '')}>*/}
//                         {/*<div className="more">*/}
//                             {/*<a className="" onClick={this.handleClickMore}>+</a>*/}
//                         {/*</div>*/}
//                         {/*<Field name="q" id="q" component="textarea"*/}
//                                {/*onChange={this.handleOnChangeQ}*/}
//                                {/*placeholder="Rédiges ton message..."*/}
//                                {/*style={{height:+this.state.textareaHeight+"px"}}/>*/}
//                         {/*<div>*/}
//                             {/*<button type="submit" onClick={this.handleSendMessage}><i className="fa fa-send"></i></button>*/}
//                         {/*</div>*/}
//                     {/*</div>*/}
//                 </div>
//             </form>
//         )
//     }
// }

// const AssistantForm = reduxForm({
//     form: 'assistant',
//     enableReinitialize: true
// })(Assistant);

class Home extends Component {
  constructor(props) {
    super(props);

    if (props.user) {
      // this.props.loadMessage(props.user.objectId);
    }

    this.handleSubmit = this.handleSubmit.bind(this);

    // this.props.searchAbonnements().then(action => {
    //     if(action.payload.status===200) {
    //         // console.log(action.payload.data.results);
    //     }
    //
    // });
  }

  componentWillReceiveProps(nextProps) {
    if (!this.props.user && nextProps.user) {
      // this.props.loadMessage(nextProps.user.objectId);
    }
  }

  handleSubmit = (values, dispatch, props) => {
    if (this.props.user) {
      if (values.q) {
        dispatch(change("assistant", "q", ""));
        this.props.createMessage({
          text: values.q,
          userId: this.props.user.objectId,
          sendTo: values.to
        });
      }
    } else {
      // Ne rien faire car le createur est obligatoire
    }
  };

  render() {
    const { user } = this.props;

    console.info("props", this.props);
    return (
      <React.Fragment>
        <Content>
          <h1>Bienvenue dans votre espace administrateur</h1>

          {user && (
            <StyledApp>
              {(user.get("type") === "admin" ||
                (user.get("profils") &&
                  user.get("profils").includes("boutique-catalogue"))) && (
                <Link to="/boutique/produits">
                  <div className="icon">
                    <span className="icon-produits" />
                  </div>
                  <span>Produits</span>
                </Link>
              )}

              {(user.get("type") === "admin" ||
                (user.get("profils") &&
                  user.get("profils").includes("boutique-contacts"))) && (
                <Link to="/boutique/contacts">
                  <div className="icon">
                    <span className="icon-contacts" />
                  </div>
                  <span>Contacts</span>
                </Link>
              )}

              {(user.get("type") === "admin" ||
                (user.get("profils") &&
                  user.get("profils").includes("boutique-demandes"))) && (
                <Link to="/boutique/demandes">
                  <div className="icon">
                    <span className="icon-demandes" />
                  </div>
                  <span>Demandes</span>
                </Link>
              )}

              {(user.get("type") === "admin" ||
                (user.get("profils") &&
                  user.get("profils").includes("website-pages"))) && (
                <Link to="/website/pages">
                  <div className="icon">
                    <span className="icon-apages" />
                  </div>
                  <span>Pages</span>
                </Link>
              )}

              {(user.get("type") === "admin" ||
                (user.get("profils") &&
                  user.get("profils").includes("website-articles"))) && (
                <Link to="/website/articles">
                  <div className="icon">
                    <span className="icon-articles" />
                  </div>
                  <span>Articles</span>
                </Link>
              )}

              {(user.get("type") === "admin" ||
                (user.get("profils") &&
                  user.get("profils").includes("website-memos"))) && (
                <Link to="/website/memos">
                  <div className="icon">
                    <span className="icon-memos" />
                  </div>
                  <span>Mémos</span>
                </Link>
              )}
            </StyledApp>
          )}
        </Content>
        {/*<Gtd type='intras'/>*/}
        {/*<AssistantForm onSubmit={this.handleSubmit}*/}
        {/*user={this.props.user} messages={this.props.items}/>*/}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (globalState, ownProps) => {
  const props = globalState.cms;

  return props;
};

const mapDispatchToProps = dispatch => {
  return {
    createMessage: message => {
      // dispatch(create('Message', message));
    },
    searchAbonnements: () => {
      const jourEnCours = moment().isoWeekday();
      const semaine = [
        "lundi",
        "mardi",
        "mercredi",
        "jeudi",
        "vendredi",
        "samedi",
        "dimanche"
      ];
      const jour = semaine[jourEnCours - 1];

      const attributes = {};
      attributes[jour] = true;

      // return dispatch(findByAttributes('Abonnement', attributes));
    },
    loadMessage: userId => {
      // dispatch(loadByUserId('Message', userId));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Home);
