import React from 'react';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';

import Data from '../../../../../CMS/components/Data/index';

import cheque from '../../params/cheque.json';

const Cheque = (props) => {
    if(!props.show) return null;

    return (
        <div className="boutique__paiement__mode--cheque">
            <Data data={cheque}/>
        </div>
    )
};

const selector = formValueSelector('item');

export default connect(
    (state, props) => {
        return {
            show: selector(state, 'mode') && selector(state, 'mode') === 'cheque',
        }
    }
)(Cheque);