import * as functions from "../../../CMS/functions";
import moment from "moment/moment";

export function initialize(data, boutique) {
  const objet = functions.initialize(data);
  return {
    ...objet,
    boutique: boutique.id,
    date: moment().format("YYYY-MM-DD"),
    deliveryDate: moment().format("YYYY-MM-DD"),
    validation: "fullPayment",
    etat: "",
    amount_cashed: 0,
    amount_refunded: 0,
    amount_canceled: 0,
    paymentStatus: "",
    productsStatus: "",
    isArchived: false,
    isPaidToCarrier: false,
    environment: process.env.NODE_ENV
  };
}

export function validate(item) {
  item.isPaidToCarrier = Boolean(item.isPaidToCarrier);
  const livraison =
    item.deliveryCost && !item.isPaidToCarrier
      ? parseFloat(item.deliveryCost)
      : 0;
  const packingCost = item.packingCost ? parseFloat(item.packingCost) : 0;
  let total = 0;
  if (item.produits && item.produits.length > 0) {
    total = item.produits.reduce((cumul, produit) => {
      cumul += parseFloat(produit.price) * parseInt(produit.qty, 10);
      if (produit.discount) {
        // Il y a une remise
        switch (produit.discountType) {
          case "pourcentage":
            cumul -=
              (parseFloat(produit.price) *
                parseInt(produit.qty, 10) *
                parseFloat(produit.discount)) /
              100;
            break;

          default:
            cumul -= parseFloat(produit.discount);
        }
      }

      return cumul;
    }, livraison + packingCost);
  }
  item.total = total;
  item.updatedAt = moment().toISOString();
  item.isArchived = Boolean(item.isArchived);
  // supprimer les champs suivants lors de l'enregistrement
  delete item.amount_canceled;
  delete item.amount_cashed;
  delete item.amount_refunded;

  return item;
}
