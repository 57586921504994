import React, { Component } from "react";
import { Link, navigate } from "@reach/router";
import styled from "styled-components";

import Notification from "../containers/Notification";
import logo from "../images/innedit-white.svg";
import moment from "moment";

const Page = styled.div`
  position: absolute;
  display: flex;
  flex-direction: row;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;

  a.forget {
    color: white;
  }

  > div {
    flex: 1;
    height: 100%;

    &.login-box {
      background: #4f75f8;
      width: 100%;

      text-align: center;

      .logo {
        height: 64px;
        margin-top: ${props => (props.theme.mobile ? "1.5rem" : "3rem")};
        margin-bottom: ${props => (props.theme.mobile ? "1.5rem" : "9rem")};

        img {
          display: inline-block;
          height: 64px;
        }
      }

      h2 {
        color: #fff;
        font-size: ${props => (props.theme.mobile ? "15px" : "inherit")};
      }

      form {
        max-width: 420px;
        margin-left: auto;
        margin-right: auto;
        padding-top: ${props => (props.theme.mobile ? "1.5rem" : "0.5rem")};

        p {
          display: flex;
          flex-direction: row;
          width: 100%;
          justify-content: center;

          input {
            //border: 1px solid #d1d7e0;
            border: none;
            border-top-left-radius: 2px;
            border-bottom-left-radius: 2px;
            color: #34313f;
            //height: 3rem;
            padding: 0.75rem 1rem;
            outline: none;
            flex: 1;
          }

          .icon {
            background: #fff;
            //height: 3rem;
            padding: 0.75rem 1rem;
            border-top-right-radius: 2px;
            border-bottom-right-radius: 2px;
          }
        }

        button {
          width: 80%;
          color: #fff;
          border: none;
          background-color: #41539b;
          border-radius: 1.5rem;
          margin-top: 0.25rem;
          letter-spacing: 1px;
          line-height: 1.5rem;
          height: 3rem;
          padding: 0.75rem 1rem;
          text-transform: uppercase;
          transition: background-color 0.2s ease;

          &:hover {
            background-color: #3a426e;
          }
        }
      }
    }
  }
`;

class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      password: ""
    };

    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.handleTextFieldChange = this.handleTextFieldChange.bind(this);
  }

  componentWillMount() {
    if (!this.props.loadingUser && this.props.isLogged) {
      navigate("/dashboard");
    }
  }

  handleFormSubmit(e) {
    e.preventDefault();

    const { email, password } = this.state;

    if (!email || !password) {
      // display error to user
      return;
    }

    this.props.login(email, password).then(action => {
      if (action.error) {
        alert(action.payload.message);
      }
      // if(action.payload.status===200) {
      //     localStorage.setItem('token', action.payload.data.sessionToken);
      // } else {
      //     alert(action.payload.response.data.error);
      // }
    });
  }

  handleTextFieldChange(e) {
    this.setState({
      [e.target.name]: e.target.value
    });
  }

  componentWillReceiveProps(nextProps) {
    if (!nextProps.loadingUser && nextProps.isLogged && nextProps.user) {
      // L'utilisateur est connecté -> On le redirige vers la bonne page
      if (nextProps.location.query && nextProps.location.query.next) {
        navigate(nextProps.location.query.next);
      } else {
        navigate(`/dashboard`);
      }
    }
  }

  render() {
    const { info, error, isLoggingIn } = this.props;

    return (
      <Page>
        {/*<div className="info-box"></div>*/}
        <div className="login-box">
          <div className="logo">
            <a href="https://www.innedit.fr">
              <img src={logo} alt="Innedit" id="logo" />
            </a>
          </div>
          <h2>Connectez-vous à votre contenu</h2>
          <form onSubmit={this.handleFormSubmit} id="body">
            {(error || info) && <Notification error={error} info={info} />}

            <p className="control has-icon">
              <input
                className="input"
                type="email"
                name="email"
                value={this.state.email}
                onChange={this.handleTextFieldChange}
                required
                placeholder="Adresse email"
              />
              <span className="icon is-small">
                <i className="fa fa-user" />
              </span>
            </p>
            <p className="control has-icon">
              <input
                className="input"
                type="password"
                name="password"
                value={this.state.password}
                onChange={this.handleTextFieldChange}
                minLength={8}
                placeholder="Mot de passe"
                required
              />
              <span className="icon is-small">
                <i className="fa fa-lock" />
              </span>
            </p>
            <p className="control submit">
              <button className="button" disabled={isLoggingIn}>
                {isLoggingIn ? "Connexion en cours..." : "Se connecter"}
              </button>
            </p>
            <Link to="/forgetpassword" className="forget">
              Mot de passe oublié ?
            </Link>
          </form>
          <div style={{ marginTop: "1.5rem" }}>{moment().toISOString()}</div>
        </div>
      </Page>
    );
  }
}

export default Login;
