import React, {Component} from 'react';
import {connect} from "react-redux";
import Intras from './Intra/List';

class Gtd extends Component {
    render() {
        const {user} = this.props;

        if(!user) {
            return (<div>Chargement en cours de l'utilisateur</div>);
        }

        switch(this.props['*']) {
            case 'intras':
                return (<Intras {...this.props}/>);

            default:
                return (<div>Problème de configuration, le type '{this.props['*']}' n'existe pas</div>)
        }
    }
}

export default connect((globalState, ownProps) => {
    let props = {};

    props.user = globalState.cms.user;
    return props;
})(Gtd);