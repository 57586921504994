import React from "react";
import PropTypes from "prop-types";
import Inventaire from "../containers/Produit/components/Data/Inventaire";
import ProduitSku from "../containers/Produit/components/Data/Sku";
import ModeleSku from "../containers/Modele/components/Data/Sku";
import ModeleVariants from "../containers/Modele/components/Data/Variants";
import ProduitList from "../containers/Produit/components/Data/List/index";
import ProduitIsReserved from "../containers/Produit/components/Data/IsReserved";
import ProduitInfos from "../containers/Produit/components/Data/Infos";
import ProduitQtyAvailable from "../containers/Produit/components/Data/QtyAvailable";
import ProduitAlgolia from "../containers/Produit/components/Data/Algolia";

import Photos from "../components/Data/Photos";
import Dimensions from "../containers/Produit/components/Data/Dimensions/index";
import ProduitDeclinaisons from "../containers/Produit/components/Data/Declinaisons/index";
import ProduitTraductions from "../containers/Produit/components/Data/Traductions/index";
import Caracteristiques from "../containers/Produit/components/Data/Caracteristiques/index";
import Select from "../../CMS/containers/Boutique/components/Data/Select";
import List from "../../CMS/containers/Boutique/components/Data/List";
import POS from "./Data/POS";
import MultiSelect from "../../CMS/containers/Feature/components/Data/MultiSelect";

import FournisseurSelect from "../containers/Fournisseur/components/Data/Select";
import ServiceSelect from "../containers/Service/components/Data/Select";
import ContactSelect from "../containers/Contact/components/Data/Select";
import ContactDemandes from "../containers/Contact/components/Data/Demandes/";
import ContactFiltre from "../containers/Contact/components/Data/Filtre/index";

import CommandeSelect from "../containers/Commande/components/Data/Select";
import CommandeRequest from "../containers/Commande/components/Data/Request";
import CommandeNumero from "../containers/Commande/components/Data/Numero";
import CommandeResume from "../containers/Commande/components/Data/Resume";
import CommandeProduits from "../containers/Commande/components/Data/Produits";
import CommandePaiements from "../containers/Commande/components/Data/Paiements";
import CommandeAcompte from "../containers/Commande/components/Data/Validation/Acompte";
import CommandeValidationAutomatic from "../containers/Commande/components/Data/Validation/Automatic";
import CommandeTabsDetails from "../containers/Commande/components/Data/Tabs/Details";
import CommandeTabsClient from "../containers/Commande/components/Data/Tabs/Client";
import CommandeTabsLivraison from "../containers/Commande/components/Data/Tabs/Livraison";
import CommandeTabsOptions from "../containers/Commande/components/Data/Tabs/Options";

import PaiementCheque from "../containers/Paiement/components/Data/Cheque";
import PaiementStripe from "../containers/Paiement/components/Data/Stripe";
import DemandeRequests from "../containers/Demande/components/Data/Requests";
import DemandeContact from "../containers/Demande/components/Data/Contact";
import DemandeReponse from "../containers/Demande/components/Data/Reponse";
import DemandeCommande from "../containers/Demande/components/Data/Commande";

const Data = props => {
  const { type, ...others } = props;
  delete others.component;

  switch (type) {
    case "fournisseur/select":
      return <FournisseurSelect {...others} />;

    case "service/select":
      return <ServiceSelect {...others} />;

    case "contact/select":
      return <ContactSelect {...others} />;

    case "contact/demandes":
      return <ContactDemandes {...others} />;

    case "contact/filtre":
      return <ContactFiltre {...others} />;

    case "commande/select":
      return <CommandeSelect {...others} />;

    case "commande/request":
      return <CommandeRequest {...others} />;

    case "commande/messages":
      // return <CommandeMessages {...others} />;
      break;

    case "commande/numero":
      return <CommandeNumero {...others} />;

    case "commande/resume":
      return <CommandeResume {...others} />;

    case "commande/produits":
      return <CommandeProduits {...others} />;

    case "commande/paiements":
      return <CommandePaiements {...others} />;

    case "commande/acompte":
      return <CommandeAcompte {...others} />;

    case "commande/validation/automatic":
      return <CommandeValidationAutomatic {...others} />;

    case "commande/tabs/details":
      return <CommandeTabsDetails {...others} />;

    case "commande/tabs/client":
      return <CommandeTabsClient {...others} />;

    case "commande/tabs/livraison":
      return <CommandeTabsLivraison {...others} />;

    case "commande/tabs/options":
      return <CommandeTabsOptions {...others} />;

    case "produit/list":
      return <ProduitList {...others} />;

    case "produit/algolia":
      return <ProduitAlgolia {...others} />;

    case "produit/infos":
      return <ProduitInfos {...others} />;

    case "produit/sku":
      return <ProduitSku {...others} />;

    case "modele/sku":
      return <ModeleSku {...others} />;

    case "modele/variants":
      return <ModeleVariants {...others} />;

    case "produit/isReserved":
      return <ProduitIsReserved {...others} />;

    case "produit/qtyAvailable":
      return <ProduitQtyAvailable {...others} />;

    case "photos":
      return <Photos {...others} />;

    case "produit/inventaire":
      return <Inventaire {...others} />;

    case "paiement/stripe":
      return <PaiementStripe {...others} />;

    case "paiement/cheque":
      return <PaiementCheque {...others} />;

    case "produit/caracteristiques":
      return <Caracteristiques {...others} />;

    case "produit/dimensions":
      return <Dimensions {...others} />;

    case "produit/declinaisons":
      return <ProduitDeclinaisons {...others} />;

    case "produit/traductions":
      return <ProduitTraductions {...others} />;

    case "pointofsale":
      return <POS {...others} />;

    case "multiselect":
      return <MultiSelect {...others} />;

    case "select":
      return <Select {...others} />;

    case "list":
      return <List {...others} />;

    case "demande/requests":
      return <DemandeRequests {...others} />;
    case "demande/contact":
      return <DemandeContact {...others} />;
    case "demande/reponse":
      return <DemandeReponse {...others} />;
    case "demande/commande":
      return <DemandeCommande {...others} />;
    default:
  }

  return <div>Ce type de composant n'existe pas : {type}</div>;
};

Data.propTypes = {
  type: PropTypes.string.isRequired
};

export default Data;
