import React, { Component } from "react";
import { connect } from "react-redux";
import { formValueSelector } from "redux-form";
import StyledRequest from "../../styles/Request";

class Request extends Component {
  constructor(props) {
    super(props);

    this.state = {
      actif: 0
    };

    this.handleChangeActif = this.handleChangeActif.bind(this);
  }

  handleChangeActif = event => {
    const actif = parseInt(event.currentTarget.getAttribute("data-index"), 10);
    this.setState({
      actif
    });
  };

  render() {
    return (
      <StyledRequest className="boutique__demande__component--messages">
        <div className="request">
          <div className="type_request">
            <button data-index="0" onClick={this.handleChangeActif}>
              <span className="text">Demande</span>
              {this.state.actif === 0 && <span className="triangle" />}
            </button>
            <button data-index="1" onClick={this.handleChangeActif}>
              <span className="text">Réponse</span>
              {this.state.actif === 1 && <span className="triangle" />}
            </button>
            <button data-index="2" onClick={this.handleChangeActif}>
              <span className="text">Vente</span>
              {this.state.actif === 2 && <span className="triangle" />}
            </button>
            <button data-index="3" onClick={this.handleChangeActif}>
              <span className="text">Livraison</span>
              {this.state.actif === 3 && <span className="triangle" />}
            </button>
          </div>
          <textarea placeholder="Quel est votre message" />
          <div className="actions">
            <button>Publier</button>
          </div>
        </div>
      </StyledRequest>
    );
  }
}

const selector = formValueSelector("item");

export default connect((state, props) => {
  return {
    advancePayment:
      selector(state, "validation") &&
      selector(state, "validation") === "advancePayment"
  };
})(Request);
