import React, { Component } from "react";
import Dropzone from "react-dropzone";
import { DragDropContext } from "react-dnd";
import HTML5Backend from "react-dnd-html5-backend";

import Photo from "./Item";
import StyledModal from "../../styles/Modal";
import StyledPhotos from "../../styles/Photos";
import StyledTitle from "../../styles/Title";
import svg from "../../../../images/dropzone.svg";
import Media from "../../datas/Media";
import Action from "../../datas/Action";

import { getDomainAssetsURL } from "../../../Boutique/functions";

class Photos extends Component {
  state = {
    photoIndex: null,
    loadingInProgress: false
  };

  constructor(props) {
    super(props);

    this.onDrop = this.onDrop.bind(this);
    this.onOpenClick = this.onOpenClick.bind(this);
    this.handleOnShowPhoto = this.handleOnShowPhoto.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleChangePosition = this.handleChangePosition.bind(this);
    this.handleRemoveItem = this.handleRemoveItem.bind(this);
  }

  onDrop(files) {
    const _this = this;
    this.setState({
      loadingInProgress: true
    });
    const promises = [];
    files.forEach(image => {
      promises.push(
        Media.uploadPhoto(
          this.props.boutique,
          image,
          this.props.docType,
          this.props.id
        )
      );
    });

    Promise.all(promises)
      .then(values => {
        const tmpPhotos = this.props.items;
        values.forEach(document => {
          if (document.exists) {
            tmpPhotos.push({
              media: document.id,
              fullPath: document.get("fullPath"),
              title: document.get("title"),
              description: document.get("description"),
              link: ""
            });
          } else {
            console.error("Le document n'existe pas");
          }
        });
        this.props.updateItems(tmpPhotos);
        _this.setState({
          loadingInProgress: false
        });
      })
      .catch(error => {
        //TODO afficher le message d'erreur -> Mettre en place un système de notification
        console.error(error.code, error.message);
        _this.setState({
          loadingInProgress: false
        });
      });
  }

  onOpenClick(e) {
    e.preventDefault();
    this.refs.dropzone.open();

    return false;
  }

  handleOnShowPhoto(e, index) {
    e.preventDefault();

    this.setState({
      photoIndex: index
    });

    return false;
  }

  /**
   * Cette fonction permet d'alterner 2 positions dans le tableau de photos
   *
   * @param newPosition
   * @param oldPosition
   */
  handleChangePosition(newPosition, oldPosition) {}

  /**
   * Cette methode permet de supprimer une photo du tableau
   *
   * @param index
   */
  handleRemoveItem(index) {
    Action.create(this.props.boutique, "media-delete", {
      id: this.props.items[index].media,
      boutique: this.props.boutique.id,
      docType: this.props.docType,
      docId: this.props.id
    });
    const tmpPhotos = this.props.items.slice(0);
    tmpPhotos.splice(index, 1);
    this.props.updateItems(tmpPhotos);
    this.setState({
      loadingInProgress: false
    });
  }

  handleCloseModal(e) {
    e.preventDefault();

    this.setState({
      photoIndex: null
    });

    return false;
  }

  render() {
    const { items } = this.props;

    return (
      <StyledPhotos className="cms__component--medias">
        <button
          className="is-pulled-right"
          id="upload-photo"
          onClick={this.onOpenClick}
        >
          {this.props.addText}
        </button>
        <StyledTitle>{this.props.title}</StyledTitle>
        <div className="group">
          <Dropzone
            ref="dropzone"
            onDrop={this.onDrop}
            disableClick
            className={items && items.length > 0 ? "dropzone-hide" : "dropzone"}
            activeClassName="dropzone-active"
            accept="image/*"
          >
            {items &&
              items.length === 0 && (
                <div className="first-upload">
                  <img src={svg} alt="Upload files" />
                  <h4>{this.props.uploadFilesText}</h4>
                </div>
              )}
          </Dropzone>
          {items &&
            items.length > 0 && (
              <div className="list">
                <ol>
                  {items.map((photo, index) => (
                    <Photo
                      key={index}
                      index={index}
                      photo={photo}
                      boutique={this.props.boutique}
                      itemRef={this.props.itemRef}
                      handleOnShowPhoto={this.handleOnShowPhoto}
                      handleChangePosition={this.handleChangePosition}
                      handleRemoveItem={this.handleRemoveItem}
                    />
                  ))}
                </ol>
                <div className="is-clearfix" />
              </div>
            )}
          {this.state.loadingInProgress && <span>Chargement en cours</span>}
        </div>
        {this.state.photoIndex !== null && (
          <StyledModal>
            <div className="photo">
              <button
                onClick={e => this.handleCloseModal(e)}
                className="photo-close"
              >
                <span className="text">Fermer</span>
              </button>
              <div className="photo-content">
                <img
                  src={`${getDomainAssetsURL(this.props.boutique)}/${
                    items[this.state.photoIndex]
                  }`}
                  alt={items[this.state.photoIndex]}
                />
              </div>
            </div>
          </StyledModal>
        )}
      </StyledPhotos>
    );
  }
}

export default DragDropContext(HTML5Backend)(Photos);
