import styled from 'styled-components'

export default styled.div`
  background: #fff;
  border: 1px solid #e4e7ed;
  border-radius: 4px;
  margin-bottom: 3rem;
  
  .type_request {
    background: #44414d;
    display: flex;
    border-bottom: 1px solid #dddfe2;
    
    border-radius: 4px 4px 0 0;
    
    a {
      cursor: pointer;
      flex: 1;
      color: #fff;
      text-align: center;
      font-size: 85%;
      //text-transform: uppercase;
      position: relative;
      height: 16px;
      padding: 0 1.5rem;
      margin: 9px 0;
      line-height: 16px;
      border-right: 1px solid #dddfe2;
      
      &:last-child {
        border: none;
      }
      
      .triangle {
        border-bottom: 8px solid #dddfe2;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        bottom: -9px;
        font-size: 0;
        height: 0;
        left: 49%;
        line-height: 0;
        position: absolute;
        width: 0;
    
        &:after {
            border-bottom: 8px solid #fff;
            border-left: 8px solid transparent;
            border-right: 8px solid transparent;
            content: '';
            left: -8px;
            position: absolute;
            top: 1px;
        }
      }
    }
  }

  textarea {
    width: 100%;
    border: none;
    resize: none;
    outline: none;
    padding: 0.75rem;
  }
  
  .actions {
    border-top: 1px solid #dddfe2;
  }
`