import React, { Component } from "react";
import PropTypes from "prop-types";
import StyledRow from "../../../CMS/styles/View/List/Row";

class Row extends Component {
  constructor(props) {
    super(props);

    this.state = {
      mustBeLoadedFournisseur: false,
      fournisseurRef: null,
      fournisseurData: null,
      mustBeLoadedType: false,
      typeRef: null,
      typeData: null
    };

    this.loadFournisseur = this.loadFournisseur.bind(this);
    this.loadType = this.loadType.bind(this);
  }

  componentDidMount() {
    if (this.state.mustBeLoadedFournisseur) {
      this.loadFournisseur(this.state.fournisseurRef);
    }
    if (this.state.mustBeLoadedType) {
      this.loadType(this.state.typeRef);
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.state.mustBeLoadedFournisseur) {
      this.loadFournisseur(this.state.fournisseurRef);
    }
    if (this.state.mustBeLoadedType) {
      this.loadType(this.state.typeRef);
    }
  }

  static getDerivedStateFromProps(props, state) {
    // if (
    //   props.doc &&
    //   ((!state.fournisseurRef && props.doc.data().fournisseurRef) ||
    //     (state.fournisseurRef &&
    //       state.fournisseurRef.id !== props.doc.data().fournisseurRef.id))
    // ) {
    //   state.mustBeLoadedFournisseur = true;
    //   state.fournisseurRef = props.doc.data().fournisseurRef;
    // }
    // if (
    //   props.doc &&
    //   ((!state.typeRef && props.doc.data().typeRef) ||
    //     (state.typeRef &&
    //       state.typeRef.id !== props.doc.data().typeRef.id))
    // ) {
    //   state.mustBeLoadedType = true;
    //   state.typeRef = props.doc.data().typeRef;
    // }

    return state;
  }

  loadFournisseur(fournisseur) {
    fournisseur.get().then(snapshot => {
      this.setState({
        fournisseurData: snapshot.data(),
        mustBeLoadedFournisseur: false
      });
    });
  }

  loadType(typeRef) {
    typeRef.get().then(snapshot => {
      this.setState({
        typeData: snapshot.data(),
        mustBeLoadedType: false
      });
    });
  }

  render() {
    const { doc, onClick } = this.props;

    const item = doc.data();

    const name = [];
    if (item.firstname) {
      if (item.lastname) {
        name.push(`${item.firstname} ${item.lastname}`);
      } else {
        name.push(item.firstname);
      }
    } else {
      if (item.lastname) {
        name.push(item.lastname);
      }
    }

    if (item.email) {
      name.push(item.email);
    } else {
      if (item.phone) {
        name.push(item.phone);
      }
    }

    return (
      <StyledRow>
        {item.subscribedToNewsletter && (
          <span className="is-subscribedToNewsletter" />
        )}
        <p className="name" style={{ flex: 1 }}>
          {name.join(" - ")}
        </p>

        <p className="control actions">
          <button onClick={onClick} className="edit small">
            <span className="icon" />
            <span className="txt">Edit</span>
          </button>
        </p>
      </StyledRow>
    );
  }
}

Row.propTypes = {
  doc: PropTypes.object.isRequired
};

export default Row;
