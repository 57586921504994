import React, { Component } from "react";
import { connect } from "react-redux";
import Planches from "./containers/Planches";

class CalculatricesOMAJE extends Component {
  render() {
    // TODO experimental - voir pour améliorer le router
    if (this.props["*"].substr(0, 14) === "omaje/planches") {
      return <Planches {...this.props} />;
    }

    return (
      <div>
        Problème de configuration, le type '{this.props["*"]}' n'existe pas
      </div>
    );
  }
}

const mapStateToProps = (globalState, ownProps) => {
  let props = {};

  return props;
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CalculatricesOMAJE);
