import React, { Component } from "react";
import { connect } from "react-redux";
import { loadDemandeByContactId } from "../../../../../../Boutique/containers/Demande/actions";
import Section from "../../../../../../CMS/components/Data/Section";
import Item from "./Item";

class Demandes extends Component {
  state = {
    id: null,
    shouldLoadDemandes: false,
    demandes: null
  };

  constructor(props) {
    super(props);

    this.loadDemandes = this.loadDemandes.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    if (props.id && (!state.id || props.id !== state.id)) {
      state.id = props.id;
      state.shouldLoadDemandes = true;
    }

    return state;
  }

  componentDidMount() {
    if (this.state.shouldLoadDemandes) {
      this.setState(
        {
          shouldLoadDemandes: false
        },
        () => {
          this.loadDemandes(this.state.id);
        }
      );
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.state.shouldLoadDemandes) {
      this.setState(
        {
          shouldLoadDemandes: false
        },
        () => {
          this.loadDemandes(this.state.id);
        }
      );
    }
  }

  loadDemandes(contactId) {
    this.props.loadDemandes(contactId).then(action => {
      if (action.demandes) {
        this.setState({
          demandes: action.demandes
        });
      }
    });
  }

  render() {
    if (!this.state.demandes) {
      return <div>Chargement en cours</div>;
    }

    return (
      <Section title="Demandes">
        {this.state.demandes.length > 0 ? (
          this.state.demandes.map((demande, idx) => (
            <Item key={idx} demande={demande} boutique={this.props.boutique} />
          ))
        ) : (
          <p>Aucune demande</p>
        )}
      </Section>
    );
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    loadDemandes: contactId => {
      return dispatch(loadDemandeByContactId(ownProps.boutique, contactId));
    }
  };
};

export default connect(
  null,
  mapDispatchToProps
)(Demandes);
