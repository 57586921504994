import React from "react";
import List from "../../../CMS/containers/Boutique/components/List";
import { connect } from "react-redux";
import Item from "./Item";
import contact from "./params.json";

import { initialize, validate } from "./functions";
import ActionBar from "../../../CMS/components/ActionBar";
import Button from "../../../CMS/components/Button";
import Contact from "../../../CMS/datas/Contact";

class ContactList extends List {
  listTitle = "Contacts";
  viewList = Item;
  collectionName = "contacts";
  defaultOrderField = "name";
  defaultOrderDirection = "asc";
  itemTitle = [{ type: "attribut", attribut: "name" }];
  initializeItem = initialize;
  validateItem = validate;
  itemParams = contact;
  tabsArray = [];
  messageNoDocument = "Aucun contact";

  constructor(props) {
    super(props, {
      defaultViewList: "list",
      newItemLibelle: "Nouveau contact",
      custom: {
        wheres: {
          subscribedToNewsletter: ""
        }
      }
    });

    this.handleTransfertToProject = this.handleTransfertToProject.bind(this);
    this.state.list.menu = {};
  }

  handleTransfertToProject(e) {
    e.preventDefault();
    if (!this.props.boutique.get("firebaseApiKey")) {
      console.error(
        "Il n'est pas possible de transférer les contacts car le projet n'est pas configuré"
      );
    }

    Contact.transfertToBoutique(this.props.boutique).then(result => {
      console.info("result", result);
    });
  }

  addActionBarToRender() {
    let menuClassNames = ["menu-annexe"];
    menuClassNames.push(this.state.openMenu ? "open" : "close");

    return (
      <ActionBar className="action-bar is-clearfix">
        <ul>
          <li className="navbar-item">
            <Button icon="fa-plus" onClick={this.handleCreate} />
          </li>
          <li className="navbar-item">
            <Button
              icon="fa-download"
              onClick={this.handleTransfertToProject}
            />
          </li>
        </ul>

        <div className={menuClassNames.join(" ")}>
          <Button
            icon="fa-ellipsis-v"
            className="open-menu"
            onClick={this.handleOpenMenuOnMobile}
          />
          <div className="menu-annexe-content">
            <ul>
              <li className="navbar-item">
                <select
                  value={this.state.custom.wheres.subscribedToNewsletter}
                  onChange={e =>
                    this.handleChangeCustomWheres(e, "subscribedToNewsletter")
                  }
                >
                  <option value="">Tous les contacts</option>
                  <option value={true}>Inscrits</option>
                  <option value={false}>Non Inscrits</option>
                </select>
              </li>
            </ul>
          </div>
        </div>

        <ul className="border-left navbar-right">
          <li className="navbar-item search">
            <input
              type="text"
              value={this.state.q}
              onChange={this.handleOnChangeSearch}
              onKeyPress={this.handleOnKeyPressSearch}
              placeholder="Rechercher"
            />
            {this.state.q && (
              <button className="empty" onClick={this.handleEmptySearch}>
                <span className="icon" />
                <span className="text">Vider</span>
              </button>
            )}
          </li>
        </ul>
      </ActionBar>
    );
  }
}

const mapStateToProps = (globalState, ownProps) => {
  const props = {};

  props.loading = globalState.boutique.loading;
  props.docs = globalState.boutique.docs;

  return props;
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    // load: (boutiqueId) => dispatch(load(boutiqueId)),
    // create: (item) => {
    //     dispatch(create(valid(item)));
    // },
    // update: (item) => {
    //     dispatch(update(item.objectId, valid(item)));
    // },
    // reset: () => dispatch(reset())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ContactList);
