import React, { Component } from "react";
import { connect } from "react-redux";
import { change, formValueSelector } from "redux-form";
import Input from "../../../CMS/components/Data/Input/index";

class POS extends Component {
  state = {
    shouldUpdateFields: false
  };

  constructor(props) {
    super(props);

    this.updateFields = this.updateFields.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    if (props.isInPOS && (!props.hasInventory || !props.inStock)) {
      state.shouldUpdateFields = true;
    }

    return state;
  }

  componentDidMount() {
    if (this.state.shouldUpdateFields) {
      this.setState(
        {
          shouldUpdateFields: false
        },
        () => {
          this.updateFields();
        }
      );
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.state.shouldUpdateFields) {
      this.setState(
        {
          shouldUpdateFields: false
        },
        () => {
          this.updateFields();
        }
      );
    }
  }

  updateFields() {
    // forcer la non présence en magasin
    // La présence en magasin impose une tenue de stock
    this.props.dispatch(change("item", "isInPOS", false));
    this.props.dispatch(change("item", "inStock", false));
    this.props.dispatch(change("item", "qtyAvailable", 0));
  }

  render() {
    const { hasInventory, qtyAvailable } = this.props;
    const disabled =
      !hasInventory || !qtyAvailable || parseInt(qtyAvailable, 10) <= 0;

    return (
      <Input
        type="checkbox"
        name="isInPOS"
        description="Est disponible au magasin ?"
        className="pointofsale"
        label="Point de vente"
        disabled={disabled}
      />
    );
  }
}

const selector = formValueSelector("item");

export default connect((state, props) => {
  return {
    hasInventory: selector(state, "hasInventory"),
    qtyAvailable: selector(state, "qtyAvailable"),
    inStock: selector(state, "inStock"),
    isInPOS: selector(state, "isInPOS")
  };
})(POS);
