import React from "react";
import styled from "styled-components";

const Div = styled.div`
  position: absolute;
  .inner {
    background-color: #000;
    border-radius: 4px;
    color: #fff;
    max-width: 200px;
    padding: 3px 8px;
    text-align: center;
    white-space: nowrap;

    font-size: 10px;
    line-height: 20px;
  }

  .arrow {
    border-color: transparent;
    border-style: solid;
    height: 0;
    position: absolute;
    width: 0;

    ${props => {
      switch (props.direction) {
        case "top":
          return {
            borderTopColor: "#000",
            borderWidth: "5px 5px 0 5px",
            right: "50%",
            marginRight: "-5px",
            bottom: "-5px"
          };
        case "right":
          return {
            borderRightColor: "#000",
            borderWidth: "5px 5px 5px 0",
            bottom: "50%",
            marginBottom: "-5px",
            left: "-5px"
          };
        case "bottom":
          return {
            borderBottomColor: "#000",
            borderWidth: "0 5px 5px",
            left: "50%",
            marginLeft: "-5px",
            top: "-5px"
          };
        case "left":
        default:
          return {
            borderLeftColor: "#000",
            borderWidth: "5px 0 5px 5px",
            top: "50%",
            marginTop: "-5px",
            right: "-5px"
          };
      }
    }};
  }
`;

export default ({ value, ...others }) => (
  <Div {...others}>
    <div className="inner"> {value}</div>
    <div className="arrow" />
  </Div>
);
