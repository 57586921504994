import React, { Component } from "react";
import Section from "../../../../../CMS/components/Data/Section";
import { getFirestore } from "../../../../functions";
import moment from "moment";
import Variant from "../Variant";
import { formValueSelector } from "redux-form";
import { connect } from "react-redux";

class Variants extends Component {
  state = {
    id: undefined,
    items: undefined,
    shouldUpdatedVariants: false,
    unsubscribe: undefined
  };

  constructor(props) {
    super(props);

    this.retrieveVariants = this.retrieveVariants.bind(this);
    this.handleGenerateVariants = this.handleGenerateVariants.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    if (props.id && (!state.id || props.id !== state.id)) {
      state.id = props.id;
      state.shouldUpdatedVariants = true;
      state.items = undefined;
    }

    return state;
  }

  componentDidMount() {
    if (this.state.shouldUpdatedVariants) {
      this.retrieveVariants();
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.state.shouldUpdatedVariants) {
      this.retrieveVariants();
    }
  }

  componentWillUnmount() {
    if (this.state.unsubscribe) {
      this.state.unsubscribe();
    }
  }

  retrieveVariants() {
    const _this = this;
    if (this.state.unsubscribe) {
      this.state.unsubscribe();
    }

    const unsubscribe = this.props.itemRef
      .collection("variants")
      .where("deleted", "==", false)
      .orderBy("createdAt")
      .orderBy("order")
      .onSnapshot(querySnapshot => {
        const items = [];

        querySnapshot.docs.forEach(doc => {
          const data = doc.data();
          delete data.createdAt;
          delete data.deleted;

          const item = {
            ref: doc.ref,
            change: false,
            ...data
          };
          items.push(item);
        });


        _this.setState({
          items
        });
      });

    this.setState({
      shouldUpdatedVariants: false,
      unsubscribe: unsubscribe
    });
  }

  generateVariants(options) {
    if (options.length === 1) {
      const valeurs = options[0].values.map(value => {
        return {
          values: [
            {
              ...value,
              option: options[0].id
            }
          ]
        };
      });

      console.info(options[0].libelle, valeurs);
      return valeurs;
    }
    const tmpOptions = options.slice(0);
    const option = tmpOptions.shift();
    console.info(option.libelle);
    const newVariants = [];
    // Pour chaque valeur de l'option, on itere sur les variants en cours
    option.values.forEach(value => {
      this.generateVariants(tmpOptions).forEach(variant => {
        const tmp = {};
        tmp.values = variant.values.slice(0);
        tmp.values.push({
          ...value,
          option: option.id
        });
        newVariants.push(tmp);
      });

      console.info(`${value.id} ${value.libelle}`, newVariants);
    });
    return newVariants;
  }

  handleUpdate(index) {
    this.setState(oldState => {
      let item = oldState.items[index];
      item.change = false;
      item.updatedAt = moment().toISOString();

      const data = Object.assign({}, item);
      delete data.ref;
      delete data.change;
      delete data.timeoutId;
      item.ref
        .update({
          updatedAt: moment().toISOString(),
          ...data
        })
        .then(() => {
          console.info("mise à jour réussie");
        });

      return {
        items: oldState.items
      };
    });
  }

  handleChangeValue(name, value, index, type) {
    switch (type) {
      case "number":
        value = parseFloat(value);
        break;

      case "text":
      default:
        value = String(value);
    }

    this.setState(oldState => {
      let item = oldState.items[index];
      item[name] = value;
      item.change = true;

      if (item.timeoutId) {
        clearTimeout(item.timeoutId);
      }

      item.timeoutId = setTimeout(() => {
        this.handleUpdate(index);
      }, 1000);

      return {
        items: oldState.items
      };
    });
  }

  handleGenerateVariants(e) {
    e.preventDefault();

    const options = {};
    this.props.itemRef
      .collection("options")
      .where("deleted", "==", false)
      .get()
      .then(querySnapshot => {
        const promises = [];
        querySnapshot.docs.forEach(doc => {
          options[doc.id] = {
            ...doc.data(),
            ref: doc.ref,
            values: {}
          };
          promises.push(
            doc.ref
              .collection("values")
              .where("deleted", "==", false)
              .get()
          );
        });

        return Promise.all(promises);
      })
      .then(querySnapshots => {
        querySnapshots.forEach(querySnapshot => {
          querySnapshot.docs.forEach(doc => {
            options[doc.ref.parent.parent.id].values[doc.id] = {
              ...doc.data(),
              ref: doc.ref
            };
          });
        });
        const tabOptions = [];
        Object.keys(options).forEach(optionId => {
          const values = [];
          Object.keys(options[optionId].values).forEach(valueId => {
            values.push({ ...options[optionId].values[valueId], id: valueId });
          });
          values.sort((a, b) => b.datetime - a.datetime);
          tabOptions.push({ ...options[optionId], values, id: optionId });
        });

        const batch = getFirestore(this.props.boutique).batch();
        const variants = this.generateVariants(
          tabOptions.sort((a, b) => b.datetime - a.datetime)
        );
        const createdAt = moment().toISOString();
        variants.forEach((variant, order) => {
          let libelle = [],
            price = this.props.startPrice ? this.props.startPrice : 0;
          variant.values.forEach(value => {
            libelle.push(value.libelle);
            price += Number(value.onCost);
          });
          libelle = libelle.reverse().join(" ");
          batch.set(this.props.itemRef.collection("variants").doc(), {
            ...variant,
            libelle,
            createdAt,
            price,
            order,
            deleted: false
          });
        });
        batch.commit();
      });
  }

  render() {
    return (
      <Section
        title="Liste des déclinaisons"
        group={true}
        button={{
          onClick: this.handleGenerateVariants,
          text: "Générer les déclinaisons"
        }}
      >
        {this.state.items && this.state.items.length > 0 ? (
          <div style={{ marginBottom: "20px" }}>
            {this.state.items.map((item, index) => (
              <Variant
                itemRef={item.ref}
                item={item}
                handleChangeValue={(name, value, type) =>
                  this.handleChangeValue(name, value, index, type)
                }
                customFields={[
                  {
                    name: "price",
                    label: "Prix",
                    type: "number",
                    icon: "fa-eur"
                  }
                ]}
                key={index}
              />
            ))}
          </div>
        ) : (
          <p>Aucune déclinaison</p>
        )}
      </Section>
    );
  }
}

const selector = formValueSelector("item");

export default connect((state, props) => {
  return {
    startPrice: Number(selector(state, "startPrice"))
  };
})(Variants);
