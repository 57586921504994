import { getFirestore } from "../../Boutique/functions";
import moment from "moment";

class Content {
  static create(boutique, data) {
    return getFirestore(boutique)
      .collection("contents")
      .add(data);
  }

  static insertIntoDocument(documentRef, data = {}) {
    data.createdAt = moment().toISOString();
    data.deleted = false;

    return documentRef.collection("contents").add(data);
  }

  static update(document, values) {
    return document.ref.update(values);
  }

  static delete(document) {
    return document.ref.delete();
  }
}

export default Content;
