import React from "react";

export default () => (
  <div>
    <h1>Segmentation des contacts</h1>
    <p>
      Il sera possible dans un second temps de réaliser un filtre sur les
      contacts inscrits à la newsletter pour selectionner une partie de la liste
      de diffusion.
    </p>
    <p>Par exemple :</p>
    <ul>
      <li>uniquement les personnes qui habite aux états unis</li>
      <li>uniquement les professionnels</li>
      <li>uniquement les bons clients</li>
    </ul>
  </div>
);
