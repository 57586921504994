import {
  USER_LOAD_REQUEST,
  USER_LOAD_SUCCESS,
  USER_LOAD_ERROR,
  USER_IS_LOGOUT,
  USERS_WATCH,
  USER_CREATE,
  USER_UPDATE,
  USER_REMOVE,
  USERS_RESET
} from "../../actionTypes";
import slug from "slug";
import firestore from "../../../../api/firestore";
import moment from "moment";
import { watch as watchBoutiques } from "../Boutique/actions";

moment.locale("fr");

slug.defaults.mode = "rfc3986";

export function watch() {
  return dispatch => {
    firestore
      .collection("utilisateurs")
      .where("deleted", "==", false)
      .onSnapshot(function(querySnapshot) {
        dispatch({
          type: USERS_WATCH,
          docs: querySnapshot.docs
        });
      });
  };
}

/**
 * Cette fonction permet de charger l'utilisateur et les boutiques du projet
 *
 * -> On charge les boutiques du projet au même endroit car ce sont des paramètres
 * indispensable au fonctionnement du projet
 *
 * @param user
 * @returns {Function}
 */
export function load(user) {
  return dispatch => {
    dispatch({
      type: USER_LOAD_REQUEST
    });
    // 1. On charge l'utilisateur
    firestore
      .collection("utilisateurs")
      .doc(user.uid)
      .get()
      .then(function(user) {
        if (user && user.exists && !user.data().deleted) {
          dispatch({
            type: USER_LOAD_SUCCESS,
            user: user
          });
        } else {
          throw new Error("L'utilisateur n'existe pas ou a été supprimé");
        }

        // 2. On charge les boutiques
        dispatch(watchBoutiques(user));
      })
      .catch(error => {
        dispatch({
          type: USER_LOAD_ERROR,
          message: error
        });
      });
  };
}

export function isLogout() {
  return {
    type: USER_IS_LOGOUT
  };
}

export function create(item) {
  return {
    type: USER_CREATE,
    payload: firestore.collection("utilisateurs").add(item)
  };
}

export function update(key, item) {
  return {
    type: USER_UPDATE,
    payload: firestore
      .collection("utilisateurs")
      .doc(key)
      .update(item)
  };
}

export function remove(id) {
  return {
    type: USER_REMOVE,
    payload: firestore
      .collection("utilisateurs")
      .doc(id)
      .update({
        deleted: true,
        updatedAt: moment().toISOString()
      })
  };
}

export function reset() {
  return dispatch => {
    const unsubscribe = firestore
      .collection("utilisateurs")
      .onSnapshot(function() {});
    // Stop listening to changes
    unsubscribe();

    dispatch({
      type: USERS_RESET
    });
  };
}
