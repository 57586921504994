import React, { Component } from "react";
import StyledField from "../styles/Field";

class Input extends Component {
  render() {
    const {
      label,
      value,
      type,
      description,
      hideLabel,
      className,
      ...others
    } = this.props;

    switch (type) {
      case "checkbox":
        return (
          <StyledField className={className}>
            <label className="checkbox">
              <input type="checkbox" checked={value} {...others} />
              <span>{description}</span>
            </label>
            <label className={`label ${hideLabel ? "hide" : "show"}`}>
              <span>{label}</span>
            </label>
          </StyledField>
        );

      default:
        return (
          <StyledField className={className}>
            <input type={type} value={value} {...others} />
            <label className={`label ${hideLabel ? "hide" : "show"}`}>
              <span>{label}</span>
            </label>
          </StyledField>
        );
    }
  }
}

export default Input;
