import React, { Component } from "react";
import ViewList from "../../styles/View/List/index";
import ViewTitle from "../../styles/View/Title";
import Tabs from "../../styles/Tabs";
import Header from "../Header";

class List extends Component {
  render() {
    const { handleChangeTab } = this.props;
    return (
      <ViewList>
        <ViewTitle>{this.props.title}</ViewTitle>
        {this.props.tabs &&
          this.props.tabs.length > 0 && (
            <Tabs>
              <ul>
                {this.props.tabs.map((tab, index) => (
                  <li key={index} className={`${tab.actif ? "is-active" : ""}`}>
                    <button
                      data-index={index}
                      onClick={event => handleChangeTab(event)}
                    >
                      {tab.label}
                    </button>
                  </li>
                ))}
              </ul>
            </Tabs>
          )}

        <Header menu={this.props.menu} />

        {this.props.description &&
          this.props.description.map((description, index) => (
            <p className="description" key={index}>
              {description}
            </p>
          ))}
        {this.props.children}
      </ViewList>
    );
  }
}

export default List;
