import * as types from '../constants/actionTypes';

import User from '../api/User';

export function login(email, password) {
    return {
        type: types.LOGIN,
        payload: User.login(email, password)
    }
}

export function logout() {
    return {
        type: types.LOGOUT,
        payload: User.logout()
    }
}

export function update(user) {
    return {
        type: types.USER_UPDATE,
        payload: User.update(user)
    }
}