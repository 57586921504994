import React, { Component } from "react";
import firestore from "../../../../api/firestore";

class Traitement extends Component {
  componentDidMount() {
    firestore
      .collection("pages")
      .where("boutique", "==", "SdVBgbFtEaiOpCTS5D8T")
      .where("type", "==", "produit")
      .limit(500)
      .get()
      .then(querySnapshot => {
        console.info("querySnapshot", querySnapshot.size);
        var batch = firestore.batch();
        if (!querySnapshot.empty) {
          querySnapshot.docs.forEach(doc => {
            batch.delete(doc.ref);
          });

          batch.commit().then(function() {
            console.info(
              `Suppression réussi de ${querySnapshot.size} documents`
            );
          });
        }
      });
  }

  render() {
    return <div>Suppression des pages créer par erreur</div>;
  }
}

export default Traitement;
