import { DOCUMENTS_LOAD } from "../../actionTypes";
import { getFirestore } from "../../functions";

export function load(boutique) {
  return {
    type: DOCUMENTS_LOAD,
    payload: getFirestore(boutique).collection("services")
      .where("boutique", "==", boutique.id)
      .where("deleted", "==", false)
      .orderBy("name")
      .get()
  };
}
