import React from "react";
import { Link, Match } from "@reach/router";
import { NavItem, NavIcon, NavGroup } from "./styles";

const SubMenuItem = props => {
  const { item } = props;

  switch (item.type) {
    case "separator":
      return <li className="separator" />;

    case "link":
      return (
        <li>
          <Match path={item.to}>
            {props => {
              return (
                <Link
                  to={item.to}
                  className={
                    props.location.pathname.substr(0, item.to.length) ===
                    item.to
                      ? "is-active"
                      : "home"
                  }
                >
                  {item.name}
                </Link>
              );
            }}
          </Match>
        </li>
      );

    default:
      return null;
  }
};

export default props => {
  const { item, etat, user } = props;

  if (
    !item.type ||
    !item.acl ||
    Object.keys(item.acl).filter(
      data => data === "*" || data === user.data().type
    ).length === 0
  ) {
    if (!user.data().profils || !user.data().profils.includes(item.profil)) {
      return null;
    }
    // if (
    //   !(item.profil === "athlete" && user.athlete && user.athlete.objectId) &&
    //   !(
    //     item.profil === "coach" &&
    //     user.entraineur &&
    //     user.entraineur.objectId
    //   ) &&
    //   !(item.profil === "organizer" && user.isOrganizer) &&
    //   !(item.profil === "boutique" && user.boutique && user.boutique.objectId)
    // ) {
    //   return null;
    // }
  }

  const hasMenu = !!(item.menu && item.menu.length > 0);

  switch (item.type) {
    case "spacer":
      return <li className="spacer" />;

    case "link":
      return (
        <li>
          <div>
            <span>
              <NavItem
                onClick={() => props.handleChangePath(item.to, hasMenu)}
                className={etat === item.state ? "is-active" : ""}
              >
                <span>{item.name}</span>
                {item.menu &&
                  item.menu.length > 0 && (
                    <NavIcon>
                      <i className="fa fa-angle-right" />
                    </NavIcon>
                  )}
              </NavItem>
            </span>
            {item.state === etat &&
              item.menu &&
              item.menu.length > 0 && (
                <NavGroup>
                  <NavItem onClick={props.handleGoTopMenu}>
                    <span className="icon">
                      <i className="fa fa-angle-left" />
                    </span>
                    <span>{item.name}</span>
                  </NavItem>
                  <div>
                    {item.menu.map((list, indexList) => (
                      <ul key={indexList}>
                        {list.map((subitem, indexItem) => (
                          <SubMenuItem
                            key={indexItem}
                            item={subitem}
                            handleClose={props.handleClose}
                          />
                        ))}
                      </ul>
                    ))}
                  </div>
                </NavGroup>
              )}
          </div>
        </li>
      );

    case "search":
      return <li>Recherche</li>;
    case "separator":
      return <li className="separator" />;

    default:
      return <li />;
  }
};
