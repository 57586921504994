import styled from "styled-components";
import handshake from "../../../../../images/font-awesome/regular/handshake.svg";
import share from "../../../../../images/font-awesome/solid/share.svg";
import reservedFalse from "../../../../../images/font-awesome/regular/calendar.svg";
import reservedTrue from "../../../../../images/font-awesome/solid/calendar-check.svg";
import featuredFalse from "../../../../../images/font-awesome/regular/bookmark.svg";
import featuredTrue from "../../../../../images/font-awesome/solid/bookmark.svg";
import onlineStoreFalse from "../../../../../images/font-awesome/regular/eye-slash.svg";
import onlineStoreTrue from "../../../../../images/font-awesome/regular/eye.svg";
import goTop from "../../../../../images/font-awesome/solid/level-up-alt.svg";
import goRedo from "../../../../../images/font-awesome/solid/redo.svg";
import storeAlt from "../../../../../images/font-awesome/solid/store-alt.svg";

export default styled.div`
  cursor: pointer;

  > div {
    border: 1px solid #e4e7ed;
    border-radius: 4px;
  }

  &.isOver > div {
    border-color: red;
  }

  .isDragging {
    border-color: blue;
  }

  &.isOver > div.isDragging {
    border-color: green;
  }

  .informations {
    padding: 0.375rem;
    border-bottom: 1px solid rgba(195, 207, 216, 0.3);
  }

  .name {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

    text-align: left;
    margin-top: 0;
    margin-bottom: 0.375rem;

    //font-weight: 400;
    font-size: 13px;
    font-weight: 300;
    color: #6f8dfd;
    transition: color 0.2s ease;

    &:hover {
      color: #3e5ed6;
    }
  }

  .sku {
    display: block;
    font-size: 12px;
    font-style: italic;
  }

  .control {
    label {
      flex: 1;
    }
  }

  .controls {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding: 0.375rem;
    background: white;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  .goReserved,
  .goSoldout,
  .goFeatured,
  .goOnlineStore,
  .goTop,
  .goRedo {
    background-color: #86848c;
    mask-image: url(${reservedFalse});
    mask-repeat: no-repeat;
    mask-position: center;
    height: 18px;
    width: 18px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: #9f9da4;
    //border: 1px solid #b7c6fe;
    text-decoration: none;
    margin-left: 0.375rem;
    span {
      display: none;
    }

    &:hover {
      background-color: #34313f;
    }
  }

  .goReserved.yes {
    mask-image: url(${reservedTrue});
  }
  .goSoldout.yes {
    mask-image: url(${share});
  }
  .goSoldout.no {
    mask-image: url(${handshake});
  }
  .goFeatured.no {
    mask-image: url(${featuredFalse});
  }
  .goFeatured.yes {
    mask-image: url(${featuredTrue});
  }

  .goOnlineStore.no {
    mask-image: url(${onlineStoreFalse});
  }
  .goOnlineStore.yes {
    mask-image: url(${onlineStoreTrue});
  }

  .goTop {
    mask-image: url(${goTop});
  }

  .goRedo {
    mask-image: url(${goRedo});
  }

  figure {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }

  .soldout,
  .reserved,
  .showUnavailable,
  .notVisible,
  .draft {
    position: absolute;
    top: 0;
    height: 100%;
    display: ${props => (props.showInfo ? "flex" : "none")};
    justify-content: center;
    align-items: center;
    width: 100%;
    background: rgba(255, 255, 255, 0.6);

    span {
      display: inline-block;
      flex: 1;
      width: 100%;
      text-align: center;
      font-size: 12px;
      color: #86848c;
      //font-weight: bold;
      //font-style: italic;
      text-transform: uppercase;
    }
  }

  ${props => {
    if (props.showUnavailable !== undefined) {
      if (props.showUnavailable) {
        return {
          ".showUnavailable": {
            display: "flex"
          }
        };
      } else {
        return {
          ".showUnavailable": {
            display: "none"
          }
        };
      }
    }
  }};

  .bulles {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
  }

  .bulle {
    display: block;
    float: right;
    margin: 10px 10px 0 0;
    //color: white;
    color: #000;
    font-size: 15px;
    //background-color: rgba(0, 127, 188, 0.5);
    background-color: rgba(255, 255, 255, 0.7);
    border-radius: 15px;
    height: 25px;
    width: 25px;
    line-height: 23px;
    box-sizing: border-box;
    text-align: center;
  }

  .qtyAvailable {
    font-size: 12px;
    line-height: 25px;
  }

  .isOnlineStore {
    font-size: 8px;
    line-height: 25px;
  }

  .isInPOS:after {
    content: "";
    display: block;
    float: right;
    height: 15px;
    width: 15px;
    margin: 5px;
    background-color: #000;
    mask-image: url(${storeAlt});
    mask-repeat: no-repeat;
    mask-position: center;
  }

  .showDiscount {
    font-weight: bold;

    &:before {
      content: "";
      display: inline-block;
      margin-left: 5px;
    }
  }

  //.soldout + .bulles .showUnavailable {
  //  align-items: flex-end;
  //  background: none;
  //
  //  span {
  //    padding-bottom: 1.5rem;
  //    font-size: 90%;
  //  }
  //}

  input[type="checkbox"] + span:before {
    background-size: 14px 14px !important;
    height: 14px !important;
    width: 14px !important;
  }

  input[type="checkbox"]:checked + span:before {
    background-position: -1px -1px !important;
  }

  .price {
    margin-top: 0.375rem;
    font-size: 12px;
    line-height: 25px;
  }

  .qtyAvailable {
    font-size: 12px;
    &:after {
      content: " ";
    }
  }

  #retailPrice:before {
    content: "";
    display: inline-block;
    margin-left: 5px;
  }

  .sold {
    float: right;
  }
`;
