import moment from "moment";
import * as functions from "../../../CMS/functions";

export function initialize(data, boutique) {
  const objet = functions.initialize(data);
  return {
    ...objet,
    boutique: boutique.id,
    etat: "validated",
    saleDate: moment().format("YYYY-MM-DD")
  };
}

export function validate(document) {
  document.updatedAt = moment().toISOString();
  if (document.client_firstname) {
    if (document.client_lastname) {
      document.client_name = `${document.client_firstname} ${
        document.client_lastname
      }`;
    } else {
      document.client_name = document.client_firstname;
    }
  } else {
    if (document.client_lastname) {
      document.client_name = document.client_lastname;
    } else {
      if (document.client_email) {
        document.client_name = document.client_email;
      } else {
        document.client_name = document.client_phone;
      }
    }
  }

  return document;
}
