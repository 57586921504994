import React, {Component} from 'react'
import Bulle from './Bulle'
import {
    StyledMessages
} from './styles'

class Messages extends Component {

    render() {
        return(
            <StyledMessages>
                {this.props.items.map((item, index) =>
                    <Bulle key={index} text={item.text} reponse={item.reponse}/>
                )}
                {this.props.children}
            </StyledMessages>
        );
    }
}

export default Messages;