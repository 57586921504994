import React, { Component } from "react";
import { connect } from "react-redux";
import { resetInfoError } from "../actions/infos";

const Snackbar = props => <div>{props.children}</div>;

class Notification extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showNotification: true,
      open: true
    };

    this.handleCloseNotification = this.handleCloseNotification.bind(this);
  }

  componentWillReceiveProps() {
    this.setState({
      showNotification: true,
      open: true
    });
  }

  handleCloseNotification = event => {
    this.setState({
      showNotification: false
    });
    this.props.closeNotification();
  };

  handleRequestClose = event => {
    this.setState({
      open: false
    });
  };

  render() {
    if (this.props.error && this.state.showNotification) {
      return (
        <Snackbar
          open={this.state.open}
          message={this.props.error.error}
          autoHideDuration={4000}
          onRequestClose={this.handleRequestClose}
        />
      );
    }

    if (this.props.info && this.state.showNotification) {
      return (
        <Snackbar
          open={this.state.open}
          message={this.props.info}
          autoHideDuration={4000}
          onRequestClose={this.handleRequestClose}
        />
      );
    }
    return <div />;
  }
}

const mapStateToProps = (globalState, ownProps) => {
  return {
    error: ownProps.error,
    info: ownProps.info
  };
};

const mapDispatchToProps = dispatch => {
  return {
    closeNotification: () => {
      dispatch(resetInfoError());
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Notification);
