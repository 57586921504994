import React, { Component } from "react";
import { getDomainAssetsURL } from "../../../../functions";
import nextPhoto from "../../../../../../images/next-photo.svg";
import StyledCard from "../../styles/Card";

class Item extends Component {
  render() {
    const { doc, boutique } = this.props;

    if (!boutique || !doc || boutique.id !== doc.get("boutique")) return null;

    switch (doc.get("type")) {
      case "photo":
        let photo;
        try {
          if (doc.get("fullPath")) {
            photo = `${getDomainAssetsURL(boutique)}/${doc.get(
              "fullPath"
            )}?w=320&h=320&c=max`;
          } else {
            photo = nextPhoto;
          }
        } catch (e) {
          photo = nextPhoto;
        }

        const width = this.props.nbParLigne
          ? parseFloat(100 / this.props.nbParLigne)
          : 20;

        return (
          <li
            style={{
              display: "block",
              padding: "1.5rem 0 0 1.5rem",
              boxSizing: "border-box",
              width: `${width}%`
            }}
          >
            <StyledCard>
              <figure className="card-image aspect-ratio aspect-ratio--square">
                <img
                  src={photo}
                  alt="Chargement"
                  className="aspect-ratio__content"
                />
              </figure>
              <h3 className="name">{doc.get("name")}</h3>
            </StyledCard>
          </li>
        );

      default:
        return null;
    }
  }
}

export default Item;
