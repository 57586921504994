import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import CMSList from "../../../../components/View/List";
import CMSForm from "../../../../components/View/Form";
import CMSView from "../../../../components/View/index";
import CMSData from "../../../../components/Data/index";
import Row from "./Row";
import user from "../../params.json";

import { initialize, validate } from "../../functions";

import { watch, create, update, remove, reset } from "../../actions";

const ListCards = styled.ol`
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: 1.5rem 0 0 -1.5rem;
  padding: 0;
  box-sizing: content-box;
`;

class List extends Component {
  constructor(props) {
    super(props);

    this.state = {
      item: null,
      key: null
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCreate = this.handleCreate.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleListView = this.handleListView.bind(this);
    this.handleGridView = this.handleGridView.bind(this);
  }

  componentWillMount() {
    this.props.load();
  }

  componentWillUnmount() {
    this.props.reset();
  }

  handleCreate = event => {
    // Création d'un nouveau utilisateur vide
    const item = initialize(user);
    this.setState({
      item: item,
      key: null
    });
  };

  handleDelete = event => {
    this.props.remove(this.state.key).then(action => {
      // TODO Afficher un message de confirmation
      this.setState({
        item: null,
        key: null
      });
    });
  };

  handleSubmit = item => {
    if (this.state.key) {
      this.props.update(this.state.key, item);
    } else {
      // console.error('erreur submit', 'l'utilisateur doit exister');
      this.props.create(item).then(action => {
        console.log(action);
        if (!action.error) {
          this.setState({
            item,
            key: action.payload.id
          });
        }
      });
    }
  };

  handleClose = () => {
    this.setState({
      item: null,
      key: null
    });
  };

  handleChange = (index, event) => {
    this.setState({
      item: this.props.users[index].data(),
      // item: Object.entries(this.props.docs)[index][1].data(),
      key: this.props.users[index].id
    });
  };

  handleListView = () => {};

  handleGridView = () => {};

  render() {
    let propsList = {
      title: "Utilisateurs",
      loading: this.props.loading,
      menu: {
        left: [
          {
            text: "Ajouter",
            onClick: this.handleCreate
          }
        ]
      }
    };
    let propsForm = {
      initialValues: this.state.item,
      required: ["displayname"]
    };

    if (this.state.item) {
      propsForm.menu = {
        left: [
          {
            className: "delete",
            text: "Supprimer",
            onClick: this.handleDelete
          }
        ],
        right: [
          {
            text: "Fermer",
            onClick: this.handleClose
          },
          {
            text: "Enregistrer",
            submit: true
          }
        ]
      };
    }

    // TODO voir pour ajouter un tableau d'attributs dans le title :  prenom et nom
    return (
      <CMSView className="cms__utilisateur">
        {this.state.item ? (
          <CMSForm
            {...propsForm}
            title={{ type: "attribut", attribut: "displayname" }}
            onSubmit={this.handleSubmit}
          >
            <CMSData data={user} id={this.state.key} user={this.props.user} />
          </CMSForm>
        ) : (
          <CMSList {...propsList}>
            {this.props.users ? (
              this.props.users.length > 0 ? (
                <ListCards>
                  {Object.values(this.props.users).map((doc, index) => (
                    <Row
                      key={index}
                      onClick={event => this.handleChange(index, event)}
                      doc={doc}
                    />
                  ))}
                </ListCards>
              ) : (
                <div>Aucun utilisateur</div>
              )
            ) : (
              <div>Chargement en cours</div>
            )}
          </CMSList>
        )}
      </CMSView>
    );
  }
}

const mapStateToProps = (globalState, ownProps) => {
  return {
    users: globalState.cms.users,
    user: globalState.cms.user
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    load: () => {
      dispatch(watch());
    },
    create: item => {
      return dispatch(create(validate(item)));
    },
    update: (key, item) => {
      dispatch(update(key, validate(item))).then(action => {
        console.log(item);
      });
    },
    remove: id => dispatch(remove(id)),
    reset: () => dispatch(reset())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(List);
