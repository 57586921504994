import React from 'react';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';

import Input from '../../../../../CMS/components/Data/Input/index';
import Data from '../../../../../CMS/components/Data/index';

import inventaire from '../../inventaire.json';

const Inventaire = (props) => {
    return (
        <div className="boutique__produit__component--inventaire">
            <Input name="hasInventory"
                   type="checkbox"
                   label="Politique d'inventaire"
                   description="Tenir un inventaire pour ce produit ?" className="no-margin-bottom"/>
            {props.hasInventory && (<Data data={inventaire} user={props.user}/>)}
            {!props.hasInventory || props.hasNegativeStock ? (
                <div>
                    <Input type="text" name="delay"
                           label="Délai de fabrication"
                           placeholder="Ce message sera affiché si le produit n'est pas ou plus en stock"/>
                </div>
            ):null}
        </div>
    )
};

const selector = formValueSelector('item');

export default connect(
    (state, props) => {
        return {
            hasInventory: selector(state, 'hasInventory'),
            hasNegativeStock: selector(state, 'negativeStock') ? selector(state, 'negativeStock') : false,
        }
    }
)(Inventaire);