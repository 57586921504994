import moment from "moment";
import * as functions from "../../../CMS/functions";

export function initialize(data, boutique) {
  const objet = functions.initialize(data);
  return {
    ...objet,
    boutique: boutique.id,
    isVisible: true,
    parent: ""
  };
}

export function validate(item) {
  item.updatedAt = moment().toISOString();
  item.isVisible = Boolean(item.isVisible);

  return item;
}
