import React, { Component } from "react";
import PropTypes from "prop-types";
import CMSList from "../../../components/View/List";
import CMSForm from "../../../components/View/Form";
import CMSView from "../../../components/View/index";
import CMSData from "../../../components/Data/index";
import Button from "../../../components/Button/index";
import moment from "moment";
import HTML5Backend from "react-dnd-html5-backend";
import { DragDropContext } from "react-dnd";
import { navigate } from "@reach/router";
import { diff } from "deep-object-diff";
import {
  DOCUMENT_CREATE,
  DOCUMENT_REMOVE,
  DOCUMENT_UPDATE,
  WATCH_RESET,
  WATCH_SUCCESS
} from "../../../../Boutique/actionTypes";
import { initialize, validate } from "../../../../Boutique/functions";
import Collection from "../../../../CMS/datas/Collection";

class WrapperList extends Component {
  render() {
    const { style, children, ...others } = this.props;

    if (style) {
      const StyleCmp = style;
      return <StyleCmp {...others}>{children}</StyleCmp>;
    }

    return <ol {...others}>{children}</ol>;
  }
}

WrapperList = DragDropContext(HTML5Backend)(WrapperList);

class List extends Component {
  actionTypeWatch = WATCH_SUCCESS;
  actionTypeCreate = DOCUMENT_CREATE;
  actionTypeUpdate = DOCUMENT_UPDATE;
  actionTypeRemove = DOCUMENT_REMOVE;
  actionTypeReset = WATCH_RESET;
  listTitle = "Catalogue";
  viewList = ({ doc, onClick }) => <li onClick={onClick}>{doc.id}</li>;
  viewCard = null;
  collectionName = "pages";
  collectionType = null;
  defaultWheres = {};
  defaultOrderField = "datetime";
  defaultOrderDirection = "desc";
  itemTitle = "Produit";
  initializeItem = initialize;
  validateItem = validate;
  itemParams = {};
  messageNoDocument = "";
  listStyle = null;

  static contextTypes = {
    store: PropTypes.object
  };

  constructor(props, options) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCreate = this.handleCreate.bind(this);
    this.handleUpdate = this.handleUpdate.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.loadItemById = this.loadItemById.bind(this);
    this.handleListView = this.handleListView.bind(this);
    this.handleGridView = this.handleGridView.bind(this);

    this.handleEmptySearch = this.handleEmptySearch.bind(this);
    this.handleOnChangeSearch = this.handleOnChangeSearch.bind(this);
    this.handleOnChangeNbParLigne = this.handleOnChangeNbParLigne.bind(this);
    this.handleOnKeyPressSearch = this.handleOnKeyPressSearch.bind(this);
    this.handleSubmitSearch = this.handleSubmitSearch.bind(this);

    this.loadNewDocuments = this.loadNewDocuments.bind(this);
    this.loadAlternativeFunc = this.loadAlternativeFunc.bind(this);
    this.loadTabsArray = this.loadTabsArray.bind(this);
    this.showDocs = this.showDocs.bind(this);

    this.handleClose = this.handleClose.bind(this);
    this.handleNext = this.handleNext.bind(this);
    this.handlePrevious = this.handlePrevious.bind(this);
    this.handleChangeTab = this.handleChangeTab.bind(this);

    this.handleChangePage = this.handleChangePage.bind(this);
    this.handleNextPage = this.handleNextPage.bind(this);
    this.handleChangeCustomWheres = this.handleChangeCustomWheres.bind(this);
    this.handleChangePosition = this.handleChangePosition.bind(this);

    this.handleOpenMenuOnMobile = this.handleOpenMenuOnMobile.bind(this);

    this.state = {
      mustBeLoadedBoutique: false,
      mustBeLoadedItem: false,
      boutique: null,
      pathname: null,
      actionInProgess: false,
      canBeSubmited: false,
      hasUpdatedFields: false,
      item: null,
      index: null,
      key: null,
      ref: null,
      resetWheresWhenChangeTab: false,
      q: "",
      openMenu: false,
      view:
        options && options.defaultViewList ? options.defaultViewList : "list",
      nbParLigne: this.props.isMobile ? 2 : 5,
      nbParPage: 40,
      pageIndex: 0,
      lastDocument: null,
      tabIndex: 0,
      tabsArray: options && options.tabsArray ? options.tabsArray : null,
      custom: options && options.custom ? options.custom : null,
      list: {
        menu: { left: [{ icon: "fa-plus", onClick: this.handleCreate }] }
      },
      form: {
        newItemLibelle:
          options && options.newItemLibelle
            ? options.newItemLibelle
            : "Nouveau document",
        menu: {
          left: [
            {
              className: "delete",
              icon: "fa-trash",
              onClick: this.handleDelete
            },
            {
              className: "previous",
              icon: "fa-chevron-left",
              onClick: this.handlePrevious,
              type: "navigation"
            },
            {
              className: "next",
              icon: "fa-chevron-right",
              onClick: this.handleNext,
              type: "navigation"
            }
          ],
          right: [
            { icon: "fa-close", onClick: this.handleClose },
            { icon: "fa-save", submit: true }
          ]
        },
        onChange: (values, dispatch, props, previousValues) => {
          if (this.timeoutId) {
            clearTimeout(this.timeoutId);
          }

          // si createdAt est modifié c'est que ce n'est pas le même produit
          // Donc on n'enregistre rien car cela ne sert à rien - il n'y aucune valeur de modifiée
          if (!diff(previousValues, values).createdAt) {
            this.setState({ hasUpdatedFields: true });

            this.timeoutId = setTimeout(() => {
              if (this.state.canBeSubmited) {
                this.handleSubmit(values, false);
              }
            }, 500);
          }
        }
      }
    };
  }

  componentDidMount() {
    window.addEventListener("scroll", this.onScroll);
    if (this.state.boutique) {
      if (this.state.mustBeLoadedBoutique) {
        this.loadNewDocuments();
      }
      if (this.state.mustBeLoadedItem) {
        this.loadItemById();
      }
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.state.boutique) {
      if (this.state.mustBeLoadedBoutique) {
        this.loadNewDocuments();
      }
      if (this.state.mustBeLoadedItem) {
        this.loadItemById();
      }
    }
  }

  componentWillUnmount() {
    this.props.resetCollection(this.actionTypeReset, this.props.unsubscribe);
    window.removeEventListener("scroll", this.onScroll);
  }

  static getDerivedStateFromProps(props, state) {
    const id = props["*"].split("/")[1];
    // TODO Il y a un bug lors de la suppression d'un produit
    // Voir pour repartir à zero ou pas lors de la suppression d'un produit

    // On verifie si l'on a changé de boutique ou d'url
    if (
      props.boutique &&
      (!state.boutique || state.boutique.id !== props.boutique.id)
    ) {
      state.boutique = props.boutique;
      state.mustBeLoadedBoutique = true;
    }

    // On vérifie si l'on a changé d'url et que la boutique existe
    if (
      props.location &&
      props.location.pathname &&
      (!state.pathname || state.pathname !== props.location.pathname)
    ) {
      state.pathname = props.location.pathname;
      // Si le new pathname n'a pas l'id en cours, on ferme le document en cours
      if (!id) {
        state.item = null;
        state.index = null;
        state.key = null;
        state.ref = null;
        state.canBeSubmited = false;
      } else {
        if (id !== state.key) {
          state.item = null;
          state.index = null;
          state.key = id;
          state.ref = null;
          state.mustBeLoadedItem = true;
        }
      }
    }

    return state;
  }

  handleOpenMenuOnMobile(e) {
    e.preventDefault();

    this.setState(oldState => {
      return {
        openMenu: !oldState.openMenu
      };
    });
  }

  loadNewDocuments() {
    this.loadAlternativeFunc(this.state.boutique);
    this.setState(
      oldState => {
        if (
          oldState.custom &&
          oldState.custom.wheres &&
          Object.keys(oldState.custom.wheres).length > 0
        ) {
          Object.keys(oldState.custom.wheres).forEach(key => {
            oldState.custom.wheres[key] =
              oldState.defaultValues &&
              oldState.defaultValues.wheres &&
              oldState.defaultValues.wheres &&
              oldState.defaultValues.wheres[key]
                ? oldState.defaultValues.wheres[key]
                : "";
          });
        }
        oldState.q = "";
        oldState.mustBeLoadedBoutique = false;

        return oldState;
      },
      () => {
        this.showDocs(this.state.boutique);
      }
    );
    // this.loadTabsArray(nextProps.boutique);
  }

  loadItemById() {
    let index = undefined;
    if (this.props.docs) {
      index = this.props.docs.findIndex(doc => doc.id === this.state.key);
    }

    if (index !== undefined && index > -1) {
      this.setState({
        index: index,
        item: this.props.docs[index].data(),
        key: this.props.docs[index].id,
        ref: this.props.docs[index].ref,
        canBeSubmited: true
      });
    } else {
      Collection.findById(
        this.state.boutique,
        this.collectionName,
        this.state.key
      )
        .then(document => {
          console.info("document", document.data());
          this.setState({
            index: undefined,
            item: document.data(),
            key: document.id,
            ref: document.ref,
            canBeSubmited: true
          });
        })
        .catch(error => {
          console.error(error.message);
          console.info("this.props", this.props);
          const pathnames = this.props.location.pathname.split("/");
          pathnames.pop();
          this.props.navigate(pathnames.join("/"));
        });
    }

    this.setState({
      mustBeLoadedItem: false
    });
  }

  onScroll = () => {
    // const pos = window.pageYOffset + window.innerHeight;
  };

  handleOnChangeSearch(e) {
    this.setState({
      q: e.target.value,
      pageIndex: 0,
      lastDocument: null
    });
  }

  handleOnChangeNbParLigne(e) {
    this.setState({ nbParLigne: e.target.value });
  }

  handleOnKeyPressSearch(event) {
    if (event.which === 13) {
      this.showDocs(this.props.boutique);
    }
  }

  handleSubmitSearch(e) {
    e.preventDefault();
    this.showDocs(this.props.boutique);

    return false;
  }

  handleEmptySearch(e) {
    this.setState(
      {
        q: "",
        pageIndex: 0
      },
      () => {
        this.showDocs(this.props.boutique);
      }
    );
  }

  loadAlternativeFunc(boutique) {}

  showDocs(boutique, notReset) {
    // On reset la liste des documents si on doit reset et que la liste n'est pas vide
    if (!Boolean(notReset) && this.props.docs && this.props.docs.length > 0) {
      console.info("resetCollection");
      this.props.resetCollection(this.actionTypeReset, this.props.unsubscribe);
    }

    let wheres = this.defaultWheres;
    let orderField = this.defaultOrderField;
    let orderDirection = this.defaultOrderDirection;
    if (this.state.tabsArray && this.state.tabsArray.length > 0) {
      wheres = this.state.tabsArray[this.state.tabIndex].wheres;
      if (this.state.tabsArray[this.state.tabIndex].orderField) {
        orderField = this.state.tabsArray[this.state.tabIndex].orderField;
      }
      if (this.state.tabsArray[this.state.tabIndex].orderDirection) {
        orderDirection = this.state.tabsArray[this.state.tabIndex]
          .orderDirection;
      }
    }

    if (
      this.state.custom &&
      this.state.custom.wheres &&
      Object.keys(this.state.custom.wheres).length > 0
    ) {
      Object.keys(this.state.custom.wheres).forEach(key => {
        if (
          this.state.custom.wheres[key] ||
          typeof this.state.custom.wheres[key] === "boolean"
        ) {
          wheres[key] = this.state.custom.wheres[key];
        } else {
          delete wheres[key];
        }
      });
    }

    return this.props.watchCollection(
      this.actionTypeWatch,
      this.collectionName,
      this.collectionType,
      this.state.q.trim(),
      wheres,
      orderField,
      orderDirection,
      this.state.nbParPage,
      this.state.pageIndex,
      this.state.lastDocument ? this.state.lastDocument : null
    );
  }

  loadTabsArray(boutique) {
    // TODO Gérer ce traitement
    // TODO modifier le chargements des tabs
    // Je préconise l'utilisation d'un valeur dans la base de données à chargement de l'utilisateur
    // On récupére une liste des différentes tabs paramètrées pour l'utilisateur
    // const _this = this;
    // this.props.loadTabsArray(boutique).then(action => {
    //     if(action.payload.size>0) {
    //         // Il y a des collections
    //         // On ajout les collections à tabsArray
    //         let tabsArray = _this.state.tabsArray.slice(0);
    //         action.payload.docs.forEach((doc, index) => {
    //             const wheres = [];
    //             wheres[`collections.${doc.id}`] = true;
    //             tabsArray.push({
    //                 label: doc.data().libelle,
    //                 actif: false,
    //                 onClick: this.handleChangeTab,
    //                 wheres: wheres
    //             });
    //
    //             _this.setState({
    //                 tabsArray: tabsArray
    //             });
    //         })
    //     }
    // });
  }

  handleCreate(event) {
    // Création d'un nouveau produit vide
    if (this.props.boutique) {
      this.setState(
        {
          item: this.initializeItem(this.itemParams, this.props.boutique),
          index: undefined,
          key: undefined,
          ref: undefined,
          canBeSubmited: true
        },
        () => {
          // this.props.createNotification({
          //   type: "info",
          //   message: `Initialisation d'un nouveau produit`
          // });
        }
      );
    } else {
      console.error("Il faut pouvoir associé l'item a la boutique");
    }
  }

  handleUpdate(index, item) {
    // TODO mettre un validateur des attributs de item
    // Cependant le validate comme il est créer n'est pas bon
    // car il ecrase toutes les données qui ne sont pas dans le item
    // Pour rappel, cette fonction permet de mettre à jour une selection d'attribut
    // et pas tous les champs réelllement présent
    // const validateItem = this.validateItem(item);
    this.props.updateDocumentByRef(
      this.actionTypeUpdate,
      this.props.docs[index].ref,
      item
    );
  }

  handleDelete(event) {
    event.preventDefault();
    if (this.state.ref) {
      const confirmation = window.confirm(
        "Voulez-vous vraiment supprimer cet objet ?"
      );
      if (confirmation) {
        const _this = this;
        this.props
          .removeDocumentByRef(this.actionTypeRemove, this.state.ref)
          .then(() => {
            const paths = this.props.location.pathname.split("/");
            paths.pop();
            navigate(paths.join("/"));
            _this.setState(
              {
                item: undefined,
                index: undefined,
                key: undefined,
                ref: undefined,
                canBeSubmited: false
              },
              () => {
                _this.props.createNotification({
                  type: "info",
                  message: `Suppression réussie`
                });
              }
            );
          });
      }
    } else {
      this.props.createNotification({
        type: "error",
        message: `Impossible de supprimer l'item`
      });
    }
  }

  handleSubmit(item, hasHandleAfter = true) {
    const validateItem = this.validateItem(item);

    if (this.state.form.required && this.state.form.required.length > 0) {
      // Il y a au moins un champs obligatoire
      // On va vérifier si c'est OK
      const fieldsNoValided = [];
      this.state.form.required.forEach(key => {
        if (!validateItem[key]) {
          console.error(`Le champs ${key} n'est pas renseigné`);
          fieldsNoValided.push(key);
        }
      });

      if (fieldsNoValided.length > 0) {
        return this.props.createNotification({
          type: "error",
          message: `${fieldsNoValided.join(", ")} non validé.s`
        });
      }
    }

    if (!this.state.actionInProgess) {
      this.setState(
        {
          actionInProgess: true
        },
        () => {
          if (this.state.ref) {
            this.props
              .updateDocumentByRef(
                this.actionTypeUpdate,
                this.state.ref,
                validateItem
              )
              .then(action => {
                if (!action.error) {
                  this.props.createNotification({
                    type: "info",
                    message: "Mise à jour réussie"
                  });

                  this.setState(
                    {
                      actionInProgess: false,
                      hasUpdatedFields: false
                    },
                    () => {
                      // TODO ajouter un système pour bloquer la navigation temps que le traitement n'est pas fini
                      if (hasHandleAfter) {
                        this.handleWhenUpdateSuccess(
                          this.state.ref,
                          validateItem
                        );
                      }
                    }
                  );
                }
              });
          } else {
            this.props
              .createDocumentInCollection(
                this.actionTypeCreate,
                this.collectionName,
                this.collectionType,
                validateItem
              )
              .then(action => {
                if (!action.error) {
                  navigate(`${this.state.pathname}/${action.payload.id}`);
                  this.setState(
                    {
                      validateItem,
                      index: 0,
                      key: action.payload.id,
                      ref: action.payload,
                      pageIndex: 0,
                      lastDocument: null,
                      actionInProgess: false,
                      hasUpdatedFields: false
                    },
                    () => {
                      this.props.createNotification({
                        type: "info",
                        message: "Création réussie"
                      });

                      this.showDocs(this.props.boutique);
                      // if (hasHandleAfter) {
                      this.handleWhenCreateSuccess(
                        action.payload,
                        validateItem
                      );
                      // }
                    }
                  );
                } else {
                  this.props.createNotification({
                    type: "error",
                    message: "Erreur lors de la création de l'item"
                  });
                }
              });
          }
        }
      );
    } else {
      this.props.createNotification({
        type: "error",
        message: "Enregistrement en cours - action annuler"
      });
    }
  }

  handleClose(e) {
    if (e) {
      e.preventDefault();
    }

    if (!this.state.hasUpdatedFields && !this.state.actionInProgess) {
      // On affiche l'url principal de la list
      const paths = this.props.location.pathname.split("/");
      paths.pop();
      navigate(paths.join("/"));
      this.setState({
        item: null,
        index: null,
        key: null,
        ref: null,
        canBeSubmited: false
      });
    }
  }

  handlePrevious(e) {
    e.preventDefault();

    this.setState(oldState => {
      const previousIndex = parseInt(oldState.index, 10) - 1;
      if (previousIndex >= 0) {
        const pathnames = this.props.location.pathname.split("/");
        pathnames.pop();
        pathnames.push(this.props.docs[previousIndex].id);
        navigate(pathnames.join("/"));
        return {
          index: previousIndex,
          item: this.props.docs[previousIndex].data(),
          key: this.props.docs[previousIndex].id,
          ref: this.props.docs[previousIndex].ref
        };
      }
    });
  }

  handleNext(e) {
    e.preventDefault();

    this.setState(oldState => {
      const nextIndex = parseInt(oldState.index, 10) + 1;
      if (this.props.docs.length > nextIndex) {
        const pathnames = this.props.location.pathname.split("/");
        pathnames.pop();
        pathnames.push(this.props.docs[nextIndex].id);
        navigate(pathnames.join("/"));
        return {
          index: nextIndex,
          item: this.props.docs[nextIndex].data(),
          key: this.props.docs[nextIndex].id,
          ref: this.props.docs[nextIndex].ref
        };
      }
    });
  }

  handleChange(index, event) {
    navigate(`${this.props.location.pathname}/${this.props.docs[index].id}`);
    this.setState({
      index: index,
      item: this.props.docs[index].data(),
      key: this.props.docs[index].id,
      ref: this.props.docs[index].ref,
      canBeSubmited: true
    });
  }

  handleListView() {
    this.setState({
      view: "list"
    });
  }

  handleGridView() {
    this.setState({
      view: "grid"
    });
  }

  addActionBarToRender() {
    return null;
  }

  addChildrenBeforeToRender() {
    return null;
  }
  /**
   * Cette fonction permet d'ajouter du contenu dans la fonction render()
   *
   * @returns {null}
   */
  addChildrenAfterToRender() {
    return null;
  }

  /**
   * Cette fonction permet d'ajouter un traitement après la mise à jour du document
   *
   * @param ref
   * @param item
   * @returns {null}
   */
  handleWhenUpdateSuccess(ref, item) {
    return null;
  }

  /**
   * Cette fonction permet d'ajouter untraitement après la création du document
   *
   * @param ref
   * @param item
   * @returns {null}
   */
  handleWhenCreateSuccess(ref, item) {
    return null;
  }

  handleChangeTab(event) {
    event.preventDefault();

    const tabIndex = event.currentTarget.getAttribute("data-index");
    let tabsArray = this.state.tabsArray.slice(0);
    tabsArray.forEach((tab, index) => {
      tabsArray[index].actif = index === parseInt(tabIndex, 10);
    });

    this.setState(
      oldState => {
        if (oldState.resetWheresWhenChangeTab) {
          oldState.q = "";
          if (
            oldState.custom &&
            oldState.custom.wheres &&
            Object.keys(oldState.custom.wheres).length > 0
          ) {
            Object.keys(oldState.custom.wheres).forEach(key => {
              oldState.custom.wheres[key] = "";
            });
          }
        }

        oldState.tabIndex = parseInt(tabIndex, 10);
        oldState.tabsArray = tabsArray;
        oldState.pageIndex = 0;
        oldState.lastDocument = null;

        return oldState;
      },
      () => {
        this.showDocs(this.props.boutique);
      }
    );
  }

  handleChangePage(e) {
    const page = parseInt(e.currentTarget.getAttribute("data-page"), 10);

    this.setState(
      {
        pageIndex: page
      },
      () => {
        window.scrollTo(0, 0);
        this.showDocs(this.props.boutique);
      }
    );
  }

  handleNextPage(e) {
    e.preventDefault();

    this.setState(
      oldState => {
        console.info(
          "lastDocument",
          this.props.docs[this.props.docs.length - 1].id
        );
        return {
          pageIndex: parseInt(oldState.pageIndex, 10) + 1,
          lastDocument: this.props.docs[this.props.docs.length - 1]
        };
      },
      () => {
        this.showDocs(this.props.boutique, true);
      }
    );
  }

  handleChangeCustomWheres(e, key) {
    const value = e.target.value;
    this.setState(
      oldState => {
        if (value === "true" || value === "false") {
          oldState.custom.wheres[key] = value === "true";
        } else {
          oldState.custom.wheres[key] = value;
        }

        oldState.pageIndex = 0;
        oldState.lastDocument = null;
        // TODO voir comment faire pour mettre à zero la liste
        // oldState.q = "";

        oldState.openMenu = false;
        return oldState;
      },
      () => {
        this.showDocs(this.props.boutique);
      }
    );
  }

  handleChangePosition(oldIndex, newIndex) {
    //TODO revoir cette fonction car tous les documents sont dans this.props.docs
    let newIndexDatetime = parseInt(
      this.props.docs[newIndex].data().datetime,
      10
    );
    let newIndexMinusOneDatetime = parseInt(moment().format("x"), 10);
    if (newIndex === 0) {
      // Il faut vérifier si on est sur la premiere page ou non
      if (!this.state.lastDocument) {
        // On met à jour le datetime avec la date du moment
        newIndexDatetime = parseInt(moment().format("x"), 10);
      } else {
        // On est sur une autre page que la premiere
        // Il faut récupérer le datetime du dernier produit de la page précédente
        // TODO revoir ce traitement car il n'est plus bon
        newIndexMinusOneDatetime = this.state.lastDocument.data().datetime;
      }
    } else {
      // Cas de figure général - pas de problème
      newIndexMinusOneDatetime = parseInt(
        this.props.docs[newIndex - 1].data().datetime,
        10
      );
    }

    const newDatetime =
      newIndexDatetime + (newIndexMinusOneDatetime - newIndexDatetime) / 2;

    this.props.updateDocumentByRef(
      this.actionTypeUpdate,
      this.props.docs[oldIndex].ref,
      {
        datetime: newDatetime
      }
    );
  }

  render() {
    const props = {
      list: {
        ...this.state.list,
        title: this.listTitle,
        loading: this.props.loading,
        tabs: this.state.tabsArray
      },
      form: {
        ...this.state.form,
        initialValues: this.state.item,
        id: this.state.key,
        docIndex: this.state.index
      }
    };

    const View =
      this.state.view === "grid" && this.viewCard
        ? this.viewCard
        : this.viewList;

    const showNextPage =
      this.props.docs &&
      this.state.nbParPage * (this.state.pageIndex + 1) ===
        this.props.docs.length;

    return (
      <CMSView className={`boutique__${this.collectionName}`}>
        {this.state.item ? (
          <CMSForm
            {...props.form}
            title={this.itemTitle}
            onSubmit={this.handleSubmit}
          >
            <CMSData
              data={this.itemParams}
              id={this.state.key}
              user={this.props.user}
              boutique={this.props.boutique}
              itemRef={this.state.ref}
              customHandlers={this.propsFormCustomHandlers}
            />
          </CMSForm>
        ) : (
          <CMSList {...props.list} handleChangeTab={this.handleChangeTab}>
            <div className={"add-actionbar-to-render"}>
              {this.addActionBarToRender()}
            </div>
            <div className={"add-children-before-to-render"}>
              {this.addChildrenBeforeToRender()}
            </div>
            {this.props.docs &&
              (this.props.docs.length > 0 ? (
                <React.Fragment>
                  {this.props.nbHits && (
                    <div style={{ textAlign: "center" }}>
                      {this.props.nbHits} document
                      {this.props.nbHits > 1 ? "s " : " "}
                      trouvé
                      {this.props.nbHits > 1 ? "s" : ""}
                      {this.props.nbHits > 80 && (
                        <span style={{ marginLeft: "1.5rem" }}>
                          ⇢ Veuillez affiner la recherche
                        </span>
                      )}
                    </div>
                  )}
                  <WrapperList
                    style={this.listStyle}
                    className={`boutique__${this.collectionName}--list`}
                  >
                    {this.props.docs.map((doc, index) => (
                      <View
                        key={index}
                        index={index}
                        handleUpdate={values =>
                          this.handleUpdate(index, values)
                        }
                        handleChangePosition={this.handleChangePosition}
                        onClick={event => this.handleChange(index, event)}
                        doc={doc}
                        boutique={this.props.boutique}
                        nbParLigne={this.state.nbParLigne}
                        showInfo={
                          this.state.tabsArray &&
                          this.state.tabsArray[this.state.tabIndex].showInfo
                        }
                        showUnavailable={
                          this.state.tabsArray &&
                          this.state.tabsArray[this.state.tabIndex]
                            .showUnavailable
                        }
                        showBulles={
                          this.state.tabsArray &&
                          this.state.tabsArray[this.state.tabIndex].showBulles
                        }
                      />
                    ))}
                  </WrapperList>
                </React.Fragment>
              ) : (
                <div>{this.messageNoDocument}</div>
              ))}

            {this.props.loading ? (
              <div
                style={{
                  width: "100%",
                  textAlign: "center",
                  marginTop: "1.5rem"
                }}
              >
                <span
                  style={{
                    height: "34px",
                    lineHeight: "34px",
                    display: "inline-block"
                  }}
                >
                  Chargement en cours
                </span>
              </div>
            ) : showNextPage ? (
              <div
                style={{
                  width: "100%",
                  textAlign: "center",
                  marginTop: "1.5rem"
                }}
              >
                <Button
                  onClick={this.handleNextPage}
                  text="Page suivante"
                  clickOnVisible={true}
                />
              </div>
            ) : (
              <div
                style={{
                  width: "100%",
                  textAlign: "center",
                  marginTop: "1.5rem"
                }}
              >
                <span
                  style={{
                    height: "34px",
                    lineHeight: "34px",
                    display: "inline-block"
                  }}
                >
                  {!this.props.nbHits ||
                  this.props.nbHits === this.props.docs.length
                    ? "Tous les documents sont chargés"
                    : "Chargement en cours"}
                </span>
              </div>
            )}
          </CMSList>
        )}

        <div className={"add-children-to-render"}>
          {this.addChildrenAfterToRender()}
        </div>
      </CMSView>
    );
  }
}

export default List;
