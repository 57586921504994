// NEWSLETTER
export const NEWSLETTERS_WATCH = "NEWSLETTERS_WATCH";
export const NEWSLETTERS_LOAD = "NEWSLETTERS_LOAD";
export const NEWSLETTER_CREATE = "NEWSLETTER_CREATE";
export const NEWSLETTER_UPDATE = "NEWSLETTER_UPDATE";
export const NEWSLETTER_REMOVE = "NEWSLETTER_REMOVE";
export const NEWSLETTERS_RESET = "NEWSLETTERS_RESET";

// INSTAGRAM_POST
export const INSTAGRAM_POSTS_WATCH = "INSTAGRAM_POSTS_WATCH";
export const INSTAGRAM_POSTS_LOAD = "INSTAGRAM_POSTS_LOAD";
export const INSTAGRAM_POST_CREATE = "INSTAGRAM_POST_CREATE";
export const INSTAGRAM_POST_UPDATE = "INSTAGRAM_POST_UPDATE";
export const INSTAGRAM_POST_REMOVE = "INSTAGRAM_POST_REMOVE";
export const INSTAGRAM_POSTS_RESET = "INSTAGRAM_POSTS_RESET";
