import React, { Component } from "react";
import { change, formValueSelector } from "redux-form";
import { connect } from "react-redux";
import moment from "moment";
import Input from "../../../../../CMS/components/Data/Input";

class QtyAvailable extends Component {
  state = {
    isSold: undefined,
    shouldUpdateFields: false
  };

  constructor(props) {
    super(props);

    this.updateFields = this.updateFields.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    // Le composant est affiché uniquement lorsque hasInventory = true
    if (!props.isDraft && props.hasInventory) {
      if (state.isSold === undefined) {
        state.isSold = props.isSold;
      } else {
        if (props.isSold !== state.isSold) {
          // Il y a un changement d'état
          // la qtyAvailable est passé de 0 à + ou inversement
          // Il faut mettre à jour les champs annexes
          state.shouldUpdateFields = true;
          state.isSold = props.isSold;
        }
      }
    }

    return state;
  }

  componentDidMount() {
    if (this.state.shouldUpdateFields) {
      this.setState(
        {
          shouldUpdateFields: false
        },
        () => {
          this.updateFields();
        }
      );
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.state.shouldUpdateFields) {
      this.setState(
        {
          shouldUpdateFields: false
        },
        () => {
          this.updateFields();
        }
      );
    }
  }

  updateFields() {
    this.props.change("inStock", !this.state.isSold);

    if (this.state.isSold) {
      this.props.change("soldoutAt", moment().format("YYYY-MM-DDTHH:mm"));
      this.props.change("isInPOS", false);
    }
  }

  render() {
    const { name, placeholder, inputType, label, className } = this.props;

    return (
      <Input
        name={name}
        placeholder={placeholder}
        type={inputType}
        label={label}
        className={className}
      />
    );
  }
}

const selector = formValueSelector("item");

const mapStateToProps = (globalState, ownProps) => {
  const isDraft = selector(globalState, "isDraft");
  const inStock = selector(globalState, "inStock");
  const qtyAvailable = selector(globalState, "qtyAvailable");
  const hasInventory = selector(globalState, "hasInventory");
  const isSold = !isDraft && hasInventory && parseInt(qtyAvailable, 10) <= 0;

  return {
    qtyAvailable,
    hasInventory,
    isSold,
    inStock
  };
};

const mapDispatchToProps = dispatch => {
  return {
    change: (field, value) => dispatch(change("item", field, value))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(QtyAvailable);
