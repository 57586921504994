import React, { Component } from "react";
import Input from "../../../../../CMS/components/Data/Input/index";
import Field from "../../../../../CMS/styles/Field";
import { connect } from "react-redux";
import { formValueSelector } from "redux-form";
import { change } from "redux-form";
import { getNewNumero } from "../../actions";
import Button from "../../../../../CMS/components/Button";
import Columns from "../../../../../CMS/components/Data/Columns";

class Numero extends Component {
  constructor(props) {
    super(props);

    this.handleGenerate = this.handleGenerate.bind(this);
  }

  // componentDidMount() {
  //   if (!this.props.numero || this.props.numero === "") {
  //     this.props.generate(this.props.date);
  //   }
  // }

  handleGenerate(event) {
    event.preventDefault();
    this.props.generate(this.props.date);
  }

  render() {
    const { name, label, placeholder } = this.props;

    return (
      <Columns>
        <Input
          type="text"
          name={name}
          label={label}
          placeholder={placeholder}
          className="no-border-right no-right-radius no-margin-right no-margin-bottom is-expanded"
        />
        <Field noMarginLeft noMarginBottom>
          <div className="has-addons">
            <Button
              smallIcon
              icon="fa-repeat"
              onClick={this.handleGenerate}
              className="no-left-radius"
            />
          </div>
        </Field>
      </Columns>
    );
  }
}

const selector = formValueSelector("item");

const mapStateToProps = (globalState, ownProps) => {
  return {
    numero: selector(globalState, "numero"),
    date: selector(globalState, "date")
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    generate: date => {
      dispatch(getNewNumero(ownProps.boutique, date)).then(action => {
        dispatch(change("item", "numero", action.numero));
      });
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Numero);
