// LOGIN
export const LOGIN = 'LOGIN';

// SIGNUP
export const SIGNUP_REQUEST = 'SIGNUP_REQUEST';
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
export const SIGNUP_FAILURE = 'SIGNUP_FAILURE';

// LOGOUT
export const LOGOUT = 'LOGOUT';

// INFOS
export const INFOS_RESET = 'INFOS_RESET';

// NAVIGATION
export const NAVIGATION_OPEN = 'NAVIGATION_OPEN';
export const NAVIGATION_CLOSE = 'NAVIGATION_CLOSE';
export const NAVIGATION_SHOW = 'NAVIGATION_SHOW';

// USER
export const USER_LOAD = 'USER_LOAD';
export const USER_LOAD_FAILURE = 'USER_LOAD_FAILURE';
export const USER_UPDATE = 'USER_UPDATE';
export const USER_PASSWORD = 'USER_PASSWORD';
export const USER_PASSWORD_ERROR = 'USER_PASSWORD_ERROR';

// USERS
export const USERS_LOAD = 'USERS_LOAD';
export const USERS_CREATE = 'USERS_CREATE';
export const USERS_UPDATE = 'USERS_UPDATE';
export const USERS_REMOVE = 'USERS_REMOVE';

// ENTRAINEURS
export const ENTRAINEURS_CREATE = 'ENTRAINEURS_CREATE';
export const ENTRAINEURS_UPDATE = 'ENTRAINEURS_UPDATE';
export const ENTRAINEURS_REMOVE = 'ENTRAINEURS_REMOVE';

// ATHLETES
export const ATHLETES_CREATE = 'ATHLETES_CREATE';
export const ATHLETES_UPDATE = 'ATHLETES_UPDATE';
export const ATHLETES_REMOVE = 'ATHLETES_REMOVE';

// SEANCES
export const SEANCES_LOAD = 'SEANCES_LOAD';
export const SEANCES_SEARCH = 'SEANCES_SEARCH';

// PRODUCTS
export const PRODUCTS_CREATE = 'PRODUCTS_CREATE';
export const PRODUCTS_UPDATE = 'PRODUCTS_UPDATE';


export const LOAD = 'LOAD';
export const SEARCH = 'SEARCH';
export const RESET = 'RESET';
export const CREATE = 'CREATE';
export const UPDATE = 'UPDATE';
export const REMOVE = 'REMOVE';

export const UPLOAD = 'UPLOAD';
