import moment from "moment";
import * as functions from "../CMS/functions";
import firebase from "firebase/app";
import firebaseDefault from "../../api/firebase";
import "firebase/firestore";
import "firebase/storage";
import slug from "slug";

/**
 *
 * @param boutique
 * @returns {firebase.app.App|firebase}
 */
export function getFirebase(boutique) {
  if (
    boutique &&
    boutique.get("name") &&
    boutique.get("firebaseApiKey") &&
    boutique.get("firebaseAuthDomain") &&
    boutique.get("firebaseDatabaseURL") &&
    boutique.get("firebaseProjectId") &&
    boutique.get("firebaseStorageBucket") &&
    boutique.get("firebaseMessagingSenderId") &&
    boutique.get("firebaseAuthEmail") &&
    boutique.get("firebaseAuthPassword")
  ) {
    if (
      firebase.apps.find(app => {
        return app.name === slug(boutique.get("name"));
      })
    ) {
      return firebase.app(slug(boutique.get("name")));
    }
    const config = {
      apiKey: boutique.get("firebaseApiKey"),
      authDomain: boutique.get("firebaseAuthDomain"),
      databaseURL: boutique.get("firebaseDatabaseURL"),
      projectId: boutique.get("firebaseProjectId"),
      storageBucket: boutique.get("firebaseStorageBucket"),
      messagingSenderId: boutique.get("firebaseMessagingSenderId")
    };
    const fb = firebase.initializeApp(config, slug(boutique.get("name")));
    fb.auth().signInWithEmailAndPassword(
      boutique.get("firebaseAuthEmail"),
      boutique.get("firebaseAuthPassword")
    );
    return fb;
  }

  return firebaseDefault;
}

/**
 *
 * @param boutique
 * @returns {firebase.firestore.Firestore}
 */
export function getFirestore(boutique) {
  return getFirebase(boutique).firestore();
}

export function getStorage(boutique) {
  return getFirebase(boutique).storage();
}

/**
 * Cette fonction permet de créer une query
 * Cela permet de ne pas passer en paramètre firestore
 *
 * @param boutique
 * @param collectionName
 * @returns {firebase.firestore.CollectionReference}
 */
export function createQuery(boutique, collectionName) {
  return getFirestore(boutique).collection(collectionName);
}

export function getDomainAssetsURL(boutique) {
  if (boutique && boutique.get("domainAssetsURL")) {
    if (
      boutique.get("domainAssetsURL").substr(0, 8) === "https://" ||
      boutique.get("domainAssetsURL").substr(0, 7) === "http://" ||
      boutique.get("domainAssetsURL").substr(0, 2) === "//"
    ) {
      return `${boutique.get("domainAssetsURL")}/image/upload`;
    }
    return `//${boutique.get("domainAssetsURL")}/image/upload`;
  }

  return process.env.REACT_APP_CDN_DOMAIN_NAME;
}

/**
 * Cette fonction permet de créer toutes les clauses wheres pour la requête
 *
 * @param query
 * @param wheres
 * @param orderField
 * @param orderDirection
 * @returns query
 */
export function createWhereClauses(query, wheres, orderField, orderDirection) {
  let tmp;

  if (wheres && Object.keys(wheres).length > 0) {
    Object.keys(wheres).forEach(key => {
      if (!Array.isArray(wheres[key])) {
        if (
          wheres[key] !== null &&
          typeof wheres[key] === "object" &&
          !wheres[key].firestore
        ) {
          tmp = Object.assign({}, wheres[key]);
          tmp.operator = tmp.operator ? tmp.operator : "==";
        } else {
          tmp = {
            operator: "==",
            value: wheres[key]
          };
        }
        wheres[key] = [];
        wheres[key].push({
          operator: tmp.operator,
          value: tmp.value
        });
      }

      wheres[key].forEach(where => {
        const operator = where.operator;
        if (where.operator !== "==") {
          orderField = key;
        }
        if (where.value) {
          query = query.where(String(key), operator, where.value);
        } else {
          if (parseInt(where.value, 10) === 0) {
            query = query.where(String(key), operator, 0);
          } else {
            if (typeof where.value === "boolean") {
              query = query.where(String(key), operator, false);
            } else {
              query = query.where(String(key), operator, "");
            }
          }
        }
      });
    });
  }
  // On ajout la clause deleted == false car il n'est pas possible de voir les documents supprimés
  // avec cette fonction
  query = query.where("deleted", "==", false);
  query = query.orderBy(orderField, orderDirection);

  return query;
}

export function initialize(data, boutique) {
  const objet = functions.initialize(data);
  return {
    ...objet,
    boutique: boutique.id
  };
}

export function validate(document) {
  document.updatedAt = moment().toISOString();

  return document;
}
