// import { getFirestore } from "../../Boutique/functions";
// import algoliasearch from "algoliasearch";

class DataFunctions {
  /**
   * Cette méthode crée les filtres pour algolia
   *
   * @param wheres
   * @returns {string}
   */
  static getAlgoliaFilters(wheres) {
    let filters = [];

    if (wheres && Object.keys(wheres).length > 0) {
      Object.keys(wheres).forEach(key => {
        let operator = ":";
        let value = wheres[key];
        if (Array.isArray(wheres[key])) {
          wheres[key].forEach(filtre => {
            if (typeof filtre.value === "boolean") {
              value = Boolean(filtre.value);
              operator = ":";
            }
            if (Number.isInteger(filtre.value)) {
              value = parseInt(filtre.value, 10);
              switch (filtre.operator) {
                case "<":
                  operator = "<";
                  break;

                case ">":
                  operator = ">";
                  break;

                case "==":
                default:
                  operator = "=";
              }
            }
            filters.push(`${key}${operator}${value}`);
          });
        } else {
          if (Number.isInteger(value)) {
            operator = "=";
          }
          filters.push(`${key}${operator}${value}`);
        }
      });
    }

    return filters.join(" AND ");
  }

  static getFirestoreWheres(query, wheres) {
    if (wheres && Object.keys(wheres).length > 0) {
      Object.keys(wheres).forEach(key => {
        if (wheres[key]) {
          query = query.where(String(key), "==", wheres[key]);
        } else {
          if (parseInt(wheres[key], 10) === 0) {
            query = query.where(String(key), "==", 0);
          } else {
            query = query.where(String(key), "==", false);
          }
        }
      });
    }

    return query;
  }
}

export default DataFunctions;
