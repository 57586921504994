import React from "react";
import StyledRow from "../../../../../../../CMS/styles/View/List/Row";
import Button from "../../../../../../../CMS/components/Button";
import Field from "../../../../../../../CMS/styles/Field";

export default ({ onClick, doc }) => {
  const data = doc.data();
  return (
    <StyledRow>
      <div className="infos">
        <span className="name" onClick={onClick}>
          {data.libelle}
        </span>
      </div>
      <Field noMarginTop className="field-actions">
        <div className="has-addons">
          {doc.id && (
            <Button
              smallIcon
              id={doc.id}
              type="id"
              className="no-left-radius"
            />
          )}
          <Button smallIcon icon="fa-edit" onClick={onClick} />
        </div>
      </Field>
    </StyledRow>
  );
};
