const organisationMenu = [
  [
    {
      name: "Tableau de bord",
      type: "link",
      to: "/organisation/tdb"
    },
    {
      name: "Intras",
      type: "link",
      to: "/organisation/intras"
    },
    {
      name: "Projets",
      type: "link",
      to: "/organisation/projets"
    },
    {
      name: "Références",
      type: "link",
      to: "/organisation/references"
    }
  ]
];

const planningMenu = [
  [
    {
      name: "Tableau de bord",
      type: "link",
      to: "/planning/tdb"
    },
    {
      name: "Fiches",
      type: "link",
      to: "/planning/fiches"
    },
    {
      name: "Projets",
      type: "link",
      to: "/planning/projets"
    }
  ]
];

export default [
  {
    state: "organisation",
    name: "Méthode GTD",
    type: "link",
    className: "separator",
    to: "/organisation/tdb",
    menu: organisationMenu,
    profil: "organisation",
    acl: {
      admin: true
    }
  },
  {
    state: "planning",
    name: "Planning",
    type: "link",
    className: "separator",
    to: "/planning/tdb",
    menu: planningMenu,
    profil: "planning",
    acl: {
      admin: true
    }
  },
  {
    state: "carnet",
    name: "Carnet",
    type: "link",
    className: "separator",
    to: "/carnet",
    profil: "carnet",
    acl: {
      admin: true
    }
  },
  {
    type: "spacer",
    acl: {
      "*": true
    }
  }
];
