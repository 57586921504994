import React from "react";
import PropTypes from "prop-types";
import Select from "./Select";

const Data = props => {
  const { type, ...others } = props;

  switch (type) {
    case "select":
      return <Select {...others} />;

    default:
  }

  return <div>Espace</div>;
};

Data.propTypes = {
  type: PropTypes.string.isRequired
};

export default Data;
