const contactsMenu = [
  [
    {
      name: "Tous les contacts",
      type: "link",
      to: "/boutique/contacts"
    },
    // {
    //   name: "Groupes",
    //   type: "link",
    //   to: "/boutique/groupes"
    // },
    {
      name: "Campagnes",
      type: "link",
      to: "/boutique/campagnes"
    }
    // {
    //   name: "Modèles",
    //   type: "link",
    //   to: "/communication/modeles"
    // }
  ]
];

const catalogueMenu = [
  [
    {
      name: "Produits",
      type: "link",
      to: "/boutique/produits"
    },
    {
      name: "Modèles",
      type: "link",
      to: "/boutique/modeles"
    },
    {
      name: "Services",
      type: "link",
      to: "/boutique/services"
    },
    {
      type: "separator"
    },
    {
      name: "Caractéristiques",
      type: "link",
      to: "/boutique/caracteristiques"
    },
    {
      name: "Catégories",
      type: "link",
      to: "/boutique/categories"
    }
  ]
];

const comptaMenu = [
  [
    {
      name: "Dépenses",
      type: "link",
      to: "/boutique/depenses"
    },
    {
      name: "Ventes",
      type: "link",
      to: "/boutique/ventes"
    }
  ]
];

const paramsMenu = [
  [
    {
      name: "Boutique",
      type: "link",
      to: "/boutique/params"
    },
    {
      name: "Fournisseurs",
      type: "link",
      to: "/boutique/fournisseurs"
    }
  ]
];

export default [
  {
    state: "boutique-catalogue",
    name: "Catalogue",
    type: "link",
    className: "separator",
    to: "/boutique/produits",
    profil: "boutique-catalogue",
    menu: catalogueMenu,
    acl: {
      admin: true
    }
  },
  {
    type: "separator",
    profil: "boutique-contacts",
    acl: {
      admin: true
    }
  },
  {
    state: "boutique-contacts",
    name: "Contacts",
    type: "link",
    className: "separator",
    to: "/boutique/contacts",
    profil: "boutique-contacts",
    menu: contactsMenu,
    acl: {
      admin: true
    }
  },
  {
    state: "boutique-commandes",
    name: "Commandes",
    type: "link",
    className: "separator",
    to: "/boutique/commandes",
    profil: "boutique-commandes",
    acl: {
      admin: true
    }
  },
  {
    state: "boutique-abonnements",
    name: "Abonnements",
    type: "link",
    className: "separator",
    to: "/boutique/abonnements",
    profil: "boutique-abonnements",
    acl: {
      admin: true
    }
  },
  {
    type: "separator",
    profil: "boutique-demandes",
    acl: {
      admin: true
    }
  },
  {
    state: "boutique-demandes",
    name: "Demandes",
    type: "link",
    className: "separator",
    profil: "boutique-demandes",
    to: "/boutique/demandes",
    acl: {
      admin: true
    }
  },
  {
    type: "separator",
    profil: "boutique-compta",
    acl: {
      admin: true
    }
  },
  {
    state: "boutique-compta",
    name: "Comptabilité",
    type: "link",
    className: "separator",
    profil: "boutique-compta",
    to: "/boutique/ventes",
    menu: comptaMenu,
    acl: {
      admin: true
    }
  },
  {
    type: "spacer",
    acl: {
      "*": true
    }
  },
  {
    state: "boutique-params",
    name: "Paramètres",
    type: "link",
    className: "separator",
    to: "/boutique/params",
    menu: paramsMenu,
    profil: "boutique-params",
    acl: {
      admin: true
    }
  }
];
