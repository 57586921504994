import moment from "moment";
import {
  DEMANDES_REQUEST_LIST,
  API_ERROR,
  API_SUCCESS
} from "../../../Boutique/actionTypes";
import { getFirestore } from "../../../Boutique/functions";

function isEmail(mail) {
  // La 1ère étape consiste à définir l'expression régulière d'une adresse email
  let regEmail = new RegExp(
    "^[0-9a-z._-]+@{1}[0-9a-z.-]{2,}[.]{1}[a-z]{2,5}$",
    "i"
  );

  return regEmail.test(mail);
}

// /**
//  * Cette fonction permet de vérifier que le numéro de telephone est valide
//  *
//  * TODO améliorer cette fonction qui est trop simpliste
//  *
//  * @param phone
//  * @returns {boolean}
//  */
// function isPhone(phone) {
//     if(!phone || phone === '') {
//         return false;
//     }
//
//     let chiffres = String(phone);
//     chiffres = chiffres.replace(/[^0-9]/g, '');
//
//     return chiffres.length === 10;
// }

export function loadDemandeByContactId(boutique, contactId) {
  return dispatch => {
    let query = getFirestore(boutique).collection("demandes");
    //TODO voir pour ajouter le where deleted == false
    return query
      .where("boutique", "==", boutique.id)
      .where("contact", "==", contactId)
      .orderBy("createdAt", "desc")
      .get()
      .then(querySnapshot => {
        return dispatch({
          type: DEMANDES_REQUEST_LIST,
          demandes: querySnapshot.docs
        });
      });
  };
}

export function importNewRequests(boutique) {
  return dispatch => {
    // a. On récupére le nombre de demandes pour la journée
    const debut = moment().format("YYWWE");
    let numero = 1;
    let query = getFirestore(boutique).collection("demandes");
    return query
      .where("numero", ">", debut)
      .where("boutique", "==", boutique.id)
      .orderBy("numero")
      .get()
      .then(querySnapshot => {
        if (querySnapshot.size > 0) {
          numero =
            parseInt(
              querySnapshot.docs[querySnapshot.size - 1]
                .data()
                .numero.substr(-3),
              10
            ) + 1;
        }

        // a.2. On récupére les demandes en attente
        query = getFirestore(boutique).collection("demandes");
        return query
          .where("hasResponse", "==", false)
          .where("boutique", "==", boutique.id)
          .where("deleted", "==", false)
          .get()
          .then(demandeSnapshot => {
            let demandes = {};
            let mail, id;

            if (demandeSnapshot.size > 0) {
              // Il y a des demandes en attentes
              demandeSnapshot.docs.forEach(demande => {
                const id = demande.data().contact
                  ? demande.data().contact
                  : demande.data().contact_email;
                demandes[id] = [];
                demandes[id].requests = demande.data().requests;
                demandes[id].ref = demande.ref;
              });
            }

            // b. On traite toutes les requests
            // TODO temporiser les datas du schema 5 minutes pour laisser le temps au traitement de faire son boulot
            return boutique
              .get("requestSchema")
              .collection("datas")
              .where("deleted", "==", false)
              .orderBy("createdAt", "asc")
              .get()
              .then(querySnapshot => {
                let data;

                if (querySnapshot.size > 0) {
                  // Il faut traiter toutes les demandes qui sont en résultat
                  querySnapshot.docs.forEach(document => {
                    // 1. On recherche si il n'y a pas de demandes en cours pour la même personne
                    // Le champs contact_email est obligatoire
                    data = document.data();
                    if (data["contact_email"]) {
                      mail = data["contact_email"].trim().toLowerCase();
                      // On test si l'adresse mail est bien une adresse mail valide
                      if (!mail || !isEmail(mail)) {
                        // La demande n'est pas correctement saisie, on ne fait rien
                        return;
                      }
                      id = mail;

                      // On test si le prospect n'existe déjà pas dans la liste des demandes
                      console.info(id, demandes);
                      if (!demandes[id]) {
                        demandes[id] = {};
                        demandes[id]["requests"] = [];
                        demandes[id].contact_email = mail;
                        demandes[id].requestedAt = data.createdAt;
                        demandes[id].createdAt = moment().toISOString();
                        demandes[id].numero =
                          debut + ("000" + numero).slice(-3);
                        demandes[id].hasResponse = false;
                        demandes[id].deleted = false;
                        demandes[id].boutique = boutique.id;
                        numero++;
                      }

                      if (data["contact_firstname"]) {
                        demandes[id]["contact_firstname"] =
                          data["contact_firstname"];
                      }
                      if (data["contact_lastname"]) {
                        demandes[id]["contact_lastname"] =
                          data["contact_lastname"];
                      }

                      if (data["contact_phone"]) {
                        demandes[id]["contact_phone"] = data["contact_phone"];
                      }
                      if (data["contact_entreprise"]) {
                        demandes[id]["contact_entreprise"] =
                          data["contact_entreprise"];
                      }
                      if (data["contact_zip"]) {
                        demandes[id]["contact_zip"] = data["contact_zip"];
                      }
                      if (data["contact_city"]) {
                        demandes[id]["contact_city"] = data["contact_city"];
                      }
                      if (data["contact_country"]) {
                        demandes[id]["contact_country"] =
                          data["contact_country"];
                      }
                      if (data["contact_newsletter"]) {
                        demandes[id]["contact_newsletter"] = true;
                      }

                      // TODO importer les champs qui sont utilisé dans le schema
                      const tmp = {};
                      if (data.content) {
                        tmp["content"] = data.content;
                      }
                      if (data.produit_id) {
                        tmp["produit_id"] = data.produit_id;
                      }
                      if (Object.keys(tmp).length > 0) {
                        demandes[id]["requests"].push(tmp);
                      }
                    }
                  });

                  let batch = getFirestore(boutique).batch();

                  // 2. On crée les nouvelles demandes
                  Object.keys(demandes).forEach(key => {
                    let ref = demandes[key].ref
                      ? demandes[key].ref
                      : getFirestore(boutique)
                          .collection("demandes")
                          .doc();

                    delete demandes[key].ref;

                    batch.set(
                      ref,
                      {
                        ...demandes[key]
                      },
                      { merge: true }
                    );
                  });

                  let batchSandwich = getFirestore().batch();

                  // 3. On supprime les requests
                  querySnapshot.docs.forEach(document => {
                    batchSandwich.delete(document.ref);
                  });

                  batchSandwich.commit();

                  return batch.commit().then(function() {
                    return dispatch({
                      type: DEMANDES_REQUEST_LIST,
                      demandes: demandes
                    });
                  });
                }
              });
          });
      });
  };
}

/**
 * Envoi de la demande de prix par mail
 *
 * @param demandeId
 * @param boutique
 * @returns {Promise<Response | {payload: *, type: *, error: boolean}>}
 */
export function sendAnswer(demandeId, boutique) {
  return fetch(process.env.REACT_APP_URL_API, {
    method: "POST",
    body: JSON.stringify({
      type: "send",
      collection: "demandes",
      demandeId: demandeId
    }),
    headers: {
      "X-Application-Id": boutique.get("applicationId")
    }
  })
    .then(function(response) {
      if (response.status === 200) {
        return {
          type: API_SUCCESS,
          payload: response.json()
        };
      }
      return {
        type: API_ERROR,
        error: true,
        payload: response.text()
      };
    })
    .catch(error => {
      return {
        type: API_ERROR,
        error: true,
        payload: new Promise(() => error.message)
      };
    });
}
