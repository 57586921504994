import React from 'react';

const Split = (props) => {
    return (
        <div className="cms-view-split">
            {props.children}
            {/*<div className="column">*/}
                {/*{props.children[0]}*/}
            {/*</div>*/}
            {/*<div className="column">*/}
                {/*{props.children[1]}*/}
            {/*</div>*/}
        </div>
    )
};

export default Split;
