import React, { Component } from "react";
import moment from "moment";
import StyledList from "../../styles/Requests/List";
import StyledTitle from "../../../../../CMS/styles/Title";
import Group from "../../../../../CMS/components/Data/Group";
// import { formValueSelector } from "redux-form";
import { connect } from "react-redux";

class Commande extends Component {
  render() {
    const {
      demande: { orderedAt, commande_message, commande_produits }
    } = this.props;

    if (!commande_produits) {
      return null;
    }

    return (
      <div>
        <div>
          La commande a été enregistrée le{" "}
          <span
            style={{
              display: "block",
              fontStyle: "italic",
              fontWeight: "bold"
            }}
          >
            {moment(orderedAt).format("dddd Do MMMM YYYY à H:mm")}.
          </span>
        </div>
        {commande_message && (
          <div style={{ margin: "1.5rem 0 0 0" }}>
            <StyledTitle>Message</StyledTitle>
            <Group>
              <p>{commande_message}</p>
            </Group>
          </div>
        )}

        <div style={{ margin: "1.5rem 0 3rem 0" }}>
          <StyledTitle>Produits</StyledTitle>
          <StyledList>
            {commande_produits.map((produit, index) => {
              let unite = "";

              switch (produit.unite) {
                case "paire":
                  unite = "la paire";
                  break;

                case "lot":
                  unite = "le lot";
                  break;

                case "piece":
                default:
                  unite = "pièce";
              }

              return (
                <li key={index}>
                  <div>
                    {produit.photo && (
                      <img
                        className="produit-photo"
                        alt={produit.name}
                        src={`${process.env.REACT_APP_CDN_DOMAIN_NAME}/${
                          produit.photo
                        }`}
                      />
                    )}
                  </div>
                  <div className="demande">
                    <ul className="produit">
                      <li className="title">{produit.name}</li>
                      <li>Code produit : #{produit.sku}</li>
                      <li>{produit.dimensions}</li>
                    </ul>
                  </div>
                  <div>
                    <div className="prix">
                      {produit.prix}€ {unite}
                    </div>
                  </div>
                </li>
              );
            })}
          </StyledList>
        </div>
      </div>
    );
  }
}

// const selector = formValueSelector("item");

export default connect((state, props) => {
  return {
    demande: state.form.item.values
  };
})(Commande);
