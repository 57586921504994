import { connect } from "react-redux";
import List from "../../../../../../CMS/containers/Boutique/components/List";
import Item from "./Item";
import page from "../params.json";

import { initialize, validate } from "../functions";

import {
  INSTAGRAM_POSTS_WATCH,
  INSTAGRAM_POST_CREATE,
  INSTAGRAM_POST_UPDATE,
  INSTAGRAM_POST_REMOVE,
  INSTAGRAM_POSTS_RESET
} from "../../../../../actionTypes";

class InstagramList extends List {
  actionTypeWatch = INSTAGRAM_POSTS_WATCH;
  actionTypeCreate = INSTAGRAM_POST_CREATE;
  actionTypeUpdate = INSTAGRAM_POST_UPDATE;
  actionTypeRemove = INSTAGRAM_POST_REMOVE;
  actionTypeReset = INSTAGRAM_POSTS_RESET;
  collectionName = "instagram_posts";
  defaultOrderField = "title";
  defaultOrderDirection = "asc";
  listTitle = "Posts d'Instagram";
  viewList = Item;
  itemTitle = { type: "attribut", attribut: "description" };
  initializeItem = initialize;
  validateItem = validate;
  itemParams = page;
  messageNoDocument = "Aucun post";
  // listStyle = StyledList;

  constructor(props) {
    super(props, {
      newItemLibelle: "Nouveau post"
    });

    this.state.form.required = ["description"];

    this.state.list.menu.left = [
      {
        text: "Ajouter",
        onClick: this.handleCreate
      }
    ];
  }
}

const mapStateToProps = (globalState, ownProps) => {
  const props = {};

  props.docs = globalState.boutique.newsletters;

  return props;
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InstagramList);
