import React, { Component } from "react";
import PropTypes from "prop-types";
import StyledTabs from "../../styles/Tabs";

import CMSData from "./index";
import { connect } from "react-redux";

const Menu = props => {
  const { tabs, handleSetTab, actif } = props;

  return (
    <StyledTabs className="tabs is-boxed">
      <ul>
        {tabs.map((tab, index) => (
          <li key={index} className={tab.tabIndex === actif ? "is-active" : ""}>
            <button data-index={tab.tabIndex} onClick={handleSetTab}>
              {tab.name}
            </button>
          </li>
        ))}
      </ul>
    </StyledTabs>
  );
};

class Tabs extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tab: 0
    };

    this.handleSetTab = this.handleSetTab.bind(this);
  }

  handleSetTab = event => {
    event.preventDefault();
    const tab = event.currentTarget.getAttribute("data-index");
    this.setState({
      tab: parseInt(tab, 10)
    });
  };

  render() {
    let tabs = [];
    let tab = this.state.tab === 0 ? undefined : this.state.tab;
    this.props.tabs.forEach((title, index) => {
      if (typeof title === "string") {
        tabs.push({
          name: title,
          tabIndex: index
        });
        if (tab === undefined) {
          tab = index;
        }
      } else {
        if (title.condition && title.condition.attribut) {
          // if (this.props[title.condition.attribut]) {
          switch (title.condition.operator) {
            case "!=":
              if (
                this.props[title.condition.attribut] !== title.condition.value
              ) {
                tabs.push({
                  ...title,
                  tabIndex: index
                });
                if (tab === undefined) {
                  tab = index;
                }
              }
              break;

            case "==":
            default:
              if (
                this.props[title.condition.attribut] === title.condition.value
              ) {
                tabs.push({
                  ...title,
                  tabIndex: index
                });
                if (tab === undefined) {
                  tab = index;
                }
              }
            // }
          }
        } else {
          tabs.push({
            ...title,
            tabIndex: index
          });
          if (tab === undefined) {
            tab = index;
          }
        }
      }
    });

    const child = this.props.children[tab];
    return (
      <div className="cms__component--tabs">
        <Menu
          tabs={tabs}
          actif={this.state.tab}
          handleSetTab={this.handleSetTab}
        />
        <CMSData {...child.props} />
      </div>
    );
  }
}

Tabs.propTypes = {
  tabs: PropTypes.array.isRequired
};

export default connect(state => {
  return { ...state.form.item.values };
})(Tabs);
