import React from "react";
import Button from "../../../../../CMS/components/Button";
import List from "../../../../../CMS/containers/Boutique/components/List";
import ActionBar from "../../../../../CMS/components/ActionBar";
import Item from "./Item";
import media from "../../params.json";
import Modal from "../../../../../CMS/components/View/Modal";
// import { initialize, validate } from "../../functions";
import Medias from "../../../../../CMS/components/Medias/";

class MediasList extends List {
  collectionName = "medias";
  defaultOrderField = "createdAt";
  defaultOrderDirection = "desc";
  listTitle = "Médias";
  viewList = Item;
  itemTitle = { type: "attribut", attribut: "name" };
  // initializeItem = initialize;
  // validateItem = validate;
  itemParams = media;
  messageNoDocument = "Aucun média";

  constructor(props) {
    super(props, {
      newItemLibelle: "Nouveau média",
      custom: {
        openModal: false
      }
    });

    this.handleUploadNewMedias = this.handleUploadNewMedias.bind(this);
    this.handleOnChangeMedias = this.handleOnChangeMedias.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);

    this.state.form.required = ["name", "fullPath"];

    this.state.list.menu = {};
    this.state.form.menu.left = [
      {
        className: "delete",
        icon: "fa-trash",
        onClick: this.handleDelete
      },
      {
        className: "next",
        icon: "fa-chevron-right",
        onClick: this.handleNext
      }
    ];
    this.state.form.menu.right = [
      {
        icon: "fa-close",
        onClick: this.handleClose
      }
    ];
  }

  loadAlternativeFunc(boutique) {
    // this.props.searchCategories(boutique).then(action => {
    //   this.setState(oldState => {
    //     oldState.custom.categories = action.payload.docs;
    //     return oldState;
    //   });
    // });
  }

  handleUploadNewMedias(e) {
    e.preventDefault();
    this.setState(oldState => {
      oldState.custom.openModal = true;

      return oldState;
    });
  }

  handleCloseModal(e) {
    e.preventDefault();

    this.setState(oldState => {
      oldState.custom.openModal = false;

      return oldState;
    });
  }

  handleOnChangeMedias(values) {
    console.info("handleOnChangeMedias", values);
  }

  addActionBarToRender() {
    return (
      <ActionBar className="action-bar is-clearfix">
        <ul>
          <li className="navbar-item">
            <Button icon="fa-plus" onClick={this.handleUploadNewMedias} />
          </li>
        </ul>

        <ul className="border-left navbar-right">
          <li className="navbar-item nbByRow">
            <input
              type="number"
              value={this.state.nbParLigne}
              onChange={this.handleOnChangeNbParLigne}
            />
          </li>
        </ul>
      </ActionBar>
    );
  }

  addChildrenBeforeToRender() {
    if (!this.state.custom.openModal) {
      return null;
    }

    return (
      <Modal
        title="Ajout de nouveaux médias"
        handleClose={this.handleCloseModal}
      >
        <Medias
          items={[]}
          updateItems={value => this.handleOnChangeMedias(value)}
          boutique={this.props.boutique}
          title="Médias"
          addText="Ajouter une document"
          uploadFilesText="Déposer vos documents pour les télécharger"
        />
      </Modal>
    );
  }
}

export default MediasList;
