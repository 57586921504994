import React, { Component } from "react";
import defaultPhoto from "../../../../../../images/next-photo.svg";
import Messages from "../../../../../CMS/components/Messages/index";
import moment from "moment";
import {
  createQuery,
  getDomainAssetsURL
} from "../../../../../Boutique/functions";

class Demande extends Component {
  state = {
    produitId: null,
    produitData: null,
    produitMustBeLoaded: false,
    produitLoadingInProgress: false
  };

  constructor(props) {
    super(props);

    this.loadProduit = this.loadProduit.bind(this);
    this.handleChangeValue = this.handleChangeValue.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    if (
      props.item.produit_id &&
      (!state.produitId ||
        (state.produitId && state.produitId !== props.item.produit_id))
    ) {
      state.produitMustBeLoaded = true;
      state.produitLoadingInProgress = false;
      state.produitId = props.item.produit_id;
    }

    return state;
  }

  componentDidMount() {
    if (
      this.state.produitMustBeLoaded &&
      !this.state.produitLoadingInProgress
    ) {
      this.loadProduit(this.props.boutique, this.state.produitId);
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      this.state.produitMustBeLoaded &&
      !this.state.produitLoadingInProgress
    ) {
      this.loadProduit(this.props.boutique, this.state.produitId);
    }
  }

  loadProduit(boutique, produitId) {
    console.info("loadProduit boutique", boutique);
    this.setState({
      produitLoadingInProgress: true
    });

    createQuery(boutique, "produits")
      .doc(produitId)
      .get()
      .then(snapshot => {
        let data;
        if (snapshot.exists && !snapshot.data().deleted) {
          const produit = snapshot.data();

          data = {
            ...produit
          };

          if (!this.props.item.produit_name) {
            let photo = defaultPhoto;
            if (produit.photos && produit.photos.length > 0) {
              // photo = `${produit.photos[0]}?w=320&h=320&c=max`;
              // TODO On ajout les options directement lors de l'affichage
              photo = `${produit.photos[0]}`;
            }

            let prixDeVente, prixBarre;
            prixDeVente = produit.salePrice ? produit.salePrice : produit.price;

            if (
              (!produit.salePrice && produit.retailPrice) ||
              produit.salePrice
            ) {
              prixBarre = produit.salePrice
                ? produit.price
                : produit.retailPrice;
            }

            let volume = 0;
            if (produit.diametre) {
              // Le produit est rond
              volume =
                Math.ceil(
                  (produit.height * produit.diametre * produit.diametre) /
                    100000
                ) / 10;
            } else {
              volume =
                Math.ceil(
                  (produit.height * produit.width * produit.depth) / 100000
                ) / 10;
            }

            this.props.handleUpdateField(this.props.name, {
              produit_name: produit.name,
              produit_photo: photo,
              produit_prixDeVente: prixDeVente,
              produit_width: produit.width,
              produit_depth: produit.depth,
              produit_height: produit.height,
              produit_diametre: produit.diametre,
              produit_volume: volume,
              produit_option: produit.optionPrice
                ? produit.optionPrice
                : "priceRequest",
              produit_unite: produit.unitPrice ? produit.unitPrice : "piece",
              produit_prixBarre: prixBarre,
              produit_pathname: produit.pathname
            });
          }
        }

        this.setState({
          produitData: data,
          produitMustBeLoaded: false,
          produitLoadingInProgress: false
        });
      });
  }

  handleChangeValue(e) {
    e.preventDefault();
    const name = e.currentTarget.name;
    const value = e.currentTarget.value;

    console.info(name, value);

    this.props.handleUpdateField(this.props.name, {
      [name]: value
    });
  }

  render() {
    const { item } = this.props;

    const messages = [];

    if (item.message) {
      messages.push({
        text: item.message
      });
    } else {
      if (item.content) {
        messages.push({
          text: item.content
        });
      }
    }

    const produit = this.state.produitData;
    return (
      <li className="demande">
        {item.produit_name && (
          <div className="produit">
            {item.produit_photo && (
              <img
                className="photo"
                alt={item.produit_name}
                src={`${getDomainAssetsURL(this.props.boutique)}/${
                  item.produit_photo
                }`}
              />
            )}

            <ul>
              <li>{item.produit_name}</li>
              <li>
                <ul>
                  {produit && (
                    <React.Fragment>
                      <li>
                        {item.produit_option === "priceRequest"
                          ? "Demande de prix"
                          : "Demande de disponibilité"}
                      </li>
                      <li>
                        <span>
                          {produit.salePrice > 0
                            ? produit.salePrice
                            : produit.price}{" "}
                          €
                        </span>
                        {produit.salePrice ||
                          (produit.retailPrice && (
                            <span>
                              {produit.retailPrice > 0
                                ? produit.retailPrice
                                : produit.price}{" "}
                              €
                            </span>
                          ))}
                      </li>
                      {produit.diametre ? (
                        <li>
                          Dimensions : {produit.diametre} cm de diamètre sur{" "}
                          {produit.height} cm (
                          {Math.ceil(
                            (produit.height *
                              produit.diametre *
                              produit.diametre) /
                              100000
                          ) / 10}{" "}
                          m<sup>3</sup>)
                        </li>
                      ) : (
                        <li>
                          Dimensions : {produit.depth} x {produit.width} sur{" "}
                          {produit.height} cm (
                          {Math.ceil(
                            (produit.height * produit.width * produit.depth) /
                              100000
                          ) / 10}{" "}
                          m<sup>3</sup>)
                        </li>
                      )}
                      <li className="stock">
                        {produit.hasInventory ? (
                          parseInt(produit.qtyAvailable, 10) > 0 ? (
                            `${produit.qtyAvailable} pièce${
                              produit.qtyAvailable > 1 ? "s" : ""
                            } en stock`
                          ) : (
                            <span className="rupture-stock">
                              En rupture de stock
                            </span>
                          )
                        ) : (
                          "A fabriquer ou à commander"
                        )}
                      </li>

                      {produit.min > 1 && (
                        <li>Quantité minimale : {produit.min}</li>
                      )}
                      <li>
                        créé le{" "}
                        {moment(produit.createdAt).format("dddd Do MMMM YYYY")}
                      </li>
                    </React.Fragment>
                  )}
                </ul>
              </li>
            </ul>
            <div className="actions" style={{ marginLeft: "0.75rem" }}>
              {produit &&
                (Boolean(produit.qtyAvailable) || !produit.hasInventory) && (
                  <div
                    className="prix"
                    style={{ textAlign: "right", marginBottom: "0.75rem" }}
                  >
                    <input
                      type="number"
                      name="produit_prixDeVente"
                      value={item.produit_prixDeVente}
                      onChange={this.handleChangeValue}
                    />
                    <select
                      name="produit_unite"
                      value={item.produit_unite}
                      onChange={this.handleChangeValue}
                    >
                      <option value="piece">Pièce</option>
                      <option value="paire">La paire</option>
                      <option value="lot">Le lot</option>
                    </select>
                  </div>
                )}

              <button
                className="supprimer"
                onClick={this.props.handleDeleteField}
              >
                <span>Supprimer</span>
              </button>
            </div>
          </div>
        )}

        <div className="messages">
          <Messages items={messages} />
        </div>
      </li>
    );
  }
}

export default Demande;
