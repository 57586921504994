import React, { Component } from "react";
import { changeIndex } from "../Boutique/actions";
import { connect } from "react-redux";
import styled from "styled-components";
import { navigate } from "@reach/router";

const StyledSelect = styled.div`
  position: relative;
  //background: #44414e;
  box-sizing: border-box;

  select {
    -moz-appearance: none;
    -webkit-appearance: none;
    border: none;
    background: none;
    position: relative;
    cursor: pointer;
    display: block;
    outline: none;
    font-weight: 300;

    height: 35px;
    padding: 0 21px;
    box-sizing: border-box;
    line-height: 20px;
    color: #e1e6eb;
    width: ${props => (props.theme.mobile ? "100%" : "149px")};

    option {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      display: block;
      width: ${props => (props.theme.mobile ? "100%" : "149px")};
    }
  }

  &:after {
    //@include arrow(#000);
    border: 1px solid #e1e6eb;
    border-right: 0;
    border-top: 0;
    content: " ";
    display: block;
    height: 0.5em;
    pointer-events: none;
    position: absolute;
    transform: rotate(-45deg);
    width: 0.5em;
    margin-top: -0.375em;
    right: 1.125em;
    top: 50%;
    z-index: 4;
  }
`;
class Boutiques extends Component {
  constructor(props) {
    super(props);

    this.state = {
      indexBoutique: null
    };

    this.handleOnChange = this.handleOnChange.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    if (
      (!state.indexBoutique && props.indexBoutique) ||
      (state.indexBoutique &&
        props.indexBoutique &&
        state.indexBoutique !== props.indexBoutique)
    ) {
      state.indexBoutique = props.indexBoutique;
    }

    return state;
  }

  handleOnChange(event) {
    this.props.changeBoutique(event.target.value);
    navigate("/dashboard");
  }

  render() {
    if (!this.props.boutiques) return null;

    if (this.props.boutiques.length === 0) {
      return <button>Créer une boutique</button>;
    }

    return (
      <StyledSelect>
        <select
          onChange={this.handleOnChange}
          defaultValue={this.state.indexBoutique}
        >
          {this.props.boutiques.map((boutique, index) => (
            <option key={index} value={index}>
              {boutique.data().libelle
                ? `${boutique.data().libelle} - ${boutique.data().name}`
                : boutique.data().name}
            </option>
          ))}
        </select>
      </StyledSelect>
    );
  }
}

const mapStateToProps = (globalState, ownProps) => {
  const props = {};
  props.boutiques = globalState.cms.boutiques;
  props.indexBoutique = globalState.cms.indexBoutique;

  return props;
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    changeBoutique: index => dispatch(changeIndex(index))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Boutiques);
