import React, { Component } from "react";
import Input from "../../../../../../CMS/components/Data/Input/index";
import Field from "../../../../../../CMS/styles/Field";
import Button from "../../../../../../CMS/components/Button/index";
import styled from "styled-components";
import { change } from "redux-form";
import moment from "moment";

const StyledAutoComplete = styled.ul`
  top: 37px;
  width: 100%;
  list-style: none;
  position: absolute;
  margin: 0;
  font-size: 13px;
  padding: 0.375rem 0;
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
  z-index: 1000;
  color: black;

  li {
    margin: 0;
    padding: 0.75rem;
    cursor: pointer;

    &:hover {
      background: #eee;
    }
  }
`;

const AutoComplete = ({ values, onClick }) => (
  <StyledAutoComplete>
    {values.map((value, index) => (
      <li key={index} onClick={e => onClick(e, value)}>
        {value.data().libelle}
      </li>
    ))}
  </StyledAutoComplete>
);

class Item extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: undefined,
      mustBeLoaded: false,
      feature: undefined,
      exists: Boolean(this.props.data.exists),
      values: null,
      foundValues: null,
      showFoundValues: false,
      valeur: this.props.data.valeur
    };

    this.loadValeurs = this.loadValeurs.bind(this);
    this.handleOnChange = this.handleOnChange.bind(this);
    this.handleOnAutoComplete = this.handleOnAutoComplete.bind(this);
    this.handleAddValue = this.handleAddValue.bind(this);
    this.handleToggleValues = this.handleToggleValues.bind(this);
  }

  componentDidMount() {
    if (this.state.mustBeLoaded) {
      this.loadValeurs();
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.state.mustBeLoaded) {
      this.loadValeurs();
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (
      props.data &&
      props.data.id &&
      (!state.id || props.data.id !== state.id)
    ) {
      state.id = props.data.id;
      state.feature = undefined;
      state.mustBeLoaded = true;
    }

    return state;
  }

  loadValeurs() {
    this.setState({
      mustBeLoaded: false
    });

    this.props.boutique.ref
      .collection("productFeatures")
      .doc(this.state.id)
      .get()
      .then(document => {
        document.ref
          .collection("valeurs")
          .orderBy("libelle")
          .get()
          .then(querySnapshot => {
            this.setState({
              values: querySnapshot.docs
            });
          });
        this.setState({
          feature: document
        });
      });
  }

  handleOnChange(e) {
    e.preventDefault();
    const value = e.currentTarget.value;

    let foundValues = null;
    if (this.state.values && this.state.values.length > 0) {
      foundValues = this.state.values.filter(doc => {
        return doc
          .data()
          .libelle.toLowerCase()
          .includes(value.toLowerCase());
      });
    }

    this.setState({
      foundValues: foundValues,
      showFoundValues: !!foundValues,
      valeur: value,
      exists: false
    });

    this.props.dispatch(change("item", e.currentTarget.name, value));
    this.props.dispatch(change("item", `${this.props.name}.exists`, false));
  }

  handleOnAutoComplete(e, value) {
    const valeur = value.data().libelle;
    this.props.dispatch(change("item", `${this.props.name}.valeur`, valeur));
    this.props.dispatch(change("item", `${this.props.name}.exists`, true));
    this.setState({
      showFoundValues: false,
      valeur: valeur,
      foundValues: null,
      exists: true
    });
  }

  handleAddValue(e) {
    e.preventDefault();
    if (this.props.docRef) {
      this.props.docRef
        .collection("valeurs")
        .add({
          libelle: this.state.valeur,
          deleted: false,
          createdAt: moment().toISOString()
        })
        .then(() => {
          this.loadValeurs();
          this.setState({
            exists: true
          });
          this.props.dispatch(
            change("item", `${this.props.name}.exists`, true)
          );
        });
    }
  }

  handleToggleValues(e) {
    e.preventDefault();

    this.setState(oldState => {
      return {
        foundValues: oldState.showFoundValues ? null : oldState.values,
        showFoundValues: !oldState.showFoundValues
      };
    });
  }

  render() {
    const { name, data, handleRemove } = this.props;

    return (
      <div style={{ display: "flex" }} className="cms__component--columns">
        <Input
          type="text"
          name={`${name}.libelle`}
          placeholder="Libellé"
          label="Libellé"
          readOnly={!!data.id}
          className={
            "is-expanded no-margin-right no-margin-bottom no-border-right no-right-radius"
          }
        />
        <Field
          className="is-expanded"
          noMarginRight
          noMarginLeft
          noLeftRadius
          noMarginBottom
          noBorderRight
          noRightRadius
        >
          <input
            name={`${name}.valeur`}
            type="text"
            placeholder="Valeur"
            value={this.state.valeur}
            onChange={this.handleOnChange}
            autoComplete="off"
          />
          {this.state.showFoundValues &&
            this.state.foundValues &&
            this.state.foundValues.length > 0 && (
              <AutoComplete
                values={this.state.foundValues}
                onClick={this.handleOnAutoComplete}
              />
            )}
          <label className="label">
            <span>Valeur</span>
          </label>
        </Field>
        <Field noMarginLeft noMarginBottom>
          <div className="has-addons">
            {data.id && (
              <Button
                smallIcon
                id={data.id}
                type="id"
                className="no-left-radius"
              />
            )}
            {data.id && this.state.values && this.state.values.length > 0 && (
              <Button
                smallIcon
                icon="fa-database"
                onClick={this.handleToggleValues}
              />
            )}
            {data.id &&
              this.props.docRef &&
              this.state.valeur &&
              !this.state.exists && (
                <Button
                  smallIcon
                  icon="fa-plus-square"
                  onClick={this.handleAddValue}
                />
              )}
            <Button
              smallIcon
              icon="fa-trash"
              onClick={handleRemove}
              className={!data.id && "no-left-radius"}
            />
          </div>
        </Field>
      </div>
    );
  }
}

export default Item;
