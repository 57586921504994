import React, {Component} from 'react';
import {connect} from 'react-redux';

import CMSList from '../../CMS/components/View/List';
import CMSForm from '../../CMS/components/View/Form';
import CMSView from '../../CMS/components/View/index';
import CMSData from '../../CMS/components/Data/index';

import Item from './Item'

import StyledIntras from '../styles/Intras'

import {
    initialize
} from './functions';

import {
    validate
} from './functions';

import intra from './intra.json'

import {
    watch,
    create,
    update,
    remove,
    reset
} from './actions';

class List extends Component {
    constructor(props) {
        super(props);

        this.state = {
            item: null,
            key: null,
            ref: null,
            wheres: {},
            tabIndex: 0
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleCreate = this.handleCreate.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.handleChangeTab = this.handleChangeTab.bind(this);
    }

    componentWillMount() {
        if(this.props.user) {
            this.props.watchIntras(this.props.user, this.state.wheres);
        }
    }

    componentWillReceiveProps(nextProps) {
        if(!this.props.user && nextProps.user) {
            this.props.watchIntras(nextProps.user, this.state.wheres);
        }
    }

    componentWillUnmount() {
        this.props.resetIntras();
    }

    handleCreate = (event) => {
        if(this.props.user) {
            this.setState({
                item: initialize(intra),
                key: null,
                ref: null,
            });
        } else {
            console.error("Il faut pouvoir associé l'intra à un utilisateur");
        }
    };

    handleSubmit = (item) => {
        if(this.state.ref) {
            this.props.updateIntra(this.state.ref, item);
        } else {
            this.props.createIntra(item).then((action) => {
                if(!action.error) {
                    this.setState({
                        item,
                        key: action.payload.id,
                        ref: action.payload
                    });
                }
            });
        }
    };

    handleChange = (index, event) => {
        this.setState({
            item: this.props.intras[index].data(),
            key: this.props.intras[index].id,
            ref: this.props.intras[index].ref
        });
    };

    handleDelete = (event) => {
        //TODO supprimer egalement les documents associés (paiements, etc.)
        const confirmation = window.confirm('Voulez-vous supprimer cet intra ?');
        if(confirmation) {
            this.props.removeIntra(this.state.ref);
            this.setState({
                item: null,
                key: null,
                ref: null
            });
        }
    };

    handleClose = () => {
        this.setState({
            item: null,
            key:null,
            ref: null
        });
    };

    handleChangeTab(event, wheres) {
        event.preventDefault();
        const index = event.currentTarget.getAttribute('data-index');
        this.props.watchDemandes(this.props.user, wheres);
        this.setState({
            wheres,
            tabIndex: parseInt(index, 10)
        });
    }

    render() {
        //TODO Corriger l'erreur de rafraichissement de la liste lors d'ajout ou de suppression par le biais de watch
        let propsList = {
            title: "Intras",
            loading: this.props.loading,
            menu: {
                left: [{
                    text: "Créer",
                    onClick: this.handleCreate
                }]
            }
        };
        let propsForm = {
            initialValues: this.state.item,
            required: ['name']
        };

        if(this.state.item) {
            propsForm.menu = {
                left: [{
                    className: "delete",
                    text: "Supprimer",
                    onClick: this.handleDelete
                }],
                right: [{
                    text: "Fermer",
                    onClick: this.handleClose
                }, {
                    text: "Enregistrer",
                    submit: true
                }]
            };
        };

        return (
            <CMSView className="gtd_intras">
                {this.state.item ? (
                    <CMSForm {...propsForm}
                             title={this.state.key ? {type:'attribut', attribut: 'libelle'} : "Création d'un intra"}
                             onSubmit={this.handleSubmit}
                    >
                        <CMSData data={intra}
                                 id={this.state.key}
                                 user={this.props.user}
                        />
                    </CMSForm>
                ) : (
                    <CMSList {...propsList}>
                        {this.props.intras && this.props.intras.length>0 ? (
                            <StyledIntras>
                                {this.props.intras && this.props.intras.map((doc, index) =>
                                    <Item doc={doc} key={index} onClick={(event) => this.handleChange(index, event)}/>
                                )}
                            </StyledIntras>
                        ) : (
                            <div>Aucun intra</div>
                        )}
                    </CMSList>
                )}
            </CMSView>
        )
    }
}



const mapStateToProps = (globalState, ownProps) => {
    const props = {};

    props.intras = globalState.gtd.intras;

    return props;
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        watchIntras: (user, wheres) => dispatch(watch(user, wheres)),
        createIntra: (item) => {
            return dispatch(create(validate(item), ownProps.user));
        },
        updateIntra: (ref, item) => {
            dispatch(update(ref, validate(item))).then(action => {
                console.log(item);
            });
        },
        removeIntra: (ref) => dispatch(remove(ref)),
        resetIntras: () => dispatch(reset(ownProps.user))
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(List);
