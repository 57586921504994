import { connect } from "react-redux";
import List from "../../../../CMS/containers/Boutique/components/List";
import Item from "./Item";
import page from "../params.json";

import { initialize, validate } from "../functions";

import {
  NEWSLETTERS_WATCH,
  NEWSLETTER_CREATE,
  NEWSLETTER_UPDATE,
  NEWSLETTER_REMOVE,
  NEWSLETTERS_RESET
} from "../../../actionTypes";

class NewsletterList extends List {
  actionTypeWatch = NEWSLETTERS_WATCH;
  actionTypeCreate = NEWSLETTER_CREATE;
  actionTypeUpdate = NEWSLETTER_UPDATE;
  actionTypeRemove = NEWSLETTER_REMOVE;
  actionTypeReset = NEWSLETTERS_RESET;
  collectionName = "newsletters";
  defaultOrderField = "title";
  defaultOrderDirection = "asc";
  listTitle = "Newsletters";
  viewList = Item;
  itemTitle = { type: "attribut", attribut: "title" };
  initializeItem = initialize;
  validateItem = validate;
  itemParams = page;
  messageNoDocument = "Aucune newsletter";
  // listStyle = StyledList;

  constructor(props) {
    super(props, {
      newItemLibelle: "Nouvelle newsletter"
    });

    this.state.form.required = ["title"];

    this.state.list.menu.left = [
      {
        text: "Ajouter",
        onClick: this.handleCreate
      }
    ];
  }
}

const mapStateToProps = (globalState, ownProps) => {
  const props = {};

  props.docs = globalState.boutique.newsletters;

  return props;
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewsletterList);
