import React from "react";
import { connect } from "react-redux";
import { formValueSelector } from "redux-form";

import Data from "../../../../../../CMS/components/Data/index";
import automatic from "../../../params/validation-automatic.json";

const Automatic = props => {
  return (
    <div className="boutique__demande__component--validation-automatic">
      {props.advancePayment && <Data data={automatic} />}
    </div>
  );
};

const selector = formValueSelector("item");

export default connect((state, props) => {
  return {
    advancePayment:
      selector(state, "validation") &&
      selector(state, "validation") === "automatic"
  };
})(Automatic);
