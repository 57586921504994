import React from "react";
import styled from "styled-components";
import Group from "./Group";
import StyledTitle from "../../styles/Title";

const Section = styled.section`
  .is-pulled-right {
    float: right;
    color: #4b71fc;
    font-size: 13px;
  }

  //.group {
  //    box-sizing: border-box;
  //    background: white;
  //    //border: 1px solid #e4e7ed;
  //    border: 1px solid rgba(228, 231, 237, 0.5);
  //    border-radius: 2px;
  //    width: 100%;
  //    margin-bottom: 1.5rem;
  //    padding: 1.5rem;
  //
  //    .product-search,
  //    .message-search {
  //      display: flex;
  //      flex-direction: row;
  //      justify-content: space-around;
  //
  //        label {
  //          margin-right: 1.5rem;
  //          text-align: right;
  //          display: inline-block;
  //          line-height: 34px;
  //          padding: 0;
  //          height: 34px;
  //          flex: 1;
  //        }
  //
  //        input {
  //          flex: 2;
  //          display: inline-block;
  //          height: 34px;
  //          padding: 0 0.75rem;
  //          line-height: 34px;
  //          min-width: 270px;
  //          border-radius: 2px;
  //          border: 1px solid #d7d6d9;
  //          color: #34313f;
  //        }
  //        .browse-by {
  //          //flex: 1;
  //          margin-left: 1.5rem;
  //
  //          a {
  //              display: inline-block;
  //              font-weight: 300;
  //              line-height: 1.3334;
  //              padding: 7px 15px;
  //              height: 34px;
  //              box-sizing: border-box;
  //              border: 1px solid #b7c6fe;
  //              border-radius: 2px;
  //              color: #4b71fc;
  //              vertical-align: top;
  //          }
  //
  //        }
  //    }
  //}

  //.column {
  //  display: flex;
  //  flex-direction: row;
  //
  //  .field {
  //      padding: 41px 20px 20px 20px;
  //  }
  //}
`;

export default ({ title, children, button, group, groupClassName, style }) => (
  <Section>
    {button && (
      <button className="is-pulled-right" onClick={button.onClick}>
        {button.text}
      </button>
    )}
    {title && <StyledTitle>{title}</StyledTitle>}
    {group ? (
      <Group className={groupClassName ? groupClassName : ""}>{children}</Group>
    ) : (
      children
    )}
  </Section>
);
