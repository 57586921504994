import React, { Component } from "react";
import StyledEntry from "../../../../styles/View/List/Entry";
import moment from "moment";

class Entry extends Component {
  state = {
    value: null,
    entry: null
  };

  static getDerivedStateFromProps(props, state) {
    if (
      props.value &&
      ((!state.value && props.value) ||
        (state.value && state.value.id !== props.value.id))
    ) {
      state.value = props.value;
      const entry = {};
      props.fields.forEach(field => {
        const datas = field.data();
        if (props.value.data()[datas.name]) {
          entry[datas.label] = props.value.data()[datas.name];
        }
      });

      state.entry = entry;
    }

    return state;
  }

  render() {
    const { handleDeleteEntry } = this.props;

    return (
      <StyledEntry>
        <div className="date">
          <span className="day">
            {moment(this.state.value.data()["createdAt"]).format("Do")}
          </span>
          <span className="month">
            {moment(this.state.value.data()["createdAt"]).format("MMM")}
          </span>
        </div>
        <ul>
          {this.state.value.get("contact") && (
            <li>
              <label>Contact</label>
              <span>{this.state.value.get("contact")}</span>
            </li>
          )}
          {this.state.value.get("contact_firstname") && (
            <li>
              <label>(Contact) Prénom</label>
              <span>{this.state.value.get("contact_firstname")}</span>
            </li>
          )}
          {this.state.value.get("contact_lastname") && (
          <li>
            <label>(Contact) Nom</label>
            <span>{this.state.value.get("contact_lastname")}</span>
          </li>
          )}
          {this.state.value.get("contact_phone") && (
            <li>
              <label>(Contact) Téléphone</label>
              <span>{this.state.value.get("contact_phone")}</span>
            </li>
          )}
          {this.state.value.get("contact_email") && (
            <li>
              <label>(Contact) Adresse email</label>
              <span>{this.state.value.get("contact_email")}</span>
            </li>
          )}
          {this.state.value.get("contact_newsletter") && (
            <li>
              <label>(Contact) Inscrit à la newsletter ?</label>
              <span>OUI</span>
            </li>
          )}
          {this.state.value.get("contact_zip") && (
            <li>
              <label>(Contact) Code postal</label>
              <span>{this.state.value.get("contact_zip")}</span>
            </li>
          )}
          {this.state.value.get("contact_city") && (
            <li>
              <label>(Contact) Ville</label>
              <span>{this.state.value.get("contact_city")}</span>
            </li>
          )}
          {this.state.value.get("contact_entreprise") && (
            <li>
              <label>(Contact) Entreprise</label>
              <span>{this.state.value.get("contact_entreprise")}</span>
            </li>
          )}
          {this.state.value.get("contact_country") && (
            <li>
              <label>(Contact) Pays</label>
              <span>{this.state.value.get("contact_country")}</span>
            </li>
          )}
          {Object.keys(this.state.entry).map((key, index) => (
            <li key={index}>
              <label>{key}</label>
              <span>
                {typeof this.state.entry[key] === "object"
                  ? Object.keys(this.state.entry[key]).length
                  : Array.isArray(this.state.entry[key])
                    ? this.state.entry[key].length
                    : this.state.entry[key]}
              </span>
            </li>
          ))}
        </ul>
        <button onClick={handleDeleteEntry}>
          <span className="icon" />
          <span className="text">Supprimer</span>
        </button>
      </StyledEntry>
    );
  }
}
export default Entry;
