import React, { Component } from "react";
import Draft from "../../../../../../components/Draft";

class Text extends Component {
  render() {
    const { data, handleOnChangeField } = this.props;

    return (
      <Draft
        value={data.text}
        onChange={value => handleOnChangeField("text", value)}
      />
    );
  }
}

export default Text;
