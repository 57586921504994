import React from "react";
import Button from "../../../../../CMS/components/Button";
import List from "../../../../../CMS/containers/Boutique/components/List";
import ActionBar from "../../../../../CMS/components/ActionBar";
import Card from "./Card";
import produit from "../../params.json";
import { initialize, validate } from "../../functions";
import Produit from "../../../../../CMS/datas/Produit";
import Boutique from "../../../../../CMS/datas/Boutique";

class ProduitsList extends List {
  collectionName = "produits";
  defaultOrderField = "datetime";
  defaultOrderDirection = "desc";
  listTitle = "Produits";
  viewCard = Card;
  itemTitle = { type: "attribut", attribut: "name" };
  initializeItem = initialize;
  validateItem = validate;
  itemParams = produit;
  messageNoDocument = "Aucun produit";

  constructor(props) {
    super(props, {
      defaultViewList: "grid",
      newItemLibelle: "Nouveau produit",
      tabsArray: [
        {
          label: "En vente",
          actif: true,
          showInfo: true,
          showBulles: true,
          wheres: {
            onSale: true,
            isOnlineStore: true
          }
        },
        {
          label: "Vendus",
          actif: false,
          showInfo: false,
          showUnavailable: true,
          showBulles: true,
          wheres: {
            hasInventory: true,
            isOnlineStore: true,
            qtyAvailable: 0
          }
        },
        {
          label: "Cachés",
          actif: false,
          showInfo: true,
          showUnavailable: false,
          showBulles: true,
          wheres: {
            isOnlineStore: false
          }
        },
        {
          label: "En vedette",
          actif: false,
          showBulles: true,
          wheres: {
            isFeatured: true
          }
        },
        {
          label: "Réservés",
          actif: false,
          showInfo: true,
          showBulles: true,
          wheres: {
            isReserved: true
          }
        },
        {
          label: "Sans inventaire",
          actif: false,
          showInfo: true,
          showBulles: true,
          wheres: {
            hasInventory: false
          }
        },
        {
          label: "Tous les produits",
          actif: false,
          showInfo: true,
          showBulles: true,
          wheres: {}
        }
      ],
      custom: {
        categories: [],
        wheres: {
          category: "",
          sku: ""
        }
      }
    });

    this.handleTransfertToProject = this.handleTransfertToProject.bind(this);
    this.handleUpdateOnSale = this.handleUpdateOnSale.bind(this);
    this.handleViewPage = this.handleViewPage.bind(this);

    this.state.form.required = ["name"];
    // this.state.form.asyncBlurFields = ["pathname"];
    // this.state.form.asyncValidate = (values, dispath) => {
    //   // on vérifie si l'url n'est pas utilisé par un autre produit
    //   return this.props
    //     .checkUrlById(
    //       values.pathname,
    //       "produit",
    //       this.state.boutique.id,
    //       this.state.key
    //     )
    //     .then(action => {
    //       if (!action.check) {
    //         const error = {
    //           pathname: {
    //             code: 3,
    //             message: "Cette url est déjà utilisée"
    //           }
    //         };
    //         throw error;
    //       }
    //     });
    // };

    this.state.list.menu = {};
    this.state.form.menu.right = [
      { icon: "fa-eye", onClick: this.handleViewPage },
      { icon: "fa-close", onClick: this.handleClose },
      { icon: "fa-save", submit: true }
    ];
  }

  handleTransfertToProject(e) {
    e.preventDefault();
    if (!this.props.boutique.get("firebaseApiKey")) {
      console.error(
        "Il n'est pas possible de transférer les produits car le projet n'est pas configuré"
      );
    }

    Produit.transfertToBoutique(this.props.boutique).then(result => {
      console.info("result", result);
    });
  }

  handleUpdateOnSale(e) {
    e.preventDefault();
    if (!this.props.boutique.get("firebaseApiKey")) {
      console.error(
        "Il n'est pas possible de transférer les produits car le projet n'est pas configuré"
      );
    }

    Produit.updateOnSale(this.props.boutique).then(result => {
      console.info("result", result);
    });
  }

  handleViewPage(e) {
    const url = `${this.props.boutique.get("websiteURL")}/${
      this.state.item.pathname
    }`;
    window.open(url, "_blank");
  }

  /**
   * Cette fonction permet d'ajouter un traitement après la création du document
   *
   * @param ref
   * @param item
   */
  handleWhenCreateSuccess(ref, item) {
    // Créer le numéro SKU
    const _this = this;
    Produit.getNewSku(this.props.boutique, item.publishedAt).then(sku => {
      _this.props.changeFormValue("sku", sku);
    });
  }

  /**
   * Cette fonction permet d'ajouter un traitement après la mise à jour du document
   *
   * @param ref
   * @param item
   * @returns {null}
   */
  handleWhenUpdateSuccess(ref, item) {
    // this.setState({
    //   item: null,
    //   index: null,
    //   key: null,
    //   ref: null
    // });
  }

  loadAlternativeFunc(boutique) {
    Boutique.searchFeatures(boutique, "productCategories").then(docs => {
      this.setState(oldState => {
        oldState.custom.categories = docs;
        return oldState;
      });
    });
  }

  addActionBarToRender() {
    let menuClassNames = ["menu-annexe"];
    menuClassNames.push(this.state.openMenu ? "open" : "close");

    return (
      <ActionBar className="action-bar is-clearfix">
        <ul>
          <li className="navbar-item">
            <Button icon="fa-plus" onClick={this.handleCreate} />
          </li>
        </ul>

        <div className={menuClassNames.join(" ")}>
          <Button
            icon="fa-ellipsis-v"
            className="open-menu"
            onClick={this.handleOpenMenuOnMobile}
          />
          <div className="menu-annexe-content">
            {this.state.custom.categories &&
              this.state.custom.categories.length > 0 && (
                <ul>
                  <li className="navbar-item feature select">
                    <select
                      onChange={e =>
                        this.handleChangeCustomWheres(e, "category")
                      }
                      value={this.state.custom.wheres.category}
                    >
                      <option value="">-- Choisir une catégorie --</option>
                      {this.state.custom.categories.map((categorie, index) => (
                        <option key={index} value={categorie.id}>
                          {categorie.data().libelle}
                        </option>
                      ))}
                    </select>
                  </li>
                </ul>
              )}

            <ul>
              <li className="navbar-item">
                <input
                  type="text"
                  value={this.state.custom.wheres.sku}
                  onChange={e => this.handleChangeCustomWheres(e, "sku")}
                  placeholder="Sku"
                />
              </li>
              <li className="navbar-item nbByRow">
                <input
                  type="number"
                  value={this.state.nbParLigne}
                  onChange={this.handleOnChangeNbParLigne}
                />
              </li>
            </ul>
          </div>
        </div>

        <ul className="border-left navbar-right">
          <li className="navbar-item search">
            <input
              type="text"
              value={this.state.q}
              onChange={this.handleOnChangeSearch}
              onKeyPress={this.handleOnKeyPressSearch}
              placeholder="Rechercher"
            />
            {this.state.q && (
              <button className="empty" onClick={this.handleEmptySearch}>
                <span className="icon" />
                <span className="text">Vider</span>
              </button>
            )}
          </li>
        </ul>
      </ActionBar>
    );
  }
}

export default ProduitsList;
