import React, { Component } from "react";
import { connect } from "react-redux";
import CMSList from "../../../../components/View/List";
import CMSForm from "../../../../components/View/Form";
import CMSView from "../../../../components/View/index";
import CMSData from "../../../../components/Data/index";
import Row from "./Row";
import Entries from "./Entries";
import schema from "../../params.json";

import { initialize, validate } from "../../functions";

import { watch, create, update, remove, reset } from "../../actions";

import { create as createNotification } from "../../../Notification/actions";
import Button from "../../../../components/Button";
import ActionBar from "../../../../components/ActionBar";

// TODO Réécrire ce composant
class List extends Component {
  constructor(props) {
    super(props);

    this.state = {
      index: null,
      item: null,
      ref: null,
      view: null,
      boutiqueId: ""
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCreate = this.handleCreate.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleShowDatas = this.handleShowDatas.bind(this);
    this.handleChangeBoutique = this.handleChangeBoutique.bind(this);
  }

  componentWillMount() {
    this.props.loadSchemas(this.state.boutiqueId);
  }

  componentWillUnmount() {
    this.props.reset();
  }

  handleChangeBoutique(e) {
    e.preventDefault();
    this.setState(
      {
        boutiqueId: e.currentTarget.value
      },
      () => {
        this.props.loadSchemas(this.state.boutiqueId);
      }
    );
  }

  showSchemas = () => {
    this.props.loadSchemas();
  };

  handleCreate = event => {
    // Création d'un nouveau schema vide
    const item = initialize(schema);
    this.setState(
      {
        item: item,
        view: "item",
        index: null,
        ref: null
      },
      () => {
        this.props.createNotification({
          type: "info",
          message: "Initialisation d'un nouveau schema réussie"
        });
      }
    );
  };

  handleDelete = event => {
    if (
      this.state.index !== null &&
      this.state.index >= 0 &&
      this.props.schemas[this.state.index]
    ) {
      const confirmation = window.confirm("Voulez-vous supprimer ce schema ?");
      if (confirmation) {
        this.props
          .remove(this.props.schemas[this.state.index].ref)
          .then(action => {
            console.info("remove schema", action);
            this.setState(
              {
                item: null,
                index: null,
                ref: null
              },
              () => {
                this.props.createNotification({
                  type: "info",
                  message: "Suppression du schema réussie"
                });
              }
            );
          });
      }
    } else {
      this.props.createNotification({
        type: "error",
        message: "Erreur de paramètre lors de la suppression du schema"
      });
    }
  };

  handleSubmit = item => {
    if (this.state.ref) {
      this.props.update(this.state.ref, item);
    } else {
      this.props.create(item, this.props.espace).then(action => {
        console.info("creation d'un schema", action);
        if (!action.error) {
          this.setState(
            {
              ref: action.payload
            },
            () => {
              this.props.createNotification({
                type: "info",
                message: "Création d'un schema réussie"
              });
            }
          );
        }
      });
    }
  };

  handleClose = () => {
    this.setState({
      index: null,
      item: null,
      ref: null
    });
  };

  handleChange = (index, event) => {
    this.setState({
      index: index,
      ref: this.props.schemas[index].ref,
      item: this.props.schemas[index].data(),
      view: "item"
    });
  };

  handleShowDatas = (index, event) => {
    this.setState({
      index: index,
      ref: this.props.schemas[index].ref,
      item: this.props.schemas[index].data(),
      view: "datas"
    });
  };

  render() {
    let propsList = {
      title: "Schémas",
      loading: this.props.loading
      // menu: {
      //   left: [
      //     {
      //       text: "Ajouter",
      //       onClick: this.handleCreate
      //       // }, {
      //       //     icon: 'fa-trash',
      //       //     onClick: this.handleDelete
      //     }
      //   ]
      // }
    };

    let propsForm = {
      initialValues: this.state.item,
      required: ["name"],
      newItemLibelle: "Nouveau schéma"
    };

    if (this.state.item) {
      propsForm.id = this.state.ref ? this.state.ref.id : null;
      switch (this.state.view) {
        case "datas":
          propsForm.menu = {
            right: [
              {
                text: "Fermer",
                onClick: this.handleClose
              }
            ]
          };
          break;

        case "item":
        default:
          propsForm.menu = {
            left: [
              {
                className: "delete",
                text: "Supprimer",
                onClick: this.handleDelete
              }
            ],
            right: [
              {
                text: "Fermer",
                onClick: this.handleClose
              },
              {
                text: "Enregistrer",
                submit: true
              }
            ]
          };
      }
    }

    return (
      <CMSView className="cms__schema">
        {this.state.item ? (
          this.state.view === "item" ? (
            <CMSForm
              {...propsForm}
              title={{ type: "attribut", attribut: "name" }}
              onSubmit={this.handleSubmit}
            >
              <CMSData
                data={schema}
                docref={this.state.ref}
                user={this.props.user}
              />
            </CMSForm>
          ) : (
            <CMSForm
              {...propsForm}
              title={{ type: "attribut", attribut: "name" }}
              onSubmit={this.handleSubmit}
            >
              <Entries
                item={this.state.item}
                doc={this.props.schemas[this.state.index]}
              />
            </CMSForm>
          )
        ) : (
          <CMSList {...propsList}>
            <ActionBar className="action-bar is-clearfix">
              <ul>
                <li className="navbar-item">
                  <Button text="Ajouter" onClick={this.handleCreate} />
                </li>
              </ul>

              <div className="menu-annexe">
                {this.props.boutiques &&
                  this.props.boutiques.length > 0 && (
                    <ul className="border-left">
                      <li className="navbar-item feature select">
                        <select
                          onChange={this.handleChangeBoutique}
                          value={this.state.boutiqueId}
                        >
                          <option value="">-- Choisir une boutique --</option>
                          {this.props.boutiques.map((boutique, index) => (
                            <option key={index} value={boutique.id}>
                              {boutique.get("name")}
                            </option>
                          ))}
                        </select>
                      </li>
                    </ul>
                  )}
              </div>
            </ActionBar>
            {this.props.schemas ? (
              <ol>
                {this.props.schemas.map((doc, index) => (
                  <Row
                    key={index}
                    onClickEdit={event => this.handleChange(index, event)}
                    onClickDatas={event => this.handleShowDatas(index, event)}
                    doc={doc}
                  />
                ))}
              </ol>
            ) : (
              <div>Aucun schéma</div>
            )}
          </CMSList>
        )}
      </CMSView>
    );
  }
}

const mapStateToProps = (globalState, ownProps) => {
  const props = {};

  props.loading = globalState.cms.loading;
  props.schemas = globalState.cms.schemas;
  props.boutiques = globalState.cms.boutiques;
  props.user = globalState.cms.user;

  return props;
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    loadSchemas: boutiqueId => dispatch(watch(boutiqueId)),
    create: item => dispatch(create(validate(item))),
    update: (ref, item) => {
      dispatch(update(ref, validate(item)));
    },
    remove: id => dispatch(remove(id)),
    reset: () => dispatch(reset()),
    createNotification: notification =>
      dispatch(createNotification(notification))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(List);
