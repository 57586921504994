import { connect } from "react-redux";
import {
  createDocumentInCollection,
  removeDocumentById,
  removeDocumentByRef,
  resetCollection,
  updateDocumentById,
  updateDocumentByRef,
  watchCollection
} from "../../../../Boutique/actions";
import { create as createNotification } from "../../Notification/actions";
import { change } from "redux-form";

const mapStateToProps = (globalState, ownProps) => {
  let props = {};

  props.user = globalState.cms.user;
  props.unsubscribe = globalState.boutique.unsubscribe;
  props.lastDocument = globalState.boutique.lastDocument;

  return props;
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    watchCollection: (
      type,
      collectionName,
      collectionType,
      q,
      wheres,
      orderField,
      orderDirection,
      nbParPage,
      pageIndex,
      lastDocument
    ) => {
      return dispatch(
        watchCollection(
          ownProps.boutique,
          type,
          collectionName,
          collectionType,
          q,
          wheres,
          orderField,
          orderDirection,
          nbParPage,
          pageIndex,
          lastDocument
        )
      );
    },
    createDocumentInCollection: (
      type,
      collectionName,
      collectionType,
      item
    ) => {
      return dispatch(
        createDocumentInCollection(
          type,
          ownProps.boutique,
          collectionName,
          collectionType,
          item
        )
      );
    },
    updateDocumentByRef: (type, ref, item) => {
      return dispatch(updateDocumentByRef(type, ref, item));
    },
    updateDocumentById: (type, collectionName, id, item) => {
      return dispatch(
        updateDocumentById(type, ownProps.boutique, collectionName, id, item)
      );
    },
    removeDocumentByRef: (type, ref) => {
      return dispatch(removeDocumentByRef(type, ref));
    },
    removeDocumentById: (type, collectionName, id) => {
      return dispatch(
        removeDocumentById(type, ownProps.boutique, collectionName, id)
      );
    },
    resetCollection: (type, unsubscribe) => {
      return dispatch(resetCollection(type, unsubscribe));
    },
    createNotification: notification =>
      dispatch(createNotification(notification)),
    changeFormValue: (field, value, form = "item") =>
      dispatch(change(form, field, value))
  };
};

const HOC = (WrappedComponent, selectData) => {
  return connect(globalState => {
    let props = {};
    if (globalState.cms.boutiques && globalState.cms.boutiques.length > 0) {
      props.boutique = globalState.cms.boutiques[globalState.cms.indexBoutique];
    }

    props.docs = globalState.boutique.docs;
    props.nbHits = globalState.boutique.nbHits;
    props.loading = globalState.boutique.loading;
    props.isMobile = globalState.cms.mobile;

    return props;
  })(WrappedComponent);
};

export { HOC, mapStateToProps, mapDispatchToProps };

export default HOC;
