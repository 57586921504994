class Feature {
  static findByTypeAndId(boutique, type, id) {
    return boutique.ref
      .collection(type)
      .doc(id)
      .get()
      .then(documentSnapshot => {
        if (documentSnapshot.exists && !documentSnapshot.get("deleted")) {
          return documentSnapshot;
        }
        throw new Error("La caractéristique n'existe pas ou a été supprimée");
      });
  }
}

export default Feature;
