import {
    ESPACES_WATCH,
    ESPACES_LOAD,
    ESPACE_LOAD_BY_ID,
    ESPACE_CREATE,
    ESPACE_UPDATE,
    ESPACE_REMOVE,
    DOCS_RESET
} from '../../actionTypes';
import slug from 'slug';
import firestore from '../../../../api/firestore';
import moment from 'moment'

moment.locale('fr');

slug.defaults.mode ='rfc3986';

export function watch() {
    return dispatch => {
        firestore.collection("espaces").where('deleted', '==', false).onSnapshot(function(querySnapshot) {
            dispatch({
                type: ESPACES_WATCH,
                docs: querySnapshot.docs
            });
        });
    }
}

export function load() {
    return {
        type: ESPACES_LOAD,
        payload: firestore.collection("espaces").where('deleted', '==', false).get()
    }
}

export function loadById(id) {
    return dispatch => {
        firestore.collection('espaces').doc(id).get().then(function(doc) {
            dispatch({
                type: ESPACE_LOAD_BY_ID,
                espace: doc
            });
        }).catch(function(error) {
            dispatch({
                type: ESPACE_LOAD_BY_ID,
                error: error
            });
        });
    }
}


export function create(item) {
    return {
        type: ESPACE_CREATE,
        payload: firestore.collection('espaces').add(item)
    };
}

export function update(key, item) {
    return {
        type: ESPACE_UPDATE,
        payload: firestore.collection("espaces").doc(key).update(item)
    };
}

export function remove(id) {
    return {
        type: ESPACE_REMOVE,
        payload: firestore.collection("espaces").doc(id).update({
            deleted: true,
            updatedAt: moment().toISOString()
        })
    }
}

export function reset() {
    return dispatch => {
        const unsubscribe = firestore.collection("espaces").onSnapshot(function () {});
        unsubscribe();

        dispatch({
            type: DOCS_RESET
        });
    }
}