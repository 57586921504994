import moment from 'moment';
import * as functions from '../../functions';

export function initialize(data) {
    const objet = functions.initialize(data);
    return {
        ...objet
    }
}

export function validate(schema) {
    schema.updatedAt = moment().toISOString();

    return schema;
}