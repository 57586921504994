import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import {
  HOC,
  mapStateToProps,
  mapDispatchToProps
} from "../CMS/containers/Boutique/components/HOC";
import Demandes from "./containers/Demande/components/List/index";
import Produits from "./containers/Produit/components/List/index";
import Services from "./containers/Service/components/List/index";
import Abonnements from "./containers/Abonnement/components/List/index";
import Modeles from "./containers/Modele/components/List/index";
import Categories from "./containers/Produit/containers/Categorie/components/List/index";
import Caracteristiques from "./containers/Produit/containers/Caracteristique/components/List/index";
import Commandes from "./containers/Commande/components/List/index";
import Campagnes from "./containers/Campagne/components/List/index";
import Contacts from "./containers/Contact/List";
import Medias from "./containers/Media/components/List/index";
import Depenses from "./containers/Depense/components/List/index";
import Ventes from "./containers/Vente/components/List/index";
import Fournisseurs from "./containers/Fournisseur/components/List/index";
import Params from "./containers/Params/index";
import Traitement from "./containers/Traitement/index";

class Boutique extends Component {
  render() {
    const { user, path } = this.props;

    if (!user) {
      return <div>Chargement en cours de l'utilisateur</div>;
    }

    switch (path) {
      case "medias/*":
        return <Medias {...this.props} />;

      case "boutique/*": {
        if (this.props["*"].substr(0, 8) === "contacts") {
          return <Contacts {...this.props} />;
        }

        if (this.props["*"].substr(0, 8) === "demandes") {
          return <Demandes {...this.props} />;
        }

        if (this.props["*"].substr(0, 9) === "campagnes") {
          return <Campagnes {...this.props} />;
        }

        if (this.props["*"].substr(0, 8) === "produits") {
          return <Produits {...this.props} />;
        }

        if (this.props["*"].substr(0, 8) === "services") {
          return <Services {...this.props} />;
        }

        if (this.props["*"].substr(0, 11) === "abonnements") {
          return <Abonnements {...this.props} />;
        }

        if (this.props["*"].substr(0, 7) === "modeles") {
          return <Modeles {...this.props} />;
        }

        if (this.props["*"].substr(0, 9) === "commandes") {
          return <Commandes {...this.props} />;
        }

        if (this.props["*"].substr(0, 6) === "ventes") {
          return <Ventes {...this.props} />;
        }
        if (this.props["*"].substr(0, 12) === "fournisseurs") {
          return <Fournisseurs {...this.props} />;
        }
        if (this.props["*"].substr(0, 8) === "depenses") {
          return <Depenses {...this.props} />;
        }

        if (this.props["*"].substr(0, 10) === "categories") {
          return <Categories {...this.props} />;
        }
        if (this.props["*"].substr(0, 16) === "caracteristiques") {
          return <Caracteristiques {...this.props} />;
        }
        if (this.props["*"].substr(0, 6) === "params") {
          return <Params {...this.props} />;
        }
        if (this.props["*"].substr(0, 10) === "traitement") {
          return <Traitement {...this.props} />;
        }

        break;
      }

      default:
    }

    return (
      <div>
        Problème de configuration, le type '{this.props["*"]}' n'existe pas
      </div>
    );
  }
}

const enhance = compose(
  HOC,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
);

export default enhance(Boutique);
