import React, { Component } from "react";
import { connect } from "react-redux";
import { navigate } from "@reach/router";
import Navigation from "./Navigation";
import Home from "../../../../containers/Home";
// import Notification from "../Notification/Item";
import styled from "styled-components";
// import close from "../../../../images/font-awesome/solid/times.svg";

const Root = styled.div`
  // display: flex;
  // flex-direction: ${props => (props.theme.mobile ? "column" : "row")};
  // height: 100%;
  // width: 100%;
  // position: absolute;
  // overflow: hidden;
`;

const Main = styled.div`
  display: flex;
  //flex: 1 1 100%;
  //flex-direction: column;
  min-height: 100vh;
  //position: relative;
  //width: 100%;
  margin-left: ${props => (props.theme.mobile ? 0 : "200px")};
  //padding-top: 0;
  //margin-left: 0;
  //-webkit-overflow-scrolling: touch;
`;

const Page = styled.div`
  //display: flex;
  //flex: 1 1 100%;
  //flex-direction: column;
  //overflow-x: hidden;
  //overflow-y: auto;
  width: 100%;
  background: #f6f7f9;
  //position: relative;
`;

// const StyledNotification = styled.div`
//   position: absolute;
//   top: 1.5rem;
//   z-index: 100000;
//
//   .notification {
//     border-radius: 5px;
//     padding: 0.75rem 1.5rem;
//     width: 300px;
//     margin: 0.75rem 1.5rem;
//
//     &.info {
//       background: green;
//     }
//
//     &.error {
//       background: red;
//     }
//
//     button {
//       float: right;
//
//       span {
//         display: none;
//       }
//
//       &:after {
//         content: "";
//         background-image: url(${close});
//         width: 21px;
//         height: 21px;
//         display: block;
//         background-repeat: no-repeat;
//         background-position: center;
//       }
//     }
//   }
// `;

class Backoffice extends Component {
  componentWillReceiveProps(nextProps) {
    // On verifie que l'utilisateur est toujours connecté
    // TODO supprimer isLogged car il fait doublon avec user car is isLogged, le user est différent null
    if (!nextProps.loadingUser && !nextProps.isLogged) {
      // Le cas échéant, on redirige vers la page d'accueil
      navigate("/");
    }
  }

  render() {
    // Si on est sur cette page, on se trouve dans les situations suivantes :
    // 1. L'utilisateur est en cours de chargement
    if (this.props.loadingUser) {
      return <div>En attente de chargement de l'utilisateur</div>;
    }

    return (
      <Root>
        <Navigation pathname={this.props.location.pathname} />
        {/*<StyledNotification>*/}
        {/*{Object.keys(this.props.notifications).length > 0 &&*/}
        {/*Object.keys(this.props.notifications).map((key, index) => (*/}
        {/*<Notification key={index} value={this.props.notifications[key]} />*/}
        {/*))}*/}
        {/*</StyledNotification>*/}
        <Main>
          <Page>{this.props.children || <Home />}</Page>
        </Main>
      </Root>
    );
  }
}

const mapStateToProps = (globalState, ownProps) => {
  return {
    loadingUser: globalState.cms.loadingUser,
    isLogged: globalState.cms.isLogged,
    notifications: globalState.cms.notifications
  };
};

// const mapDispatchToProps = dispatch => {
//   return {
//     loadEspace: id => dispatch(loadEspace(id))
//   };
// };

export default connect(
  mapStateToProps,
  null
)(Backoffice);
