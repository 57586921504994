import { connect } from "react-redux";
import List from "../../../../../CMS/containers/Boutique/components/List";

import Item from "./Item";

import StyledCommandes from "../../styles/Commandes";

import { initialize, validate } from "../../functions";

import commande from "../../params/commande.json";

import { removeAllByCommande as removePaiements } from "../../../Paiement/actions";

import { getNewNumero } from "../../actions";
import { change } from "redux-form";
import Commande from "../../../../../CMS/datas/Commande";

class CommandesList extends List {
  listTitle = "Commandes";
  viewList = Item;
  collectionName = "commandes";
  defaultOrderField = "numero";
  defaultOrderDirection = "desc";
  itemTitle = "Commande";
  initializeItem = initialize;
  validateItem = validate;
  itemParams = commande;
  messageNoDocument = "Aucune commande";
  listStyle = StyledCommandes;

  constructor(props) {
    super(props, {
      defaultViewList: "list",
      tabsArray: [
        {
          label: "Toutes",
          actif: true,
          wheres: {
            isArchived: false
          },
          orderField: "deliveryDate",
          orderDirection: "asc"
        },
        {
          label: "Archivées",
          actif: false,
          wheres: {
            isArchived: true
          },
          orderField: "updatedAt",
          orderDirection: "desc"
        }
      ]
    });

    this.handleTransfertToProject = this.handleTransfertToProject.bind(this);
    this.state.form.newItemLibelle = "Nouvelle commande";
    this.state.form.required = ["deliveryDate"];
    this.state.list.menu.left = [
      {
        icon: "fa-plus",
        onClick: this.handleCreate
      },
      {
        text: "fa-download",
        onClick: this.handleTransfertToProject
      }
    ];

    this.state.form.menu.left = [
      {
        className: "delete",
        text: "Supprimer",
        onClick: this.handleDelete
      }
    ];
    this.state.form.menu.right = [
      {
        text: "Fermer",
        onClick: this.handleClose
      }
    ];
  }

  // TODO Ajouter un traitement pour supprimer les ventes de la commande lors de la suppression

  handleTransfertToProject(e) {
    e.preventDefault();
    if (!this.props.boutique.get("firebaseApiKey")) {
      console.error(
        "Il n'est pas possible de transférer les commandes car le projet n'est pas configuré"
      );
    }

    Commande.transfertToBoutique(this.props.boutique).then(result => {
      console.info("result", result);
    });
  }

  /**
   * Cette fonction permet d'ajouter un traitement après la création du document
   *
   * @param ref
   * @param item
   */
  handleWhenCreateSuccess(ref, item) {
    // Créer le numéro de la commande
    this.props.generateNumero(item.date);
  }

  handleDelete(event) {
    //TODO supprimer egalement les documents associés (paiements, etc.)
    const confirmation = window.confirm(
      "Voulez-vous supprimer cette commande ?"
    );
    if (confirmation) {
      // Supprimer tous les paiements de la commande
      this.props.removePaiements(this.state.ref).then(action => {
        // Suppression de la commande
        this.props.removeDocumentByRef(this.actionTypeRemove, this.state.ref);
        this.setState({
          item: null,
          index: null,
          key: null,
          ref: null
        });
      });
    }
  }
}

const mapStateToProps = (globalState, ownProps) => {
  const props = {};

  props.docs = globalState.boutique.docs;

  return props;
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    removePaiements: commandeRef => dispatch(removePaiements(commandeRef)),
    generateNumero: date => {
      dispatch(getNewNumero(ownProps.boutique, date)).then(action => {
        dispatch(change("item", "numero", action.numero));
      });
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CommandesList);
