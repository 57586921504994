import React from "react";
import PropTypes from "prop-types";
import List from "./Data/List/index";

const Data = props => {
  const { type, ...others } = props;
  delete others.component;

  switch (type) {
    case "list":
      return <List {...others} />;

    default:
  }

  return <div>Content</div>;
};

Data.propTypes = {
  type: PropTypes.string.isRequired
};

export default Data;
