import React, { Component } from "react";
import Section from "../../../../../../CMS/components/Data/Section";
import { FieldArray, formValueSelector } from "redux-form";
import { connect } from "react-redux";
import Item from "./Item";

class Caracteristiques extends Component {
  state = {
    features: undefined
  };

  componentWillMount() {
    const features = this.props.fields.getAll();
    this.props.boutique.ref
      .collection("productFeatures")
      .where("deleted", "==", false)
      .get()
      .then(querySnapshot => {
        this.setState(
          {
            features: querySnapshot.docs
          },
          () => {
            if (querySnapshot.size > 0) {
              querySnapshot.forEach(doc => {
                const index =
                  features && features.length > 0
                    ? features.findIndex(feature => feature.id === doc.id)
                    : -1;
                if (index < 0) {
                  this.props.fields.push({
                    id: doc.id,
                    libelle: doc.data().libelle,
                    valeur: ""
                  });
                }
              });
            }
          }
        );
      });
  }

  render() {
    const { fields } = this.props;

    return (
      <Section
        title="Caractéristiques"
        button={{
          text: "Ajouter un caractéristique",
          onClick: () => fields.push({})
        }}
        group={true}
      >
        {fields.length > 0 ? (
          fields.map((field, idx, features) => (
            <Item
              key={idx}
              name={field}
              data={features.get(idx)}
              docRef={this.state.features && this.state.features[idx].ref}
              boutique={this.props.boutique}
              handleRemove={() => fields.remove(idx)}
              dispatch={this.props.dispatch}
            />
          ))
        ) : (
          <p>Aucune caractéristique</p>
        )}
      </Section>
    );
  }
}

const selector = formValueSelector("item");

export default connect(state => {
  return {
    category: selector(state, "category")
  };
})(props => <FieldArray {...props} component={Caracteristiques} />);
