import styled from "styled-components";
import pen from "../../../../../images/font-awesome/solid/pen.svg";
import mail from "../../../../../images/font-awesome/solid/envelope.svg";

export default styled.li`
  width: 100%;
  display: flex;
  background: #fff;
  border: 1px solid #e4e7ed;
  margin-left: 1.5rem;
  margin-bottom: 0;

  .doc-main {
    display: flex;
  }

  &:not(:first-child) {
    border-top: none;
  }

  > span,
  > p,
  > div.infos,
  .doc-main div.infos {
    line-height: 50px;
    margin: 0 0 0 0.75rem;
    //font-size: 13px;
  }

  > div.infos,
  .doc-main div.infos {
    display: flex;
    flex: 1;
  }

  .name {
    line-height: 50px;
    //font-size: 13px;
    font-weight: 400;
    cursor: pointer;
  }

  .description {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .name + .description {
    margin-left: 0.75rem;
  }

  .options {
    display: ${props => (props.theme.mobile ? "none" : "display")};
  }

  .date {
    background: #e4e7ed;
    text-align: center;
    padding: 0.375rem;
    border-radius: 4px;
    height: 40px;
    width: 40px;
    box-sizing: border-box;
    color: hsla(0, 0%, 0%, 0.7) !important;
    margin: 5px;

    span {
      margin: 0;
    }

    .day {
      font-size: 16px;
      line-height: 18px;
      display: block;
      font-weight: bold;
    }
    .month {
      font-size: 10px;
      display: block;
      line-height: 15px;
    }
  }

  .price {
    &:after {
      content: " €";
    }
  }

  .field-actions {
    height: 50px;
    align-items: center;
    margin: 0 0.75rem;
  }

  .is-subscribedToNewsletter {
    margin: 0 0 0 0.75rem;
    display: block;
    height: 50px;
    width: 15px;
    background-color: rgba(0, 0, 0, 0.7);
    background-image: none;
    background-size: 20px 20px;
    mask: url(${mail}) no-repeat 50% 50%;
  }

  .actions {
    text-align: right;

    button {
      line-height: 30px;
      margin: 10px;
      cursor: pointer;
      outline: none;
      vertical-align: top;
      padding: 0 1.5rem;
      background: #fff;
      border: 1px solid #b7c6fe;
      border-radius: 2px;
      box-shadow: none !important;
      color: #4b71fc;

      &.edit {
        padding: 0 0.75rem;
        span.icon {
          margin: 4px 0;
          display: block;
          height: 20px;
          width: 20px;
          background-color: #4b71fc;
          background-image: none;
          background-size: 20px 20px;
          mask: url(${pen}) no-repeat 50% 50%;
        }

        span.txt {
          display: none;
        }

        &.small span.icon {
          background-size: 14px 14px;
          height: 14px;
          width: 14px;
          margin: 7px 0;
        }
      }
    }
  }

  > .doc-children {
    margin-bottom: 0.75rem;

    a {
      color: inherit;
    }
  }
`;
