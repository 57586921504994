import {
    INTRAS_WATCH,
    INTRAS_RESET
} from "./actionTypes";

const initialState = {
    intras: null
};

export default function gtdReducer(state = initialState, action) {
    switch(action.type) {
        case INTRAS_WATCH:
            return {...state, intras: action.docs};

        case INTRAS_RESET:
            return {...state, intras: null};

        default:
    }

    return state;
}