import React, { Component } from "react";
import styled from "styled-components";
import { getDomainAssetsURL } from "../../../../../../Boutique/functions";
import {
  CompositeDecorator,
  convertFromRaw,
  Editor,
  EditorState
} from "draft-js";
import Button from "../../../../../components/Button";
import slug from "slug";
import Produit from "../../../../../../Boutique/containers/Produit/components/Crud/Item";

const Div = styled.div`
  &.medias_above-center,
  &.produits_above-center {
    display: flex;
    flex-direction: column;

    .medias,
    .produits {
      max-width: 100%;
      align-self: center;
    }
  }

  &.medias_below-center,
  &.produits_below-center {
    display: flex;
    flex-direction: column-reverse;

    .medias,
    .produits {
      margin-top: 1.5rem;
      max-width: 100%;
      align-self: center;
    }
  }

  &.medias_beside-text-left,
  &.produits_beside-text-left {
    .medias,
    .produits {
      float: left;
      max-width: 50%;
      margin-right: 1.5rem;
    }
  }

  &.medias_beside-text-right,
  &.produits_beside-text-right {
    .medias,
    .produits {
      float: right;
      max-width: 50%;
      margin-left: 1.5rem;
    }
  }

  &.medias_beside-text-left-centered,
  &.produits_beside-text-left-centered {
    display: flex;
    flex-direction: row;
    .medias,
    .produits {
      margin-right: 1.5rem;
      max-width: 50%;
    }
  }

  &.medias_beside-text-right-centered,
  &.produits_beside-text-right-centered {
    display: flex;
    flex-direction: row-reverse;
    .medias,
    .produits {
      margin-left: 1.5rem;
      max-width: 50%;
    }
  }

  .medias,
  .produits {
    align-content: start;
    display: grid;
    grid-gap: 0.75rem;

    &.mediasNbColumns-na,
    &.produitsNbColumns-na,
    &.mediasNbColumns-1,
    &.produitssNbColumns-1 {
      grid-template-columns: auto;
      grid-template-rows: auto;
    }
    &.mediasNbColumns-2,
    &.produitsNbColumns-2 {
      grid-template-columns: auto auto;
      grid-template-rows: auto auto;
    }
    &.mediasNbColumns-3,
    &.produitsNbColumns-3 {
      grid-template-columns: auto auto auto;
      grid-template-rows: auto auto auto;
    }
    &.mediasNbColumns-4,
    &.produitsNbColumns-4 {
      grid-template-columns: auto auto auto auto;
      grid-template-rows: auto auto auto auto;
    }
    &.mediasNbColumns-5,
    &.produitsNbColumns-5 {
      grid-template-columns: auto auto auto auto auto;
      grid-template-rows: auto auto auto auto auto;
    }
    &.mediasNbColumns-6,
    &.produitsNbColumns-6 {
      grid-template-columns: auto auto auto auto auto auto;
      grid-template-rows: auto auto auto auto auto auto;
    }
  }
`;

const HeaderType = ({ type, alignement = "left", children }) => {
  if (!alignement || alignement === "n/a") {
    alignement = "left";
  }
  switch (type) {
    case "h1":
      return <h1 style={{ textAlign: alignement }}>{children}</h1>;

    case "h3":
      return <h3 style={{ textAlign: alignement }}>{children}</h3>;

    case "h4":
      return <h4 style={{ textAlign: alignement }}>{children}</h4>;

    case "h5":
      return <h5 style={{ textAlign: alignement }}>{children}</h5>;

    case "h6":
      return <h6 style={{ textAlign: alignement }}>{children}</h6>;

    case "hidden":
      return null;

    case "h2":
    default:
      return <h2 style={{ textAlign: alignement }}>{children}</h2>;
  }
};

function findLinkEntities(contentBlock, callback, contentState) {
  contentBlock.findEntityRanges(character => {
    const entityKey = character.getEntity();
    return (
      entityKey !== null &&
      contentState.getEntity(entityKey).getType() === "LINK"
    );
  }, callback);
}
const Link = props => {
  const { url } = props.contentState.getEntity(props.entityKey).getData();
  return <a href={url}>{props.children}</a>;
};

const decorator = new CompositeDecorator([
  {
    strategy: findLinkEntities,
    component: Link
  }
]);

class Item extends Component {
  render() {
    const {
      boutique,
      index,
      nbContent,
      document,
      handleEdit,
      handleUp,
      handleDown,
      handleDelete
    } = this.props;

    const classNames = [];
    if (
      document.get("mediasPosition") &&
      document.get("medias") &&
      document.get("medias").length > 0
    ) {
      classNames.push(`medias_${document.get("mediasPosition")}`);
    }
    if (
      document.get("produitsPosition") &&
      document.get("produits") &&
      document.get("produits").length > 0
    ) {
      classNames.push(`produits_${document.get("produitsPosition")}`);
    }

    return (
      <div style={{ display: "flex", margin: "0.75rem 0" }}>
        <div className="infos" style={{ flex: 1, marginRight: "1.5rem" }}>
          {document.get("header") && (
            <HeaderType
              type={document.get("headerType")}
              alignement={document.get("headerAlignement")}
            >
              {document.get("header")}
            </HeaderType>
          )}
          <Div className={classNames.join(" ")}>
            {document.get("medias") &&
              document.get("medias").length > 0 && (
                <div
                  className={`medias mediasNbColumns-${
                    document.get("mediasNbColumns")
                      ? slug(document.get("mediasNbColumns"))
                      : "na"
                  }`}
                >
                  {document.get("medias").map((photo, index) => (
                    <img
                      src={`${getDomainAssetsURL(boutique)}/${
                        photo.fullPath
                      }?w=320&h=320&c=max`}
                      key={index}
                      alt={photo.title ? photo.title : photo.fullPath}
                    />
                  ))}
                </div>
              )}
            {document.get("produits") &&
              document.get("produits").length > 0 && (
                <div
                  className={`produits produitsNbColumns-${
                    document.get("produitsNbColumns")
                      ? slug(document.get("produitsNbColumns"))
                      : "na"
                  }`}
                >
                  {document.get("produits").map((produitId, index) => (
                    <Produit
                      itemId={produitId}
                      key={index}
                      boutique={this.props.boutique}
                      showName={document.get("produitsShowName")}
                      show={true}
                      mode="show"
                    />
                  ))}
                </div>
              )}
            {document.get("text") && (
              <div className="text">
                <Editor
                  editorState={EditorState.createWithContent(
                    convertFromRaw(document.get("text")),
                    decorator
                  )}
                  readOnly={true}
                />
              </div>
            )}
          </Div>
        </div>
        <div>
          <Button smallIcon onClick={handleEdit} icon="fa-edit" />
          {index > 0 && (
            <Button smallIcon onClick={handleUp} icon="fa-arrow-up" />
          )}
          {index < nbContent - 1 && (
            <Button smallIcon onClick={handleDown} icon="fa-arrow-down" />
          )}
          <Button smallIcon onClick={handleDelete} icon="fa-trash" />
        </div>
      </div>
    );
  }
}

export default Item;
