import React, { Component } from "react";
import Section from "../../../../components/Data/Section";
import Group from "../../../../components/Data/Group";
import Field from "../Field";
import moment from "moment";
import { DragDropContext } from "react-dnd";
import HTML5Backend from "react-dnd-html5-backend";
import styled from "styled-components";
import sort from "../../../../../../images/font-awesome/solid/sort.svg";

const Ul = styled.div`
  list-style: none;
  padding: 1.5rem;

  li > div {
    display: flex;
    margin-bottom: 0.75rem;

    &:last-child {
      margin-bottom: 0;
    }

    .button-order {
      text-align: center;
      height: 34px;
      width: 34px;
      border-top: 1px solid #d7d6d9;
      border-bottom: 1px solid #d7d6d9;
      border-left: 1px solid #d7d6d9;

      &:after {
        content: "";
        display: block;
        background-color: #86848c;
        mask-image: url(${sort});
        mask-repeat: no-repeat;
        mask-position: center;
        width: 18px;
        height: 18px;
        margin: 7px;
      }
    }
  }
`;

class Fields extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fields: null
    };

    this.loadFields = this.loadFields.bind(this);
    this.handleAdd = this.handleAdd.bind(this);
    this.handleUpdate = this.handleUpdate.bind(this);
    this.handleRemove = this.handleRemove.bind(this);
  }

  componentWillMount() {
    if (this.props.docref) {
      this.loadFields(this.props.docref);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (!this.props.docref && nextProps.docref) {
      this.loadFields(nextProps.docref);
    }
  }

  loadFields = ref => {
    const _this = this;
    ref
      .collection("fields")
      .orderBy("order")
      .get()
      .then(querySnapshot => {
        const fields = [];
        querySnapshot.forEach(snap => {
          fields.push({
            id: snap.id,
            ref: snap.ref,
            datas: snap.data()
          });
        });
        _this.setState({
          fields: fields
        });
      });
  };

  handleAdd = e => {
    e.preventDefault();
    const newField = {
      order: parseInt(this.state.fields.length + 1, 10),
      createdAt: moment().toISOString(),
      deleted: false
    };
    this.props.docref
      .collection("fields")
      .add(newField)
      .then(docRef => {
        docRef.get().then(snapshot => {
          this.setState(oldState => {
            oldState.fields.push({
              id: snapshot.id,
              ref: snapshot.ref,
              datas: snapshot.data()
            });
            return {
              fields: oldState.fields
            };
          });
        });
      });
  };

  handleUpdate = (index, field) => {
    this.state.fields[index].ref.update({ ...field }).then(() => {
      this.setState(oldState => {
        oldState.fields[index].datas = field;

        return {
          fields: oldState.fields
        };
      });
    });
  };

  handleRemove = (e, index) => {
    e.preventDefault();
    this.state.fields[index].ref.delete().then(() => {
      this.setState(oldState => {
        oldState.fields.splice(index, 1);
        return {
          fields: oldState.fields
        };
      });
    });
  };

  render() {
    const { docref, title } = this.props;

    // On n'affiche pas les champs lorsque le schema n'est pas créé
    if (!docref) {
      return null;
    }

    return (
      <Section
        button={{
          text: "Ajouter un champ",
          onClick: this.handleAdd
        }}
        title={title}
      >
        <Group>
          {this.state.fields ? (
            this.state.fields.length > 0 ? (
              <Ul>
                {this.state.fields.map((field, index) => (
                  <Field
                    key={field.id}
                    docref={field.ref}
                    datas={field.datas}
                    index={index}
                    handleRemove={this.handleRemove}
                    handleUpdate={this.handleUpdate}
                  />
                ))}
              </Ul>
            ) : (
              <p>Aucun champ</p>
            )
          ) : (
            <p>Chargement en cours</p>
          )}
        </Group>
      </Section>
    );
  }
}

export default DragDropContext(HTML5Backend)(Fields);
