import React, { Component } from "react";
import PropTypes from "prop-types";
import { Field, change } from "redux-form";
import ComponentField from "../../../../../CMS/styles/Field";
// import { connect } from "react-redux";
// import { formValueSelector } from "redux-form";

import { load } from "../../actions";

class Select extends Component {
  constructor(props) {
    super(props);

    this.state = {
      options: []
    };

    this.handleChangeValue = this.handleChangeValue.bind(this);
    this.loadCommande = this.loadCommande.bind(this);
  }

  componentWillMount() {
    if (this.props.boutique) {
      this.loadCommande(this.props.boutique);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (!this.props.boutique && nextProps.boutique) {
      this.loadCommande(nextProps.boutique);
    }
  }

  loadCommande(boutique) {
    this.props.meta.dispatch(load(boutique)).then(action => {
      if (!action.error) {
        if (action.payload.size > 0) {
          this.setState(
            {
              options: action.payload.docs
            },
            () => {
              if (this.input.value) {
                this.input.classList.remove("is-empty");
              } else {
                this.input.classList.add("is-empty");
              }

              this.input.addEventListener("focus", function(event) {
                event.currentTarget.classList.add("is-focused");
              });
              this.input.addEventListener("blur", function(event) {
                event.currentTarget.classList.remove("is-focused");
              });
              this.input.addEventListener("keyup", function(event) {
                if (event.currentTarget.value.length === 0) {
                  event.currentTarget.classList.add("is-empty");
                } else {
                  event.currentTarget.classList.remove("is-empty");
                }
              });
            }
          );
        }
      } else {
        console.error(action.payload.message);
      }
    });
  }

  handleChangeValue(event) {
    const index = this.state.options.findIndex(
      value => value.id === event.currentTarget.value
    );

    this.props.meta.dispatch(
      change("item", this.props.input.name, event.currentTarget.value)
    );
    if (this.state.options[index]) {
      this.props.meta.dispatch(
        change(
          "item",
          this.props.input.name + "Ref",
          this.state.options[index].ref
        )
      );
    }
  }

  render() {
    const {
      input: { name, value },
      label,
      horizontal,
      meta: { touched, error }
    } = this.props;

    return (
      <ComponentField
        className={
          "field select " +
          (touched && error ? "error " : "") +
          (horizontal ? "horizontal " : "")
        }
      >
        {this.state.options.length > 0 ? (
          <select
            name={name}
            value={value}
            ref={input => {
              this.input = input;
            }}
            onChange={this.handleChangeValue}
          >
            <option value="">Non spécifié</option>
            {this.state.options.map((item, index) => (
              <option key={index} value={item.id}>
                {item.data().numero
                  ? `#${item.data().numero} par ${
                      item.data().contact_firstname
                    } ${item.data().contact_lastname}`
                  : item.id}
              </option>
            ))}
          </select>
        ) : (
          <div>...</div>
        )}
        <label className="label">
          <span>{label}</span>
        </label>
        {touched &&
          error && (
            <div className={"error error_" + error.code}>
              <span className="icon">
                <i className="fa fa-exclamation" />
              </span>
              <span>{error.message}</span>
            </div>
          )}
      </ComponentField>
    );
  }
}

Select.propTypes = {
  label: PropTypes.string
};

// const selector = formValueSelector("item");
// Select = connect((state, props) => {
//   if (props.condition) {
//     return {
//       show:
//         selector(state, props.condition.attribut) &&
//         selector(state, props.condition.attribut) === props.condition.value
//     };
//   }
//
//   return {
//     show: true
//   };
// })(Select);

export default props => <Field {...props} component={Select} />;
