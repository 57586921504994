import React, { Component } from "react";
import PropTypes from "prop-types";
import { FieldArray, change } from "redux-form";
import Section from "../../../../../CMS/components/Data/Section";
import Group from "../../../../../CMS/components/Data/Group";
import StyledList from "../../styles/Requests/List";
import Request from "../Requests/Item";

class Requests extends Component {
  static contextTypes = {
    store: PropTypes.object
  };

  constructor(props) {
    super(props);

    this.state = {
      openModalProduit: false,
      openModalMessage: false,
      index: null,
      demande: null
    };

    this.handleUpdateField = this.handleUpdateField.bind(this);
    this.handleChangeField = this.handleChangeField.bind(this);
    this.handleDeleteField = this.handleDeleteField.bind(this);
  }

  handleUpdateField = (name, attrs) => {
    Object.keys(attrs).forEach(key => {
      this.context.store.dispatch(change("item", name + "." + key, attrs[key]));
    });
  };

  handleChangeField = event => {
    const name = event.currentTarget.name;
    let value = "";


    switch (event.currentTarget.type) {
      case "checkbox":
        value = event.currentTarget.checked;
        break;

      case "number":
        value =
          Number(event.currentTarget.value) > 0
            ? Number(event.currentTarget.value)
            : null;
        break;

      default:
        value = event.currentTarget.value;
    }

    this.handleUpdateField(`requests[${this.state.index}]`, {
      [name]: value
    });

    // // TODO, revoir cette fonction en mettant à jour directement dans le form sans passer par le state
    // this.setState(oldState => {
    //   let demande = Object.assign({}, oldState.demande);
    //   let tmp = demande;
    //   name.split(".").forEach((key, index, t) => {
    //     if (index === t.length - 1) {
    //       tmp[key] = value;
    //     } else {
    //       tmp = tmp[key];
    //     }
    //   });
    //
    //   return {
    //     demande: demande
    //   };
    // });
  };

  handleDeleteField = (e, index) => {
    e.preventDefault();
    this.props.fields.remove(index);
  };

  render() {
    const { fields } = this.props;

    return (
      <Section className="boutique__devis__component--demandes">
        {fields.length > 0 ? (
          <StyledList>
            {fields.map((name, index, requests) => (
              <Request
                key={index}
                name={name}
                item={requests.get(index)}
                boutique={this.props.boutique}
                handleUpdateField={this.handleUpdateField}
                handleDeleteField={e => this.handleDeleteField(e, index)}
              />
            ))}
          </StyledList>
        ) : (
          <Group>
            <p>Aucune demande</p>
          </Group>
        )}
      </Section>
    );
  }
}

export default props => <FieldArray {...props} component={Requests} />;
