import List from "../../../CMS/containers/Boutique/components/List";

import Item from "./Item";
import page from "./params.json";

import { initialize, validate } from "./functions";

class PostList extends List {
  collectionName = "posts";
  defaultOrderField = "publishedAt";
  defaultOrderDirection = "desc";
  listTitle = "Articles";
  viewList = Item;
  itemTitle = { type: "attribut", attribut: "name" };
  initializeItem = initialize;
  validateItem = validate;
  itemParams = page;
  messageNoDocument = "Aucun article";

  constructor(props) {
    super(props, {
      newItemLibelle: "Nouvel article"
    });

    this.handleViewPage = this.handleViewPage.bind(this);

    this.state.form.required = ["name"];

    this.state.list.menu.left = [
      {
        text: "Ajouter",
        onClick: this.handleCreate
      }
    ];

    this.state.form.menu.right = [
      { icon: "fa-eye", onClick: this.handleViewPage },
      { icon: "fa-close", onClick: this.handleClose },
      { icon: "fa-save", submit: true }
    ];
  }

  handleViewPage(e) {
    const url = `${this.props.boutique.get("websiteURL")}/${
      this.state.item.pathname
    }`;
    window.open(url, "_blank");
  }
}

export default PostList;
