import * as functions from "../../../CMS/functions";

export function initialize(data, boutique) {
  const objet = functions.initialize(data);
  return {
    ...objet,
    boutique: boutique.id,
    isSend: false,
    locale: "fr_FR",
    senderName: boutique.get("fromName"),
    senderMail: boutique.get("fromMail")
  };
}
