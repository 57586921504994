import React, { Component } from "react";
import PropTypes from "prop-types";
import Section from "../../../../components/Data/Section";
import StyledField from "../../../../styles/Field";
import Group from "../../../../components/Data/Group";
import { Field } from "redux-form";
import Boutique from "../../../../datas/Boutique";

class MultiSelect extends Component {
  constructor(props) {
    super(props);

    this.state = {
      items: null
    };
  }

  componentWillMount() {
    Boutique.searchFeatures(this.props.boutique, this.props.collectionName)
      .then(docs => {
        const items = [];
        docs.forEach(doc => {
          items.push({
            objectId: doc.id,
            libelle: doc.data().libelle,
            change: false
          });
        });
        this.setState({
          items: items
        });
      })
      .catch(error => {
        console.error(error.message);
      });
  }

  render() {
    const { title, className } = this.props;

    if (!this.state.items || this.state.items.length === 0) {
      return null;
    }

    // TODO refaire le composant
    return (
      <Section title={title}>
        <Group style={{ padding: "20px 20px 5px 20px" }} className={className}>
          {this.state.items.map((item, index) => (
            <StyledField style={{ margin: 0 }} key={index}>
              <label className="checkbox">
                <Field
                  name={`${this.props.name}.${item.objectId}`}
                  component="input"
                  type="checkbox"
                />
                <span>{item.libelle}</span>
              </label>
            </StyledField>
          ))}
        </Group>
      </Section>
    );
  }
}

MultiSelect.propTypes = {
  collectionName: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
};

export default MultiSelect;
