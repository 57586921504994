import React, { Component } from "react";
import { Field } from "redux-form";
import ComponentField from "../../../styles/Field";

class Checkbox extends Component {
  render() {
    const {
      input,
      description,
      icon,
      horizontal,
      label,
      meta: { touched, error },
      className,
      ...others
    } = this.props;

    return (
      <ComponentField
        className={
          `cms__field checkbox ${className} ` +
          (touched && error ? " error" : "") +
          (horizontal ? "horizontal " : "")
        }
      >
        {icon &&
          false && (
            <span className="icon is-small">
              <i className={"fa " + icon} />
            </span>
          )}

        <label className="checkbox">
          <Field {...input} {...others} component="input" type="checkbox" />
          <span>{description}</span>
        </label>

        <label className="label">
          <span>{label}</span>
        </label>
        {touched &&
          error && (
            <div className={"error error_" + error.code}>
              <span className="icon">
                <i className="fa fa-exclamation" />
              </span>
              <span>{error.message}</span>
            </div>
          )}
      </ComponentField>
    );
  }
}

export default Checkbox;
