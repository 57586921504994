import React from "react";
import StyledRow from "../../../../../CMS/styles/View/List/Row";
export default ({ doc, onClick }) => (
  <StyledRow onClick={onClick}>
    <p className="" style={{ flex: 1 }}>
      {doc.get("title")}
    </p>
    <p className="control actions">
      <button onClick={onClick} className="edit small">
        <span className="icon" />
        <span className="txt">Edit</span>
      </button>
    </p>
  </StyledRow>
);
