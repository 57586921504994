import styled from "styled-components";
import bulleReponse from "./images/bulle-reponse.png";
import bulle from "./images/bulle.png";


export const StyledMessages = styled.div`
  display: flex !important;
  flex-direction: column;
  
  font-size: 90%;
  margin-top: 0.75rem;
  margin-bottom: 0.375rem; 
`;

export const StyledBulle = styled.div`
    background: ${props => props.reponse ?  '#097EF8': '#E8E8E8'};
    border-radius: 15px;
    padding: 0.375rem 0.75rem;
    margin-bottom: 0.375rem;
    flex: 1;
    color: ${props => props.reponse ? '#fff' : '#000'};
    display: inline-block !important;
    position: relative;
    align-self: ${props => props.reponse ? 'flex-end' : 'flex-start'};
    
    &:after {
        content: '';
        background: url(${props => props.reponse ?  bulleReponse : bulle}) no-repeat;
        width: 14px;
        height: 7px;
        display:block;
        position: absolute;
        left: ${props => props.reponse ?  'auto': '-1px'};
        right: ${props => props.reponse ?  '-1px': 'auto'};
        bottom: 0;
    }
`;