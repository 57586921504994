import moment from "moment";
import * as functions from "../../../../../CMS/functions";

export function initialize(data) {
  const objet = functions.initialize(data);
  return {
    ...objet
  };
}

export function validate(categorie) {
  categorie.updatedAt = moment().toISOString();

  return categorie;
}
