import React, { Component } from "react";
import Section from "../../../../../../components/Data/Section";
import Select from "../../../../../../components/Select";
import Input from "../../../../../../components/Input";
import Produits from "../../../../../../../Boutique/containers/Produit/components/Crud/";

class ProduitsTab extends Component {
  options = {
    produitsPosition: [
      {
        value: "above-center",
        label: "Centré au dessus"
      },
      {
        value: "below-center",
        label: "Centré en dessous"
      },
      {
        value: "beside-text-right",
        label: "A droite du texte"
      },
      {
        value: "beside-text-left",
        label: "A gauche du texte"
      },
      {
        value: "beside-text-right-centered",
        label: "Centré à droite du texte"
      },
      {
        value: "beside-text-left-centered",
        label: "Centré à gauche du texte"
      }
    ],
    produitsNbColumns: [
      {
        value: 1,
        label: "1"
      },
      {
        value: 2,
        label: "2"
      },
      {
        value: 3,
        label: "3"
      },
      {
        value: 4,
        label: "4"
      },
      {
        value: 5,
        label: "5"
      },
      {
        value: 6,
        label: "6"
      }
    ]
  };

  render() {
    const { data, boutique, handleOnBlur, handleOnChangeField } = this.props;

    return (
      <div>
        <Produits
          items={data.produits ? data.produits : []}
          updateItems={value => handleOnChangeField("produits", value)}
          boutique={boutique}
        />
        <Section title="Options" group={true}>
          <Select
            onChange={handleOnBlur}
            value={data.produitsPosition}
            name="produitsPosition"
            label="Position et alignement"
            options={this.options.produitsPosition}
          />
          <Select
            onChange={handleOnBlur}
            value={data.produitsNbColumns}
            label="Nombre de colonnes"
            name="produitsNbColumns"
            options={this.options.produitsNbColumns}
          />
          <Input
            type="checkbox"
            onChange={handleOnBlur}
            value={data.produitsClickToEnlarge}
            name="produitsClickToEnlarge"
            label="Agrandir"
            description="Cliquer pour agrandir"
          />
          <Input
            type="checkbox"
            onChange={handleOnBlur}
            value={data.produitsClickToShow}
            name="produitsClickToShow"
            label="Voir le produit"
            description="Cliquer pour voir"
          />
          <Input
            type="checkbox"
            onChange={handleOnBlur}
            value={data.produitsShowName}
            name="produitsShowName"
            label="Voir le nom du produit"
            description="Est-ce que le nom est visible ?"
          />
        </Section>
      </div>
    );
  }
}

export default ProduitsTab;
