import Tabs from "../../../../../components/Tabs";
import Modal from "../../../../../components/View/Modal";
import React from "react";
import Text from "./tabs/Text";
import Titre from "./tabs/Titre";
import Images from "./tabs/Images";
import Produits from "./tabs/Produits";

const Content = ({
  item,
  boutique,
  docId,
  handleClose,
  handleOnBlur,
  handleOnChangeField
}) => {
  const buttons = [
    {
      className: "close",
      text: "Fermer",
      onClick: handleClose,
      primary: true
    }
  ];

  const data = item.data();

  const TabTexte = (
    <Text
      data={data}
      handleOnBlur={handleOnBlur}
      handleOnChangeField={handleOnChangeField}
    />
  );

  const TabTitre = (
    <Titre
      data={data}
      handleOnChange={handleOnBlur}
      handleOnChangeField={handleOnChangeField}
    />
  );

  const TabImages = (
    <Images
      data={data}
      handleOnBlur={handleOnBlur}
      handleOnChangeField={handleOnChangeField}
      boutique={boutique}
      docId={docId}
    />
  );

  const TabProduits = (
    <Produits
      data={data}
      handleOnBlur={handleOnBlur}
      handleOnChangeField={handleOnChangeField}
      boutique={boutique}
      docId={docId}
    />
  );

  return (
    <Modal title="Contenu" handleClose={handleClose} buttons={buttons}>
      <Tabs
        items={[
          {
            name: "Texte",
            content: TabTexte
          },
          {
            name: "Titre",
            content: TabTitre
          },
          {
            name: "Images",
            content: TabImages
          },
          {
            name: "Produits",
            content: TabProduits
          }
        ]}
      />
    </Modal>
  );
};

export default Content;
