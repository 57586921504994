import React, { Component } from "react";
import StyledField from "../../../styles/Field";
import { change } from "redux-form";
import InputElement from "react-maskedinput";

const InputMask = props => {
  const { name, value, ...rest } = props;

  delete rest.meta;

  return <InputElement name={name} value={value} {...rest} />;
};

class Text extends Component {
  constructor(props) {
    super(props);

    this.handleOnChange = this.handleOnChange.bind(this);
  }

  componentDidMount() {
    if (this.input.value) {
      this.input.classList.remove("is-empty");
    } else {
      this.input.classList.add("is-empty");
    }

    this.input.addEventListener("focus", function(event) {
      event.currentTarget.classList.add("is-focused");
    });
    this.input.addEventListener("blur", function(event) {
      event.currentTarget.classList.remove("is-focused");
    });
    this.input.addEventListener("keyup", function(event) {
      if (event.currentTarget.value.length === 0) {
        event.currentTarget.classList.add("is-empty");
      } else {
        event.currentTarget.classList.remove("is-empty");
      }
    });
  }

  handleOnChange = event => {
    this.props.meta.dispatch(
      change("item", event.currentTarget.name, event.currentTarget.value)
    );
  };

  render() {
    const {
      input,
      icon,
      mask,
      label,
      horizontal,
      className,
      language,
      meta: { asyncValidating, touched, error },
      traductions,
      ...others
    } = this.props;



    let classNames = ["cms__field"];
    if (touched && error) classNames.push("error");
    if (horizontal) classNames.push("horizontal");
    if (asyncValidating) classNames.push("asyncValidating");
    if (language) classNames = classNames.concat(["language", `lang_${language}`]);
    if (className) classNames = classNames.concat(className.split(" "));

    return (
      <StyledField className={[...new Set(classNames)].join(" ")}>
        {icon && (
          <span className="input-icon icon is-small">
            <i className={"fa " + icon} />
          </span>
        )}

        {mask ? (
          <InputMask
            {...input}
            {...others}
            ref={input => {
              this.input = input;
            }}
            onChange={this.handleOnChange}
          />
        ) : (
          <input
            {...input}
            {...others}
            ref={input => {
              this.input = input;
            }}
            onChange={this.handleOnChange}
            autoComplete="off"
          />
        )}

        <label className="label">
          <span>{label}</span>
        </label>
        {touched && error && (
          <div className={"error error_" + error.code}>
            <span className="icon">
              <i className="fa fa-exclamation" />
            </span>
            <span>{error.message}</span>
          </div>
        )}
      </StyledField>
    );
  }
}

export default Text;
