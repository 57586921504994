import { getFirestore } from "../../Boutique/functions";
import algoliasearch from "algoliasearch";
import DataFunctions from "./functions";
import moment from "moment";
// import Url from "./Url";

class Produit {
  /**
   * Cette méthode permet de réaliser une recherche dans la collection produits
   *
   * @param boutique
   * @param options
   * @returns {Promise<firebase.firestore.DocumentReference>}
   */
  static search(boutique, options = {}) {
    const defaultNbParPage = 40;

    if (options.q) {
      // Recherche avec Algolia
      const wheres = options.wheres ? options.wheres : {};
      wheres.boutique = boutique.id;
      let index = undefined;
      // TODO a supprimer par la suite
      if (boutique.get("firebaseProjectId")) {
        index = `produits_${boutique.get("firebaseProjectId").toUpperCase()}`;
      } else {
        wheres.type = "produit";
        index = `pages_${boutique.data().indexAlgolia.toUpperCase()}`;
      }

      return algoliasearch(
        process.env.REACT_APP_ALGOLIA_APP_ID,
        process.env.REACT_APP_ALGOLIA_SEARCH_KEY
      )
        .initIndex(index)
        .search(options.q, {
          hitsPerPage: options.nbParPage ? options.nbParPage : defaultNbParPage,
          page: options.page ? options.page : 0,
          analytics: false,
          attributesToRetrieve: "*",
          getRankingInfo: true,
          responseFields: "*",
          filters: DataFunctions.getAlgoliaFilters(wheres),
          ...options.params
        })
        .then(action => {
          return action.hits;
        });
    } else {
      // recherche avec firestore
      let query = getFirestore(boutique)
        .collection("produits")
        .where("boutique", "==", boutique.id)
        .where("deleted", "==", false);

      query = DataFunctions.getFirestoreWheres(query, options.wheres);

      if (options.orderBy) {
        if (options.orderDirection) {
          query = query.orderBy(options.orderBy, options.orderDirection);
        } else {
          query = query.orderBy(options.orderBy);
        }
      }

      return query
        .limit(options.nbParPage ? options.nbParPage : defaultNbParPage)
        .get()
        .then(querySnapshot => {
          return querySnapshot.docs;
        });
    }
  }

  static transfertToBoutique(boutique) {
    const batchSandwich = getFirestore().batch();
    let nbPageTransfered = 0;
    return getFirestore()
      .collection("produits")
      .where("boutique", "==", boutique.id)
      .where("deleted", "==", false)
      .limit(200)
      .get()
      .then(querySnpashot => {
        if (!querySnpashot.empty) {
          let batch = getFirestore(boutique).batch();

          let newRef = undefined,
            data = undefined,
            newUrlRef = undefined,
            dateEncours = undefined;
          querySnpashot.docs.forEach(document => {
            nbPageTransfered++;
            newRef = getFirestore(boutique)
              .collection("produits")
              .doc(document.id);
            data = document.data();
            if (data.features) {
              data.features.forEach((feature, index) => {
                delete data.features[index].ref;
              });
            }
            delete data.boutiqueRef;
            delete data.categoryRef;
            batch.set(newRef, data, { merge: true });

            dateEncours = moment().toISOString();
            // Créer la nouvelle url
            newUrlRef = getFirestore(boutique)
              .collection("urls")
              .doc();
            batch.set(newUrlRef, {
              boutique: boutique.id,
              createdAt: dateEncours,
              deleted: false,
              docId: newRef.id ? newRef.id : document.id,
              docRef: newRef,
              parentId: "",
              pathname: `produit/${data.pathname}`,
              type: "produit"
            });
            batchSandwich.set(
              document.ref,
              {
                updatedAt: dateEncours,
                importedAt: dateEncours,
                importedX: 1,
                deleted: true
              },
              { merge: true }
            );
          });

          batchSandwich.commit();
          return batch.commit().then(() => {
            return nbPageTransfered;
          });
        } else {
          return 0;
        }
      });
  }

  static updateOnSale(boutique) {
    let nbProduitUpdated = 0;
    return getFirestore(boutique)
      .collection("produits")
      .where("boutique", "==", boutique.id)
      .where("inStock", "==", false)
      .where("updatedAt", "<", "2019-11-18T11:06")
      .where("deleted", "==", false)
      .limit(200)
      .get()
      .then(querySnpashot => {
        console.info("querySnpashot", querySnpashot);
        if (!querySnpashot.empty) {
          let batch = getFirestore(boutique).batch();

          querySnpashot.docs.forEach(document => {
            nbProduitUpdated++;
            batch.set(
              document.ref,
              {
                onSale: false,
                updatedAt: moment().toISOString()
              },
              { merge: true }
            );
          });

          return batch.commit().then(() => {
            return nbProduitUpdated;
          });
        } else {
          return 0;
        }
      });
  }

  static GenerateUrls(boutique) {
    // let nbPageTransfered = 0;
    // return getFirestore(boutique)
    //   .collection("produits")
    //   .where("boutique", "==", boutique.id)
    //   .where("deleted", "==", false)
    //   .limit(500)
    //   .get()
    //   .then(querySnpashot => {
    //     querySnpashot.forEach(produit => {
    //       // On génére l'url
    //       Url.clear(boutique, produit.get("name"), "produit", produit.id);
    //       nbPageTransfered++;
    //     });
    //     console.info(`génération de ${nbPageTransfered} urls`);
    //   });
  }

  /**
   * Recherche un modele par l'intermédiaire de l'id
   *
   * @param boutique
   * @param id
   * @returns {Promise<unknown>}
   */
  static findById(boutique, id) {
    return getFirestore(boutique)
      .collection("produits")
      .doc(id)
      .get()
      .then(documentSnapshot => {
        if (!documentSnapshot.exists) {
          throw new Error("Le produit n'existe pas");
        }

        if (documentSnapshot.get("boutique") !== boutique.id) {
          throw new Error("Ce produit n'appartient pas à cette boutique");
        }

        if (documentSnapshot.get("deleted")) {
          throw new Error("Ce produit a été supprimé");
        }

        return documentSnapshot;
      });
  }

  static getNewSku(boutique, date) {
    const debut = date
      ? moment(date).format("YYWWE")
      : moment().format("YYWWE");
    const fin = "" + parseInt(debut, 10) + 1;

    // TODO améliorer la génération du sku comme pour les url pathnames
    return getFirestore(boutique)
      .collection("produits")
      .where("boutique", "==", boutique.id)
      .where("sku", ">", debut)
      .where("sku", "<", fin)
      .orderBy("sku")
      .get()
      .then(querySnapshot => {
        if (querySnapshot.size > 0) {
          const sku = querySnapshot.docs[querySnapshot.size - 1].data().sku;
          // TODO gérer des sku supérieur à 999 -> A01 -> A02 ->
          return debut + ("000" + (parseInt(sku.substr(-3), 10) + 1)).slice(-3);
        }

        return debut + "001";
      });
  }
}

export default Produit;
