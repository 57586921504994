import styled from "styled-components";

export default styled.form`
  // padding-bottom: 30px;

  .not.action-bar:not(.secondary) {
    position: fixed;
    bottom: 0;
    margin: 0;
    left: ${props => (props.theme.mobile ? 0 : "200px")};
    right: 0;

    z-index: 500;
  }
`;
