import React, { Component } from "react";
import Tooltip from "../Tooltip";
import Button from "./style";

class Id extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showId: false
    };

    this.idRef = React.createRef();
  }

  componentDidMount() {
    if (this.idRef && this.idRef.current) {
      this.idRef.current.addEventListener("mouseover", () => {
        this.setState({
          showId: true
        });
      });

      this.idRef.current.addEventListener("mouseout", () => {
        this.setState({
          showId: false
        });
      });
    }
  }

  render() {
    const { id, className, smallIcon, ...others } = this.props;

    const classNames = className ? className.split(" ") : [];
    if (smallIcon) {
      classNames.push("small-icon");
    }
    return (
      <Button
        className={classNames.join(" ")}
        {...others}
        ref={this.idRef}
        onClick={e => {
          e.preventDefault();
          navigator.clipboard.writeText(id);
        }}
      >
        <span className="icon">
          <i className="fa fa-id-card" />
        </span>
        {this.state.showId && (
          <Tooltip
            style={{ top: "3px", right: "50px" }}
            value={id}
            direction="left"
          />
        )}
      </Button>
    );
  }
}

export default Id;
