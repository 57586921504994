import React, {Component} from 'react';
import { connect } from 'react-redux';
import { update } from '../actions/auth';

import CMSForm from '../sysext/CMS/components/View/Form';
import CMSView from '../sysext/CMS/components/View/index';
import CMSData from '../sysext/CMS/components/Data/index';

import edit from '../params/user-edit.yml';



class User extends Component {
    constructor(props) {
        super(props);

        this.handleUpdate = this.handleUpdate.bind(this);
    }

    handleUpdate = (user) => {
        this.props.update(user);
    };

    render() {
        const {path} = this.props;

        const propsFormEdit = {
            initialValues: {
                displayName: this.props.user.displayName ? this.props.user.displayName : ''
            },
            required: ['displayName'],
            menu: {
                right: [{
                    text: "Enregistrer",
                    submit: true
                }]
            }
        };

        switch(path) {
            case '/edit':
                return(
                    <CMSView className="user__edit">
                        <CMSForm {...propsFormEdit}
                                 title={{type:'attribut', attribut: 'displayName'}}
                                 onSubmit={this.handleUpdate}
                        >
                            <CMSData data={edit}/>
                        </CMSForm>
                    </CMSView>
                );

            default:
        }

        return (
            <div>Utilisateur par défaut</div>
        );
    }

}

const mapStateToProps = (globalState, ownProps) => {
    const props = globalState.cms;
    props.path = ownProps.routes[ownProps.routes.length-1].path;
    props.openNavigation = globalState.cms.openNavigation;

    return props;
};

const mapDispatchToProps = (dispatch) => {
    return {
        update: (user) => {
            dispatch(update(user));
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(User);
