export const TEST = "TEST";

export const STORAGE_TYPE_PHOTO = "photo";

export const API_LOADING = "API_LOADING";
export const API_SUCCESS = "API_SUCCESS";
export const API_ERROR = "API_ERROR";

export const WATCH_LOADING = "WATCH_LOADING";
export const WATCH_SUCCESS = "WATCH_SUCCESS";
export const WATCH_ERROR = "WATCH_ERROR";
export const WATCH_RESET = "WATCH_RESET";
export const WATCH_UNSUBSCRIBE = "WATCH_UNSUBSCRIBE";
export const SCROLL_ERROR = "SCROLL_ERROR";
export const SCROLL_SUCCESS = "SCROLL_SUCCESS";

export const DOCUMENT_CREATE = "DOCUMENT_CREATE";
export const DOCUMENT_UPDATE = "DOCUMENT_UPDATE";
export const DOCUMENT_REMOVE = "DOCUMENT_REMOVE";

export const DOCUMENTS_LOAD = "DOCUMENTS_LOAD";

export const CREATE = "CREATE";
export const UPDATE = "UPDATE";
export const REMOVE = "REMOVE";

export const LOAD_BY_ID_REQUEST = "LOAD_BY_ID_REQUEST";
export const LOAD_BY_ID_SUCCESS = "LOAD_BY_ID_SUCCESS";
export const LOAD_BY_ID_FAILURE = "LOAD_BY_ID_FAILURE";
export const LOAD_BY_ID_ERROR = "LOAD_BY_ID_ERROR";

// BOUTIQUE
export const BOUTIQUES_WATCH = "BOUTIQUES_WATCH";
export const BOUTIQUES_LOAD = "BOUTIQUES_LOAD";
export const BOUTIQUE_CREATE = "BOUTIQUE_CREATE";
export const BOUTIQUE_UPDATE = "BOUTIQUE_UPDATE";
export const BOUTIQUES_RESET = "BOUTIQUES_RESET";
export const BOUTIQUE_PHOTOS_ADD = "BOUTIQUE_PHOTOS_ADD";

// PAIEMENT
export const PAIEMENTS_BY_COMMANDE_WATCH = "PAIEMENTS_BY_COMMANDE_WATCH";
export const PAIEMENTS_BY_COMMANDE_LOAD = "PAIEMENTS_BY_COMMANDE_LOAD";
export const PAIEMENT_LOAD = "PAIEMENT_LOAD";
export const PAIEMENT_CREATE = "PAIEMENT_CREATE";
export const PAIEMENT_UPDATE = "PAIEMENT_UPDATE";
export const PAIEMENTS_REMOVE_BY_COMMANDE = "PAIEMENTS_REMOVE_BY_COMMANDE";
export const PAIEMENTS_RESET = "PAIEMENTS_RESET";

// DEPENSE
export const DEPENSES_WATCH = "DEPENSES_WATCH";
export const DEPENSE_CREATE = "DEPENSE_CREATE";
export const DEPENSE_UPDATE = "DEPENSE_UPDATE";
export const DEPENSE_REMOVE = "DEPENSE_REMOVE";
export const DEPENSES_RESET = "DEPENSES_RESET";

// VENTE
export const VENTES_WATCH = "VENTES_WATCH";
export const VENTE_CREATE = "VENTE_CREATE";
export const VENTE_UPDATE = "VENTE_UPDATE";
export const VENTE_REMOVE = "VENTE_REMOVE";
export const VENTES_RESET = "VENTES_RESET";

// FOURNISSEUR
export const FOURNISSEURS_WATCH = "FOURNISSEURS_WATCH";
export const FOURNISSEURS_LOAD = "FOURNISSEURS_LOAD";
export const FOURNISSEUR_CREATE = "FOURNISSEUR_CREATE";
export const FOURNISSEUR_UPDATE = "FOURNISSEUR_UPDATE";
export const FOURNISSEUR_REMOVE = "FOURNISSEUR_REMOVE";
export const FOURNISSEURS_RESET = "FOURNISSEURS_RESET";

export const UPDATE_BOUTIQUE_REQUEST = "UPDATE_BOUTIQUE_REQUEST";
export const UPDATE_BOUTIQUE_SUCCESS = "UPDATE_BOUTIQUE_SUCCESS";
export const UPDATE_BOUTIQUE_FAILURE = "UPDATE_BOUTIQUE_FAILURE";
export const UPDATE_BOUTIQUE_ERROR = "UPDATE_BOUTIQUE_ERROR";

//COLLECTION
export const LIST_COLLECTION_REQUEST = "LIST_COLLECTION_REQUEST";
export const LIST_COLLECTION_SUCCESS = "LIST_COLLECTION_SUCCESS";
export const LIST_COLLECTION_FAILURE = "LIST_COLLECTION_FAILURE";
export const LIST_COLLECTION_ERROR = "LIST_COLLECTION_ERROR";

export const CREATE_COLLECTION_REQUEST = "CREATE_COLLECTION_REQUEST";
export const CREATE_COLLECTION_SUCCESS = "CREATE_COLLECTION_SUCCESS";
export const CREATE_COLLECTION_FAILURE = "CREATE_COLLECTION_FAILURE";
export const CREATE_COLLECTION_ERROR = "CREATE_COLLECTION_ERROR";

export const UPDATE_COLLECTION_REQUEST = "UPDATE_COLLECTION_REQUEST";
export const UPDATE_COLLECTION_SUCCESS = "UPDATE_COLLECTION_SUCCESS";
export const UPDATE_COLLECTION_FAILURE = "UPDATE_COLLECTION_FAILURE";
export const UPDATE_COLLECTION_ERROR = "UPDATE_COLLECTION_ERROR";

export const REMOVE_COLLECTION_REQUEST = "REMOVE_COLLECTION_REQUEST";
export const REMOVE_COLLECTION_SUCCESS = "REMOVE_COLLECTION_SUCCESS";
export const REMOVE_COLLECTION_FAILURE = "REMOVE_COLLECTION_FAILURE";
export const REMOVE_COLLECTION_ERROR = "REMOVE_COLLECTION_ERROR";

// CONTACT
export const CONTACTS_WATCH = "CONTACTS_WATCH";
export const CONTACTS_LOAD = "CONTACTS_LOAD";
export const CONTACT_CREATE = "CONTACT_CREATE";
export const CONTACT_UPDATE = "CONTACT_UPDATE";
export const CONTACT_REMOVE = "CONTACT_REMOVE";
export const CONTACTS_RESET = "CONTACTS_RESET";

// COMMANDE
export const COMMANDES_WATCH = "COMMANDES_WATCH";
export const COMMANDES_SCROLL = "COMMANDES_SCROLL";
export const COMMANDES_LOAD = "COMMANDES_LOAD";
export const COMMANDE_CREATE = "COMMANDE_CREATE";
export const COMMANDE_UPDATE = "COMMANDE_UPDATE";
export const COMMANDE_REMOVE = "COMMANDE_REMOVE";
export const COMMANDE_NEW_NUMERO = "COMMANDE_NEW_NUMERO";
export const COMMANDES_RESET = "COMMANDES_RESET";

// DEMANDE
export const DEMANDES_WATCH = "DEMANDES_WATCH";
export const DEMANDES_SCROLL = "DEMANDES_SCROLL";
export const DEMANDE_CREATE = "DEMANDE_CREATE";
export const DEMANDE_UPDATE = "DEMANDE_UPDATE";
export const DEMANDE_REMOVE = "DEMANDE_REMOVE";
export const DEMANDES_RESET = "DEMANDES_RESET";
export const DEMANDES_REQUEST_LIST = "DEMANDES_REQUEST_LIST";

// URL
export const URLS_WATCH = "URLS_WATCH";
export const URLS_SCROLL = "URLS_SCROLL";
export const URLS_SEARCH = "URLS_SEARCH";
export const URL_CREATE = "URL_CREATE";
export const URL_UPDATE = "URL_UPDATE";
export const URL_REMOVE = "URL_REMOVE";
export const URLS_RESET = "URLS_RESET";
export const URL_CLEAR = "URL_CLEAR";
export const URL_CHECK = "URL_CHECK";

// PAGE
export const PAGES_WATCH = "PAGES_WATCH";
export const PAGES_SCROLL = "PAGES_SCROLL";
export const PAGE_CREATE = "PAGE_CREATE";
export const PAGE_UPDATE = "PAGE_UPDATE";
export const PAGE_REMOVE = "PAGE_REMOVE";
export const PAGES_RESET = "PAGES_RESET";

// BANNER
export const BANNERS_WATCH = "BANNERS_WATCH";
export const BANNER_CREATE = "BANNER_CREATE";
export const BANNER_UPDATE = "BANNER_UPDATE";
export const BANNER_REMOVE = "BANNER_REMOVE";
export const BANNERS_RESET = "BANNERS_RESET";

// COUPON
export const COUPONS_WATCH = "COUPONS_WATCH";
export const COUPON_CREATE = "COUPON_CREATE";
export const COUPON_UPDATE = "COUPON_UPDATE";
export const COUPON_REMOVE = "COUPON_REMOVE";
export const COUPONS_RESET = "COUPONS_RESET";

// POST
export const POSTS_WATCH = "POSTS_WATCH";
export const POST_CREATE = "POST_CREATE";
export const POST_UPDATE = "POST_UPDATE";
export const POST_REMOVE = "POST_REMOVE";
export const POSTS_RESET = "POSTS_RESET";

// MEMO
export const MEMOS_WATCH = "MEMOS_WATCH";
export const MEMO_CREATE = "MEMO_CREATE";
export const MEMO_UPDATE = "MEMO_UPDATE";
export const MEMO_REMOVE = "MEMO_REMOVE";
export const MEMOS_RESET = "MEMOS_RESET";

// MODELE
export const MODELE_NEW_SKU = "MODELE_NEW_SKU";
export const MODELES_WATCH = "MODELES_WATCH";
export const MODELE_CREATE = "MODELE_CREATE";
export const MODELE_UPDATE = "MODELE_UPDATE";
export const MODELE_REMOVE = "MODELE_REMOVE";
export const MODELES_RESET = "MODELES_RESET";

// PRODUIT
export const PRODUITS_WATCH = "PRODUITS_WATCH";
export const PRODUIT_CREATE = "PRODUIT_CREATE";
export const PRODUIT_UPDATE = "PRODUIT_UPDATE";
export const PRODUIT_REMOVE = "PRODUIT_REMOVE";
export const PRODUIT_FIND_URL = "PRODUIT_FIND_URL";
export const PRODUIT_UPDATE_URL = "PRODUIT_UPDATE_URL";
export const PRODUIT_SEARCH_VALID_URL = "PRODUIT_SEARCH_VALID_URL";
export const PRODUIT_SEARCH = "PRODUIT_SEARCH";
export const PRODUIT_NEW_SKU = "PRODUIT_NEW_SKU";
export const PRODUITS_RESET = "PRODUITS_RESET";

export const PRODUIT_PHOTOS_WATCH = "PRODUIT_PHOTOS_WATCH";
export const PRODUIT_PHOTOS_RESET = "PRODUIT_PHOTOS_RESET";
export const PRODUIT_PHOTOS_ADD = "PRODUIT_PHOTOS_ADD";
export const PRODUIT_PHOTOS_REMOVE = "PRODUIT_PHOTOS_REMOVE";

export const LIST_PRODUIT_REQUEST = "LIST_PRODUIT_REQUEST";
export const LIST_PRODUIT_SUCCESS = "LIST_PRODUIT_SUCCESS";
export const LIST_PRODUIT_FAILURE = "LIST_PRODUIT_FAILURE";
export const LIST_PRODUIT_ERROR = "LIST_PRODUIT_ERROR";

export const CREATE_PRODUIT_REQUEST = "CREATE_PRODUIT_REQUEST";
export const CREATE_PRODUIT_SUCCESS = "CREATE_PRODUIT_SUCCESS";
export const CREATE_PRODUIT_FAILURE = "CREATE_PRODUIT_FAILURE";
export const CREATE_PRODUIT_ERROR = "CREATE_PRODUIT_ERROR";

export const UPDATE_PRODUIT_REQUEST = "UPDATE_PRODUIT_REQUEST";
export const UPDATE_PRODUIT_SUCCESS = "UPDATE_PRODUIT_SUCCESS";
export const UPDATE_PRODUIT_FAILURE = "UPDATE_PRODUIT_FAILURE";
export const UPDATE_PRODUIT_ERROR = "UPDATE_PRODUIT_ERROR";

// VALEUR
export const CREATE_VALEUR_REQUEST = "CREATE_VALEUR_REQUEST";
export const CREATE_VALEUR_SUCCESS = "CREATE_VALEUR_SUCCESS";
export const CREATE_VALEUR_FAILURE = "CREATE_VALEUR_FAILURE";
export const CREATE_VALEUR_ERROR = "CREATE_VALEUR_ERROR";

// FOURNISSEUR
export const CREATE_FOURNISSEUR_REQUEST = "CREATE_FOURNISSEUR_REQUEST";
export const CREATE_FOURNISSEUR_SUCCESS = "CREATE_FOURNISSEUR_SUCCESS";
export const CREATE_FOURNISSEUR_FAILURE = "CREATE_FOURNISSEUR_FAILURE";

export const UPDATE_FOURNISSEUR_REQUEST = "UPDATE_FOURNISSEUR_REQUEST";
export const UPDATE_FOURNISSEUR_SUCCESS = "UPDATE_FOURNISSEUR_SUCCESS";
export const UPDATE_FOURNISSEUR_FAILURE = "UPDATE_FOURNISSEUR_FAILURE";

export const CREATE_CARACTERISTIQUE_REQUEST = "CREATE_CARACTERISTIQUE_REQUEST";
export const CREATE_CARACTERISTIQUE_SUCCESS = "CREATE_CARACTERISTIQUE_SUCCESS";
export const CREATE_CARACTERISTIQUE_FAILURE = "CREATE_CARACTERISTIQUE_FAILURE";

export const UPDATE_CARACTERISTIQUE_REQUEST = "UPDATE_CARACTERISTIQUE_REQUEST";
export const UPDATE_CARACTERISTIQUE_SUCCESS = "UPDATE_CARACTERISTIQUE_SUCCESS";
export const UPDATE_CARACTERISTIQUE_FAILURE = "UPDATE_CARACTERISTIQUE_FAILURE";

export const REMOVE_CARACTERISTIQUE_REQUEST = "REMOVE_CARACTERISTIQUE_REQUEST";
export const REMOVE_CARACTERISTIQUE_SUCCESS = "REMOVE_CARACTERISTIQUE_SUCCESS";
export const REMOVE_CARACTERISTIQUE_FAILURE = "REMOVE_CARACTERISTIQUE_FAILURE";

export const SEARCH = "SEARCH";
export const SEARCH_CARACTERISTIQUE = "SEARCH_CARACTERISTIQUE";
export const SEARCH_VALEUR = "SEARCH_VALEUR";
export const SEARCH_COLLECTION = "SEARCH_COLLECTION";
export const SEARCH_FOURNISSEUR = "SEARCH_FOURNISSEUR";

export const PAGES_LOADING = "PAGES_LOADING";
export const PAGES_SUCCESS = "PAGES_SUCCESS";
export const PAGES_ERROR = "PAGES_ERROR";

export const PRODUITS_LOADING = "PRODUITS_LOADING";
export const PRODUITS_SUCCESS = "PRODUITS_SUCCESS";
export const PRODUITS_ERROR = "PRODUITS_ERROR";

export const CONTACTS_LOADING = "CONTACTS_LOADING";
export const CONTACTS_SUCCESS = "CONTACTS_SUCCESS";
export const CONTACTS_ERROR = "CONTACTS_ERROR";
