import React, { Component } from "react";
import Boutiques from "../Boutique/components/Admin/index";
import Schemas from "../Schema/components/Admin/index";
import Users from "../User/components/Admin/index";
import Transfert from "./Transfert";

class Admin extends Component {
  render() {
    switch (this.props["*"]) {
      case "boutiques":
        return <Boutiques {...this.props} />;

      case "schemas":
        return <Schemas {...this.props} />;

      case "users":
        return <Users {...this.props} />;

      case "transfert":
        return <Transfert {...this.props} />;

      default:
        return (
          <div>
            Problème de configuration, le type '{this.props["*"]}' n'existe pas
          </div>
        );
    }
  }
}

export default Admin;
