import { connect } from "react-redux";
import List from "../../../../../CMS/containers/Boutique/components/List";
import Row from "./Row";
import vente from "../../params.json";

import { initialize, validate } from "../../functions";

import moment from "moment";
import React from "react";
import ActionBar from "../../../../../CMS/components/ActionBar";
import Button from "../../../../../CMS/components/Button";
import Vente from "../../../../../CMS/datas/Vente";

class VentesList extends List {
  collectionName = "ventes";
  defaultOrderField = "saleDate";
  defaultOrderDirection = "desc";
  listTitle = "Ventes";
  viewList = Row;
  itemTitle = { type: "attribut", attribut: "client_name" };
  initializeItem = initialize;
  validateItem = validate;
  itemParams = vente;
  messageNoDocument = "Aucune vente";

  constructor(props) {
    super(props);

    this.state.list.menu = null;
    this.state.form.newItemLibelle = "Nouvelle vente";
    this.state.form.required = ["montant"];

    this.state.form.menu.left = [];

    this.state.form.menu.right = [
      {
        icon: "fa-close",
        onClick: this.handleClose
      }
    ];

    this.state.defaultValues = {
      wheres: {
        saleDate: [
          {
            operator: ">",
            value: moment()
              .subtract(1, "months")
              .format("YYYY-MM-DD")
          },
          {
            operator: "<=",
            value: moment().format("YYYY-MM-DD")
          }
        ]
      }
    };

    this.state.custom = {
      wheres: {
        saleDate: [
          {
            operator: ">",
            value: moment()
              .subtract(1, "months")
              .format("YYYY-MM-DD")
          },
          {
            operator: "<=",
            value: moment().format("YYYY-MM-DD")
          }
        ],
        origine: ""
      }
    };

    this.handleChangeDate = this.handleChangeDate.bind(this);
    this.handleTransfertToProject = this.handleTransfertToProject.bind(this);
  }

  handleTransfertToProject(e) {
    e.preventDefault();
    if (!this.props.boutique.get("firebaseApiKey")) {
      console.error(
        "Il n'est pas possible de transférer les ventes car le projet n'est pas configuré"
      );
    }

    Vente.transfertToBoutique(this.props.boutique).then(result => {
      console.info("result", result);
    });
  }

  handleChangeDate(index, value) {
    this.setState(
      oldState => {
        oldState.custom.wheres.saleDate[index].value = value;
        return oldState;
      },
      () => {
        this.showDocs(this.props.boutique);
      }
    );
  }

  addActionBarToRender() {
    const dateBefore =
      this.state.custom.wheres.saleDate && this.state.custom.wheres.saleDate[0]
        ? this.state.custom.wheres.saleDate[0].value
        : "";

    const dateAfter =
      this.state.custom.wheres.saleDate && this.state.custom.wheres.saleDate[1]
        ? this.state.custom.wheres.saleDate[1].value
        : "";
    return (
      <ActionBar className="action-bar is-clearfix">
        <ul>
          <li className="navbar-item">
            <Button icon="fa-plus" onClick={this.handleCreate} />
          </li>{" "}
          <li className="navbar-item">
            <Button
              icon="fa-download"
              onClick={this.handleTransfertToProject}
            />
          </li>
        </ul>
        <ul className="border-left">
          <li className="navbar-item">
            <input
              type="date"
              value={dateBefore}
              onChange={e => this.handleChangeDate(0, e.currentTarget.value)}
            />
          </li>
          <li className="navbar-item">
            <input
              type="date"
              value={dateAfter}
              onChange={e => this.handleChangeDate(1, e.currentTarget.value)}
            />
          </li>
        </ul>

        <ul className="border-left">
          <li className="navbar-item feature select">
            <select
              onChange={e => this.handleChangeCustomWheres(e, "origine")}
              value={this.state.custom.wheres.origine}
            >
              <option value="">-- Choisir une origine --</option>
              <option value="achat">Achat</option>
              <option value="commande">Commande</option>
            </select>
          </li>
        </ul>

        {this.state.custom.fournisseurs &&
          this.state.custom.fournisseurs.length > 0 && (
            <ul className="border-left">
              <li className="navbar-item feature select">
                <select
                  onChange={e =>
                    this.handleChangeCustomWheres(e, "fournisseur")
                  }
                  value={this.state.custom.wheres.fournisseur}
                >
                  <option value="">-- Choisir un fournisseur --</option>
                  {this.state.custom.fournisseurs.map((fournisseur, index) => (
                    <option key={index} value={fournisseur.id}>
                      {fournisseur.data().nom}
                    </option>
                  ))}
                </select>
              </li>
            </ul>
          )}
        {this.state.custom.types &&
          this.state.custom.types.length > 0 && (
            <ul className="border-left">
              <li className="navbar-item feature select">
                <select
                  onChange={e => this.handleChangeCustomWheres(e, "type")}
                  value={this.state.custom.wheres.type}
                >
                  <option value="">-- Choisir un type --</option>
                  {this.state.custom.types.map((type, index) => (
                    <option key={index} value={type.id}>
                      {type.data().libelle}
                    </option>
                  ))}
                </select>
              </li>
            </ul>
          )}
      </ActionBar>
    );
  }

  addChildrenBeforeToRender() {
    if (!this.props.docs || this.props.docs.length === 0) return null;
    const total = this.props.docs.reduce((total, vente) => {
      return parseFloat(vente.data().montant) + total;
    }, 0);
    return <div>Total des ventes : {Math.round(total * 100) / 100} €</div>;
  }
}

const mapStateToProps = (globalState, ownProps) => {
  const props = {};
  props.docs = globalState.boutique.docs;

  return props;
};

// const mapDispatchToProps = (dispatch, ownProps) => {
//   return {
//     searchCategories: boutique =>
//       dispatch(searchInCollection(boutique.ref, "productCategories"))
//   };
// };

export default connect(
  mapStateToProps,
  null
)(VentesList);
