import React, {Component} from 'react'
import {connect} from 'react-redux'
import CMSList from '../../../../components/View/List'
import Row from '../Admin/Row';
import schema from '../../params';

import {
    initialize,
    validate
} from '../../functions';

import {
    watch,
    create,
    update,
    remove,
    reset
} from '../../actions';

import {create as createNotification} from "../../../Notification/actions";

class List extends Component {
    constructor(props) {
        super(props);

        this.state = {
            index: null,
            item: null,
            ref: null,
            view: null
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleCreate = this.handleCreate.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleShowDatas = this.handleShowDatas.bind(this);
    }

    componentWillMount() {
        if(this.props.espaceRef) {
            this.showSchemas(this.props.espaceRef);
        }
    }

    componentWillReceiveProps(nextProps) {
        if(!this.props.espaceRef && nextProps.espaceRef) {
            this.showSchemas(nextProps.espaceRef);
        }
    }

    componentWillUnmount() {
        this.props.reset();
    }

    showSchemas = (ref) => {
        this.props.loadSchemas(ref);
    };

    handleCreate = (event) => {
        // Création d'un nouveau schema vide
        const item = initialize(schema);
        this.setState({
            item: item,
            view: 'item',
            index: null,
            ref: null
        }, () => {
            this.props.createNotification({
                type: 'info',
                message: "Initialisation d'un nouveau schema réussie"
            });
        });
    };

    handleDelete = (event) => {
        if(this.state.index !== null && this.state.index >= 0 && this.props.docs[this.state.index]) {
            const confirmation = window.confirm('Voulez-vous supprimer ce schema ?');
            if(confirmation) {
                this.props.remove(this.props.docs[this.state.index].ref).then((action) => {
                    console.info('remove schema', action);
                    this.setState({
                        item: null,
                        index: null,
                        ref: null
                    }, () => {
                        this.props.createNotification({
                            type: 'info',
                            message: "Suppression du schema réussie"
                        });
                    });
                })
            }
        } else {
            this.props.createNotification({
                type: 'error',
                message: "Erreur de paramètre lors de la suppression du schema"
            });
        }
    };

    handleSubmit = (item) => {
        if(this.state.ref) {
            this.props.update(this.state.ref, item);
        } else {
            this.props.create(item, this.props.espace).then((action) => {
                console.info("creation d'un schema", action);
                if(!action.error) {
                    this.setState({
                        ref: action.payload
                    }, () => {
                        this.props.createNotification({
                            type: 'info',
                            message: "Création d'un schema réussie"
                        });
                    });
                }
            });
        }
    };

    handleClose = () => {
        this.setState({
            index: null,
            item: null,
            ref: null
        });
    };

    handleChange = (index, event) => {
        this.setState({
            index: index,
            ref: this.props.docs[index].ref,
            item: this.props.docs[index].data(),
            view: 'item'
        });
    };

    handleShowDatas = (index, event) => {
        this.setState({
            index: index,
            ref: this.props.docs[index].ref,
            item: this.props.docs[index].data(),
            view: 'datas'
        });
    };

    render() {
        let propsList = {
            loading: this.props.loading,
        };

        return (
            <CMSList {...propsList}>
                {this.props.docs ? (
                    <ol>
                        {Object.values(this.props.docs).map((doc, index) =>
                            <Row key={index}
                                 onClickEdit={(event) => this.handleChange(index, event)}
                                 onClickDatas={(event) => this.handleShowDatas(index, event)}
                                 doc={doc}/>
                        )}
                    </ol>
                ) : (
                    <div>Aucun schéma</div>
                )}
            </CMSList>
        )
    }
}

const mapStateToProps = (globalState, ownProps) => {
    const props = {};

    props.espaceRef = ownProps.itemRef;
    props.loading = globalState.cms.loading;
    props.docs = globalState.cms.schemas;

    return props;
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        loadSchemas: (ref) => {
            dispatch(watch(ref));
        },
        create: (item, espace) => {
            return dispatch(create(validate(item), espace));
        },
        update: (ref, item) => {
            dispatch(update(ref, validate(item)));
        },
        remove : (id) => dispatch(remove(id)),
        reset: () => dispatch(reset()),
        createNotification: (notification) => dispatch(createNotification(notification))
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(List);