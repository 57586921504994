import styled from 'styled-components'

export default styled.div`
  margin-bottom: 3rem;
  
    .steps-process .step-index-inner {
      position: inherit;
      width: auto;
      height: auto;
    }
    
    .steps-process-horizontal {
      margin-top: 1.5rem;
      text-align: center;
    
      li {
        display: inline-block;
        text-align: center;
        width: 100px;
      }
    
      .step-index {
        border: 2px solid #d1d7e0;
        border-radius: 50%;
        display: block;
        height: 26px;
        margin: 0 auto 10px;
        position: relative;
        width: 26px;
    
        &:not(.step-last):after {
          background-color: #d1d7e0;
          content: "";
          display: block;
          height: 2px;
          left: 28px;
          position: absolute;
          top: 11px;
          width: 66px;
        }
      }
    
      .step-first {
        left: auto;
        position: relative;
      }
    
      .is-completed {
        .step-index {
          border-color: #65c96d;
          &:after {
            //background-color: #65c96d;
          }
        }
    
        .step-completed-icon {
          display: block;
        }
      }
    
      .step-completed-icon {
        color: #65c96d;
        display: none;
        font-size: 14px;
        margin: 1px 0;
        
        &:before {
          content: "\\2713";
        }
      }
    
      .is-active .step-index {
        background-color: #4b71fc;
        border-color: #4b71fc;
      }
    }
    
    .steps-process .is-completed .step-number {
      display: none;
    }
    
    .step-number {
      color: #d1d7e0;
      font-weight: 600;
      line-height: 22px;
    }
    
    .steps-process .is-active .step-number {
      color: #fff;
    }
    
    .step-number, .step-message {
      font-size: 13px;
    }
    
    .step-message {
      color: inherit;
    }

    .analytics {
      display: flex;
      flex-direction: row;
      
      background-color: #4b71fc;
      border: 1px solid #4b71fc;
      border-radius: 2px;
      
      .total {
        border-right: 1px solid rgba(215, 221, 244, 0.2);
        
        padding: 0.75rem 1.5rem;
        text-align: center;
        label {
          display: block;
          color: #fff;
          text-transform: uppercase;
          font-size: 12px;
          line-height: 1.6rem;
        }
        span {
          color: #fff;
          display: block;
          font-size: 40px;
          line-height: 4.2rem;
        }
      }
      
      .infos {
          flex: 1;
          
          h2 {
              margin: 0.75rem 0 0 1.5rem;
              display: block;
              color: #fff;
              text-transform: uppercase;
              font-size: 12px;
              line-height: 1.6rem;
          }
          
          div.datas {
              display: flex;
              flex-direction: row;
              align-items: center;
              
              > div {
                // flex: 1;
                padding: 0 1.5rem;
                margin-top: 0.75rem;
                margin-bottom: 0.75rem;
                border-right: 1px solid rgba(215, 221, 244, 0.2);
                
                label {
                  display: block;
                  color: #fff;
                  font-size: 12px;
                }
                
                span {
                  color: #fff;
                  display: block;
                  font-size: 30px;
                  line-height: 2rem;
                }
                :last-child {
                  border: none;
                }
              }
          }
          
          
      }
      
    }
`;