import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { Field, formValueSelector } from "redux-form";

import Text from "./Text";
import Checkbox from "./Checkbox";
import Radio from "./Radio";
import Date from "./Date";
import { connect } from "react-redux";
import Traductions from "../Traductions";

class Input extends PureComponent {
  normalize = (value, previousValue, allValues, previousAllValues) => {
    switch (this.props.normalize) {
      case "username":
        return value.replace(/[^A-Za-z_]/g, "").toLowerCase();
      default:
    }

    return value;
  };

  format = (value, name) => {
    if (this.props.format) {
      return moment(value).format(this.props.format);
    }
    return value;
  };

  // shouldComponentUpdate(nextProps, nextState) {
  //   return Object.keys(diff(this.props, nextProps)).length > 0;
  // }

  render() {
    let {
      className,
      label,
      description,
      placeholder,
      orientation,
      step,
      icon,
      type,
      prefix,
      name,
      onBlur,
      disabled,
      options,
      min,
      max,
      readOnly,
      style,
      isTranslatable,
      traductions
    } = this.props;

    type = type ? type : "text";

    if (prefix && name) {
      name = prefix + "." + name;
    }

    let Cmpt;

    switch (type) {
      case "checkbox":
        Cmpt = Checkbox;
        break;

      case "radio":
        Cmpt = Radio;
        break;

      case "date":
      case "datetime-local":
        Cmpt = Date;
        break;

      case "email":
      case "text":
      default:
        Cmpt = Text;
    }

    // TODO faire la liste des propriétés autorisés et supprimer les autres
    const props = {
      min,
      max,
      options,
      disabled,
      onBlur,
      className,
      description,
      label,
      placeholder,
      orientation,
      step,
      icon,
      type,
      name,
      style,
      component: Cmpt,
      normalize: this.normalize,
      format: this.format,
      readOnly: !!readOnly
    };

    return (
      <>
        <Field {...props} />
        {isTranslatable && traductions && (
          <Traductions {...props} traductions={traductions} />
        )}
      </>
    );
  }
}

Input.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired
};

const selector = formValueSelector("item");

export default connect(globalState => {
  return {
    traductions: selector(globalState, "traductions")
  };
})(Input);
