import List from "../../../../../CMS/containers/Boutique/components/List";
import { connect } from "react-redux";
import StyledList from "../../styles/List";
import Item from "./Item";
import demande from "../../params.json";
import actionObject from "../../../../../CMS/datas/Action";

import { initialize, validate } from "../../../../../Boutique/functions";

import { sendAnswer } from "../../actions";
import moment from "moment";

class DemandeList extends List {
  collectionName = "demandes";
  defaultOrderField = "createdAt";
  defaultOrderDirection = "asc";
  // defaultWheres = {
  //   hasResponse: false
  // };
  listTitle = "Demandes";
  viewList = Item;
  itemTitle = { type: "attribut", attribut: "contact_name" };
  initializeItem = initialize;
  validateItem = validate;
  itemParams = demande;
  messageNoDocument = "Aucune demande";
  listStyle = StyledList;

  constructor(props) {
    super(props, {
      defaultViewList: "list",
      tabsArray: [
        {
          label: "En attente",
          actif: true,
          wheres: {
            hasResponse: false
          }
        },
        {
          label: "+ d'infos",
          actif: false,
          wheres: {
            isChecked: false,
            hasMoreInfos: true
          }
        },
        {
          label: "Traitées",
          actif: false,
          wheres: {
            isChecked: true
          },
          orderField: "updatedAt",
          orderDirection: "desc"
        },
        {
          label: "Répondues",
          actif: false,
          wheres: {
            hasResponse: true
          },
          orderField: "updatedAt",
          orderDirection: "desc"
        }
      ],
      custom: {
        openModal: false
      }
    });

    this.state.form.menu.right = [
      {
        icon: "fa-close",
        onClick: this.handleClose
      },
      {
        icon: "fa-paper-plane",
        onClick: this.handleAnswer,
        primary: true
      }
    ];

    this.handleAnswer = this.handleAnswer.bind(this);
  }

  handleAnswer = event => {
    event.preventDefault();

    const boutique = this.props.boutique;
    if (
      !boutique.get("demandeMailSujet") ||
      !boutique.get("demandeMailText") ||
      !boutique.get("websiteURL") ||
      !boutique.get("fromName") ||
      !boutique.get("fromMail")
    ) {
      alert("problème de paramètre de la boutique");
      console.error("demandeMailSujet", boutique.get("demandeMailSujet"));
      console.error("demandeMailText", boutique.get("demandeMailText"));
      console.error("websiteURL", boutique.get("websiteURL"));
      console.error("fromName", boutique.get("fromName"));
      console.error("fromMail", boutique.get("fromMail"));
      return false;
    }

    const _this = this;
    this.props.item.hasResponse = true;
    this.props.item.isChecked = true;
    this.props.item.updatedAt = moment().toISOString();
    this.props.item.answeredAt = moment().toISOString();
    if (!this.props.item.reponse) {
      this.props.item.reponse = this.props.boutique.get("demandeReponseAuto")
        ? this.props.boutique.get("demandeReponseAuto")
        : "";
    }

    const id = this.state.ref.id;
    this.props
      .updateDocumentByRef(
        this.actionTypeUpdate,
        this.state.ref,
        this.props.item
      )
      .then(action => {
        if (!action.error) {
          if (!boutique.get("firebaseProjectId")) {
            sendAnswer(id, boutique).then(action => {
              if (!action.error) {
                _this.props.createNotification({
                  type: "info",
                  message: "Envoi réussi"
                });
              } else {
                _this.props.createNotification({
                  type: "error",
                  message: action.message
                });
              }
            });
          } else {
            const data = {
              id: id,
              subject: boutique.get("demandeMailSujet"),
              template: boutique.get("demandeMailText"),
              websiteURL: boutique.get("websiteURL"),
              fromName: boutique.get("fromName"),
              fromMail: boutique.get("fromMail")
            };

            if (boutique.get("responseMail")) {
              data.replyTo = boutique.get("responseMail");
            }

            actionObject.create(boutique, "demande-send", data).then(action => {
              _this.props.createNotification({
                type: "info",
                message: "Envoi programmé"
              });
            });
          }

          _this.handleClose();
        } else {
          _this.props.createNotification({
            type: "error",
            message: "Erreur lors de la mise à jour"
          });
        }
      });
  };
}

const mapStateToProps = (globalState, ownProps) => {
  const props = {};
  props.docs = globalState.boutique.docs;
  if (globalState.form.item) {
    props.item = globalState.form.item.values;
  }

  return props;
};

export default connect(
  mapStateToProps,
  null
)(DemandeList);
