import moment from "moment";
import uuid from "uuid/v4";
import * as functions from "../../functions";

/**
 * Cette fonction permet d'initialiser une boutique
 *
 * @param data
 * @returns {{createdAt, deleted}}
 */
export function initialize(data) {
  const objet = functions.initialize(data);
  return {
    ...objet,
    applicationId: uuid()
  };
}

/**
 * Cette fonction permet de valider une boutique
 *
 * @param document
 * @returns {*}
 */
export function validate(document) {
  document.updatedAt = moment().toISOString();

  return document;
}
