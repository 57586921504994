import React, { Component } from "react";
import { connect } from "react-redux";
import CMSList from "../../../../components/View/List";
import CMSForm from "../../../../components/View/Form";
import CMSView from "../../../../components/View/index";
import CMSData from "../../../../components/Data/index";
import Row from "./Row";
import boutique from "../../params.json";

import { initialize, validate } from "../../functions";

import { create, update, remove } from "../../actions";

class List extends Component {
  constructor(props) {
    super(props);

    this.state = {
      item: null,
      itemRef: null
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCreate = this.handleCreate.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleCreate = event => {
    // Création d'une nouvelle boutique vide
    this.setState({
      item: initialize(boutique),
      itemRef: null
    });
  };

  handleDelete = event => {
    if (window.confirm("Voulez-vous vraiment supprimer cette boutique ?")) {
      this.props.remove(this.state.itemRef).then(action => {
        // TODO Afficher un message de confirmation
        this.setState({
          item: null,
          itemRef: null
        });
      });
    }
  };

  handleSubmit = item => {
    if (this.state.itemRef) {
      this.props.update(this.state.itemRef, item);
    } else {
      this.props.create(item).then(action => {
        if (!action.error) {
          this.setState({
            item,
            itemRef: action.payload.ref
          });
        }
      });
    }
  };

  handleClose = () => {
    this.setState({
      item: null,
      itemRef: null
    });
  };

  handleChange = (index, event) => {
    this.setState({
      item: this.props.boutiques[index].data(),
      itemRef: this.props.boutiques[index].ref
    });
  };

  render() {
    let propsList = {
      title: "Boutiques",
      loading: this.props.loading,
      menu: {
        left: [
          {
            icon: "fa-plus",
            onClick: this.handleCreate
          },
          {
            icon: "fa-trash",
            onClick: this.handleDelete
          }
        ]
      }
    };
    let propsForm = {
      initialValues: this.state.item,
      required: ["name"]
    };

    if (this.state.item) {
      propsForm.menu = {
        left: [
          {
            className: "delete",
            icon: "fa-trash",
            onClick: this.handleDelete
          }
        ],
        right: [
          {
            icon: "fa-close",
            onClick: this.handleClose
          },
          {
            icon: "fa-save",
            submit: true
          }
        ]
      };
    }

    return (
      <CMSView className="cms__boutique">
        {this.state.item ? (
          <CMSForm
            {...propsForm}
            title={{ type: "attribut", attribut: "name" }}
            onSubmit={this.handleSubmit}
          >
            <CMSData
              data={boutique}
              itemRef={this.state.itemRef}
              user={this.props.user}
            />
          </CMSForm>
        ) : (
          <CMSList {...propsList}>
            {this.props.boutiques ? (
              this.props.boutiques.length > 0 ? (
                <ol>
                  {Object.values(this.props.boutiques).map((doc, index) => (
                    <Row
                      key={index}
                      onClick={event => this.handleChange(index, event)}
                      doc={doc}
                    />
                  ))}
                </ol>
              ) : (
                <div>Aucune boutique</div>
              )
            ) : (
              <div>Chargement en cours</div>
            )}
          </CMSList>
        )}
      </CMSView>
    );
  }
}

const mapStateToProps = (globalState, ownProps) => {
  const props = {};
  props.boutiques = globalState.cms.boutiques;

  return props;
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    create: item => dispatch(create(validate(item))),
    update: (ref, item) => dispatch(update(ref, validate(item))),
    remove: ref => dispatch(remove(ref))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(List);
