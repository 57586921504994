import React from "react";
import moment from "moment";

/**
 * Ce composant permet d'afficher une demande dans la liste des demandes
 *
 * @param doc
 * @param onClick
 * @returns {*}
 */
export default ({ doc, onClick }) => {
  const item = doc.data();
  const numero = doc.data().numero ? doc.data().numero : doc.id;

  let totalProduits = 0,
    totalPaiements = 0,
    livraison = 0;

  // Traitement des produits de la demande
  if (item.produits && item.produits.length > 0) {
    item.produits.forEach(produit => {
      if (produit.price) {
        totalProduits += parseFloat(produit.price) * parseInt(produit.qty, 10);
        if (produit.discount) {
          // Il y a une remise
          switch (produit.discountType) {
            case "pourcentage":
              totalProduits -=
                (produit.price *
                  parseInt(produit.qty, 10) *
                  parseFloat(produit.discount)) /
                100;
              break;

            default:
              totalProduits -= parseFloat(produit.discount);
          }
        }
      }
    });
  }

  const packingCost = item.packingCost ? parseFloat(item.packingCost) : 0;
  // // Traitement des paiements
  // if(item.paiements && item.paiements.length>0) {
  //     item.paiements.forEach(paiement => {
  //         if(paiement.montant) {
  //             totalPaiements +=  parseFloat(paiement.montant);
  //         }
  //     });
  // }
  let totalCommande = totalProduits + packingCost;

  if (item.deliveryCost && !Number.isNaN(parseFloat(item.deliveryCost))) {
    livraison = parseFloat(item.deliveryCost);
  }

  if (!!item.isPaidToCarrier === false && livraison > 0) {
    totalCommande += livraison;
  }

  // TODO utiliser le amount_cashed et amount_canceled pour calculer le pourcentage
  if (item.amount_cashed && !Number.isNaN(parseFloat(item.amount_cashed))) {
    totalPaiements = parseFloat(item.amount_cashed);
  }
  if (item.amount_canceled && !Number.isNaN(parseFloat(item.amount_canceled))) {
    totalPaiements -= parseFloat(item.amount_canceled);
  }

  // le amount_refunded ne peut pas être pris en compte car
  // c'est uniquement dans le cas de figure pour il y a un remboursement après commande
  let pourcentage;
  if (totalCommande > 0) {
    pourcentage = totalPaiements
      ? Math.round((totalPaiements * 100) / totalCommande)
      : 0;
  } else {
    pourcentage = 100;
  }

  const steps = [];

  // Si etat est validé = validated
  switch (item.etat) {
    case "validated": {
      switch (item.productsStatus) {
        case "waiting_production":
          steps.push({
            className: "fabrication",
            message: "Fab."
          });
          break;

        case "waiting_preparation":
          steps.push({
            className: "preparation",
            message: "Prepa."
          });
          break;

        case "waiting_shipment":
          steps.push({
            className: "expedition",
            message: "Exp."
          });
          break;

        case "waiting_delivery":
          let className = "delivery";
          if (
            item.effectiveDate &&
            moment(item.effectiveDate).isBefore(moment())
          ) {
            className = "is-completed";
          }
          steps.push({
            className: className,
            message: "Livraison"
          });
          break;

        case "completed":
          steps.push({
            className: "completed"
          });
          break;

        case "waiting_return":
          steps.push({
            className: "waiting_return",
            message: "Retour"
          });
          break;
        default:
      }
      break;
    }

    case "waiting":
    case "manual-validation": {
      switch (item.paymentStatus) {
        case "waiting":
          steps.push({
            className: "paymentStatus_waiting",
            message: "Paiement"
          });
          break;
        case "waiting_capture":
          steps.push({
            className: "paymentStatus_waiting_capture",
            message: "Capture"
          });
          break;

        default:
      }
      break;
    }

    case "canceled": {
      break;
    }
    default:
  }

  const classNameTab = [];
  classNameTab.push(`etat_${item.etat}`);
  classNameTab.push(`productsStatus_${item.productsStatus}`);
  classNameTab.push(`paymentStatus_${item.paymentStatus}`);

  // if (isValidated) {
  //   classNameTab.push("isValidated");
  // }
  // if (pourcentage === 100) {
  //   classNameTab.push("cent");
  // } else {
  //   if (item.status === "validated" && pourcentage === 0) {
  //     classNameTab.push("zero");
  //   }
  // }

  classNameTab.push(item.environment);

  let date = item.deliveryDate;

  let name = undefined;
  if (doc.get("contact_commercialName")) {
    name = doc.get("contact_commercialName");
  } else {
    const tmpNames = [];
    if (doc.get("contact_firstname")) {
      tmpNames.push(doc.get("contact_firstname"));
    }
    if (doc.get("contact_lastname")) {
      tmpNames.push(doc.get("contact_lastname"));
    }
    name = tmpNames.join(" ");
  }
  return (
    <li onClick={onClick} className={classNameTab.join(" ")}>
      <div className="numero">
        <span>{numero}</span>
      </div>
      <div className="date">
        <span className="day">{moment(date).format("Do")}</span>
        <span className="month">{moment(date).format("MMM")}</span>
      </div>
      <div className="demande">
        <strong className="client">{name}</strong>
        <ul className="produits">
          {item.produits &&
            item.produits.map((produit, index) => (
              <li key={index}>{produit.name}</li>
            ))}
        </ul>
      </div>
      {totalCommande > 0 ? (
        <div className="total">
          <span className="montant">{totalCommande} €</span>
          {pourcentage > 0 && <span className="paiement">{pourcentage}%</span>}
        </div>
      ) : (
        <div className="total">
          <span className="montant">Offert</span>
          {pourcentage > 0 && <span className="paiement">{pourcentage}%</span>}
        </div>
      )}
      {steps.length > 0 && (
        <ul className="status">
          <li className={steps[steps.length - 1].className}>
            {steps[steps.length - 1].message}
          </li>
        </ul>
      )}

      <div className="indicator" />
    </li>
  );
};
