import moment from "moment";

const voir = data => {
  let tmpData = {};

  if (data.datas) {
    data.datas.forEach(tmp => {
      const result = voir(tmp);
      Object.keys(result).forEach(temp => {
        // temp est un objet -> on doit iterer sur les key
        if (typeof temp === "object") {
          Object.keys(temp).forEach(key => {
            tmpData[key] = "";
          });
        } else {
          tmpData[temp] = "";
        }
      });
    });
  }

  if (data.name) {
    return {
      [data.name]: Object.keys(tmpData).length > 0 ? tmpData : ""
    };
  }

  return tmpData;
};

export function initialize(data) {
  let objet = {};

  const result = voir(data);
  Object.keys(result).forEach(temp => {
    // temp est un objet -> on doit iterer sur les key
    if (typeof temp === "object") {
      Object.keys(temp).forEach(key => {
        objet[key] = "";
      });
    } else {
      objet[temp] = "";
    }
  });

  return {
    ...objet,
    createdAt: moment().toISOString(),
    deleted: false
  };
}

export function validate(item) {
  item.updatedAt = moment().toISOString();
  // item.updatedAt = new Date();

  return item;
}
