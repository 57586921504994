import { getFirestore } from "../../Boutique/functions";

class Vente {
  static search(boutique, wheres) {
    let query = getFirestore(boutique)
      .collection("ventes")
      .where("boutique", "==", boutique.id)
      .where("deleted", "==", false);

    if (wheres && Object.keys(wheres).length > 0) {
      Object.keys(wheres).forEach(key => {
        if (wheres[key]) {
          query = query.where(String(key), "==", wheres[key]);
        } else {
          if (parseInt(wheres[key], 10) === 0) {
            query = query.where(String(key), "==", 0);
          } else {
            query = query.where(String(key), "==", false);
          }
        }
      });
    }
    return query.get();
  }

  /**
   * Cette methode permet de regarder les ventes d'une boutique
   *
   * @param boutique
   * @param wheres
   * @param next
   * @returns {unsubscribe}
   */
  static watch(boutique, wheres, next) {
    let query = getFirestore(boutique)
      .collection("ventes")
      .where("boutique", "==", boutique.id)
      .where("deleted", "==", false);

    if (wheres && Object.keys(wheres).length > 0) {
      Object.keys(wheres).forEach(key => {
        if (wheres[key]) {
          query = query.where(String(key), "==", wheres[key]);
        } else {
          if (parseInt(wheres[key], 10) === 0) {
            query = query.where(String(key), "==", 0);
          } else {
            query = query.where(String(key), "==", false);
          }
        }
      });
    }
    return query.onSnapshot(next);
  }
  static transfertToBoutique(boutique) {
    const batchSandwich = getFirestore().batch();
    let nbPageTransfered = 0;
    return getFirestore()
      .collection("ventes")
      .where("boutique", "==", boutique.id)
      .limit(500)
      .get()
      .then(querySnpashot => {
        if (!querySnpashot.empty) {
          let batch = getFirestore(boutique).batch();

          let newRef = undefined,
            data = undefined;
          querySnpashot.docs.forEach(document => {
            nbPageTransfered++;
            newRef = getFirestore(boutique)
              .collection("ventes")
              .doc(document.id);
            data = document.data();
            delete data.boutiqueRef;
            delete data.commandeRef;
            batch.set(newRef, data, { merge: true });
            batchSandwich.delete(document.ref);
          });

          batchSandwich.commit();
          return batch.commit().then(() => {
            return nbPageTransfered;
          });
        } else {
          return 0;
        }
      });
  }

  /**
   * Recherche une vente par l'intermédiaire de l'id
   *
   * @param boutique
   * @param id
   * @returns {Promise<unknown>}
   */
  static findById(boutique, id) {
    return getFirestore(boutique)
      .collection("ventes")
      .doc(id)
      .get()
      .then(documentSnapshot => {
        if (!documentSnapshot.exists) {
          throw new Error("La vente n'existe pas");
        }

        if (documentSnapshot.get("boutique") !== boutique.id) {
          throw new Error("Cette vente n'appartient pas à cette boutique");
        }

        if (documentSnapshot.get("deleted")) {
          throw new Error("Cette vente a été supprimé");
        }

        return documentSnapshot;
      });
  }
}

export default Vente;
