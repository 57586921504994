import React, { Component } from "react";
import { connect } from "react-redux";
import StyledResume from "../../styles/Resume";
import { loadByCommandeId } from "../../../Paiement/actions";
import Vente from "../../../../../CMS/datas/Vente";

class Resume extends Component {
  state = {
    id: undefined,
    shouldLoadPaiements: false,
    paiements: undefined,
    unsubscribe: undefined
  };

  constructor(props) {
    super(props);

    this.loadPaiements = this.loadPaiements.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    if (props.id && (!state.commandeId || state.commandeId !== props.id)) {
      state.commandeId = props.id;
      state.paiements = undefined;
      state.shouldLoadPaiements = true;
    }

    return state;
  }

  componentDidMount() {
    if (this.state.shouldLoadPaiements) {
      this.setState(
        {
          shouldLoadPaiements: false
        },
        () => {
          this.loadPaiements();
        }
      );
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.state.shouldUpdateFields) {
      this.setState(
        {
          shouldLoadPaiements: false
        },
        () => {
          this.loadPaiements();
        }
      );
    }
  }

  loadPaiements() {
    if (this.state.unsubscribe) {
      this.state.unsubscribe();
    }

    const unsubscribe = Vente.watch(
      this.props.boutique,
      {
        commande: this.state.commandeId
      },
      querySnaphost => {
        this.setState({
          paiements: querySnaphost.docs
        });
      }
    );

    this.setState({
      unsubscribe
    });
  }

  render() {
    const { item } = this.props;

    if (item && item.produits) {
      let totalProduits = 0,
        livraison = 0; // hasFabricable = false;

      if (item.produits && item.produits.length > 0) {
        item.produits.forEach(produit => {
          if (produit.fabricable) {
            // hasFabricable = true;
          }
          totalProduits +=
            parseFloat(produit.price) * parseInt(produit.qty, 10);
          if (produit.discount) {
            // Il y a une remise
            switch (produit.discountType) {
              case "pourcentage":
                totalProduits -=
                  (parseFloat(produit.price) *
                    parseInt(produit.qty, 10) *
                    parseFloat(produit.discount)) /
                  100;
                break;

              default:
                totalProduits -= parseFloat(produit.discount);
            }
          }
        });
      }

      let totalPaiements = 0;
      if (this.state.paiements && this.state.paiements.length > 0) {
        totalPaiements = this.state.paiements.reduce(
          (totalPaiements, paiement) => {
            return totalPaiements + parseFloat(paiement.get("montant"));
          },
          0
        );
      }

      const packingCost = item.packingCost ? parseFloat(item.packingCost) : 0;

      // Le total est le prix des produits + des frais d'emballage
      let totalCommande = totalProduits + packingCost;

      if (item.deliveryCost && !Number.isNaN(parseFloat(item.deliveryCost))) {
        livraison = parseFloat(item.deliveryCost);
      }

      if (!!item.isPaidToCarrier === false && livraison > 0) {
        totalCommande += livraison;
      }

      const pourcentage = Math.round((totalPaiements * 100) / totalCommande);

      // let isValidated = pourcentage===100;
      // On verifie si la demande est validée = est une commande
      if (item.validation && item.validation !== "") {
        // Les conditions de validation sont définies
        if (item.validation === "fullPayment") {
          // totalité
          // isValidated = pourcentage>=100;
        } else {
          // acompte
          if (item.acompteValue && parseFloat(item.acompteValue) > 0) {
            switch (item.acompteType) {
              case "valeur":
                // isValidated = totalPaiements >= parseFloat(item.acompteValue);
                break;

              default:
              // isValidated = pourcentage >= parseFloat(item.acompteValue);
            }
          }
        }
      }

      // const steps = [];
      // // Si devis réalisé (répondu)
      // if(item.reponse) {
      //     steps.push({
      //         className: 'is-completed',
      //         message: 'Devis'
      //     });
      // }
      //
      // // Si commande
      // if(isValidated) {
      //     steps.push({
      //         className: 'is-completed',
      //         message: 'Commande'
      //     });
      //
      //     // Si un produit à fabriquer
      //     if(hasFabricable) {
      //         steps.push({
      //             className: parseInt(item.productionProgess, 10)===100 ? 'is-completed' : '',
      //             message: 'Fabrication'
      //         });
      //     }
      //
      //     // On vérifie si le paiement a été réalisé à 100%
      //     if (pourcentage === 100) {
      //         steps.push({
      //             className: 'is-completed',
      //             message: 'Paiement'
      //         });
      //     } else {
      //         steps.push({
      //             className: '',
      //             message: 'Paiement'
      //         });
      //     }
      //
      //     // Si transport ou retrait
      //     if(item.deliveryMethod) {
      //         let className = '';
      //         if(item.effectiveDate && moment(item.effectiveDate).isBefore(moment())) {
      //             className = 'is-completed';
      //         }
      //         if(item.deliveryMethod==='store') {
      //             steps.push({
      //                 className: className,
      //                 message: 'Retrait'
      //             });
      //         } else {
      //             steps.push({
      //                 className: className,
      //                 message: 'Livraison'
      //             });
      //         }
      //     }
      // }

      let soldeInfo = "";
      if (item.balancePayment) {
        switch (item.balancePayment) {
          case "retrait":
            soldeInfo = "au retrait";
            break;
          case "transporteur":
            soldeInfo = "au transporteur";
            break;

          case "expedition":
          default:
            soldeInfo = "avant l'expédition";
        }
      }

      return (
        <StyledResume>
          {/*{steps.length>0 && (*/}
          {/*<ul className='steps-process steps-process-horizontal'>*/}
          {/*{steps.map((step, index) =>*/}
          {/*<li key={index} className={step.className}>*/}
          {/*<p>*/}
          {/*<a className={'step-index' + (index===0 ? ' step-first':'') + (index===steps.length-1 ? ' step-last':'')}>*/}
          {/*<span className="step-index-inner">*/}
          {/*<span className="step-number">{index+1}</span>*/}
          {/*<span className="icon step-completed-icon"/>*/}
          {/*</span>*/}
          {/*</a>*/}
          {/*<a className="step-message">{step.message}</a>*/}
          {/*</p>*/}
          {/*</li>*/}
          {/*)}*/}
          {/*</ul>*/}
          {/*)}*/}
          <div className="analytics">
            <div className="total">
              <label>Total</label>
              <span>{totalCommande} €</span>
            </div>
            <div className="infos">
              <h2>Détails</h2>
              <div className="datas">
                <div className="produits">
                  <span>{totalProduits} €</span>
                  <label>
                    Produits (
                    {item.produits.length > 1
                      ? item.produits.length + " références"
                      : "1 référence"}
                    )
                  </label>
                </div>
                {packingCost > 0 && (
                  <div>
                    <span>{packingCost} €</span>
                    <label>Emballage</label>
                  </div>
                )}
                {livraison > 0 && (
                  <div>
                    <span>{item.deliveryCost} €</span>
                    <label>
                      Livraison {!!item.isPaidToCarrier && "(au transporteur)"}
                    </label>
                  </div>
                )}

                {pourcentage > 0 && (
                  <div>
                    <span>{totalPaiements} €</span>
                    <label>
                      Paiement ({pourcentage}
                      %)
                    </label>
                  </div>
                )}
                {pourcentage > 0 &&
                  pourcentage < 100 && (
                    <div className="solde">
                      <span>{totalCommande - totalPaiements} €</span>
                      <label>Solde {soldeInfo ? `(${soldeInfo})` : ""}</label>
                    </div>
                  )}
              </div>
            </div>
          </div>
          <div />
        </StyledResume>
      );
    }

    return null;
  }
}

const mapStateToProps = (globalState, ownProps) => {
  const props = {};

  props.item = globalState.form.item.values;

  return props;
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    loadPaiements: id => dispatch(loadByCommandeId(id))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Resume);
