import slug from "slug";
import firestore from "../../../../api/firestore";
import moment from "moment";
import {
  SCHEMAS_WATCH,
  SCHEMAS_LIST,
  SCHEMA_CREATE,
  SCHEMA_UPDATE,
  SCHEMA_REMOVE,
  SCHEMAS_RESET
} from "../../actionTypes";

moment.locale("fr");

slug.defaults.mode = "rfc3986";

export function watch(boutiqueId) {
  return dispatch => {
    let query = firestore.collection("schemas").where("deleted", "==", false);

    if (boutiqueId) {
      query = query.where("boutique", "==", boutiqueId);
    }

    return query.onSnapshot(function(querySnapshot) {
      dispatch({
        type: SCHEMAS_WATCH,
        docs: querySnapshot.docs
      });
    });
  };
}

export function list() {
  return {
    type: SCHEMAS_LIST,
    payload: firestore
      .collection("schemas")
      .where("deleted", "==", false)
      .get()
  };
}

export function listByBoutique(boutiqueId) {
  return {
    type: SCHEMAS_LIST,
    payload: firestore
      .collection("schemas")
      .where("boutique", "==", boutiqueId)
      .where("deleted", "==", false)
      .get()
  };
}

export function create(item) {
  return {
    type: SCHEMA_CREATE,
    payload: firestore.collection("schemas").add(item)
  };
}

export function update(ref, item) {
  return {
    type: SCHEMA_UPDATE,
    payload: ref.update(item)
  };
}

export function remove(ref) {
  if (process.env.REACT_APP_SOFT_DELETE === "no") {
    return {
      type: SCHEMA_REMOVE,
      payload: ref.delete()
    };
  } else {
    return {
      type: SCHEMA_REMOVE,
      payload: ref.update({
        deleted: true,
        updatedAt: moment().toISOString()
      })
    };
  }
}

export function reset() {
  return dispatch => {
    const unsubscribe = firestore
      .collection("schemas")
      .onSnapshot(function() {});
    unsubscribe();

    dispatch({
      type: SCHEMAS_RESET
    });
  };
}
