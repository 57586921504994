export default [
  {
    state: "omaje-planches",
    name: "Planches à découper",
    type: "link",
    className: "separator",
    to: "/calculatrices/omaje/planches",
    profil: "omaje-planches",
    acl: {
      "*": true
    }
  },
  {
    state: "feramesure-verriere",
    name: "Verrières",
    type: "link",
    className: "separator",
    to: "/calculatrices/feramesure/verrieres",
    profil: "feramesure-verriere",
    acl: {
      "*": true
    }
  },
  {
    state: "notcar-pdc",
    name: "Not'car",
    type: "link",
    className: "separator",
    to: "/calculatrices/notcar",
    profil: "notcar-pdc",
    acl: {
      "*": true
    }
  }
];
