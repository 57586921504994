import React, { Component } from "react";
import Dropzone from "react-dropzone";
import { FieldArray } from "redux-form";
import Media from "../../../CMS/datas/Media";
import Photo from "../Photo";
import StyledPhotos from "../../../CMS/styles/Photos";
import StyledTitle from "../../../CMS/styles/Title";
import svg from "../../../../images/dropzone.svg";
import { DragDropContext } from "react-dnd";
import HTML5Backend from "react-dnd-html5-backend";
import StyledModal from "../../../CMS/styles/Modal";
import { getDomainAssetsURL } from "../../functions";

class Photos extends Component {
  state = {
    photoIndex: null,
    loadingInProgress: false,
    rotationInProgress: false,
    previewPhotos: []
  };

  constructor(props, context) {
    super(props, context);

    this.onDrop = this.onDrop.bind(this);
    this.onOpenClick = this.onOpenClick.bind(this);
    this.handleOnShowPhoto = this.handleOnShowPhoto.bind(this);
    this.handleOnRotate = this.handleOnRotate.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
  }

  onDrop(files) {
    const _this = this;
    this.setState({
      loadingInProgress: true
    });
    const promises = [];
    const previewPhotos = [];
    files.forEach(image => {
      const preview = URL.createObjectURL(image);

      previewPhotos.push(preview);
      promises.push(
        Media.uploadPhoto(
          this.props.boutique,
          image,
          this.props.docType,
          this.props.id
        )
      );
    });

    this.setState({
      previewPhotos
    });

    Promise.all(promises)
      .then(values => {
        values.forEach(media => {
          if (media.get("fullPath")) {
            _this.props.fields.push(media.get("fullPath"));
          }
        });
        _this.setState({
          loadingInProgress: false,
          previewPhotos: []
        });
      })
      .catch(error => {
        //TODO afficher le message d'erreur -> Mettre en place un système de notification
        console.error(error.code, error.message);
        _this.setState({
          loadingInProgress: false,
          previewPhotos: []
        });
      });
  }

  onOpenClick(e) {
    e.preventDefault();
    this.refs.dropzone.open();

    return false;
  }

  handleOnShowPhoto(e, index) {
    e.preventDefault();

    this.setState({
      photoIndex: index
    });

    return false;
  }

  handleOnRotate(e, index) {
    e.preventDefault();
    const _this = this;
    this.setState({
      rotationInProgress: true
    });
    const _fields = this.props.fields;
    console.info("rotation de la photo", this.props.fields.get(index));
    Media.rotatePhoto(this.props.boutique, this.props.fields.get(index)).then(
      destinationPath => {
        console.info("la photo a été retournée", destinationPath);
        _fields.splice(index, 1, destinationPath);
        _this.setState({
          rotationInProgress: false
        });
      }
    );

    return false;
  }

  handleCloseModal(e) {
    e.preventDefault();

    this.setState({
      photoIndex: null
    });

    return false;
  }

  render() {
    const {
      label,
      fields,
      name,
      meta: { touched, error }
    } = this.props;

    return (
      <StyledPhotos className="cms__component--photos">
        <button
          className="is-pulled-right"
          id="upload-photo"
          onClick={this.onOpenClick}
        >
          Ajouter une photo
        </button>
        <StyledTitle>{label ? label : "Photos"}</StyledTitle>
        <div className="group">
          <Dropzone
            name={name}
            ref="dropzone"
            onDrop={this.onDrop}
            disableClick
            className={fields.length > 0 ? "dropzone-hide" : "dropzone"}
            activeClassName="dropzone-active"
            accept="image/*"
          >
            {fields.length === 0 && (
              <div className="first-upload">
                <img src={svg} alt="Upload files" />
                <h4>Déposer vos photos pour les télécharger</h4>
              </div>
            )}
          </Dropzone>
          {fields.length > 0 && (
            <div className="list">
              <ol>
                {fields.map((field, index) => (
                  <Photo
                    key={index}
                    index={index}
                    photo={fields.get(index)}
                    boutique={this.props.boutique}
                    fields={fields}
                    itemRef={this.props.itemRef}
                    handleOnShowPhoto={this.handleOnShowPhoto}
                    handleOnRotate={this.handleOnRotate}
                  />
                ))}
              </ol>
              <div className="is-clearfix" />
            </div>
          )}
          {touched && error && <span>{error}</span>}
          {this.state.loadingInProgress && (
            <div className="loading-in-progress">
              <span>Chargement en cours des photos suivantes</span>
              <ul>
                {this.state.previewPhotos.map((photo, index) => (
                  <li key={index} style={{ height: "60px", width: "60px" }}>
                    <figure className="aspect-ratio aspect-ratio--square">
                      <img
                        src={photo}
                        alt="chargement en cours"
                        className="aspect-ratio__content"
                      />
                    </figure>
                  </li>
                ))}
              </ul>
            </div>
          )}
          {this.state.rotationInProgress && <span>Rotation en cours</span>}
        </div>
        {this.state.photoIndex !== null && (
          <StyledModal>
            <div className="photo">
              <button
                onClick={e => this.handleCloseModal(e)}
                className="photo-close"
              >
                <span className="text">Fermer</span>
              </button>
              <div className="photo-content">
                <img
                  src={`${getDomainAssetsURL(
                    this.props.boutique
                  )}/${this.props.fields.get(this.state.photoIndex)}`}
                  alt={this.props.fields.get(this.state.photoIndex)}
                />
              </div>
            </div>
          </StyledModal>
        )}
      </StyledPhotos>
    );
  }
}

// Photos.propTypes = {
//   id: PropTypes.string.isRequired
// };

const DataPhotos = ({ id, prefix, name, ...others }) => {
  return <FieldArray id={id} {...others} component={Photos} name={name} />;
};

export default DragDropContext(HTML5Backend)(DataPhotos);
