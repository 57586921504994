import React, { Component } from "react";
import Button from "../../../CMS/components/Button";
import Field from "../../../CMS/styles/Field";
import StyledRow from "../../../CMS/styles/View/List/Row";
import Page from "../../../CMS/datas/Page";
import { Link } from "@reach/router";

class ItemChildren extends Component {
  state = {
    item: undefined,
    mustBeLoad: false,
    children: undefined
  };

  constructor(props) {
    super(props);

    this.loadChildren = this.loadChildren.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    if (props.item && (!state.item || props.item.id !== state.item.id)) {
      state.mustBeLoad = true;
      state.item = props.item;
    }

    return state;
  }

  componentDidMount() {
    if (this.state.mustBeLoad) {
      this.loadChildren();
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.state.mustBeLoad) {
      this.loadChildren();
    }
  }

  loadChildren() {
    this.setState({
      mustBeLoad: false
    });
    const _this = this;

    Page.search(this.props.boutique, {
      wheres: {
        parent: this.state.item.id
      }
    }).then(pages => {
      _this.setState({
        children: pages
      });
    });
  }

  render() {
    return (
      <div className="doc-children">
        {this.state.children &&
          this.state.children.length > 0 && (
            <ul>
              {this.state.children.map((child, index) => (
                <li key={index}>
                  <Link to={`/website/pages/${child.id}`}>
                    {child.get("name") ? child.get("name") : child.id}
                  </Link>
                  <ItemChildren boutique={this.props.boutique} item={child} />
                </li>
              ))}
            </ul>
          )}
      </div>
    );
  }
}
class Item extends Component {
  render() {
    const { doc, boutique, onClick } = this.props;

    const item = doc.data();

    return (
      <StyledRow style={{ flexDirection: "column" }}>
        <div className="doc-main">
          <div className="infos">
            <span className="name" onClick={onClick}>
              {item.isRedirect ? (
                <React.Fragment>
                  <span>{item.pathname}</span>
                  <strong
                    className="redirectPathname"
                    style={{
                      marginLeft: "1.5rem"
                    }}
                  >
                    → {item.redirectPathname}
                  </strong>
                </React.Fragment>
              ) : (
                <strong className="title">
                  {item.name ? item.name : item.pathname}
                </strong>
              )}
            </span>
          </div>
          <Field noMarginTop className="field-actions">
            <div className="has-addons">
              {doc.id && (
                <Button
                  smallIcon
                  id={doc.id}
                  type="id"
                  className="no-left-radius"
                />
              )}
              <Button
                smallIcon
                icon="fa-eye"
                as="a"
                target="_blank"
                href={`${boutique.get("websiteURL")}/${item.pathname}`}
              />
              <Button smallIcon icon="fa-edit" onClick={onClick} />
            </div>
          </Field>
        </div>
        <ItemChildren boutique={this.props.boutique} item={doc} />
      </StyledRow>
    );
  }
}

export default Item;
