import React from "react";
import StyledRow from "../../../CMS/styles/View/List/Row";

/**
 * Ce composant permet d'afficher une page dans la liste des bannières
 *
 * @param doc
 * @param onClick
 * @returns {*}
 */
export default ({ doc, onClick }) => {
  const item = doc.data();

  return (
    <StyledRow onClick={onClick}>
      <p className="name" style={{ flex: 1 }}>
        {item.name}
      </p>
    </StyledRow>
  );
};
