import { connect } from "react-redux";
import List from "../../../CMS/containers/Boutique/components/List";
import Item from "./Item";
import page from "./params.json";

import { initialize, validate } from "../../../Boutique/functions";

class BannerList extends List {
  collectionName = "banners";
  defaultOrderField = "name";
  defaultOrderDirection = "asc";
  listTitle = "Bannières";
  viewList = Item;
  itemTitle = { type: "attribut", attribut: "name" };
  initializeItem = initialize;
  validateItem = validate;
  itemParams = page;
  messageNoDocument = "Aucune bannière";

  constructor(props) {
    super(props, {
      newItemLibelle: "Nouvelle bannière"
    });

    this.state.form.required = ["name"];
  }
}

const mapStateToProps = (globalState, ownProps) => {
  const props = {};

  props.docs = globalState.boutique.docs;

  return props;
};

export default connect(mapStateToProps)(BannerList);
