import {
  WATCH_SUCCESS,
  WATCH_RESET,
  WATCH_UNSUBSCRIBE,
  PAIEMENTS_BY_COMMANDE_WATCH,
  PAIEMENTS_RESET,
  WATCH_LOADING,
  WATCH_ERROR
} from "./actionTypes";

const initialState = {
  loading: false,
  paiements: undefined,
  docs: null,
  pageIndex: 0,
  nbHits: undefined,
  orderField: undefined,
  orderDirection: undefined,
  unsubscribe: undefined
};

const sortByDatetime = (a, b) => b.get("datetime") - a.get("datetime");
const sortByCreatedAt = (a, b) => b.get("createdAt") - a.get("createdAt");
const sortByCreatedAtDesc = (a, b) => a.get("createdAt") - b.get("createdAt");
const sortByPublishedAt = (a, b) => b.get("publishedAt") - a.get("publishedAt");
const sortByPublishedAtDesc = (a, b) =>
  a.get("publishedAt") - b.get("publishedAt");
const sortByUpdatedAt = (a, b) => b.get("updatedAt") - a.get("updatedAt");
const sortByUpdatedAtDesc = (a, b) => a.get("updatedAt") - b.get("updatedAt");
const sortByName = (a, b) => b.get("name") - a.get("name");
const sortByTitle = (a, b) => b.get("title") - a.get("title");
const sortBySaleDate = (a, b) => b.get("saleDate") - a.get("saleDate");
const triDocument = (a, b) => {
  const valA = a.get("datetime");
  const valB = b.get("datetime");
  return valB - valA;
};

export default function boutiqueReducer(state = initialState, action) {
  switch (action.type) {
    case PAIEMENTS_BY_COMMANDE_WATCH:
      return { ...state, paiements: action.paiements };

    case PAIEMENTS_RESET:
      return { ...state, paiements: undefined };

    case WATCH_LOADING:
      return {
        ...state,
        loading: true,
        pageIndex: action.pageIndex,
        nbHits: action.nbHits ? action.nbHits : undefined,
        orderField: action.orderField ? action.orderField : undefined
      };

    case WATCH_SUCCESS:
      let docs = state.docs ? state.docs.splice(0) : [];
      // On ajoute les nouveaux documents
      if (action.docsAdded && action.docsAdded.length > 0) {
        docs = docs.concat(action.docsAdded);
      }
      // On modifie les documents modifiés
      if (action.docsModified && action.docsModified.length > 0) {
        action.docsModified.forEach(doc => {
          const index = docs.findIndex(document => document.id === doc.id);
          if (index >= 0) {
            docs[index] = doc;
          }
        });
      }
      // On supprime les documents qui ont été supprimé
      if (action.docsRemoved && action.docsRemoved.length > 0) {
        action.docsRemoved.forEach(doc => {
          const index = docs.findIndex(document => document.id === doc.id);
          if (index >= 0) {
            docs.splice(index, 1);
          }
        });
      }
      // On traite tous les cas de figure pour les documents dans docs
      if (action.docs && action.docs.length > 0) {
        action.docs.forEach(doc => {
          const index = docs.findIndex(document => document.id === doc.id);
          if (index >= 0) {
            if (doc.exists) {
              docs[index] = doc;
            } else {
              docs.splice(index, 1);
            }
          } else {
            docs.push(doc);
          }
        });
      }

      // Il faut réordonner les documents
      if (state.orderField) {
        let sortFunc = undefined;
        switch (state.orderField) {
          case "datetime":
            sortFunc = sortByDatetime;
            break;
          case "publishedAt":
            if (state.orderDirection === "asc") {
              sortFunc = sortByPublishedAt;
            } else {
              sortFunc = sortByPublishedAtDesc;
            }
            break;

          case "updatedAt":
            if (state.orderDirection === "asc") {
              sortFunc = sortByUpdatedAt;
            } else {
              sortFunc = sortByUpdatedAtDesc;
            }
            break;

          case "createdAt":
            if (state.orderDirection === "asc") {
              sortFunc = sortByCreatedAt;
            } else {
              sortFunc = sortByCreatedAtDesc;
            }
            break;

          case "name":
            sortFunc = sortByName;
            break;
          case "title":
            sortFunc = sortByTitle;
            break;

          case " saleDate":
            sortFunc = sortBySaleDate;
            break;

          default:
            sortFunc = triDocument;
        }
        docs = docs.sort(sortFunc);
      }

      return { ...state, docs: docs, loading: false };

    case WATCH_UNSUBSCRIBE:
      return { ...state, unsubscribe: action.unsubscribe };

    case WATCH_ERROR:
      return { ...state, docs: undefined, pageIndex: 0, loading: false };

    case WATCH_RESET:
      return { ...state, docs: undefined, pageIndex: 0, nbHits: undefined };

    default:
  }

  return state;
}
