import React, { Component } from "react";
import { connect } from "react-redux";
import Pages from "./containers/Page/List";
import Banners from "./containers/Banner/List";
import Coupons from "./containers/Coupon/List";
import Posts from "./containers/Post/List";
import Memos from "./containers/Memo/List";
import Params from "./containers/Params/";

import { compose } from "redux";
import {
  HOC,
  mapStateToProps,
  mapDispatchToProps
} from "../CMS/containers/Boutique/components/HOC";

class Website extends Component {
  render() {
    const { user } = this.props;

    if (!user) {
      return <div>Chargement en cours de l'utilisateur</div>;
    }

    // TODO experimental - voir pour améliorer le router
    if (this.props["*"].substr(0, 5) === "pages") {
      return <Pages {...this.props} />;
    }

    if (this.props["*"].substr(0, 8) === "articles") {
      return <Posts {...this.props} />;
    }

    if (this.props["*"].substr(0, 7) === "banners") {
      return <Banners {...this.props} />;
    }

    if (this.props["*"].substr(0, 7) === "coupons") {
      return <Coupons {...this.props} />;
    }

    if (this.props["*"].substr(0, 6) === "params") {
      return <Params {...this.props} />;
    }

    switch (this.props["*"].substr(0, 5)) {
      case "memos":
        return <Memos {...this.props} />;

      default:
        return (
          <div>
            Problème de configuration, le type '{this.props["*"]}' n'existe pas
          </div>
        );
    }
  }
}

const enhance = compose(
  HOC,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
);

export default enhance(Website);
