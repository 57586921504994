import List from "../../../../../CMS/containers/Boutique/components/List";
import { connect } from "react-redux";
import Item from "./Item";
import demande from "../../params.json";
import Action from "../../../../../CMS/datas/Action";

import { initialize } from "../../functions";
import { validate } from "../../../../../Boutique/functions";

import moment from "moment";
import Campagne from "../../../../../CMS/datas/Campagne";

class CampagneList extends List {
  collectionName = "campagnes";
  defaultOrderField = "createdAt";
  defaultOrderDirection = "desc";
  listTitle = "Campagnes";
  viewList = Item;
  itemTitle = { type: "attribut", attribut: "title" };
  initializeItem = initialize;
  validateItem = validate;
  itemParams = demande;
  messageNoDocument = "Aucune campagne";

  constructor(props) {
    super(props, {
      tabsArray: [
        {
          label: "Brouillon",
          actif: true,
          wheres: {
            isSend: false
          }
        },
        {
          label: "Envoyées",
          actif: false,
          wheres: {
            isSend: true
          }
        }
      ],
      custom: {
        openModal: false
      }
    });

    this.handleInliner = this.handleInliner.bind(this);
    this.handleSend = this.handleSend.bind(this);
    this.handleTransfertToProject = this.handleTransfertToProject.bind(this);

    this.state.form.menu.right = [
      {
        text: "Fermer",
        onClick: this.handleClose
      },
      {
        text: "Inliner",
        onClick: this.handleInliner,
        primary: true
      },
      {
        text: "Envoyer",
        onClick: this.handleSend,
        primary: true
      }
    ];

    this.state.list.menu.left = [
      {
        text: "Ajouter",
        onClick: this.handleCreate
      },
      {
        text: "Transférer",
        onClick: this.handleTransfertToProject
      }
    ];

    this.state.form.required = [
      "title",
      "subject",
      "locale",
      "senderName",
      "senderMail"
    ];
  }

  handleTransfertToProject(e) {
    e.preventDefault();
    if (!this.props.boutique.get("firebaseApiKey")) {
      console.error(
        "Il n'est pas possible de transférer les campagnes car le projet n'est pas configuré"
      );
    }

    Campagne.transfertToBoutique(this.props.boutique).then(result => {
      console.info("result", result);
    });
  }

  // loadAlternativeFunc(boutique) {
  //   if (this.props.boutique && this.props.boutique.get("requestSchema")) {
  //   this.props.importNewRequests();
  //   }
  // }

  handleInliner(e) {
    e.preventDefault();
    // Générer le code du mail
    alert("Bienvenue à tous le monde");
  }

  handleSend(e) {
    e.preventDefault();

    const boutique = this.props.boutique;
    if (
      !boutique.get("demandeMailSujet") ||
      !boutique.get("demandeMailText") ||
      !boutique.get("websiteURL") ||
      !boutique.get("fromName") ||
      !boutique.get("fromMail")
    ) {
      alert("problème de paramètre de la boutique");
      console.error("demandeMailSujet", boutique.get("demandeMailSujet"));
      console.error("demandeMailText", boutique.get("demandeMailText"));
      console.error("websiteURL", boutique.get("websiteURL"));
      console.error("fromName", boutique.get("fromName"));
      console.error("fromMail", boutique.get("fromMail"));
      return false;
    }

    if (!this.props.item.title || !this.props.item.produits) {
      alert("le titre et les produits sont obligatoire");
      console.error("title", this.props.item.title);
      console.error("produits", this.props.item.produits);
      return false;
    }

    const _this = this;
    this.props.item.isSend = true;
    this.props.item.sendedAt = moment().toISOString();

    const id = this.state.ref.id;
    this.props
      .updateDocumentByRef(
        this.actionTypeUpdate,
        this.state.ref,
        this.props.item
      )
      .then(action => {
        if (!action.error) {
          if (!boutique.get("firebaseApiKey")) {
            alert(
              "Il n'est pas possible d'envoyer une campagne pour le moment pour cette boutique"
            );
          } else {
            const data = {
              id: id,
              websiteURL: boutique.get("websiteURL")
            };

            Action.create(boutique, "campagne-send", data).then(action => {
              console.info("campagne-send", action);
              _this.props.createNotification({
                type: "info",
                message: "Envoi programmé"
              });
            });
          }

          _this.handleClose();
        } else {
          _this.props.createNotification({
            type: "error",
            message: "Erreur lors de la mise à jour"
          });
        }
      });
  }
}

const mapStateToProps = (globalState, ownProps) => {
  const props = {};
  props.docs = globalState.boutique.docs;
  if (globalState.form.item) {
    props.item = globalState.form.item.values;
  }

  return props;
};

export default connect(
  mapStateToProps,
  null
)(CampagneList);
