import styled from "styled-components";
import voir from "../../../images/font-awesome/regular/eye.svg";
import rotate from "../../../images/font-awesome/solid/redo.svg";
import supprimer from "../../../images/font-awesome/regular/trash-alt.svg";

export default styled.div`
  width: 100%;
  display: block;
  margin-bottom: 1.5rem;

  .is-pulled-right {
    float: right;
    color: #4b71fc;
    font-size: 13px;
  }

  .dropzone,
  .list {
    background: white;
    width: 100%;
    box-sizing: border-box;
  }

  .group {
    border: 1px solid rgba(228, 231, 237, 0.8);
  }

  .list {
    padding: 20px 20px 10px 10px;
  }

  .is-clearfix {
    &:after {
      clear: both;
      content: " ";
      display: table;
    }
  }

  ol {
    list-style: none;
    margin: 0;
    padding: 0;

    > li {
      position: relative;
      display: block;
      float: left;
      width: 20%;
      padding-left: 10px;
      margin: 0 0 10px 0;
      box-sizing: border-box;

      figure {
        margin: 0;
      }

      &.isOver .aspect-ratio:before {
        border: 1px solid green !important;
      }

      &:first-child {
        width: 40%;
        margin-bottom: 9px;
      }

      .hover-overlay {
        top: 0;
        z-index: 3;
        background: rgba(49, 55, 61, 0.75);
        position: absolute;
        width: 100%;
        height: 100%;
        opacity: 0;
        transition: opacity 0.1s ease-in-out;

        &:hover {
          opacity: 1;
        }

        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        padding-bottom: 10px;

        button {
          flex: 1;
          background-color: #fff;
          mask-image: url(${supprimer});
          mask-repeat: no-repeat;
          mask-position: center;
          height: 18px;
          width: 18px;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          color: #9f9da4;
          //border: 1px solid #b7c6fe;
          text-decoration: none;
          margin-left: 0.375rem;
          span {
            display: none;
          }

          &:hover {
            background-color: #4b71fc;
          }

          &.voir {
            mask-image: url(${voir});
          }

          &.rotate {
            mask-image: url(${rotate});
          }
        }
      }
    }
  }

  .dropzone {
    border: 3px dashed transparent;
    padding: 0.75rem;

    text-align: center;

    .first-upload {
      margin: 1.5rem;
      img {
        width: 80px;
      }

      h4 {
        color: #d3dbe2;
        font-size: 14px;
      }
    }
  }

  .dropzone-hide {
    display: none;
  }
  .dropzone-active {
    border: 3px dashed #00aeef;
  }

  .aspect-ratio {
    position: relative;
    display: block;
    background: #fafbfc;
    padding: 0;

    &:before {
      z-index: 1;
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      border: 1px solid rgba(195, 207, 216, 0.3);
    }

    &.isDragging:before {
      border: 1px solid red;
    }
  }

  .aspect-ratio--square {
    width: 100%;
    padding-bottom: 100%;
    flex-shrink: 0;
  }

  .aspect-ratio__content {
    position: absolute;
    max-width: 100%;
    max-height: 100%;
    display: block;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
  }

  .loading-in-progress {
    ul {
      margin: 0;
      padding: 0;
      list-style: none;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      li {
        margin-bottom: 0.375rem;
      }
    }
  }
`;
