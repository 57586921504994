import moment from 'moment';
import * as functions from '../../functions';

export function initialize(data) {
    const objet = functions.initialize(data);
    return {
        ...objet
    }
}

export function validate(user) {
    user.updatedAt = moment().toISOString();

    return user;
}