import React, { Component } from "react";
import ButtonStyled from "./style";
import { checkIfIsVisible } from "../../../../utils/functions";

class ButtonIsVisible extends Component {
  constructor(props) {
    super(props);

    this.nodeRef = React.createRef();
    this.isVisible = this.isVisible.bind(this);
  }

  componentDidMount() {
    if (this.props.clickOnVisible) {
      this.attachListener();
      this.isVisible();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.clickOnVisible) {
      this.attachListener();
      this.isVisible();
    }
  }

  componentWillUnmount() {
    this.removeListener();
  }

  attachListener() {
    window.addEventListener("scroll", this.isVisible);
    window.addEventListener("resize", this.isVisible);
  }

  removeListener() {
    window.removeEventListener("scroll", this.isVisible);
    window.removeEventListener("resize", this.isVisible);
  }

  isVisible() {
    if (!this.nodeRef || !this.nodeRef.current.getBoundingClientRect) return;

    const html = document.documentElement;
    const boundingClientRect = this.nodeRef.current.getBoundingClientRect();
    const windowWidth = window.innerWidth || html.clientWidth;
    const windowHeight = window.innerHeight || html.clientHeight;

    if (checkIfIsVisible(boundingClientRect, windowWidth, windowHeight)) {
      this.removeListener();
      if (this.props.clickOnVisible) {
        this.nodeRef.current.click();
      }
    }
  }

  render() {
    const { onClick, text, icon, smallIcon, children, ...others } = this.props;

    let className = this.props.className ? this.props.className : [];
    if (typeof className === "string") {
      className = className.split(" ");
    }
    if (smallIcon) {
      className.push("small-icon");
    }

    return (
      <ButtonStyled
        onClick={onClick}
        ref={this.nodeRef}
        className={className.join(" ")}
        {...others}
      >
        {children ? (
          children
        ) : (
          <React.Fragment>
            {icon && (
              <span className="icon">
                <i className={"fa " + icon} />
              </span>
            )}
            {text && <span className="text">{text}</span>}
          </React.Fragment>
        )}
      </ButtonStyled>
    );
  }
}

export default ButtonIsVisible;
