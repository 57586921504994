import React, { Component } from "react";
import PropTypes from "prop-types";

import { Field } from "redux-form";
import ComponentField from "../../styles/Field";

class SelectField extends Component {
  componentDidMount() {
    if (this.input.value) {
      this.input.classList.remove("is-empty");
    } else {
      this.input.classList.add("is-empty");
    }

    this.input.addEventListener("focus", function(event) {
      event.currentTarget.classList.add("is-focused");
    });
    this.input.addEventListener("blur", function(event) {
      event.currentTarget.classList.remove("is-focused");
    });
    this.input.addEventListener("keyup", function(event) {
      if (event.currentTarget.value.length === 0) {
        event.currentTarget.classList.add("is-empty");
      } else {
        event.currentTarget.classList.remove("is-empty");
      }
    });
  }

  render() {
    const {
      input,
      label,
      horizontal,
      meta: { touched, error },
      className,
      ...others
    } = this.props;

    let classNames = ["cms__field", "select"];
    if (className) classNames = classNames.concat(className.split(" "));
    if (touched && error) classNames = classNames.push("error");
    if (horizontal) classNames = classNames.push("horizontal");

    return (
      <ComponentField className={[...new Set(classNames)].join(" ")}>
        <select
          {...input}
          {...others}
          ref={input => {
            this.input = input;
          }}
        >
          {this.props.children}
        </select>
        <label className="label">
          <span>{label}</span>
        </label>
        {touched && error && (
          <div className={"error error_" + error.code}>
            <span className="icon">
              <i className="fa fa-exclamation" />
            </span>
            <span>{error.message}</span>
          </div>
        )}
      </ComponentField>
    );
  }
}

const Select = ({ prefix, name, options, ...others }) => {
  delete others.user;

  if (prefix && name) {
    name = prefix + "." + name;
  }

  //TODO faire le tri des paramètres que l'on passe au select
  delete others.boutique;
  delete others.customHandlers;
  delete others.id;
  delete others.itemRef;

  return (
    <Field {...others} name={name} component={SelectField}>
      <option value="">---</option>
      {options &&
        options.map((option, index) => (
          <option key={index} value={option.value}>
            {option.label}
          </option>
        ))}
    </Field>
  );
};

Select.propTypes = {
  options: PropTypes.array.isRequired
};

export default Select;
