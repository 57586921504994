import styled from "styled-components";

const ProduitsList = styled.div`
  align-content: start;
  display: flex;
  flex-wrap: wrap;

  margin: 0;
  margin-left: -0.75rem;
  padding: 0;
  list-style: none;

  > div {
    margin: 0 0 0.75rem 0;
    box-sizing: border-box;
    padding: 0 0 0 0.75rem;

    div.produit {
      border: 1px solid #e4e7ed;
      border-radius: 4px;
      background: #f6f7f9;
    }

    h3 {
      font-size: 13px;
      font-weight: normal;

      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      padding: 0 0.75rem;
    }

    width: 25%;
  }
`;

export { ProduitsList };
