import React, { Component } from "react";
import PropTypes from "prop-types";

import Section from "../../../../components/Data/Section";
import Item from "./Item";
import moment from "moment";
import { DragDropContext } from "react-dnd";
import HTML5Backend from "react-dnd-html5-backend";
import { getFirestore } from "../../../../../Boutique/functions";

const sortFunc = (a, b) => b.datetime - a.datetime;

class List extends Component {
  constructor(props) {
    super(props);

    this.state = {
      itemRef: null,
      name: null,
      items: null,
      mustBeLoaded: false
    };

    this.handleAdd = this.handleAdd.bind(this);
    this.handleChangeValue = this.handleChangeValue.bind(this);
    this.handleUpdate = this.handleUpdate.bind(this);
    this.handleRemove = this.handleRemove.bind(this);
    this.handleChangePosition = this.handleChangePosition.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    if (
      (!state.itemRef && props.itemRef) ||
      (state.itemRef &&
        ((props.itemRef && props.itemRef.id !== state.itemRef.id) ||
          props.name !== state.name))
    ) {
      state.mustBeLoaded = true;
      state.items = null;
      state.itemRef = props.itemRef;
      state.name = props.name;
    } else {
      state.mustBeLoaded = false;
    }

    return state;
  }

  componentDidMount() {
    if (this.state.mustBeLoaded) {
      this.loadFeaturesList();
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.state.mustBeLoaded) {
      this.loadFeaturesList();
    }
  }

  loadFeaturesList() {
    this.setState({
      mustBeLoaded: false
    });

    const _this = this;
    this.props.itemRef
      .collection(this.props.name)
      .where("deleted", "==", false)
      .get()
      .then(querySnapshot => {
        if (!querySnapshot.empty) {
          // Il existe des features mais le datetime n'est pas renseigné
          const items = [];
          const batch = getFirestore().batch();
          let nbUpdated = 0;
          querySnapshot.forEach(doc => {
            const data = doc.data();
            delete data.createdAt;
            delete data.deleted;

            if (!doc.get("datetime")) {
              const datetime = parseInt(
                moment(doc.get("createdAt")).format("x"),
                10
              );
              batch.update(doc.ref, {
                datetime: datetime
              });
              nbUpdated++;
            }

            const item = {
              ...data,
              ref: doc.ref,
              change: false
            };
            items.push(item);
          });
          if (nbUpdated > 0) {
            batch.commit().then(function() {
              console.info(
                `Les features de la collection ${_this.props.name} ont été mise à jour`
              );
            });
          }

          this.setState({
            items: items.sort(sortFunc)
          });
        } else {
          this.setState({
            items: []
          });
        }
      });
  }

  handleAdd() {
    // TODO améliorer la gestion avec un watch
    const item = {
      libelle: "",
      datetime: parseInt(moment().format("x"), 10)
    };

    if (this.props.customFields && this.props.customFields.length > 0) {
      this.props.customFields.forEach(field => {
        item[field.name] = "";
      });
    }

    this.props.itemRef
      .collection(this.props.name)
      .add({
        createdAt: moment().toISOString(),
        deleted: false,
        ...item
      })
      .then(documentReference => {
        this.setState(oldState => {
          let items = [];
          if (oldState.items) {
            items = oldState.items.slice();
          }

          item.ref = documentReference;
          item.change = false;

          items.push(item);
          return {
            items: items.sort(sortFunc)
          };
        });
      })
      .catch(error => {
        console.error("handleAdd", error.message);
      });
  }

  handleChangeValue(name, value, index, type) {
    switch (type) {
      case "number":
        value = parseFloat(value);
        break;

      case "text":
      default:
        value = String(value);
    }

    this.setState(oldState => {
      let item = oldState.items[index];
      item[name] = value;
      item.change = true;

      if (item.timeoutId) {
        clearTimeout(item.timeoutId);
      }

      item.timeoutId = setTimeout(() => {
        this.handleUpdate(index);
      }, 1000);

      return {
        items: oldState.items
      };
    });
  }

  handleUpdate(index) {
    this.setState(oldState => {
      let item = oldState.items[index];
      item.change = false;
      item.updatedAt = moment().toISOString();

      const data = Object.assign({}, item);
      delete data.ref;
      delete data.change;
      delete data.timeoutId;
      item.ref
        .update({
          updatedAt: moment().toISOString(),
          ...data
        })
        .then(() => {
          console.info("mise à jour réussie");
        });

      return {
        items: oldState.items
      };
    });
  }

  handleRemove(index) {
    this.setState(oldState => {
      const item = oldState.items[index];
      if (item.ref) {
        item.ref
          .update({
            updatedAt: moment().toISOString(),
            deleted: true
          })
          .then(() => {
            console.info("Suppression réussie");
          });
      }

      oldState.items.splice(index, 1);

      return {
        items: oldState.items
      };
    });
  }

  handleChangePosition(oldItem, newIndex) {
    if (
      this.state.items[oldItem.index] &&
      this.state.items[oldItem.index].ref.id === oldItem.id
    ) {
      let newIndexDatetime = parseInt(this.state.items[newIndex].datetime, 10);
      let newIndexMinusOneDatetime = parseInt(moment().format("x"), 10);
      if (newIndex === 0) {
        newIndexDatetime = parseInt(moment().format("x"), 10);
      } else {
        // Cas de figure général - pas de problème
        newIndexMinusOneDatetime = parseInt(
          this.state.items[newIndex - 1].datetime,
          10
        );
      }

      this.setState(
        oldState => {
          oldState.items[oldItem.index].datetime =
            newIndexDatetime +
            (newIndexMinusOneDatetime - newIndexDatetime) / 2;

          // Trier le nouveau tableau dans le bon ordre
          oldState.items = oldState.items.sort(sortFunc);

          return oldState;
        },
        () => {
          this.handleUpdate(newIndex);
        }
      );
    }
  }

  render() {
    if (!this.props.itemRef) return null;

    if (!this.state.items) {
      return (
        <Section
          title={this.props.title}
          button={{
            text: "Ajouter",
            onClick: this.handleAdd
          }}
          group={true}
        >
          <p style={{ padding: "1.5rem", marginBottom: 0 }}>
            Chargement en cours
          </p>
        </Section>
      );
    }

    if (this.state.items.length === 0) {
      return (
        <Section
          title={this.props.title}
          button={{
            text: this.props.add ? this.props.add : "Ajouter",
            onClick: this.handleAdd
          }}
          group={true}
        >
          <p style={{ padding: "1.5rem", marginBottom: 0 }}>
            {this.props.nothing ? this.props.nothing : "Aucun élément"}
          </p>
        </Section>
      );
    }

    return (
      <Section
        title={this.props.title}
        button={{
          text: this.props.add ? this.props.add : "Ajouter",
          onClick: this.handleAdd
        }}
        group={true}
      >
        <div style={{ marginBottom: "20px" }}>
          {this.state.items.map((item, index) => (
            <Item
              title={this.props.placeholder}
              item={item}
              index={index}
              itemRef={item.ref}
              key={index}
              libelleField={this.props.libelleField}
              customFields={this.props.customFields}
              modalFields={this.props.modalFields}
              modalDatas={this.props.modalDatas}
              handleChangeValue={(name, value, type) =>
                this.handleChangeValue(name, value, index, type)
              }
              handleUpdate={() => this.handleUpdate(index)}
              handleRemove={() => this.handleRemove(index)}
              handleChangePosition={this.handleChangePosition}
            />
          ))}
        </div>
      </Section>
    );
  }
}

List.propTypes = {
  title: PropTypes.string.isRequired,
  itemRef: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired
};

List = DragDropContext(HTML5Backend)(List);

export default List;
