import React, { Component } from "react";
import isEqual from "lodash/isEqual";
import styled from "styled-components";
import {
  Editor,
  EditorState,
  RichUtils,
  convertFromRaw,
  convertToRaw,
  CompositeDecorator
} from "draft-js";
import StyledField from "../styles/Field";

const Div = styled.div`
  border: 1px solid #d7d6d9;
  border-radius: 4px;
  width: 100%;

  .controls {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    background: #fbfbfc;
    border-bottom: 1px solid #d7d6d9;
  }

  .editor {
    padding: 10px;
  }

  .RichEditor-controls {
    font-size: 12px;
    margin: 0;
    user-select: none;
  }

  .RichEditor-styleButton {
    color: #999;
    cursor: pointer;
    margin-right: 10px;
    padding: 2px 4px;
    display: inline-block;
    line-height: 18px;
  }

  .RichEditor-activeButton {
    color: #5890ff;
  }

  .RichEditor-linkControl {
    position: relative;

    .popup-input {
      position: absolute;
      top: 20px;
      background: #f9f9f9;
      padding: 0.375rem;
      width: 300px;
      left: 20px;
      input {
        width: calc(100% - 1.5rem);
      }
    }

    .buttons {
      text-align: right;
      button {
        margin-left: 0.375rem;
      }
    }
  }
`;

function findLinkEntities(contentBlock, callback, contentState) {
  contentBlock.findEntityRanges(character => {
    const entityKey = character.getEntity();
    return (
      entityKey !== null &&
      contentState.getEntity(entityKey).getType() === "LINK"
    );
  }, callback);
}
const Link = props => {
  const { url } = props.contentState.getEntity(props.entityKey).getData();
  return <a href={url}>{props.children}</a>;
};

const decorator = new CompositeDecorator([
  {
    strategy: findLinkEntities,
    component: Link
  }
]);

class Draft extends Component {
  constructor(props) {
    super(props);

    this.state = {
      focus: false,
      value: undefined,
      editorState: EditorState.createEmpty(decorator),
      shouldUpdateEditor: false
    };
    this.onChange = editorState => this._onChange(editorState);
    this.onBlur = e => this._onBlur(e);
    this.focusEditor = e => this._focusEditor(e);
    this.handleKeyCommand = command => this._handleKeyCommand(command);
    this.toggleBlockType = type => this._toggleBlockType(type);
    this.toggleInlineStyle = style => this._toggleInlineStyle(style);

    // this.logState = e => {
    //   e.preventDefault();
    //   const content = this.state.editorState.getCurrentContent();
    //   console.log(convertToRaw(content));
    // };
  }

  _onChange = editorState => {
    this.setState({ editorState });
    // TODO Mettre un setTimeOut pour enregistrer tous les 1 seconde ou plus ??
    // const contentState = editorState.getCurrentContent();
    // const value = convertToRaw(contentState);
    // this.props.onChange(value);
  };

  _onBlur = e => {
    const contentState = this.state.editorState.getCurrentContent();
    const value = convertToRaw(contentState);
    this.props.onChange(value);
    this.setState({
      focus: false
    });
  };

  _focusEditor = e => {
    if (this.editor) {
      this.setState({
        focus: true
      });
      this.editor.focus();
    }
  };

  _handleKeyCommand(command) {
    const { editorState } = this.state;
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      this.onChange(newState);
      return true;
    }
    return false;
  }

  _toggleBlockType(blockType) {
    this.onChange(RichUtils.toggleBlockType(this.state.editorState, blockType));
  }

  _toggleInlineStyle(inlineStyle) {
    this.onChange(
      RichUtils.toggleInlineStyle(this.state.editorState, inlineStyle)
    );
  }

  static getDerivedStateFromProps(props, state) {
    if (props.value && (!state.value || !isEqual(props.value, state.value))) {
      state.shouldUpdateEditor = true;
      state.value = props.value;
    }

    return state;
  }

  componentDidMount() {
    if (this.state.shouldUpdateEditor) {
      this.updateEditorState(this.state.value);
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.state.shouldUpdateEditor) {
      this.updateEditorState(this.state.value);
    }
  }

  updateEditorState(value) {
    this.setState({
      shouldUpdateEditor: false
    });
    this.setState({
      editorState: EditorState.createWithContent(
        convertFromRaw(value),
        decorator
      )
    });
  }

  render() {
    const { label } = this.props;
    const { editorState } = this.state;

    return (
      <StyledField
        style={{
          minHeight: "35px",
          height: "auto"
        }}
      >
        <Div>
          <div className="controls">
            <BlockStyleControls
              editorState={editorState}
              onToggle={this.toggleBlockType}
            />
            <InlineStyleControls
              editorState={editorState}
              onToggle={this.toggleInlineStyle}
            />
            <LinkControl
              editorState={editorState}
              editor={this.editor}
              onChange={this.onChange}
            />
          </div>
          <div onClick={this.focusEditor} className="editor">
            <Editor
              ref={editor => {
                this.editor = editor;
              }}
              editorState={this.state.editorState}
              onChange={this.onChange}
              handleKeyCommand={this.handleKeyCommand}
              onBlur={this.onBlur}
            />
          </div>
        </Div>
        <label className={`label ${this.state.focus ? "is-focused" : ""}`}>
          <span>{label}</span>
        </label>
      </StyledField>
    );
  }
}

class StyleButton extends React.Component {
  constructor() {
    super();
    this.onToggle = e => {
      e.preventDefault();
      this.props.onToggle(this.props.style);
    };
  }

  render() {
    let className = "RichEditor-styleButton";
    if (this.props.active) {
      className += " RichEditor-activeButton";
    }

    return (
      <span className={className} onMouseDown={this.onToggle}>
        {this.props.label}
      </span>
    );
  }
}

const BLOCK_TYPES = [
  { label: "H1", style: "header-one" },
  { label: "H2", style: "header-two" },
  { label: "H3", style: "header-three" },
  { label: "H4", style: "header-four" },
  { label: "H5", style: "header-five" },
  { label: "H6", style: "header-six" },
  { label: "Blockquote", style: "blockquote" },
  { label: "UL", style: "unordered-list-item" },
  { label: "OL", style: "ordered-list-item" },
  { label: "Code Block", style: "code-block" }
];

const BlockStyleControls = props => {
  const { editorState } = props;
  const selection = editorState.getSelection();
  const blockType = editorState
    .getCurrentContent()
    .getBlockForKey(selection.getStartKey())
    .getType();

  return (
    <div className="RichEditor-controls">
      {BLOCK_TYPES.map(type => (
        <StyleButton
          key={type.label}
          active={type.style === blockType}
          label={type.label}
          onToggle={props.onToggle}
          style={type.style}
        />
      ))}
    </div>
  );
};

const INLINE_STYLES = [
  { label: "Bold", style: "BOLD" },
  { label: "Italic", style: "ITALIC" },
  { label: "Underline", style: "UNDERLINE" },
  { label: "Monospace", style: "CODE" }
];

const InlineStyleControls = props => {
  var currentStyle = props.editorState.getCurrentInlineStyle();
  return (
    <div className="RichEditor-controls">
      {INLINE_STYLES.map(type => (
        <StyleButton
          key={type.label}
          active={currentStyle.has(type.style)}
          label={type.label}
          onToggle={props.onToggle}
          style={type.style}
        />
      ))}
    </div>
  );
};

class LinkControl extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showInput: false,
      value: undefined
    };

    this.promptFor = this._promptFor.bind(this);
    this.onChange = e => this.setState({ value: e.target.value });
    this.confirm = this._confirm.bind(this);
    this.onInputKeyDown = this._onInputKeyDown.bind(this);
    this.remove = this._remove.bind(this);
    this.close = this._close.bind(this);
  }

  _promptFor(e) {
    e.preventDefault();

    const { editorState } = this.props;
    const selection = editorState.getSelection();
    if (!selection.isCollapsed()) {
      const contentState = editorState.getCurrentContent();
      const startKey = editorState.getSelection().getStartKey();
      const startOffset = editorState.getSelection().getStartOffset();
      const blockWithLinkAtBeginning = contentState.getBlockForKey(startKey);
      const linkKey = blockWithLinkAtBeginning.getEntityAt(startOffset);
      let url = "";
      if (linkKey) {
        const linkInstance = contentState.getEntity(linkKey);
        url = linkInstance.getData().url;
      }
      this.setState(
        {
          showInput: true,
          value: url
        },
        () => {
          setTimeout(() => this.refs.url.focus(), 0);
        }
      );
    }
  }

  _confirm(e) {
    e.preventDefault();
    const { editorState, editor } = this.props;
    const contentState = editorState.getCurrentContent();
    const contentStateWithEntity = contentState.createEntity(
      "LINK",
      "MUTABLE",
      { url: this.state.value }
    );
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    const newEditorState = EditorState.set(editorState, {
      currentContent: contentStateWithEntity
    });

    this.props.onChange(
      RichUtils.toggleLink(
        newEditorState,
        newEditorState.getSelection(),
        entityKey
      )
    );
    this.setState(
      {
        showInput: false,
        value: ""
      },
      () => {
        setTimeout(() => editor.focus(), 0);
      }
    );
  }

  _onInputKeyDown(e) {
    if (e.which === 13) {
      this._confirm(e);
    }
  }

  _remove(e) {
    e.preventDefault();
    const { editorState } = this.props;
    const selection = editorState.getSelection();
    if (!selection.isCollapsed()) {
      this.props.onChange(RichUtils.toggleLink(editorState, selection, null));
      this.setState({
        showInput: false,
        value: ""
      });
    } else {
      console.error("Normalement la selection devrait exister");
    }
  }

  _close(e) {
    e.preventDefault();
    this.setState({
      showInput: false,
      value: ""
    });
  }

  render() {
    return (
      <div className="RichEditor-controls RichEditor-linkControl">
        {this.state.showInput && (
          <div className="popup-input">
            <input
              onChange={this.onChange}
              ref="url"
              type="text"
              value={this.state.value}
              onKeyDown={this.onInputKeyDown}
            />
            <div className="buttons">
              <button onMouseDown={this.confirm}>Valider</button>
              <button onMouseDown={this.remove}>Retirer</button>
              <button onMouseDown={this.close}>Fermer</button>
            </div>
          </div>
        )}
        <span className="RichEditor-styleButton" onMouseDown={this.promptFor}>
          Link
        </span>
      </div>
    );
  }
}

export default Draft;
