import React, { Component } from "react";
import Section from "../../../../../../CMS/components/Data/Section";
import { formValueSelector, change } from "redux-form";
import { connect } from "react-redux";
import Item from "./Item";
import { diff } from "deep-object-diff";

class Traductions extends Component {
  state = {
    id: undefined,
    value: undefined,
    traductions: undefined
  };

  constructor(props) {
    super(props);

    this.handleAddItem = this.handleAddItem.bind(this);
    this.handleChangeItem = this.handleChangeItem.bind(this);
    this.handleRemoveItem = this.handleRemoveItem.bind(this);
    this.updateValue = this.updateValue.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    if (props.id && (!state.id || props.id !== state.id)) {
      state.id = props.id;
      state.value = undefined;
      state.traductions = undefined;
    }

    if (
      props.value &&
      Object.keys(props.value).length > 0 &&
      (!state.value || diff(state.value, props.value).length > 0)
    ) {
      state.value = props.value;
      state.traductions = Object.keys(props.value).map(key => {
        return {
          key,
          ...props.value[key]
        };
      });
    }

    return state;
  }

  handleAddItem = e => {
    const _this = this;
    e.preventDefault();
    this.setState(
      oldState => {
        oldState.traductions = oldState.traductions ? oldState.traductions : [];
        oldState.traductions.push({
          key: ""
        });

        return oldState;
      },
      () => {
        _this.updateValue();
      }
    );
  };

  handleChangeItem(e, index) {
    e.preventDefault();

    const value = e.currentTarget.value;
    this.setState(
      oldState => {
        oldState.traductions[index].key = value;

        return oldState;
      },
      () => {
        this.updateValue();
      }
    );
  }

  handleRemoveItem(e, index) {
    e.preventDefault();

    this.setState(
      oldState => {
        oldState.traductions.splice(index, 1);

        return oldState;
      },
      () => {
        this.updateValue();
      }
    );
  }

  updateValue = () => {
    const traductions = this.state.traductions.reduce((cumul, traduction) => {
      if (traduction.key) {
        const copie = Object.assign({}, traduction);
        delete copie.key;
        cumul[traduction.key] = copie;
      }

      return cumul;
    }, {});

    this.props.dispatch(change("item", this.props.name, traductions));
  };

  render() {
    return (
      <Section
        title="Langues"
        button={{
          text: "Ajouter une langue",
          onClick: this.handleAddItem
        }}
        group={true}
      >
        {this.state.traductions && this.state.traductions.length > 0 ? (
          this.state.traductions.map((traduction, index) => (
            <Item
              key={index}
              value={traduction.key}
              handleChange={e => this.handleChangeItem(e, index)}
              handleRemove={e => this.handleRemoveItem(e, index)}
            />
          ))
        ) : (
          <p>Aucune langue</p>
        )}
      </Section>
    );
  }
}

const selector = formValueSelector("item");

export default connect((globalState, ownProps) => {
  return {
    value: selector(globalState, ownProps.name)
  };
})(Traductions);
