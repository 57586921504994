import React from "react";
import styled from "styled-components";
import {
  Editor,
  EditorState,
  RichUtils,
  convertFromRaw,
  convertToRaw
} from "draft-js";
import StyledField from "../../styles/Field";
import { Field } from "redux-form";

const Div = styled.div`
  border: 1px solid #d7d6d9;
  border-radius: 4px;
  width: 100%;


  .controls {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    background: #FBFBFC;
    border-bottom: 1px solid #d7d6d9;
  }
  
  .editor {
    padding: 10px;
  }
  
  .RichEditor-controls {
    font-size: 12px;
    margin: 0;
    user-select: none;
  }

  .RichEditor-styleButton {
    color: #999;
    cursor: pointer;
    margin-right: 10px;
    padding: 2px 4px;
    display: inline-block;
    line-height: 18px;
  }

  .RichEditor-activeButton {
    color: #5890ff;
  }
`;

class MyEditorComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      focus: false,
      // editorState: EditorState.createEmpty()
      editorState: props.input.value
        ? EditorState.createWithContent(convertFromRaw(props.input.value))
        : EditorState.createEmpty()
    };
    this.onChange = editorState => this._onChange(editorState);
    this.onBlur = e => this._onBlur(e);
    this.focusEditor = e => this._focusEditor(e);
    this.handleKeyCommand = command => this._handleKeyCommand(command);
    this.toggleBlockType = type => this._toggleBlockType(type);
    this.toggleInlineStyle = style => this._toggleInlineStyle(style);
  }

  _onChange = editorState => {
    this.setState({ editorState });
    const contentState = editorState.getCurrentContent();
    const value = convertToRaw(contentState);
    this.props.input.onChange(value);
  };

  _onBlur = e => {
    const contentState = this.state.editorState.getCurrentContent();
    const value = convertToRaw(contentState);
    this.props.input.onChange(value);
    this.setState({
      focus: false
    });
  };

  _focusEditor = e => {
    if (this.editor) {
      this.setState({
        focus: true
      });
      this.editor.focus();
    }
  };

  _handleKeyCommand(command) {
    const { editorState } = this.state;
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      this.onChange(newState);
      return true;
    }
    return false;
  }

  _toggleBlockType(blockType) {
    this.onChange(RichUtils.toggleBlockType(this.state.editorState, blockType));
  }

  _toggleInlineStyle(inlineStyle) {
    this.onChange(
      RichUtils.toggleInlineStyle(this.state.editorState, inlineStyle)
    );
  }

  componentDidMount() {}

  render() {
    const { label } = this.props;
    const { editorState } = this.state;

    return (
      <StyledField
        style={{
          minHeight: "35px",
          height: "auto"
        }}
      >
        <Div>
          <div className="controls">
            <BlockStyleControls
              editorState={editorState}
              onToggle={this.toggleBlockType}
            />
            <InlineStyleControls
              editorState={editorState}
              onToggle={this.toggleInlineStyle}
            />
          </div>
          <div onClick={this.focusEditor} className='editor'>
            <Editor
              ref={editor => {
                this.editor = editor;
              }}
              editorState={this.state.editorState}
              onChange={this.onChange}
              handleKeyCommand={this.handleKeyCommand}
              onBlur={this.onBlur}
            />
          </div>
        </Div>
        <label className={`label ${this.state.focus ? "is-focused" : ""}`}>
          <span>{label}</span>
        </label>
      </StyledField>
    );
  }
}

class StyleButton extends React.Component {
  constructor() {
    super();
    this.onToggle = e => {
      e.preventDefault();
      this.props.onToggle(this.props.style);
    };
  }

  render() {
    let className = "RichEditor-styleButton";
    if (this.props.active) {
      className += " RichEditor-activeButton";
    }

    return (
      <span className={className} onMouseDown={this.onToggle}>
        {this.props.label}
      </span>
    );
  }
}

const BLOCK_TYPES = [
  { label: "H1", style: "header-one" },
  { label: "H2", style: "header-two" },
  { label: "H3", style: "header-three" },
  { label: "H4", style: "header-four" },
  { label: "H5", style: "header-five" },
  { label: "H6", style: "header-six" },
  // { label: "Blockquote", style: "blockquote" },
  { label: "UL", style: "unordered-list-item" },
  { label: "OL", style: "ordered-list-item" }
  // { label: "Code Block", style: "code-block" }
];

const BlockStyleControls = props => {
  const { editorState } = props;
  const selection = editorState.getSelection();
  const blockType = editorState
    .getCurrentContent()
    .getBlockForKey(selection.getStartKey())
    .getType();

  return (
    <div className="RichEditor-controls">
      {BLOCK_TYPES.map(type => (
        <StyleButton
          key={type.label}
          active={type.style === blockType}
          label={type.label}
          onToggle={props.onToggle}
          style={type.style}
        />
      ))}
    </div>
  );
};

const INLINE_STYLES = [
  { label: "Bold", style: "BOLD" },
  { label: "Italic", style: "ITALIC" },
  { label: "Underline", style: "UNDERLINE" }
  // { label: "Monospace", style: "CODE" }
];

const InlineStyleControls = props => {
  var currentStyle = props.editorState.getCurrentInlineStyle();
  return (
    <div className="RichEditor-controls">
      {INLINE_STYLES.map(type => (
        <StyleButton
          key={type.label}
          active={currentStyle.has(type.style)}
          label={type.label}
          onToggle={props.onToggle}
          style={type.style}
        />
      ))}
    </div>
  );
};

const Draft = ({
  prefix,
  disabled,
  onBlur,
  className,
  description,
  label,
  placeholder,
  icon,
  rows,
  horizontal,
  name,
  ...others
}) => {
  delete others.user;

  if (prefix && name) {
    name = prefix + "." + name;
  }

  return (
    <Field
      disabled={disabled}
      onBlur={onBlur}
      className={className}
      description={description}
      label={label}
      placeholder={placeholder}
      icon={icon}
      rows={rows}
      horizontal={horizontal}
      name={name}
      component={MyEditorComponent}
    />
  );
};

export default Draft;
