import React, { Component } from "react";
import { connect } from "react-redux";
import OMaje from "./omaje";

import { create as createNotification } from "../CMS/containers/Notification/actions";

class Calculatrices extends Component {
  render() {
    const { user } = this.props;

    if (!user) {
      return <div>Chargement en cours de l'utilisateur</div>;
    }

    // TODO experimental - voir pour améliorer le router
    if (this.props["*"].substr(0, 5) === "omaje") {
      return <OMaje {...this.props} />;
    }

    return (
      <div>
        Problème de configuration, le type '{this.props["*"]}' n'existe pas
      </div>
    );
  }
}

const mapStateToProps = (globalState, ownProps) => {
  let props = {};

  props.user = globalState.cms.user;
  if (globalState.cms.boutiques && globalState.cms.boutiques.length > 0) {
    props.boutique = globalState.cms.boutiques[globalState.cms.indexBoutique];
  }

  props.unsubscribe = globalState.boutique.unsubscribe;

  return props;
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    createNotification: notification =>
      dispatch(createNotification(notification))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Calculatrices);
