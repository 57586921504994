import "core-js/es6/map";
import "core-js/es6/set";
import "raf/polyfill";
import "moment/locale/fr";

import React from "react";
import { mobilecheck } from "./utils/functions";
import { isMobile, isWebapp } from "./sysext/CMS/actions";
import { ThemeProvider } from "styled-components";

import ReactDOM from "react-dom";
import * as serviceWorker from "./serviceWorker";
import { Router } from "@reach/router";
import Login from "./containers/Login";
import ForgetPassword from "./containers/ForgetPassword";
import Backoffice from "./sysext/CMS/containers/Backoffice/index";
import Boutique from "./sysext/Boutique/index";
import Website from "./sysext/Website/index";
import Communication from "./sysext/Communication/index";
import Organisation from "./sysext/Gtd/index";
import Calculatrices from "./sysext/Calculatrices/index";
import Assistant from "./containers/Assistant";
import Admin from "./sysext/CMS/containers/Admin/index";
import User from "./containers/User";
import NoMatch from "./components/NoMatch";
import Provider from "react-redux/es/components/Provider";
import configureStore from "./configureStore";
import firebase from "./api/firebase";
import { isLogout, load } from "./sysext/CMS/containers/User/actions";
import "font-awesome/css/font-awesome.min.css";
import "./styles/index.scss";

let store = configureStore();

const isMobileScreen = mobilecheck();
const viewport = document.querySelector("meta[name=viewport]");
viewport.setAttribute(
  "content",
  "width=device-width, height=device-height, initial-scale=1, initial-scale=1"
);
if (mobilecheck()) {
  // viewport.setAttribute('content', 'width=device-width, height=device-height, initial-scale=1, , initial-scale=1, user-scalable=no');
  // , maximum-scale=1.0, user-scalable=0
  // setTimeout(() => { window.scrollTo(0, 1); }, 1000);
  document.documentElement.classList.remove("desktop");
  document.documentElement.classList.add("mobile");
} else {
  // viewport.setAttribute("content", "width=980");
  // viewport.setAttribute('content', 'width=980, user-scalable=no');
  document.documentElement.classList.add("desktop");
  document.documentElement.classList.remove("mobile");
}

const isWebappScreen = !!window.navigator.standalone;
if (isWebappScreen) {
  document.documentElement.classList.add("webapp");
} else {
  document.documentElement.classList.remove("webapp");
}

store.dispatch(isMobile(isMobileScreen));
store.dispatch(isWebapp(isWebappScreen));

firebase.auth().onAuthStateChanged(user => {
  if (user) {
    store.dispatch(load(user));
  } else {
    store.dispatch(isLogout());
    console.log("user is logout");
  }
});

//     <Helmet>{typography.injectStyles()}</Helmet>
ReactDOM.render(
  <ThemeProvider theme={{ mobile: isMobileScreen, webapp: isWebappScreen }}>
    <Provider store={store}>
      <Router>
        <Login path="/" />
        <Login path="login" />
        <ForgetPassword path="forgetpassword" />
        <Backoffice path="dashboard" />
        <Backoffice default>
          <Boutique path="boutique/*" />
          <Boutique path="medias/*" />
          <Website path="website/*" />
          <Communication path="communication/*" />
          <Organisation path="organisation/*" />
          <Calculatrices path="calculatrices/*" />
          <Assistant path="assistant/*" />
          <Admin path="admin/*" />
          <User path="edit" />
          <User path="password" />
          <NoMatch default />
        </Backoffice>
      </Router>
    </Provider>
  </ThemeProvider>,
  document.getElementById("root")
);

serviceWorker.unregister();
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
