import React, { Component } from "react";
import Section from "../../../../../../components/Data/Section";
import Select from "../../../../../../components/Select";
import Input from "../../../../../../components/Input";
import Medias from "../../../../../../components/Medias";

class ImagesTab extends Component {
  options = {
    mediasPosition: [
      {
        value: "above-center",
        label: "Centré au dessus"
      },
      {
        value: "below-center",
        label: "Centré en dessous"
      },
      {
        value: "beside-text-right",
        label: "A droite du texte"
      },
      {
        value: "beside-text-left",
        label: "A gauche du texte"
      },
      {
        value: "beside-text-right-centered",
        label: "Centré à droite du texte"
      },
      {
        value: "beside-text-left-centered",
        label: "Centré à gauche du texte"
      }
    ],
    mediasNbColumns: [
      {
        value: 1,
        label: "1"
      },
      {
        value: 2,
        label: "2"
      },
      {
        value: 3,
        label: "3"
      },
      {
        value: 4,
        label: "4"
      },
      {
        value: 5,
        label: "5"
      },
      {
        value: 6,
        label: "6"
      }
    ]
  };

  render() {
    const {
      data,
      boutique,
      docId,
      handleOnBlur,
      handleOnChangeField
    } = this.props;

    return (
      <div>
        <Medias
          items={data.medias ? data.medias : []}
          updateItems={value => handleOnChangeField("medias", value)}
          boutique={boutique}
          docType="page"
          id={docId}
          title="Images"
          addText="Ajouter une image"
          uploadFilesText="Déposer vos images pour les télécharger"
        />
        <Section title="Options" group={true}>
          <Select
            onChange={handleOnBlur}
            value={data.mediasPosition}
            name="mediasPosition"
            label="Position et alignement"
            options={this.options.mediasPosition}
          />
          <Select
            onChange={handleOnBlur}
            value={data.mediasNbColumns}
            label="Nombre de colonnes"
            name="mediasNbColumns"
            options={this.options.mediasNbColumns}
          />
          <Input
            type="checkbox"
            onChange={handleOnBlur}
            value={data.mediasClickToEnlarge}
            name="mediasClickToEnlarge"
            label="Agrandir"
            description="Cliquer pour agrandir"
          />
        </Section>
      </div>
    );
  }
}

export default ImagesTab;
