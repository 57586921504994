import React, { Component } from "react";
import Section from "../../../../../CMS/components/Data/Section";
import Group from "../../../../../CMS/components/Data/Group";
import StyledList from "../../../Paiement/styles/List";
import Paiement from "../../../Paiement/components/List/Item";
import Vente from "../../../../../CMS/datas/Vente";

class Paiements extends Component {
  state = {
    commandeId: undefined,
    ventes: undefined,
    unsubscribe: undefined
  };

  constructor(props) {
    super(props);

    this.loadVentes = this.loadVentes.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    if (props.id && (!state.commandeId || state.commandeId !== props.id)) {
      state.commandeId = props.id;
      state.mustBeLoaded = true;
    }

    return state;
  }

  componentDidMount() {
    if (this.state.mustBeLoaded) {
      this.loadVentes();
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.state.mustBeLoaded) {
      this.loadVentes();
    }
  }

  loadVentes() {
    if (this.state.unsubscribe) {
      this.state.unsubscribe();
    }
    const unsubscribe = Vente.watch(
      this.props.boutique,
      {
        commande: this.state.commandeId
      },
      querySnapshot => {
        this.setState({
          ventes: querySnapshot.docs
        });
      }
    );

    this.setState({
      unsubscribe,
      mustBeLoaded: false
    });
  }

  componentWillUnmount() {
    if (this.state.unsubscribe) {
      this.state.unsubscribe();
    }
  }

  render() {
    if (!this.props.id) {
      return null;
    }
    return (
      <Section
        className="boutique__commande--paiements"
        title="Liste des paiements"
      >
        {this.state.ventes && this.state.ventes.length > 0 ? (
          <StyledList>
            {this.state.ventes.map((vente, index) => (
              <Paiement doc={vente} key={index} />
            ))}
          </StyledList>
        ) : (
          <Group>
            <p style={{ margin: "1.5rem" }}>Aucun paiement</p>
          </Group>
        )}
      </Section>
    );
  }
}

export default Paiements;
