class Boutique {
  static searchFeatures(boutique, collectionName, orderBy = "datetime") {
    return boutique.ref
      .collection(collectionName)
      .where("deleted", "==", false)
      .orderBy(orderBy)
      .get()
      .then(querySnapshot => {
        return querySnapshot.docs;
      });
  }
}

export default Boutique;
