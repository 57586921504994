import React, { Component } from "react";
import PropTypes from "prop-types";
import StyledRow from "../../../../../CMS/styles/View/List/Row";
import Feature from "../../../../../CMS/datas/Feature";

class Row extends Component {
  constructor(props) {
    super(props);

    this.state = {
      mustBeLoaded: false,
      type: undefined,
      typeData: undefined
    };

    this.loadType = this.loadType.bind(this);
  }

  componentDidMount() {
    if (this.state.mustBeLoaded) {
      this.loadType(this.state.type);
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.state.mustBeLoaded) {
      this.loadType(this.state.type);
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (
      props.doc &&
      props.doc.get("type") &&
      (!state.type || state.type !== props.doc.get("type"))
    ) {
      state.mustBeLoaded = true;
      state.type = props.doc.get("type");
    }

    return state;
  }

  loadType(typeId) {
    Feature.findByTypeAndId(
      this.props.boutique,
      "fournisseurTypes",
      typeId
    ).then(snapshot => {
      this.setState({
        typeData: snapshot.data(),
        mustBeLoaded: false
      });
    }).catch(error =>{
      console.error("loadType", error.message);
    });
  }

  render() {
    const { doc, onClick } = this.props;

    const item = doc.data();

    return (
      <StyledRow>
        <p className="name" style={{ flex: 1 }}>
          {item.nom}
        </p>
        {this.state.typeData && (
          <p className="type">{this.state.typeData.libelle}</p>
        )}
        <p className="control actions">
          <button onClick={onClick} className="edit small">
            <span className="icon" />
            <span className="txt">Edit</span>
          </button>
        </p>
      </StyledRow>
    );
  }
}

Row.propTypes = {
  doc: PropTypes.object.isRequired
};

export default Row;
