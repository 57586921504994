import List from "../../../CMS/containers/Boutique/components/List";

import Item from "./Item";
import memo from "./params.json";

import { initialize, validate } from "./functions";

class MemoList extends List {
  collectionName = "memos";
  defaultOrderField = "publishedAt";
  defaultOrderDirection = "desc";
  listTitle = "Mémos";
  viewList = Item;
  itemTitle = { type: "attribut", attribut: "title" };
  initializeItem = initialize;
  validateItem = validate;
  itemParams = memo;
  messageNoDocument = "Aucun mémo";

  constructor(props) {
    super(props);

    this.state.form.newItemLibelle = "Nouveau mémo";
    this.state.form.required = ["title"];

    this.state.list.menu.left = [
      {
        text: "Ajouter",
        onClick: this.handleCreate
      }
    ];
  }
}

export default MemoList;
