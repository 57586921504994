import moment from "moment";
import * as functions from "../../../CMS/functions";

export function initialize(data, boutique) {
  const objet = functions.initialize(data);
  return {
    ...objet,
    boutique: boutique.id,
    boutiqueRef: boutique.ref,
    isVisible: true,
    publishedAt: moment().format("YYYY-MM-DDTHH:mm"), // Pour connaitre la date de publication
  };
}

export function validate(item) {
  item.updatedAt = moment().toISOString();

  return item;
}
