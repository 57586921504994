import React, { Component } from "react";
import Entry from "./Entry";

class Entries extends Component {
  state = {
    datas: null,
    fields: null
  };

  constructor(props) {
    super(props);

    this.handleDeleteEntry = this.handleDeleteEntry.bind(this);
  }

  componentWillMount() {
    const _this = this;
    const { doc } = this.props;
    doc.ref
      .collection("fields")
      .orderBy("order")
      .get()
      .then(querySnapshot => {
        _this.setState({
          fields: querySnapshot.docs
        });
        doc.ref
          .collection("datas")
          .where("deleted", "==", false)
          .orderBy("createdAt")
          .onSnapshot(function(querySnapshot) {
            _this.setState({
              datas: querySnapshot.docs
            });
          });
      });
  }

  handleDeleteEntry = (e, index) => {
    e.preventDefault();
    this.state.datas[index].ref.delete().then(() => {
      console.info("Suppression de l'enregistrement");
    });
  };

  render() {
    if (this.state.datas === null) {
      return <div>Chargement en cours</div>;
    }

    return (
      <div>
        {this.state.datas.length > 0 ? (
          this.state.datas.map((data, index) => (
            <Entry
              key={index}
              value={data}
              fields={this.state.fields}
              handleDeleteEntry={e => this.handleDeleteEntry(e, index)}
            />
          ))
        ) : (
          <div>Aucune donnée</div>
        )}
      </div>
    );
  }
}

export default Entries;
