import styled from "styled-components";

export default styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: ${props => (props.theme.mobile ? 0 : "200px")};
  background: rgba(0, 0, 0, 0.6);
  z-index: 200000;

  .dialog {
    background: #fff;
    width: 100%;
    margin: 0 auto;
    height: 100%;
    display: flex;
    flex-direction: column;

    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

    .dialog__header {
      position: relative;
      background: #34313f;
      border: 0;
      padding: 20px 20px 20px 22.5px;

      h3 {
        margin: 0;
        padding: 0;
        color: #fff;
      }
    }

    .dialog__content {
      flex: 1;
      padding: 1.5rem;
      overflow-y: auto;

      .tab {
        margin: 3rem auto;
      }

      -webkit-overflow-scrolling: touch;
    }

    .dialog__footer {
      background-color: #f6f7f9;
      border-top: 1px solid #e4e7ed;
      padding: 7px;

      ul {
        margin: 0;
        padding: 0;
        list-style: none;
        text-align: right;

        li {
          margin: 0 0 0 10px;
          padding: 0;
          display: inline-block;
        }
      }
    }
  }

  .photo {
    background: rgba(0, 0, 0, 0.8);
    width: 100vw;
    margin: 0 auto;
    height: 100vh;
    display: flex;
    flex-direction: column;
  }

  .photo-content {
    display: flex;
    align-self: center;
    align-items: center;
    height: 100vh;

    img {
      max-height: 100vh;
      max-width: 100vw;
      margin: 0;
    }
  }

  .photo-close,
  .dialog--header--close {
    position: absolute;
    border: none;
    background: none;
    margin-right: 0;
    padding: 5px;
    top: 13px;
    right: 13px;
    color: #aeadb3;
    transition: color 0.2s ease;
    outline: none;
    cursor: pointer;

    &:hover {
      color: #d7d6d9;
    }

    .text {
      display: none;
    }

    &:before {
      content: "✕";
    }
  }
`;
