import React from "react";

import Input from "./Input/index";
import Textarea from "./Textarea";
import Draft from "./Draft";
import Label from "./Label";
import Select from "./Select";
import Section from "./Section";
import Rows from "./Rows";
import Columns from "./Columns";
import Column from "./Column";
import Tabs from "./Tabs";
import Group from "./Group";
import List from "./List";
import Info from "../Information";

import Espace from "../../containers/Espace/components/Data/index";
import Boutique from "../../../Boutique/components/Data";
import Website from "../../../Website/components/Data";
import Feature from "../../containers/Feature/components/Data";
import Schema from "../../containers/Schema/components/Data";
import Content from "../../containers/Content/components/Data";

const components = {
  section: Section,
  rows: Rows,
  columns: Columns,
  column: Column,
  tabs: Tabs,
  group: Group,
  info: Info,
  textarea: Textarea,
  draft: Draft,
  label: Label,
  select: Select,
  boutique: Boutique,
  website: Website,
  espace: Espace,
  list: List,
  schema: Schema,
  feature: Feature,
  content: Content
};

const Data = props => {
  const {
    data: { component, datas },
    ...newProps
  } = props;
  const Component = component ? components[component] : Input;

  if (Array.isArray(props.data) && props.data.length > 0) {
    return (
      <div className={"cms__data"}>
        {props.data.map((data, index) => (
          <Data key={index} data={data} {...newProps} />
        ))}
      </div>
    );
  }

  return (
    <Component {...props.data} {...newProps}>
      {datas &&
        datas.map((data, index) => (
          <Data key={index} data={data} {...newProps} />
        ))}
    </Component>
  );
};

export default Data;
