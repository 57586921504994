import { connect } from "react-redux";
import List from "../../../../../CMS/containers/Boutique/components/List";
import Row from "./Row";
import service from "../../params.json";
import { initialize } from "../../functions";

class ServicesList extends List {
  collectionName = "services";
  defaultOrderField = "datetime";
  defaultOrderDirection = "desc";
  listTitle = "Services";
  viewList = Row;
  initializeItem = initialize;
  itemTitle = { type: "attribut", attribut: "name" };
  itemParams = service;
  messageNoDocument = "Aucun service";

  constructor(props) {
    super(props);

    this.state.form.newItemLibelle = "Nouvel service";
    this.state.form.required = ["name"];
  }
}

const mapStateToProps = (globalState, ownProps) => {
  const props = {};
  props.docs = globalState.boutique.docs;

  return props;
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ServicesList);
