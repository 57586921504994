import React, { Component } from "react";
import { getDomainAssetsURL } from "../../../../functions";
import defaultImage from "../../../../../../images/next-photo.svg";
import Produit from "../../../../../CMS/datas/Produit";

class Item extends Component {
  state = {
    itemId: undefined,
    mustBeLoaded: false,
    itemDoc: undefined
  };

  constructor(props) {
    super(props);
    this.loadItem = this.loadItem.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    if (props.itemId && (!state.itemId || state.itemId !== props.itemId)) {
      state.mustBeLoaded = true;
      state.itemId = props.itemId;
      state.itemDoc = undefined;
    }

    return state;
  }

  componentDidMount() {
    if (this.state.mustBeLoaded) {
      this.loadItem();
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.state.mustBeLoaded) {
      this.loadItem();
    }
  }

  loadItem() {
    this.setState({
      mustBeLoaded: false
    });

    Produit.findById(this.props.boutique, this.state.itemId).then(produit => {
      this.setState({
        itemDoc: produit
      });
    });
  }

  render() {
    if (!this.state.itemDoc) {
      return null;
    }

    const produit = this.state.itemDoc;

    switch (this.props.mode) {
      case "show":
        if (produit.get("photos") && produit.get("photos").length > 0) {
          if (this.props.showName) {
            return (
              <div>
                <figure>
                  <img
                    src={`${getDomainAssetsURL(this.props.boutique)}/${
                      produit.get("photos")[0]
                    }?h=320&w=320&c=max`}
                    alt={produit.get("name")}
                  />
                </figure>
                <h3>{produit.get("name")}</h3>
              </div>
            );
          }
          return (
            <img
              src={`${getDomainAssetsURL(this.props.boutique)}/${
                produit.get("photos")[0]
              }?h=320&w=320&c=max`}
              alt={produit.get("name")}
            />
          );
        }
        return null;

      case "edit":
        return (
          <div>
            <div className="produit">
              <figure className="aspect-ratio aspect-ratio--square">
                {produit.get("photos") ? (
                  <img
                    src={`${getDomainAssetsURL(this.props.boutique)}/${
                      produit.get("photos")[0]
                    }?h=320&w=320&c=max`}
                    alt={produit.get("name")}
                    className="aspect-ratio__content"
                  />
                ) : (
                  <img
                    src={defaultImage}
                    alt={produit.get("name")}
                    className="aspect-ratio__content"
                  />
                )}
              </figure>
              <h3>{produit.get("name")}</h3>

              <button
                data-id={this.props.itemId}
                onClick={this.props.handleRemoveProduct}
              >
                Retirer
              </button>
            </div>
          </div>
        );
      default:
    }
  }
}

export default Item;
