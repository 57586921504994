import React, { Component } from "react";
import PropTypes from "prop-types";
import { Field, change, formValueSelector } from "redux-form";
import ComponentField from "../../../../../CMS/styles/Field";
import Page from "../../../../../CMS/datas/Page";
import Url from "../../../../../CMS/datas/Url";
import { connect } from "react-redux";

class Select extends Component {
  constructor(props) {
    super(props);

    this.state = {
      boutique: undefined,
      options: []
    };

    this.handleChangeValue = this.handleChangeValue.bind(this);
    this.loadPages = this.loadPages.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    if (
      props.boutique &&
      (!state.boutique || state.boutique.id !== props.boutique.id)
    ) {
      state.mustBeLoaded = true;
      state.boutique = props.boutique;
    }

    return state;
  }

  componentDidMount() {
    if (this.state.mustBeLoaded) {
      this.loadPages(this.state.boutique);
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.state.mustBeLoaded) {
      this.loadPages(this.state.boutique);
    }
  }

  loadPages(boutique) {
    this.setState({
      mustBeLoaded: false
    });

    Page.search(boutique).then(docs => {
      // il faut retirer la page en cours
      const index = docs.findIndex(doc => doc.id === this.props.id);
      docs.splice(index, 1);

      this.setState(
        {
          options: docs
        },
        () => {
          if (this.input.value) {
            this.input.classList.remove("is-empty");
          } else {
            this.input.classList.add("is-empty");
          }

          this.input.addEventListener("focus", function(event) {
            event.currentTarget.classList.add("is-focused");
          });
          this.input.addEventListener("blur", function(event) {
            event.currentTarget.classList.remove("is-focused");
          });
          this.input.addEventListener("keyup", function(event) {
            if (event.currentTarget.value.length === 0) {
              event.currentTarget.classList.add("is-empty");
            } else {
              event.currentTarget.classList.remove("is-empty");
            }
          });
        }
      );
    });
  }

  handleChangeValue = event => {
    if (this.props.shouldUpdateUrl) {
      Url.clear(
        this.props.boutique,
        this.props.libelle,
        this.props.urlType,
        this.props.id,
        event.currentTarget.value
      );
    }
    this.props.meta.dispatch(
      change("item", this.props.input.name, event.currentTarget.value)
    );
  };

  render() {
    const {
      input: { name, value },
      label,
      horizontal,
      className,
      meta: { touched, error }
    } = this.props;

    return (
      <ComponentField
        className={
          "field select " +
          (touched && error ? "error " : "") +
          (horizontal ? "horizontal " : "") +
          (className ? className : "")
        }
      >
        {this.state.options && this.state.options.length > 0 ? (
          <select
            name={name}
            value={value}
            ref={input => {
              this.input = input;
            }}
            onChange={this.handleChangeValue}
          >
            <option value="">Non spécifié</option>
            {this.state.options.map((item, index) => {
              return (
                <option key={index} value={item.id}>
                  {item.get("pathname")} - {item.get("name")}
                </option>
              );
            })}
          </select>
        ) : (
          <div>...</div>
        )}
        <label className="label">
          <span>{label}</span>
        </label>
        {touched &&
          error && (
            <div className={"error error_" + error.code}>
              <span className="icon">
                <i className="fa fa-exclamation" />
              </span>
              <span>{error.message}</span>
            </div>
          )}
      </ComponentField>
    );
  }
}

Select.propTypes = {
  label: PropTypes.string,
  shouldUpdateUrl: PropTypes.bool.isRequired
};

const selector = formValueSelector("item");

export default connect((globalState, ownProps) => {
  return {
    libelle: selector(globalState, ownProps.urlFieldLibelle)
  };
})(props => <Field {...props} component={Select} />);
